import './style.scss';
import * as React from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { ConfirmationModal } from '../../../Modals/ConfirmationModal';
// import Select, { Option } from 'rc-select';
import { User, Users } from 'Interfaces/User';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { ActionTransmitInterface } from 'Interfaces/ActionTransmitInterface';
import { toast } from 'react-toastify';
import { validEmail } from '../../../../utils/utils';
import { getLocalization } from '../../../../global/global';
import Select from 'react-dropdown-select';
import { useState } from 'react';

interface Props {
  users: Users;
  clientPersist: ClientPersistInterface;
  link: string;
  questionId: string;
  tableFormId: string;
  id: string;
  getSharerEmail: (id: string, signal: AbortSignal) => Promise<Response>;
  sendActionEmail: (actionTransmit: ActionTransmitInterface, signal: AbortSignal) => Promise<Response>;
}

let abortController = new AbortController();

const Transmit = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState<Users>([]);
  const [unregisteredUsers, setUnregisteredUsers] = useState('');
  const [accessUntil, setAccessUntil] = useState('');
  const [accessUntilVisible, setAccessUntilVisisble] = React.useState(true);
  const [groupError, setGroupError] = React.useState<string[]>([]);
  const [hasError, setHasError] = React.useState(false);
  const [emailBody, setEmailBody] = React.useState('');
  const [fixedEmail, setFixedEmail] = React.useState('');
  const [width, setWidth] = React.useState('');

  const { onlyAccessSharedData } = props.clientPersist;

  React.useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [props]);

  const closeModal = () => {
    setValue([]);
    setShowModal(false);
    setWidth('');
  };

  React.useEffect(() => {
    if (props.id && onlyAccessSharedData === true) {
      if (abortController === null) {
        abortController = new AbortController();
      }
      props.getSharerEmail(props.id, abortController.signal).then(
        response => response.json()
      ).then(json => {
        if (json.email && validEmail(json.email)) {
          setFixedEmail(json.email);
          setValue(props.users.filter(u => u.name === json.userName));
          setAccessUntilVisisble(false);
        } else if (json.userName && validEmail(json.userName)) {
          setFixedEmail(json.userName);
          setValue(props.users.filter(u => u.name === json.userName));
          setAccessUntilVisisble(false);
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }, [props.id]);

  const handleOnChange = (value) => {
    setValue(value);
  };

  const users = React.useMemo(() => {
    return props.users.filter(u => !u.name.endsWith('-deleted'));
  }, [props.users]);

  const getModalBody = () => {
    return (
      <div>
        {groupError.length > 0 && (
          <Alert variant={'warning'}>
            User(s) {groupError.join(', ')} are in a different group,
            contact admin to change the user to be in the same group as yourself.
          </Alert>
        )}
        <Form.Group>
          <Form.Label>Send to</Form.Label>
          <div className={'w-100 position-relative'}>
            {fixedEmail !== '' ? (
              <span>{fixedEmail}</span>
            ) : width !== '' && (
              <Select
                labelField={'name'}
                valueField={'id'}
                options={users}
                onChange={(v) => handleOnChange(v)}
                values={value}
                style={{}}
                searchable={true}
                searchBy={'name'}
              />
            )}
          </div>
        </Form.Group>
        {props.clientPersist.shareDataUnregisteredUsers && fixedEmail === '' && (
          <>
            <Form.Group>
              <Form.Label>Share with unregistered user :</Form.Label>
              <Form.Control
                name={'emailAddress'}
                type={'input'}
                className={'form-control-sm'}
                placeholder={'Email address of unregistered user'}
                value={unregisteredUsers}
                isInvalid={hasError && !validEmail(unregisteredUsers)}
                onChange={(e) => setUnregisteredUsers(e.target.value)}
              />
            </Form.Group>
          </>
        )}
        {accessUntilVisible && (
          <div className={`form-group`}>
            <label>{getLocalization('accessUntil')}</label>
            <input
              className={`form-control form-control-sm ${accessUntil === '' && hasError ? 'is-invalid' : ''}`}
              type="date"
              name="emailBody"
              value={accessUntil}
              onChange={(e) => setAccessUntil(e.target.value)}
            />
          </div>
        )}
        <Form.Group>
          <Form.Label>Message :</Form.Label>
          <Form.Control
            name={'emailBody'}
            as={'textarea'}
            className={'form-control-sm'}
            placeholder={'Message'}
            value={emailBody}
            rows={3}
            // @ts-ignore
            onChange={(e) => setEmailBody(e.target.value)}
          />
        </Form.Group>
      </div>
    );
  };

  const transmit = () => {
    if (unregisteredUsers !== '' && !validEmail(unregisteredUsers)) {
      setHasError(true);
      return;
    }
    if (value.length > 0 || unregisteredUsers.length > 0) {
      const nonGroup: string[] = [];
      value.forEach(v => {
        if (`${v.groupId}` !== `${props.clientPersist.groupId}`) {
          nonGroup.push(v.name);
        }
      });

      if (nonGroup.length > 0) {
        setGroupError(nonGroup);
        return;
      }
      abortController = new AbortController();
      const toastId = Date.now() + Math.floor(Math.random() * 100);
      toast('Sending...', {
        toastId,
        type: toast.TYPE.INFO,
        autoClose: false
      });
      props.sendActionEmail({
          userIds: value.map((u: User) => `${u.id}`),
          url: props.link,
          unregisteredUsers,
          questionId: props.questionId,
          tableFormId: props.tableFormId,
          accessUntil,
          emailBody
      }, abortController.signal)
      .then(response => response.json()).then(json => {
        if (json.status === 'OK') {
          closeModal();
          setShowModal(false);
          setValue([]);
          toast.update(toastId, {
            type: toast.TYPE.SUCCESS,
            render: 'Sent.'
          });
        } else {
          toast.update(toastId, {
            type: toast.TYPE.ERROR,
            render: 'Unable to send. Check that the user email address is valid.'
          });
        }
        setTimeout(() => toast.dismiss(toastId), 3000);
      }).catch(err => console.error(err));
      setGroupError(nonGroup);
    }
  };

  const onEntered = () => {
    setWidth('200px');
  };

  const modal = showModal ? (
    <ConfirmationModal
      onConfirm={transmit}
      onClose={() => closeModal()}
      localizations={{
        cancel: 'Cancel',
        confirm: 'Send',
        confirmStyle: 'success',
        header: (
          <label>Transmit</label>
        ),
        body: getModalBody()
      }}
      onEntered={onEntered}
    />
  ) : null;

  return (
    <React.Fragment>
      {modal}
      <Button
        size={'sm'}
        onClick={() => setShowModal(true)}
        className={'float-right'}
      >
        {`Transmit`}
      </Button>
    </React.Fragment>
  );
};

export default Transmit;
