import './GalleryContainer.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import Viewer from 'react-viewer';
import { getLocalization, globalWindow } from '../../global/global';
import { GalleryItemComponent } from './GalleryItemComponent';
import { DataPoint, FileInterface } from '../../Interfaces/DataPoint';
import FormUtils from '../SingleInstance/utils/FormUtils';
import { FormInterface, FormsInterface } from '../../Interfaces/Forms/FormsInterface';
import { GalleryMenuInterface } from '../../Interfaces/GalleryMenuInterface';
import { SingleInstance } from '../../Interfaces/ModuleSelectionInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import { formsSelector } from '../../reducers/formsReducer';
import { getLocationHierarchyQuerySelector } from '../../reducers/filtersMenuReducer';
import { galleryMenuSelector, selectAnswersForGallerySelectedForm } from '../../reducers/galleryMenuReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'typescript-fsa';
import { fetchGalleryPOIs, savePOI } from '../../actions/pois';
import { setSingleInstance } from '../../actions/moduleSelectionActions';
import { connect } from 'react-redux';
import { questionTypes } from '../../Interfaces/Forms/QuestionInterface';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import { ModalComponentNames, ModalInterface } from '../../Interfaces/ModalInterface';
import { navigateAddModal, navigateRemoveModal } from '../../actions/navigationAction';
import { LoadingComponent } from '../components/LoadingComponent';
import { Alert } from 'react-bootstrap';
import { getFormUtils } from '../SingleInstance/utils/FormUtilsHolder';

const className = 'GalleryContainer';

interface IStateProps {
  forms: FormsInterface['collection'];
  galleryMenu: GalleryMenuInterface;
  locationHierarchyQuery: ReturnType<typeof getLocationHierarchyQuerySelector>;
  answers: DataPoint[];
}

interface IActionProps {
  fetchPOI: (
      subFormIds: string[], requestParams?: string[]) => Promise<Response>;
  setSingleInstance: (singleInstance: SingleInstance) => void;
  navigateAddModal: (modal: ModalInterface) => void;
  navigateRemoveModal: (modalName: ModalComponentNames) => void;
  savePOI: (dataPoint: DataPoint) => void;
}

interface IGalleryItem {
  file: FileInterface;
  dataPoint: DataPoint;
  form?: FormInterface;
}

export interface IOwnState {
  offset: number;
  showViewer: boolean;
  activeIndex: number;
  subFormIds: string[];
  viewerImages: ImageDecorator[];
  galleryItems: IGalleryItem[];
  cacheGalleryItems: IGalleryItem[];
  loading: boolean;
  loaded: boolean;
}

export type GalleryContainerProps = IActionProps & IStateProps;

export class GalleryContainerClass extends React.Component<GalleryContainerProps, IOwnState> {
  constructor(props) {
    super(props);
    this.state = {
      offset: props.answers.length,
      showViewer: false,
      activeIndex: -1,
      subFormIds: [],
      viewerImages: [],
      galleryItems: [],
      cacheGalleryItems: [],
      loading: false,
      loaded: false
    };
  }

  public componentDidMount() {
    this.checkForSubForms();
    this.getGalleryItems();
  }

  public componentDidUpdate(prevProps: GalleryContainerProps, prevState: IOwnState) {
    if (this.props.galleryMenu.selectedForm !== prevProps.galleryMenu.selectedForm) {
      this.checkForSubForms();
    } else if (
        this.props.locationHierarchyQuery !== prevProps.locationHierarchyQuery
        || this.state.subFormIds !== prevState.subFormIds
    ) {
      this.setState({
        offset: 0,
        loading: false,
        galleryItems: []
      }, () => this.loadGallery());
      // this.loadGallery();
    }

    if (this.props.answers !== prevProps.answers && this.props.answers.length > prevProps.answers.length) {
      const newAnswers = this.props.answers.slice(this.state.offset);
      let newGalleryItems: IGalleryItem[] = [];
      newAnswers.forEach((dataPoint) => {
        newGalleryItems = [
          ...newGalleryItems,
          ...this.getImages(dataPoint)
        ];
      });
      const length = this.state.galleryItems.length > 0 ?
        this.state.galleryItems.length : this.state.cacheGalleryItems.length;
      const loaded = (length + newGalleryItems.length) % 40;
      // We have 0 images so fetch the next batch.
      if (newGalleryItems.length === 0 || (loaded < 35) || length < 40) {
        this.setState({
          offset: this.props.answers.length,
          loading: false,
          cacheGalleryItems: [...this.state.cacheGalleryItems, ...newGalleryItems]
        }, () => this.loadGallery());
      } else {
        this.setState({
          offset: this.props.answers.length,
          loading: false,
          cacheGalleryItems: [],
          galleryItems: this.state.galleryItems.concat([...this.state.cacheGalleryItems, ...newGalleryItems])
        });
      }
      // this.getGalleryItems();
    }
    if (this.state.galleryItems !== prevState.galleryItems) {
      this.updateViewerImages();
    }
  }

  private checkForSubForms() {
    const model = this.props.forms.find(f => f.ref === this.props.galleryMenu.selectedForm);
    if (model) {
      const formUtils = new FormUtils(model);
      const subFormIds = formUtils.getSubFormQuestionIds();
      this.setState({
        subFormIds,
        viewerImages: [],
        galleryItems: [],
        cacheGalleryItems: [],
        offset: this.props.answers.length
      });
    } else {
      this.setState({
        subFormIds: [],
        viewerImages: [],
        galleryItems: [],
        cacheGalleryItems: [],
        offset: this.props.answers.length
      });
    }
  }

  private loadGallery() {
    const { galleryMenu: {selectedForm} } = this.props;
    const { subFormIds } = this.state;
    if (this.state.loading) {
      return;
    }
    if (selectedForm) {
      if (this.props.answers.length > 0) {
        // Check if the offset has gone over the total number of POIs available.
        const total = this.props.answers[0].totalCount;
        if (total && total <= this.state.offset) {
          if (this.state.cacheGalleryItems.length > 0) {
            this.setState({
              cacheGalleryItems: [],
              galleryItems: this.state.galleryItems.concat(this.state.cacheGalleryItems)
            });
          }
          return;
        }
      }
      const params = [
        `off=${this.state.offset}`, 'limit=30'
      ];
      const fetchPromise = this.props.fetchPOI(
          subFormIds,
          params
      );
      this.setState({ loading: true, loaded: false });
      fetchPromise.then(response => response.json()).then(json => {
        console.log('Chart loaded.');
        if (json.loaded) {
          this.setState({ loading: false, loaded: true });
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }

  @bind
  private onScroll(e) {
    // const st = e.target.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    // we are at the bottom
    if (e.target.scrollTop + 600 >= e.target.scrollHeight) {
      // this.setState((prevState, props) => ({ loading: true }));
      this.loadGallery();
    }
    // this.lastScrollTop = st <= 0 ? 0 : st;
  }

  @bind
  private hideViewer() {
    this.setState({ showViewer: false });
  }

  @bind
  private showViewer(id: number) {
    this.setState({
      showViewer: true,
      activeIndex: id
    });
  }

  @bind
  private deleteImage(fileToBeDeleted: FileInterface, dataPoint: DataPoint) {
    // const resp = extractFile([...this.props.answers], file.id);
    // console.log(resp);
    const files = dataPoint.files?.filter((file) => file.id !== fileToBeDeleted.id);
    if (files) {
      const dataPointWithoutFileToBeDeleted = {...dataPoint, files};
      const galleryItems = this.state.galleryItems.filter((item) => {
        return item.file.id !== fileToBeDeleted.id;
      });
      this.props.savePOI(dataPointWithoutFileToBeDeleted);
      this.setState({galleryItems});
      /*, (rowId) => {
        // TODO updating values using callbacks can cause memory leaks and inconsistencies
        // This needs to be fixed when the savePOI action is fixed not to use callbacks
        this.setState({galleryItems});
      });*/
    }
  }

  @bind
  private confirmDeleteImage(file: FileInterface, dataPoint: DataPoint) {
    this.props.navigateAddModal({
      component: ModalComponentNames.ConfirmationModal,
      props: {
        onClose: () => this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal),
        onConfirm: () => {
          this.deleteImage(file, dataPoint);
          this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal);
        },
        localizations: {
          body: (
              <div>
                <p>{getLocalization('galleryDeleteAlert')}</p>
              </div>
          ),
          cancel: getLocalization('cancel'),
          confirm: getLocalization('deleteData'),
          confirmStyle: 'danger',
          header: getLocalization('confirm')
        }
      }
    });
  }

  /**
   *
   * @param dataPoint - the data point with the images
   * @param parentDataPoint - the parent datapoint if any. we need this so that we open the main form instead of
   *                           subform when the image is clicked.
   */
  private getImagesFromDataPointFiles(dataPoint: DataPoint, parentDataPoint?: DataPoint): IGalleryItem[] {
    const {forms} = this.props;
    let galleryItems: IGalleryItem[] = [];
    if (dataPoint.files) {
      for (const file of dataPoint.files) {
        const form = forms.find(f => f.ref === dataPoint.questionnaire_id);
        if (file.mimeType.includes('image')) {
          if (file.questionId) {
            if (file.questionId.toLowerCase() === 'backgrounddiagram') {
              // Ignore this file and its image
              continue;
            }
            if (form) {
              const formUtils = getFormUtils(form);
              const question = formUtils.getQuestion(file.questionId);
              if (question && question.type === questionTypes.DIGITAL_SIGNATURE_QUESTION) {
                // Ignore this file and its image
                continue;
              }
            }
          }
          const dp = parentDataPoint ? parentDataPoint : dataPoint;
          const galleryForm = parentDataPoint ? forms.find(f => f.ref === parentDataPoint.questionnaire_id) : form;
          galleryItems = [
            ...galleryItems,
            {file, dataPoint: dp, form: galleryForm}
          ];
        }
      }
    }
    return galleryItems;
  }

  private getImagesFromSubForms(dataPoint: DataPoint): IGalleryItem[] {
    const {subFormIds} = this.state;
    let galleryItems: IGalleryItem[] = [];
    if (subFormIds.length !== 0) {
      subFormIds.forEach((questionId) => {
        const subFormAnswerList = dataPoint[questionId] as DataPoint[];
        if (subFormAnswerList && subFormAnswerList.length !== 0) {
          for (const subFormAnswer of subFormAnswerList) {
            galleryItems = [
              ...galleryItems,
              ...this.getImagesFromDataPointFiles(subFormAnswer, dataPoint)
            ];
          }
        }
      });
    }
    return galleryItems;
  }

  private getImages(dataPoint: DataPoint): IGalleryItem[] {
    return [
      ...this.getImagesFromDataPointFiles(dataPoint),
      ...this.getImagesFromSubForms(dataPoint)
    ];
  }

  private updateViewerImages() {
    const viewerImages: IOwnState['viewerImages'] = this.state.galleryItems.map((item): ImageDecorator => {
      return {
        src: item.file.url ? `${item.file.url}?userid=${globalWindow.userName}&key=${globalWindow.pwrd}` : '',
        alt: item.file.fileName
      };
    });
    this.setState({viewerImages});
  }

  private getGalleryItems() {
    let galleryItems: IGalleryItem[] = [];
    this.props.answers.forEach((dataPoint) => {
      galleryItems = [
        ...galleryItems,
        ...this.getImages(dataPoint)
      ];
    });
    this.setState({galleryItems});
  }

  private renderGalleryItems(): JSX.Element[] {
    return this.state.galleryItems.map((item, index) => {
      return (
          <GalleryItemComponent
              key={item.file.id}
              file={item.file}
              imageIndex={index}
              dataPoint={item.dataPoint}
              confirmDelete={this.confirmDeleteImage}
              setSingleInstance={this.props.setSingleInstance}
              form={item.form}
              showViewer={this.showViewer}
          />
      );
    });
  }

  private getEndMessage(): JSX.Element | null {
    if (this.props.answers.length > 0) {
      // Check if the offset has gone over the total number of POIs available.
      const total = this.props.answers[0].totalCount;
      if (total && total <= this.state.offset) {
        return (
          <Alert variant={'primary'}>
            {this.state.galleryItems.length === 0 ? (
              <span>No images based on selected filters.</span>
            ) : (
              <span>All images have been loaded.</span>
            )}
          </Alert>
        );
      }
    } else {
      if (this.props.answers.length === 0 && this.state.loaded) {
        return (
          <Alert variant={'primary'}>
            <span>No images based on selected filters.</span>
          </Alert>
        );
      }
    }
    return null;
  }

  public render(): JSX.Element {
    return (
      <div className={className} onScroll={this.onScroll}>
        <Viewer
            className={`${className}-viewer`}
            visible={this.state.showViewer}
            onClose={this.hideViewer}
            images={this.state.viewerImages}
            activeIndex={this.state.activeIndex}
        />
        {this.renderGalleryItems()}
        {this.state.loading && (<LoadingComponent />)}
        {this.getEndMessage()}
      </div>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  const galleryMenu = galleryMenuSelector(state);
  return {
    forms: formsSelector(state).collection,
    galleryMenu,
    locationHierarchyQuery: getLocationHierarchyQuerySelector(state),
    answers: selectAnswersForGallerySelectedForm(state)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): IActionProps => {
  return {
    fetchPOI: (
        subFormIds, requestParams?
    ) => {
      return dispatch(fetchGalleryPOIs(subFormIds, requestParams));
    },
    setSingleInstance: (singleInstance) => dispatch(setSingleInstance(singleInstance)),
    navigateAddModal: (modal: ModalInterface) => {
      dispatch(navigateAddModal(modal));
    },
    navigateRemoveModal: (modalName: ModalComponentNames) => {
      dispatch(navigateRemoveModal(modalName));
    },
    savePOI: (dataPoint: DataPoint) => {
      dispatch(savePOI(dataPoint));
    }
  };
};

export const GalleryContainer = connect<IStateProps, IActionProps, {}, StateInterface>(
    mapStateToProps, mapDispatchToProps
)(GalleryContainerClass);

export default GalleryContainer;
