import { globalWindow } from '../../../global/global';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';

/*
  Check if user can export data point.
*/
export const canExport = (clientPersist: ClientPersistInterface, model): boolean => {
  if (clientPersist.roles.indexOf('enumerator') !== -1 || clientPersist.roles.indexOf('viewer') !== -1
    || model.type === 'TASKFORM') {
    return false;
  }
  return true;
};

export const canDelete = (dataPoint, clientPersist: ClientPersistInterface): boolean => {
  if (Number(clientPersist.user_id) !== dataPoint.createdBy && globalWindow.editOnlyOwn
    && dataPoint.user_id !== Number(clientPersist.user_id)) {
    return false;
  }
  if (!globalWindow.canDelete) {
    return false;
  }
  if (clientPersist.roles.indexOf('viewer') !== -1) {
    return false;
  }
  return true;
};

export const canShare = (clientPersist: ClientPersistInterface): boolean => {
  const { onlyAccessSharedData, roles } = clientPersist;
  if (!onlyAccessSharedData && (roles.indexOf('enumerator') !== -1 || roles.indexOf('viewer') !== -1)) {
    return false;
  }
  return true;
};

export const canViewReport = (clientPersist: ClientPersistInterface): boolean => {
  if (clientPersist.roles.indexOf('enumerator') !== -1 || clientPersist.roles.indexOf('viewer') !== -1) {
    return false;
  }
  return true;
};

export const canCopy = (model): boolean => {
  if (model.includeStatus === false && model.type !== 'TASKFORM') {
    return false;
  }
  if (model.type === 'TASKFORM') {
    return false;
  }
  return true;
};

/**
 * Checks whether a user can view a specific question.
 */
export const canView = (question, clientPersist: ClientPersistInterface): boolean => {
  const roles = clientPersist.roles;
  if (!question.view || question.view === '' || roles.indexOf('admin') !== -1 || roles.indexOf('modeler') !== -1) {
    return true;
  }
  const questionRoles = question.view.split(',');
  if (questionRoles.indexOf(roles) !== -1) {
    return true;
  }
  return false;
};

/**
 * Checks whether a user can edit a question.
 */
export const canEdit = (question, clientPersist: ClientPersistInterface, disabled?: boolean): boolean => {
  if (disabled) {
    return false;
  }
  if (clientPersist.roles.indexOf('viewer') !== -1) {
    return false;
  }
  const roles = clientPersist.roles;
  if (roles.indexOf('admin') !== -1 || roles.indexOf('modeler') !== -1 || !question.edit || question.edit === '') {
    return true;
  }
  if (question.edit && question.edit !== '') {
    const questionRoles = question.edit.split(',');
    if (questionRoles.indexOf(roles) !== -1) {
      return true;
    }
  }
  return false;
};
