/*
  Represents the Single Instance answer model.
  This triggers updates to skip,calculated & lookup values.
*/
import { UPDATE_ANSWER } from '../actions/actionTypes';

const singleInstanceAnswer = (state = {} , action) => {
  switch (action.type) {
    case UPDATE_ANSWER:
      if (action.answer) {
        return Object.assign({}, state, action.answer);
      }
      return state;
    default:
      return state; //
  }
};

export default singleInstanceAnswer;
