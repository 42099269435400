import { getRequestConfig } from './index';

export const doGetFileUrl = (fileId: string) => {
  const fileHeaders = {};
  fileHeaders['Content-Type'] = 'text';
  const headers = getRequestConfig(fileHeaders);
  delete headers['Accept'];
  return fetch('/json/file/' + fileId, headers);
};

export const doRotateImage = (angle: string, fileId: number) => {
  const fileHeaders = {};
  const headers = getRequestConfig(fileHeaders);
  return fetch(`/json/app/rotate/${angle}/${fileId}?version=2`, Object.assign({}, headers, { method: 'POST' }));
};
