import { WindowInterface } from '../Interfaces/WindowInterface';
import moment from 'moment';

export const globalWindow: WindowInterface = (window as any);

export const getLocalization = (key: string): string => {
  const translation = globalWindow['messages'][key];
  return Boolean(translation) ? translation : `[${key}]`;
};
globalWindow.moment = moment;
globalWindow.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const globalPoimapper = globalWindow.poimapper;

globalWindow.setAuthorized = () => {
  if (window['ReactAppBridge']) {
    window['ReactAppBridge'].ReduxActions.updateClientPersist({ googleAccessToken: true });
  }
};
