import { initPolyfill } from '../utils/polyfills';
initPolyfill();
require('es6-promise/auto');
import { AJAX_CALL, UPDATE_ALERT, UPDATE_ANSWER, UPDATE_CONFIRM, UPDATE_PROGRESS } from './actionTypes';
import { AlertInterface } from '../Interfaces/AlertInterface';
import { ConfirmInterface } from '../Interfaces/ConfirmInterface';
import actionCreatorFactory, { Action, ActionCreator, AnyAction } from 'typescript-fsa';
import { StateInterface } from '../Interfaces/StateInterface';
import { ThunkDispatch } from 'redux-thunk';

export type ThunkDispatchAction = ThunkDispatch<StateInterface, any, AnyAction>;

export type AsyncActionCreator<T> = ActionCreator<() => Promise<T>>;

export type AsyncAction<T> = Action<() => Promise<T>>;

export const actionCreator = actionCreatorFactory();

export type ErrorActionCreator<ErrorType> = (error: ErrorType) => Action<ErrorType>;

// This is a helper function to create async actions that returns a promise when making an api request
export function actionCreatorAsync<T, E = {}>(
    type: string,
    handler: () => Promise<T>,
    errorActionCreator?: ErrorActionCreator<E>):
AsyncAction<T> {
  const asyncCreator: AsyncActionCreator<T> = Object.assign(
      (payload: () => Promise<T>) => ({type, payload, meta: {async: true, errorActionCreator}}),
      {type, match: (action: AnyAction): action is Action<() => Promise<T>> => true}
  );
  return asyncCreator(handler);
}

export const updateProgress = (progress) => (
  { type : UPDATE_PROGRESS, progress }
);

export const showAlert = (alert: AlertInterface) => (
  { type : UPDATE_ALERT, alert }
);

export const showConfirm = (confirm: ConfirmInterface) => (
  { type : UPDATE_CONFIRM, confirm }
);

export const updateAnswer = (answer) => (
  { type : UPDATE_ANSWER, answer }
);

export const beginAjaxCall = actionCreator(AJAX_CALL.BEGIN);
export const ajaxCallError = actionCreator(AJAX_CALL.ERROR);
export const endAjaxCall = actionCreator(AJAX_CALL.END);
