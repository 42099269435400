import * as React from 'react';
import { connect } from 'react-redux';
import MapComponent from './MapComponent';
import { StateInterface } from '../../Interfaces/StateInterface';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { SingleInstance } from '../../Interfaces/ModuleSelectionInterface';
import { DataPoint } from '../../Interfaces/DataPoint';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { getTileMapResource } from '../../actions/diagrams';
import { deletePOI, fetchMapData, fetchPOI } from '../../actions/pois';
import { setSingleInstance } from '../../actions/moduleSelectionActions';
import { HierarchyInterface } from '../../Interfaces/HierarchyInterface';
import { addPoiFormsWithGpsSelector, uniqueFormsSelector } from '../../reducers/formsReducer';
import { GroupDiagrams } from 'Interfaces/GroupDiagram';
import { reverseGeoCode } from '../../actions/reverseGeoCode';
import { LooseObject } from 'Interfaces/LooseObject';

interface Props {
  id: string;
  onFeatureAdded?: ((e: any) => void);
  dataPoint?: DataPoint;
  diagramName?: string; // when we have a diagram name then we use this as the background map.
  propsDataPoints?: DataPoint[]; // Datapoint to be rendered on the map passed as props.
}

export interface ActionProps {
  getTileMapResource: (diagramName: string, callBack: (maxZoom: number, bounds: number[] | null) => void) => void;
  fetchMapData: (signal: AbortSignal) => Promise<Response>;
  fetchPOI: (formId: string, signal: AbortSignal, rowId?: number) => Promise<Response>;
  setSingleInstance: (singleInstance: SingleInstance) => void;
  deletePOI: (rowId: number, id: string, formId: string, callBack: () => void) => void;
  reverseGeoCode: (lat: number, lng: number, callBack: (geoCodedData: LooseObject) => void) => void;
}

export interface StateProps {
  dataPoints: DataPoint[];
  forms: FormInterface[];
  addPoiForms: FormInterface[];
  filtersMenu: FiltersMenuInterface;
  clientPersist: ClientPersistInterface;
  locationHierarchy: HierarchyInterface;
  groupDiagrams: GroupDiagrams;
}

export type MapPropsInterface = Props & ActionProps & StateProps;

const MapContainer = (props: MapPropsInterface) => {
  return (
    <MapComponent {...props}/>
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    dataPoints: filterSelectedFormPOIs(state.filtersMenu, state.answers),
    forms: uniqueFormsSelector(state),
    addPoiForms: addPoiFormsWithGpsSelector(state),
    clientPersist: state.clientPersist,
    filtersMenu: state.filtersMenu,
    locationHierarchy: state.hierarchy,
    groupDiagrams: state.groupDiagrams
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    getTileMapResource: (diagramName: string, callBack: (maxZoom: number, bounds: number[] | null) => void) => {
      dispatch(getTileMapResource(diagramName, callBack));
    },
    fetchMapData: (signal)  => {
      return dispatch(fetchMapData(signal));
    },
    fetchPOI: (formId: string, signal: AbortSignal, rowId?: number) => {
      return dispatch(fetchPOI(formId, signal, rowId));
    },
    setSingleInstance: (singelInstance) => dispatch(setSingleInstance(singelInstance)),
    deletePOI: (rowId: number, id: string, formId: string, callBack: () => void) => {
      dispatch(deletePOI(rowId, id, formId, callBack));
    },
    reverseGeoCode: (lat: number, lng: number, callBack: (geoCodedData: LooseObject) => void) => {
      dispatch(reverseGeoCode(lat, lng, callBack));
    }
  };
};

const filterSelectedFormPOIs = (filtersMenu: FiltersMenuInterface, answers) => {
  const selectedForms = filtersMenu.selectedForms;
  let dataPoints = [];
  for (const form of selectedForms) {
    if (answers[form.ref]) {
      dataPoints = dataPoints.concat(answers[form.ref]);
    }
  }
  return dataPoints;
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
