
// var blockquote, header, olList, para, ulList, underline;
const para = (text, line) => {
  let trimmed;
  trimmed = line.trim();
  if (/^<\/?(ul|ol|li|h|p|bl)/i.test(trimmed)) {
    return '\n' + line + '\n';
  }
  return '\n<p>' + trimmed + '</p>\n';
};
/*
const ulList = (text, item) => {
  return '\n<ul>\n\u0009<li>' + item.trim() + '</li>\n</ul>';
};
const olList = (text, item) => {
  return '\n<ol>\n\u0009<li>' + item.trim() + '</li>\n</ol>';
}; */
const blockquote = (text, tmp, item) => {
  return '\n<blockquote>' + item.trim() + '</blockquote>';
};
const header = (text, p1, p2, p3, offset, str) => {
  let level;
  level = p1.length;
  if (level > 0 && offset.startsWith('#')) {
    return '<h' + level + '>' + p2.trim() + '</h' + level + '>';
  }
  return offset;
};
const underline = (text, chars, content) => {
  return '<u>' + content.trim() + '</u>';
};
const rules = [
  {
    regex: /(#+)(.*)/g,
    replacement: header
  }, {
    regex: /(---+)(.*)/g,
    replacement: underline
  }, {
    regex: /!\[([^\[]+)\]\(([^\)]+)\)/g,
    replacement: '<img src="$2" alt="$1">'
  }, {
    regex: /\[([^\[]+)\]\(([^\)]+)\)/g,
    replacement: '<a href="$2" target="_blank">$1</a>'
  }, {
    regex: /(\*\*|__)(.*?)\1/g,
    replacement: '<strong>$2</strong>'
  }, {
    regex: /(\*|_)(.*?)\1/g,
    replacement: '<em>$2</em>'
  }, {
    regex: /\~\~(.*?)\~\~/g,
    replacement: '<del>$1</del>'
  }, {
    regex: /\:\"(.*?)\"\:/g,
    replacement: '<q>$1</q>'
  }, {
    regex: /`(.*?)`/g,
    replacement: '<code>$1</code>'
  }, {
    regex: /\n(&gt;|\>)(.*)/g,
    replacement: blockquote
  }, {
    regex: /\n-{5,}/g,
    replacement: '\n<hr />'
  }, {
    regex: /\n([^\n]+)\n/g,
    replacement: para
  }, {
    regex: /<\/ul>\s?<ul>/g,
    replacement: ''
  }, {
    regex: /<\/ol>\s?<ol>/g,
    replacement: ''
  }, {
    regex: /<\/blockquote><blockquote>/g,
    replacement: '\n'
  }
];

/*
const addRule = (regex, replacement) => {
  regex.global = true;
  regex.multiline = false;
  rules.push({
    regex: regex,
    replacement: replacement
  });
};*/

export const  Slimdown = (text) => {
  // text = '\n' + text + '\n';
  rules.forEach((rule) => {
    text = text.replace(rule.regex, rule.replacement);
  });
  return text.trim();
};
