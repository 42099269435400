import * as React from 'react';
import bind from 'bind-decorator';
import { FormInterface } from '../../../Interfaces/Forms/FormsInterface';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import { Dropdown } from 'react-bootstrap';
import { getLocalization } from '../../../global/global';
import ImportMapComponent from './ImportMapComponent';
import ImportDataComponent from './ImportDataComponent';
import GenericModal from '../../Modals/GenericModal';
import { ExcelData } from 'Interfaces/ImportDataInterfaces';

interface Props {
  forms: FormInterface[];
  clientPersist: ClientPersistInterface;
  uploadMapImport: (file, diagramName, srs) => void;
  loadImportMappings: (formIds: string[]) => void;
  importMappings: LooseObject;
  uploadDataImport: (file: File, mappings: LooseObject, formId: string, geocode: boolean) => void;
  uploadImportExcelFile: (file: File, signal: AbortSignal) => Promise<Response>;
  importData: (excelData: ExcelData) => void;
}

interface State {
  showModal: false;

}

export default class ImportMenuComponent extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  @bind
  private showImportModal(visible) {
    this.setState({ showModal: visible });
  }

  @bind
  private importData() {
    console.log('import data');
  }

  @bind
  private getModalContent() {
    return (
      <div />
    );
  }

  public render(): JSX.Element {
    const modal = this.state.showModal ? (
      <GenericModal
        visible={this.state.showModal}
        body={this.getModalContent()}
        onConfirm={this.importData}
        confirmText={getLocalization('exports')}
        title={getLocalization('exportdata')}
        cancel={() => this.showImportModal(false)}
        cancelText={getLocalization('cancel')}
        dialogClassName="large"
      />
    ) : null;

    return (
      <React.Fragment>
        {modal}
        <Dropdown
          id={'import-btn'}
          alignRight={true}
        >
          <Dropdown.Toggle size={'sm'} id="import-map-data-menu">
            <i className="fa fa-upload" />
            {` ${getLocalization('import')}`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <ImportDataComponent
              clientPersist={this.props.clientPersist}
              forms={this.props.forms}
              loadImportMapping={this.props.loadImportMappings}
              importMappings={this.props.importMappings}
              uploadDataImport={this.props.uploadDataImport}
              uploadImportExcelFile={this.props.uploadImportExcelFile}
              importData={this.props.importData}
            />
            <ImportMapComponent
              clientPersist={this.props.clientPersist}
              uploadMapImport={this.props.uploadMapImport}
            />
          </Dropdown.Menu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
