import '../styles/UserQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { getLocalization } from '../../../global/global';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { ConfirmationModal } from '../../Modals/ConfirmationModal';

export interface IExternalProps {
  newAnswer: boolean;
  validateForm: () => boolean;
  savePOI: (saveType: IOwnState['saveType'], exportOnSave?: boolean) => void;
}

export interface IOwnState {
  showConfirm: boolean;
  newAnswer: boolean;
  saveType: 'CLOSE' | 'DRAFT' | 'NEW' | 'VIEW';
}

export default class SaveButton extends React.Component <IExternalProps, IOwnState> {

  constructor(props) {
    super(props);
    this.state = {
      showConfirm: false,
      newAnswer: props.newAnswer,
      saveType: 'DRAFT'
    };
  }

  @bind
  private onSave() {
    const valid =  this.props.validateForm();
    if (valid) {
      this.setState({ showConfirm: true, saveType: 'CLOSE'  });
    }
  }

  @bind
  private onSaveDraft() {
    this.setState({ showConfirm: true, saveType: 'DRAFT'  });
  }

  @bind
  private onSaveAndNew() {
    this.setState({ showConfirm: true, saveType: 'NEW'  });
  }

  @bind
  private onSaveAndView() {
    this.setState({ showConfirm: true, saveType: 'VIEW'  });
  }

  @bind
  private onSaveConfirmed() {
    this.closeModal();
    this.props.savePOI(this.state.saveType);
  }

  @bind
  private closeModal() {
    this.setState({ showConfirm: false });
  }

  public render(): JSX.Element {
    const confirmModal = this.state.showConfirm ? (
      <ConfirmationModal
        onConfirm={this.onSaveConfirmed}
        onClose={this.closeModal}
        localizations={
          {
            cancel: getLocalization('cancel'),
            confirm:getLocalization('save'),
            confirmStyle: 'primary',
            header: (
              <label>{getLocalization('confirm')}</label>
            ),
            body: (
              <div>{getLocalization('savePOI')}</div>
            )
          }
        }
      />
    )
    : null;
    return (
      <React.Fragment>
        {confirmModal}
        <SplitButton
          variant={'primary'}
          title={getLocalization('save')}
          id={'save-btn'}
          size="sm"
          alignRight={true}
          onClick={this.onSave}
        >
          {this.state.newAnswer && (
            <>
              <Dropdown.Item as="li" eventKey="1" onClick={this.onSaveAndNew} id={'save-new'}>
                {getLocalization('saveandnew')}
              </Dropdown.Item>
              <Dropdown.Item as="a" eventKey="2" onClick={this.onSaveAndView} id="save-view">
                {getLocalization('saveandview')}
              </Dropdown.Item>
            </>
          )}
          <Dropdown.Item as="a" eventKey="3" onClick={this.onSaveDraft} id="save-draft">
            {getLocalization('savedraft')}
          </Dropdown.Item>
        </SplitButton>
      </React.Fragment>
    );
  }
}
