import * as ChartsApi from '../api/chartsApi';
import { CHARTS } from './actionTypes';
import { actionCreator } from './index';
import { StateInterface } from '../Interfaces/StateInterface';
import { ChartModel } from 'Interfaces/ChartInterface';
import { toast } from 'react-toastify';

export const getCharts = () => {
  return (dispatch, getState) => {
    const reportResponse = ChartsApi.doGetCharts();
    reportResponse.then(response => response.json()).then(json => {
      dispatch(chartsLoaded(json));
    }).catch((error) => {
      console.log(error);
    });
  };
};

export const chartsLoaded = (charts) => (
  { type: CHARTS.LOADED, charts }
);

export const selectChart = (chartId) => (
  { type: CHARTS.SELECT, chartId }
);

export const unSelectChart = (chartId) => (
  { type: CHARTS.UNSELECT, chartId }
);

export const addNewChart = (chart: ChartModel) => (
  { type: CHARTS.NEW, chart }
);

export const loadChartData = (url: string, callBack: (data) => void) => {
  return (dispatch, getState) => {
    const clientPersist = getState().clientPersist;
    url += `&groupid=${clientPersist.groupId}&user_id=${clientPersist.user_id}&userid=${clientPersist.userName}`;
    const chartPromise = ChartsApi.doLoadChartData(url);
    chartPromise.then(response => response.json()).then(json => {
       callBack(json);
    }).catch((error) => {
      console.log(error);
    });
  };
};

export const saveChart = (chartModel: ChartModel, tempId?: string) => {
  return (dispatch, getState: () => StateInterface): Promise<Response> => {
    return new Promise((resolve, reject) => {
      const request = ChartsApi.doSaveChart(chartModel);
      request.then(response => response.json()).then(json => {
        if (json.id) {
          dispatch(chartSaved(json, tempId));
          resolve(new Response(JSON.stringify({ id: json.id })));
        }
      }).catch((error) => {
        reject(error); // console.log(error);
      });
    });
  };
};

export const chartSaved = (chart, reportId?) => (
  { type: CHARTS.SAVED, chart, reportId }
);

export const deleteChartsReport = actionCreator<string>(CHARTS.DELETE);

export const deleteCharts = (reportId: string) => {
  return async (dispatch, getState: () => StateInterface) => {
    const deleted = await ChartsApi.deleteChartReport(reportId);
    if (deleted) {
      toast.success('Chart has been deleted.');
      dispatch(deleteChartsReport(reportId));
    }
  };
};

export const loadBarChart = (chartModel: ChartModel) => {
  return (dispatch, getState: () => StateInterface): Promise<Response> => {
    return new Promise((resolve, reject) => {
      const request = ChartsApi.doLoadBarChart(chartModel);
      request.then(response => resolve(response)).catch((error) => {
        reject(error); // console.log(error);
      });
    });
  };
};

export const selectSingleInstanceChart = (chartId) => (
  { type: CHARTS.SELECT_SINGLE_INSTANCE_CHART, chartId }
);

export const unSelectSingleInstanceChart = (chartId) => (
  { type: CHARTS.UNSELECT_SINGLE_INSTANCE_CHART, chartId }
);
