import { globalWindow } from './global';

export const persistAuthorizationKey = 'poimapper-authorization';

let persistKey = `poimapper-default`;
if (!!globalWindow.db) {
  persistKey = `poimapper-${globalWindow.db}`;
}

export const persistDataKey = persistKey;
