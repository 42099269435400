import * as React from 'react';
import './ReportMenuComponent.scss';
import bind from 'bind-decorator';
import { FormInterface } from '../../../Interfaces/Forms/FormsInterface';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import { FiltersMenuInterface } from '../../../Interfaces/FiltersMenuInterface';
import { Alert, Button, ButtonGroup, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { getLocalization, globalWindow } from '../../../global/global'; // globalWindow
import GenericModal from '../../Modals/GenericModal';
import ReportQuestionSelector from './ReportQuestionSelector';
import SaveReportButton from './SaveReportButton';
import { getLegendQuestions, getSubgroupOptions } from './utils';
import { getQueryParams } from '../../../utils/utils';
import { ConfirmationModal } from '../../Modals/ConfirmationModal';

interface Props {
  forms: FormInterface[];
  clientPersist: ClientPersistInterface;
  reports: any;
  saveReportTemplate: (reportName: string, parameters: string, reportId: string) => void;
  deleteReport: (reportId: string) => void;
  filtersMenu: FiltersMenuInterface;
}

interface State {
  showModal: boolean;
  confirmDelete: boolean;
  form: string;
  validate: boolean;
  chartType: string;
  format: string;
  applylocationfilters: string;
  applydatefilters: string;
  applyuserfilters: string;
  includelocation: string;
  excludenull: string;
  legendQuestion: string;
  subgrpQuestion: string;
  criteriaQuestion: string;
  type: string;
  rowquestion: string[];
  questions: string[];
  reportId: string;
  reportName: string;
}

export default class ReportMenuComponent extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  private getInitialState() {
    return {
      showModal: false,
      confirmDelete: false,
      form: '',
      validate: false,
      chartType: '',
      format: 'xlsx',
      applylocationfilters: '0',
      applydatefilters: '0',
      applyuserfilters: '0',
      includelocation: '0',
      excludenull: '0',
      legendQuestion: '',
      subgrpQuestion: '',
      criteriaQuestion: '',
      type: 'SUM',
      rowquestion: [],
      questions: [],
      reportId: '',
      reportName: ''
    };
  }

  @bind
  private showReportModal(visible) {
    if (!visible) {
      this.setState(this.getInitialState());
    } else {
      this.setState({ showModal: visible });
    }
  }

  @bind
  private onSelectChange(e) {
    const newState: Partial<State> = {};
    newState[e.target.name] = e.target.value;
    this.setState({...this.state, ...newState});
  }

  @bind
  private onMultipleSelectChange(e) {
    const newState: Partial<State> = {};

    let i = 0;
    const options = e.target.options;
    const selectedOptions: string[] = [];
    while (i < options.length) {
      if (options[i].selected) {
        selectedOptions.push(options[i].value);
      }
      i++;
    }
    newState[e.target.name] = selectedOptions;
    this.setState({...this.state, ...newState});
  }

  @bind
  private onCheckChange(e) {
    const newState: Partial<State> = {};
    newState[e.target.name] = e.target.checked ? e.target.value : '0';
    if (e.target.name === 'chartType') {
      newState.type = 'SUM';
    }
    this.setState({...this.state, ...newState});
  }

  @bind
  private onQuestionsChange(questions) {
    this.setState({ questions });
  }

  // @ts-ignore
  @bind
  private getLegendOptions(form: FormInterface) {
    const { chartType } = this.state;
    const { clientPersist } = this.props;
    let options: (JSX.Element | null)[] = [];

    if (chartType === '-1') {
      if (form.type !== 'TABLE') {
        options.push(<option value={'user_id'}>User</option>);
      }
      if (form.isChild) {
        options.push(<option value={'parent_name'}>Parent form name</option>);
        options.push(<option value={'parent_location1'}>Parent Location Level 1</option>);
        options.push(<option value={'parent_location2'}>Parent Location Level 2</option>);
        options.push(<option value={'parent_location3'}>Parent Location Level 3</option>);
        options.push(<option value={'parent_location4'}>Parent Location Level 4</option>);
      }
    }
    if (form.type === 'POI' && !form.isChild) {
      const locationLabels = clientPersist.locationLabels;
      let i = 1;
      for (const label of locationLabels) {
        if (label) {
          options.push(<option value={i}>{label}</option>);
        }
        i++;
      }
    }

    for (const page of form.questionPage) {
      options = options.concat(getLegendQuestions(page.question, page.pageNumber, chartType));
    }
    options = options.concat(getLegendQuestions(form.question, '', chartType));
    return options;
  }

  @bind
  private getSubgroupOption(form) {
    const { chartType } = this.state;
    const { clientPersist } = this.props;
    let options: (JSX.Element | null)[] = [];

    if (chartType === '-1' || chartType === '-2') {
      if (form.type !== 'TABLE') {
        if (form.type === 'POI') {
          const locationLabels = clientPersist.locationLabels;
          let i = 1;
          for (const label of locationLabels) {
            if (label) {
              options.push(<option value={i}>{label}</option>);
            }
            i++;
          }
        }
      }
    }
    for (const page of form.questionPage) {
      options = options.concat(getSubgroupOptions(page.question, page.pageNumber, chartType));
    }
    options = options.concat(getSubgroupOptions(form.question, '', chartType));
    return options;
  }

  @bind
  private getModalContent() {
    const { forms } = this.props;
    const error = this.state.validate ? 'has-error' : '';
    const { chartType } = this.state;
    const form = forms.find(f => f.ref === this.state.form);

    const getError = () => {
      if (this.state.validate) {
        return (
          <Alert variant={'danger'}>
            Check missing any of this fields is not missing : form, chart type and questions.
          </Alert>
        );
      }
      return null;
    };

    const isInvalid = (name: string) => {
      return this.state.validate && this.state[name] === '';
    };
    /*const hasError = (value) => {
      return value === '' && error;
    };*/

    return (
      <div>
        {getError()}
        <Form.Group>
          <Form.Control
            as="select"
            name="form"
            onChange={this.onSelectChange}
            value={this.state.form}
            isInvalid={isInvalid('form')}
          >
            <option value="">{getLocalization('selone')}</option>
            {forms.map(f => {
              const prefix = f.type === 'TABLE' ? 'Table:' :
                f.type === 'TASKFORM' ? 'Taskform:' :
                f.isChild && f.type === 'POI' ? 'Subform:' : '';
              return (<option value={f.ref} key={f.id}>{`${prefix}${f.name}`}</option>);
            })}
          </Form.Control>
        </Form.Group>
        <Row>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>{getLocalization('selectcharttype')}</Form.Label>
              <Form.Check
                required={this.state.validate}
                id={`report-modal-report-type-table`}
                type="radio"
                name="chartType"
                label={getLocalization('reportsTable')}
                value="-1"
                checked={this.state.chartType === '-1'}
                onChange={this.onCheckChange}
                isInvalid={isInvalid('chartType')}
              />
              <Form.Check
                required={this.state.validate}
                id={`report-modal-report-type-cross-table`}
                type="radio"
                name="chartType"
                label={getLocalization('reportsCrosstable')}
                value="-2"
                checked={this.state.chartType === '-2'}
                onChange={this.onCheckChange}
                isInvalid={isInvalid('chartType')}
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>{getLocalization('reportsFormat')}</Form.Label>
              <Form.Check
                id={`report-modal-report-format-excel`}
                type="radio"
                name="format"
                label={getLocalization('reportsExcel')}
                value="xlsx"
                checked={this.state.format === 'xlsx'}
                onChange={this.onCheckChange}
              />
              <Form.Check
                id={`report-modal-report-format-word`}
                type="radio"
                name="format"
                label={getLocalization('reportsWord')}
                value="docx"
                checked={this.state.format === 'docx'}
                onChange={this.onCheckChange}
              />
              <Form.Check
                id={`report-modal-report-format-pdf`}
                type="radio"
                name="format"
                label={getLocalization('reportsPdf')}
                value="pdf"
                checked={this.state.format === 'pdf'}
                onChange={this.onCheckChange}
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Check
              id={`report-modal-report-apply-user-filters`}
              type="checkbox"
              name="applyuserfilters"
              label={getLocalization('applycurrentuserfilters')}
              value="1"
              checked={this.state.applyuserfilters === '1'}
              onChange={this.onCheckChange}
            />
            <Form.Check
              id={`report-modal-report-apply-date-filters`}
              type="checkbox"
              name="applydatefilters"
              label={getLocalization('applycurrentdatefilters')}
              value="1"
              checked={this.state.applydatefilters === '1'}
              onChange={this.onCheckChange}
            />
            <Form.Check
              id={`report-modal-report-apply-location-filters`}
              type="checkbox"
              name="applylocationfilters"
              label={getLocalization('applycurrentlocationfilters')}
              value="1"
              checked={this.state.applylocationfilters === '1'}
              onChange={this.onCheckChange}
            />
          </Col>
        </Row>
        {form && form.hasLocationHierarchy && (
          <Form.Group>
            <Form.Check
              id={`report-modal-report-show-location-names`}
              type="checkbox"
              name="includelocation"
              label={getLocalization('showlocationnames')}
              value="1"
              checked={this.state.includelocation === '1'}
              onChange={this.onCheckChange}
            />
          </Form.Group>
        )}
        {
          // Table & Crosstab
        }
        <Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>{this.state.chartType === '-1' ? getLocalization('selectgroupby')
                  : getLocalization('selectcolumngroup')
                }</Form.Label>
              <Form.Control
                as="select"
                name="legendQuestion"
                onChange={this.onSelectChange}
                value={this.state.legendQuestion}
              >
                <option value="">{getLocalization('selone')}</option>
                {form && chartType ? this.getLegendOptions(form) : []}
              </Form.Control>
            </Form.Group>
          </Col>
          { this.state.legendQuestion !== '' && this.state.chartType === '-1' &&
          (
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>{getLocalization('selectsubgroup')}</Form.Label>
                  <Form.Control
                      as="select"
                      name="subgrpQuestion"
                      onChange={this.onSelectChange}
                      value={this.state.subgrpQuestion}
                  >
                    <option value="">{getLocalization('selone')}</option>
                    {form ?
                        this.getSubgroupOption(form)
                            .filter( el => el && `${el.props.value}` !== this.state.legendQuestion) :
                        []
                    }
                  </Form.Control>
                </Form.Group>
              </Col>
          )
          }
        </Row>
        { // Table
          chartType === '-1' && (
            <Form.Group>
              <Form.Label>{getLocalization('excludecriteria')}</Form.Label>
              <Form.Control
                as="select"
                name="criteriaQuestion"
                onChange={this.onSelectChange}
                value={this.state.criteriaQuestion}
              >
                <option value="">{getLocalization('selone')}</option>
                {form ? this.getSubgroupOption(form) : []}
              </Form.Control>
            </Form.Group>
          )
        }
        { // Cross Tab
          chartType === '-2' && (
            <Form.Group>
              <Form.Label>{getLocalization('selrowgroup')}</Form.Label>
              <Form.Control
                as="select"
                name="rowquestion"
                onChange={this.onMultipleSelectChange}
                // @ts-ignore
                value={this.state.rowquestion}
                multiple={true}
                disabled={this.state.legendQuestion === ''}
              >
                <option value="">{getLocalization('selone')}</option>
                {form && chartType ?
                  // @ts-ignore
                  this.getLegendOptions(form).filter( el => el && el.props.value !== this.state.legendQuestion) : []}
              </Form.Control>
            </Form.Group>
          )
        }

        {
          // Table & cross tab
        }
        <Form.Group>
          <Form.Label>{getLocalization('selcalcs')}</Form.Label>
          <Form.Control
            as="select"
            name="type"
            onChange={this.onSelectChange}
            value={this.state.type}
            disabled={this.state.legendQuestion === ''}
          >
            <option value="">{getLocalization('selone')}</option>
            <option value="SUM">{getLocalization('reportsSum')}</option>
            <option value="AVG">{getLocalization('reportsAverage')}</option>
            {chartType === '-2' && (<option value="PERCENT">{getLocalization('reportsPercentage')}</option>)}
            {chartType === '-1' && (<option value="NONE">{getLocalization('reportsNone')}</option>)}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <div className={`form-group ${this.state.questions.length === 0 && error}`}>
            {getLocalization('movequestions')}
          </div>
          <ReportQuestionSelector
            form={form}
            chartType={chartType}
            questions={this.state.questions}
            onQuestionsChange={this.onQuestionsChange}
          />
        </Form.Group>
      </div>
    );
  }

  @bind
  private createReport() {
    this.setState({ showModal: true });
  }

  @bind
  private generateReport() {
    const { clientPersist } = this.props;
    const { form, includelocation, chartType, type } = this.state;
    if (!this.parametersValid()) {
      return;
    }
    const params: string[] = [ `user_id=${clientPersist.user_id}`,
      `lang=${clientPersist.lang}`, `key=${globalWindow.pwrd}`, `userid=${globalWindow.userName}`
    ];

    const extraParams = this.getReportParameters('url');

    const url = `/json/app/jasper/${form}/${includelocation}/1/${chartType}/${type}?`
      + `${params.join('&')}&${extraParams}`;
    globalWindow.open(url, '_blank');
  }

  @bind
  private parametersValid() {
    const {
      form, chartType, questions, legendQuestion, rowquestion
    } = this.state;
    if (form === '' || chartType === '' || questions.length === 0) {
      this.setState({ validate: true });
      return false;
    }
    if (chartType === '-2') {
      if (legendQuestion === '' || rowquestion.length === 0) {
        this.setState({ validate: true });
        return false;
      }
    }
    return true;
  }

  @bind
  private getReportParameters(urlOrSave: string) {
    const urlParams: string[] = [];
    const { clientPersist, filtersMenu } = this.props;
    const state = this.state;
    const params = {
      questions: state.questions.join(','),
      locs: '',
      levels: '',
      users: '',
      fromdate: '',
      todate: '',
      timeinfo: 0,
      userinfo: 0,
      legendid: state.legendQuestion,
      reportType: 0,
      locationLevels: '-1',
      combinenumericfields: 0,
      timeseriestype: '',
      locationLevelDataFilter: '',
      orientation: 1,
      rowquestionid: state.rowquestion.join(','),
      groupid: clientPersist.groupId,
      format: state.format,
      excludenull: state.excludenull,
      subgroup: state.subgrpQuestion,
      criteriafield: state.criteriaQuestion
    };

    if (this.state.applylocationfilters === '1') {
      const locs = filtersMenu.selectedLocations.map(l => l.key).join(',');
      const levels = filtersMenu.selectedLocations.map(l => Number(l.level) + 1).join(',');
      urlParams.push(`locs=${locs}`);
      urlParams.push(`levels=${levels}`);
      params.locs = locs;
      params.levels = levels;
    }
    if (this.state.applyuserfilters) {
      const users = filtersMenu.selectedUsers.map(u => u.id).join(',');
      urlParams.push(`users=${users}`);
      params.users = users;
    }
    if (this.state.applydatefilters) {
      urlParams.push(`fromdate=${filtersMenu.selectedDates.from}`);
      urlParams.push(`todate=${filtersMenu.selectedDates.to}`);
      params.fromdate = filtersMenu.selectedDates.from || '';
      params.todate = filtersMenu.selectedDates.to || '';
    }

    urlParams.push(`questions=${params.questions}`);
    urlParams.push(`timeinfo=${params.timeinfo}`);
    urlParams.push(`userinfo=${params.userinfo}`);
    urlParams.push(`legendid=${params.legendid}`);
    urlParams.push(`reportType=${params.reportType}`);
    urlParams.push(`locationLevels=${params.locationLevels}`);
    urlParams.push(`orientation=${params.orientation}`);
    urlParams.push(`rowquestionid=${params.rowquestionid}`);
    urlParams.push(`groupid=${params.groupid}`);
    urlParams.push(`format=${params.format}`);
    urlParams.push(`excludenull=${params.excludenull}`);
    urlParams.push(`subgroup=${params.subgroup}`);
    urlParams.push(`criteriafield=${params.criteriafield}`);
    urlParams.push(`combinenumericfields=0`);
    urlParams.push(`locationLevelDataFilter=`);
    return urlParams.join('&');
  }

  @bind
  private saveReportTemplate(reportName: string) {
    const parameters = this.getReportParameters('save');
    const {
      form, includelocation, chartType, type, reportId
    } = this.state;
    const url = `/json/app/jasper/${form}/${includelocation}/1/${chartType}/${type}?XXX${parameters}`;
    this.props.saveReportTemplate(reportName, url, reportId);
  }

  @bind
  private editReport(report) {
    const state: Partial<State> = {};
    const url = report.url;
    const parameters = getQueryParams(url.substring(url.indexOf('?')));
    state.form = report.form;
    state.chartType = report.report_type === 'Table' ? '-1' : report.report_type === 'Crosstab' ? '-2' : '';
    state.type = url.substring(url.lastIndexOf('/') + 1, url.indexOf('?'));
    state.reportId = report.report_id;
    state.format = parameters.format;
    state.legendQuestion = parameters.legendid;
    state.questions = parameters.questions.split(',');
    state.subgrpQuestion = parameters.subgroup;
    state.excludenull = parameters.excludenull;
    state.rowquestion = parameters.rowquestionid.split(',');
    state.criteriaQuestion = parameters.criteriafield;
    state.applydatefilters = parameters.fromdate || parameters.todate ? '1' : '0';
    state.applylocationfilters = parameters.locs ? '1' : '0';
    state.applyuserfilters = parameters.users ? '1' : '0';
    state.includelocation = report.show_locations === 'Yes' ? '1' : '0';
    state.showModal = true;
    state.reportName = report.report_name;
    this.setState((prevState, props) => ({...prevState, ...state}));
  }

  @bind
  private confirmDelete(report) {
    this.setState({ confirmDelete: true, reportId: report.report_id });
  }

  @bind
  private deleteReport() {
    const { reportId } = this.state;
    this.props.deleteReport(reportId);
    this.showReportModal(false);
  }

  @bind
  private getSaveReportButton() {
    return [(
        <SaveReportButton
          saveReportTemplate={this.saveReportTemplate}
          parametersValid={this.parametersValid}
          reportName={this.state.reportName}
        />
      )
    ];
  }

  @bind
  private applyFilters(url) {
    const parameters = getQueryParams(url.substring(url.indexOf('?')));
    const { filtersMenu, clientPersist } = this.props;
    if (parameters.locs) {
      const locs = filtersMenu.selectedLocations.map(l => l.key).join(',');
      const levels = filtersMenu.selectedLocations.map(l => Number(l.level) + 1).join(',');
      parameters.locs = locs;
      parameters.levels = levels;
      // urlParams.push(`locs=${locs}`);
      // urlParams.push(`levels=${levels}`);
    }
    parameters.user_id = clientPersist.user_id || '';
    parameters.key = globalWindow.pwrd;
    parameters.userid = globalWindow.userName;
    parameters.lang = clientPersist.lang;
    const keys = Object.keys(parameters);
    const urlParams: string[] = [];
    for (const key of keys) {
      urlParams.push(`${key}=${parameters[key]}`);
    }
    const finalUrl = url.substring(0, url.indexOf('?') + 1);
    globalWindow.open(finalUrl + urlParams.join('&'), '_blank');
  }

  @bind
  private getReports() {
    const { reports } = this.props;
    if (reports) {
      return reports.map((report) => {
        return (
          <Dropdown.Item key={`report_menu_${report.report_id}`} eventKey="1">
            {(
              <div className="report-name">
                <div className="pull-left" onClick={() => this.applyFilters(report.url)}>
                  {report.report_name}
                </div>
                <div className="pull-right">
                  <i className="fa fa-trash report-action-icon" onClick={() => this.confirmDelete(report)}/>
                  <i className="fa fa-pencil report-action-icon" onClick={() => this.editReport(report)}/>
                </div>
              </div>
            )}
          </Dropdown.Item>
        );
      });
    }
    return null;
  }

  public render(): JSX.Element {
    const modal = this.state.showModal ? (
      <GenericModal
        visible={this.state.showModal}
        body={this.getModalContent()}
        onConfirm={this.generateReport}
        confirmText={getLocalization('generateReport')}
        title={getLocalization('reportsIntro')}
        cancel={() => this.showReportModal(false)}
        cancelText={getLocalization('cancel')}
        extraButtons={this.getSaveReportButton()}
        dialogClassName="report-menu-modal"
      />
    ) : null;
    const deleteModal = this.state.confirmDelete ? (
      <ConfirmationModal
        visible={this.state.confirmDelete}
        onClose={() => this.showReportModal(false)}
        onConfirm={this.deleteReport}
        localizations={{
          cancel: getLocalization('cancel'),
          confirm: getLocalization('ok'),
          confirmStyle: 'danger',
          header: (<label>{getLocalization('confirm')}</label>),
          body: (<p>{getLocalization('reportConfirmDelete')}</p>)
        }}
      />
    ) : null;
    return (
      <React.Fragment>
        {modal}
        {deleteModal}
        <ButtonGroup>
          <Dropdown
            id={'reports-btn'}
            alignRight={true}
          >
            <Dropdown.Toggle size={'sm'} id="report-dropdown-menu">
              <i className="fa fa-tasks" />
              {` ${getLocalization('reports')}`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.getReports()}
            </Dropdown.Menu>
          </Dropdown>
          <Button
            title={getLocalization('reports')}
            onClick={this.createReport}
            size={'sm'}
            id={'add-report-btn'}
          >
            <i className="fa fa-plus" />
          </Button>
        </ButtonGroup>
      </React.Fragment>
    );
  }
}

/*
<div className="form-group">
  <div className="checkbox default">
    <label>
      <input
        type="checkbox"
        name="excludenull"
        value="1"
        onChange={this.onCheckChange}
        checked={this.state.excludenull === '1'}
      />
      {getLocalization('excludenull')}
    </label>
  </div>
</div>*/
