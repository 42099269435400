import * as React from 'react';
import bind from 'bind-decorator';
import Papa from 'papaparse';
import { FormInterface } from '../../../Interfaces/Forms/FormsInterface';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import { Dropdown, Tab, Tabs } from 'react-bootstrap';
import { getLocalization } from '../../../global/global';
import GenericModal from '../../Modals/GenericModal';
import ImportDataExcelComponent from './ImportDataExcelComponent';
import { ExcelData } from 'Interfaces/ImportDataInterfaces';

interface Props {
  forms: FormInterface[];
  clientPersist: ClientPersistInterface;
  loadImportMapping: (formIds: string[]) => void;
  importMappings: LooseObject | null;
  uploadDataImport: (file: File, mappings: LooseObject, formId: string, geocode: boolean) => void;
  uploadImportExcelFile: (file: File, signal: AbortSignal) => Promise<Response>;
  importData: (excelData: ExcelData) => void;
}

interface State {
  showModal: false;
  name: string;
  file: File | null;
  form: string;
  geocode: boolean;
  showGeocode: boolean;
  importMappings: LooseObject | null;
  selections: string[];
  fileTypeError: boolean;
  selectedTab: 'csv' | 'excel';
  excelData: ExcelData | null;
}

export default class ImportDataComponent extends React.Component<Props, State> {
  private fileInput = React.createRef<HTMLInputElement>();
  private questions: LooseObject = [];
  private fileRow = [];
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      name: '',
      file: null,
      geocode: false,
      showGeocode: false,
      form: '',
      importMappings: props.importMappings,
      selections: [],
      fileTypeError: false,
      selectedTab: 'csv',
      excelData: null
    };
    this.fileInput = React.createRef();
  }

  @bind
  private showImportModal(visible) {
    this.setState({ showModal: visible });
  }

  @bind
  private handleChange(e) {
    const state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
    if (e.target.name === 'form' && e.target.value !== '' && this.state.file) {
      this.loadQuestions();
    }
  }

  @bind
  private handleCheckChange(e) {
    const state = {};
    state[e.target.name] = e.target.checked;
    this.setState(state);
  }

  @bind
  private handleFileChange(e) {
    if (e.target.files.length > 0) {
      const files: FileList = e.target.files;
      let i = 0;
      let f: File;
      let fileTypeError = false;
      while (i < files.length) {
        f = files[i];
        if (!(f.type.match('text/csv') || f.type.match('text/plain') ||
        (f.type.match('application/vnd.ms-excel') && f.name.endsWith('.csv')))) {
          fileTypeError = true;
          this.setState({ fileTypeError });
          return;
        }
        if (f) {
          const reader = new FileReader();
          reader.onload = ((theFile) => {
            return (e) => {
              const data = Papa.parse(e.target.result);
              if (data.data.length > 0) {
                this.fileRow = data.data[0];
              }
              if (this.state.form !== '') {
                this.loadQuestions();
              }
              this.setState({ file: f, fileTypeError });
            };
          })(f);
          reader.readAsText(f);
        }
        i++;
      }
    }
  }

  @bind
  private loadQuestions() {
    const { form } = this.state;
    const formJSON = this.props.forms.find(f => f.ref === form);
    if (formJSON) {
      const qns: LooseObject[] = [];
      const allowedQuestions = [
        'IntQuestion', 'StringQuestion', 'FloatQuestion', 'NameQuestion', 'SelectMultipleQuestion',
        'SelectOneQuestion', 'BooleanQuestion', 'DateQuestion', 'StatusQuestion'
      ];
      const getQuestion = (questions: LooseObject[]): LooseObject | undefined => {
        for (const question of questions) {
          if (!question.inVisible && !question.deleted) {
            if (allowedQuestions.indexOf(question.type) !== -1) {
              qns.push({ id: question.id, type: question.type, text: question.text });
            }
            if (question.type === 'GPSQuestion' && formJSON.hasCoordinates) {
              qns.push({ id: 'Lat', type: 'GPSQuestion', text: 'Latitude' });
              qns.push({ id: 'Lon', type: 'GPSQuestion', text: 'Longitude' });
            }
            if (question.triggerValues && question.triggerValues.triggerValue) {
              for (const triggerValue of question.triggerValues.triggerValue) {
                if (triggerValue.action.subQuestions) {
                  getQuestion(triggerValue.action.subQuestions.question);
                }
              }
            }
          }
        }
        return undefined;
      };

      qns.push({ id: 'scheduledate', type: '', text: getLocalization('scheduleDate') });
      if (formJSON.hasLocationHierarchy === true) {
        const { clientPersist } = this.props;
        const labels = clientPersist.locationLabels.length > 0 ?
          clientPersist.locationLabels : ['Location 1', 'Location 2', 'Location 3', 'Location 4'];
        labels.forEach((label, index) => {
          qns.push({ id: `Location${index + 1}`, type: '', text: label });
        });
      }
      for (const page of formJSON.questionPage) {
        getQuestion(page.question);
      }
      this.questions = qns;
    }
    console.log('questions');
  }

  @bind
  private importData() {
    const { selections, form, file, geocode, selectedTab, excelData } = this.state;
    if (selectedTab === 'csv') {
      const mappings: LooseObject[] = [];
      let i = 0;
      if (file && form !== '') {
        const coord = {};
        while (i < selections.length) {
          if (selections[i]) {
            const map = {};
            map['index'] = i;
            map['questionId'] = selections[i];
            map['columnName'] = this.fileRow[i];
            const question = this.questions.find(q => q.id === selections[i]);
            if (question) {
              map['questionType'] = question.type;
            }
            if (map['questionType'] === 'GPSQuestion') {
              coord[selections[i]] = map;
            } else {
              mappings.push(map);
            }
          }
          i++;
        }
        if (coord['Lat'] && coord['Lon']) {
          mappings.push(coord);
        }
        this.props.uploadDataImport(file, mappings, form, geocode);
      }
      console.log('import data');
    } else {
      if (excelData) {
        this.props.importData(excelData);
      }
    }
  }

  @bind
  private onFileSelectClick() {
    if (this.fileInput.current) {
      this.fileInput.current.click();
    }
  }

  @bind
  private loadImportMapping() {
    if (!this.state.importMappings) {
      const formIds = this.props.forms.map((f) => {
        if (!f.isChild && f.type === 'POI') {
          return f.ref;
        }
        return '';
      }).filter( id => id !== '');
      this.props.loadImportMapping(formIds);
    } else {
      this.showImportModal(true);
    }
  }

  @bind
  private getModalContent() {
    const { forms } = this.props;
    return (
      <Tabs
        defaultActiveKey="csv"
        id="uncontrolled-tab-example"
        onSelect={(key) => this.setState({ selectedTab: key })}
      >
        <Tab eventKey="csv" title="CSV file">
          <div>
            <p><span className="text-danger">{getLocalization('importQuestionType')}</span></p>
            <p className="text-info">{getLocalization('importFileInfo')}</p>
            <p className="text-info">{getLocalization('scheduleDateInfo')}</p>
            <p className="text-info">{getLocalization('importStatusValues')}</p>
            <div className="form-group">
              <label>{getLocalization('selectform')}</label>
              <select
                className="form-control input-sm"
                onChange={this.handleChange}
                value={this.state.form}
                name="form"
              >
                <option value="">{getLocalization('selectform')}</option>
                {forms.map(f => {
                  return f.type === 'POI' && !f.isChild ? (<option value={f.ref}>{f.name}</option>) : undefined;
                }).filter( f => f !== undefined)}
              </select>
            </div>
            <div className="form-group">
              {this.state.fileTypeError && (<p className="bg-danger error-alert">Only CSV files are allowed.</p>)}
              <button className="btn btn-sm btn-primary" onClick={this.onFileSelectClick}>
                {getLocalization('selectImportFile')}
              </button>
              <input
                ref={this.fileInput}
                type="file"
                name="importFileSelect"
                accept=".csv, .txt, plain/text"
                className="d-none"
                onChange={this.handleFileChange}
              />
              <span id="import-file-name">
                {this.state.file && this.state.file.name}
              </span>
            </div>
            <div className="container-fluid import-questions">
              {this.getImportRows()}
            </div>
            {this.state.showGeocode && (
                <div className="checkbox">
                  <label>
                    <input
                        onChange={this.handleCheckChange}
                        name="geocode"
                        type="checkbox"
                        checked={this.state.geocode}
                    />{getLocalization('geocode')}
                  </label>
                </div>
            )}
          </div>
        </Tab>
        <Tab eventKey="excel" title="Excel file">
          <ImportDataExcelComponent
            forms={forms}
            uploadImportExcelFile={this.props.uploadImportExcelFile}
            setExcelData={this.setExcelData}
            clientPersist={this.props.clientPersist}
          />
        </Tab>
      </Tabs>
    );
  }

  @bind
  private onQuestionSelected(id, index) {
    const addressFields = ['address', 'street', 'city', 'zipcode', 'state'];
    const selections = [...this.state.selections];
    selections[index] = id;
    const state = {};
    state['selections'] = selections;
    if (addressFields.indexOf(id) !== -1) {
      const { form } = this.state;
      const { clientPersist } = this.props;
      const formModel = this.props.forms.find(f => f.ref === form);
      if (formModel && formModel.includeAddress &&
        (clientPersist.package !== 'Light' || clientPersist.instance === 'airliquide')) {
        state['showGeocode'] = true;
      }
    }
    this.setState(state);
  }

  private getImportRows() {
    const { selections } = this.state;
    const rows = this.fileRow.map((row, index) => {
      return (
        <div className="row" key={`${index}`}>
          <div className="col-md-1">{(index + 1)}</div>
          <div className="col-md-5">
            {row}
          </div>
          <div className="col-md-6 form-group">
            <select
              className="form-control"
              onChange={(e) => this.onQuestionSelected(e.target.value, index)}
              value={selections[index] ? selections[index] : ''}
            >
              <option>{''}</option>
              {this.questions.map((q, ind) => {
                return selections.indexOf(q.id) === -1 || selections[index] === q.id ?
                 (
                  <option value={q.id} key={`import-${index}-${ind}`}>{q.text}</option>
                ) : null;
              })}
            </select>
          </div>
        </div>
      );
    });
    return rows;
  }

  @bind
  private setExcelData(excelData: ExcelData | null) {
    this.setState({ excelData });
  }

  public static getDerivedStateFromProps(props: Props, state: State) {
    if (props.importMappings && !state.importMappings) {
      return { importMappings: props.importMappings, showModal: true };
    }
    return null;
  }

  public render(): JSX.Element {
    const modal = this.state.showModal ? (
      <GenericModal
        visible={this.state.showModal}
        body={this.getModalContent()}
        onConfirm={this.importData}
        confirmText={getLocalization('importTitle')}
        title={getLocalization('importTitle')}
        cancel={() => this.showImportModal(false)}
        cancelText={getLocalization('cancel')}
        dialogClassName={'large-modal'}
      />
    ) : null;

    return (
      <React.Fragment>
        {modal}
        <Dropdown.Item
          eventKey="2"
          onClick={() => this.loadImportMapping()}
          id={'import-data-btn'}
        >
          {getLocalization('importData')}
        </Dropdown.Item>
      </React.Fragment>
    );
  }
}
