import { ChartModel, ChartResponse, SeriesData } from '../../Interfaces/ChartInterface';
import { JSONInterface } from '../../Interfaces/JsonInterface';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { getFormUtils } from '../../views/SingleInstance/utils/FormUtilsHolder';
import { Locations } from 'Interfaces/LocationInterface';

export const compare = (a, b) => {
  if (a.x < b.x) {
    return -1;
  } else if (a.x > b.x) {
    return 1;
  } else {
    return 0;
  }
};

export const formatLineData = (data) => {
  const times: string[] = [];
  for (const d of data) {
    const lineData = JSON.parse(d.value);
    for (const ld of lineData) {
      if (times.indexOf(ld.x) === -1) {
        times.push(ld.x);
      }
    }
  }
  times.sort();
  const newValues = times.map((time) => null);
  const newData = [...data];
  for (const d of newData) {
    const lineData = JSON.parse(d.value);
    const newValue = [...newValues];
    for (const ld of lineData) {
      const index = times.indexOf(ld.x);
      if (index !== -1) {
        newValue[index] = ld;
      }
    }
    d.value = JSON.stringify(newValue);
  }
  return newData;
};

const getUniq = (array) => {
  const temp: string[] = [];
  array.forEach((arr) => {
    if (temp.indexOf(arr) === -1) {
      temp.push(arr);
    }
  });
  return temp;
};

export const getAverage = (value, count) => {
  return Math.round((value / Number(count)) * 100) / 100;
};

export const getBarChart = (model: ChartModel, chartResponse: ChartResponse, xAxisLabels?, locations?: Locations) => {

  const tempCategories = chartResponse.series.map((chartData) => {
    return chartData.x;
  });
  const categories: string[] = getUniq(tempCategories);
  const tempStacks = chartResponse.series.map(chartData => chartData.stack).filter(stack => stack !== null);
  const stacks = getUniq(tempStacks);

  const seriesData: any = [];
  chartResponse.series.forEach((series) => {
    if (stacks.length === 0) {
      if (seriesData.length === 0) {
        seriesData[0] = { name: '', data: [] };
      }
      const dataIndex = categories.indexOf(series.x);
      seriesData[0].data[dataIndex] = series.y;
    } else if (series.stack) {
      const seriesIndex = stacks.indexOf(series.stack);
      if (!seriesData[seriesIndex]) {
        seriesData[seriesIndex] = { name: series.stack, data: [] };
      }
      const dataIndex = categories.indexOf(series.x);
      seriesData[seriesIndex].data[dataIndex] = series.y;
    }
  });

  categories.forEach((category, index) => {
    for (const ser of seriesData) {
      if (!ser.data[index]) {
        ser.data[index] = 0;
      }
    }
  });

  let title = chartResponse.chartTitle;
  if (model.grouping) {
    const location = locations ? locations.find(l => `${l.key}` === `${chartResponse.grouping}`) : '';
    if (location) {
      title = `${title} - ${location.title}`;
    }
  }
  const charts: any = [];
  if (categories.length > 10) {
    const max = 10;
    let count = 0;
    while (categories.length > 0) {
      const newCat = categories.splice(0, max);
      const seriesTemp = JSON.parse(JSON.stringify(seriesData));
      // const index = count * max;
      seriesData.forEach((series, index) => {
        seriesTemp[index].data = series.data.splice(0, max);
      });
      const chartObject = getBarChartObject(
        model, seriesTemp, newCat, title, '', `Data points: ${(chartResponse.count)}`, xAxisLabels
      );
      chartObject['id'] = `${model.id}-${count}`;
      chartObject['responseId'] = chartResponse.id;
      charts.push(chartObject);
      count++;
    }
  } else {
    const chartObject = getBarChartObject(
      model, seriesData, categories, title, '', `Data points: ${(chartResponse.count)}`, xAxisLabels,
    );
    chartObject['id'] = `${model.id}-0`;
    chartObject['responseId'] = chartResponse.id;
    charts.push(chartObject);
  }
  return charts;
};

/**
 * Combines the fields into one chart
 * @param chartResponse chart response from the server
 */
export const combineCharts = (chartResponses: ChartResponse[], model: ChartModel) => {
  const categories: string[] = [];
  const seriesData: any = [];
  const stacks: string[] = [];
  const chars = 'abcdefghijklmnopqrstuvwxyz';
  /*const getStackName = (chartResponse: ChartResponse) => {
    return chartResponse.chartTitle?.replace(/ /g, '-').toLocaleLowerCase();
  };*/
  chartResponses.forEach((chartResponse, index) => {
    /**
     * Get x coordinate values.
     */
    const tempCategories = chartResponse.series.map((chartData) => {
      return chartData.x;
    });
    const cleaned = getUniq(tempCategories);
    cleaned.forEach((category) => {
      if (categories.indexOf(category) === -1) {
        categories.push(category);
      }
    });

    /**
     * Get the stack information.
     */
    const tempStacks = chartResponse.series.map(chartData => chartData.stack).filter(stack => stack !== null);
    if (tempStacks.length > 0) {
      const cleanedStack = getUniq(tempStacks);
      cleanedStack.forEach((stack) => {
        if (stacks.indexOf(stack) === -1) {
          stacks.push(stack);
        }
      });
    }
    // const stackName = getStackName(chartResponse);
    chartResponse.series.forEach((series, i) => {
      if (series.stack) {
        const dataIndex = categories.indexOf(series.x);  // stackName
        const sdIndex = seriesData.findIndex(sd1 => sd1.name === series.stack && sd1.stack === chars.charAt(index));
        if (sdIndex === -1) {
          const sdData: number[] = [];
          sdData[dataIndex] = series.y;
          seriesData.push({
            name: series.stack,
            data: sdData,
            stack: chars.charAt(index), // stackName,
            label: chartResponse.chartTitle,
            stackLabel: chars.charAt(index)
          });
        } else {
          seriesData[sdIndex].data[dataIndex] = series.y;
        }
      } else {
        if (!seriesData[index]) {
          seriesData[index] = {
            name: chartResponse.chartTitle,
            data: []
          };
        }
        const dataIndex = categories.indexOf(series.x);
        seriesData[index].data[dataIndex] = series.y;
      }
    });
  });
  seriesData.forEach((sd) => {
    for (let i = 0; i < sd.data.length; i++) {
      if (!sd.data[i]) {
        sd.data[i] = 0;
      }
    }
  });
  console.log(categories);
  console.log(seriesData);
  return { categories, seriesData };
};

/**
 * For Select questions to be stacked with no x-axis selected.
 * @param chartResponse chart response from the server
 */
export const stackOptions = (chartResponse: ChartResponse, model: ChartModel) => {
  const seriesData = chartResponse.series.map((response) => {
    return {
      name: response.x,
      data: [model.average ? getAverage(response.y, chartResponse.count) : response.y]
    };
  });
  const xAxis = chartResponse.xAxisTitle || chartResponse.chartTitle || '';
  const categories = [xAxis];
  return { categories, seriesData };
};

export const getBarChartObject = (
  chartModel: ChartModel, seriesData: any, categories: string[], title: string | null, xAxisTitle: string | null,
  subtitle?: string, xAxisLabels?: JSONInterface
) => {
  return {
    chart: {
      type: chartModel.verticalBars === '1' ? 'column' : 'bar',
      height: '70%'
    },
    title: {
      text: title
    },
    subtitle: {
      text: subtitle
    },
    yAxis: {
      min: 0,
      allowDecimals: chartModel.type !== 'BAR_CHOICE',
      title: {
          text: null,
      },
      labels: {
          overflow: 'justify'
      },
      stackLabels: {
        enabled: true,
        formatter: function() {
          const $this = this;
          console.log(this);
          // @ts-ignore // series.userOptions.stackLabel
          return $this.stack;
        }
      }
    },
    xAxis: {
      categories: categories,
      title: {
        text: xAxisTitle
      },
      labels: {
        formatter: function() {
          const $this = this;
          // @ts-ignore
          return xAxisLabels ? xAxisLabels[$this.value] : $this.value;
        }
      },
    },
    plotOptions: {
      series: {
        stacking: chartModel.stack ? 'normal' : null,
        dataLabels: {
          enabled: true
        }
      },
      bar: {
        dataLabels: {
            enabled: true
        }
      }
    },
    legend: {
      enabled: chartModel.xaxis && chartModel.combine !== 'LOCATION' && (
        chartModel.combine === 'FIELD' ||
        chartModel.stack === 'FIELD' ||
        chartModel.stack === 'OPTIONS' ||
        chartModel.type === 'BAR_CHOICE'
      )
    },
    series: seriesData,
    tooltip: {
      // @ts-ignore
      formatter: function() {
        // @ts-ignore
        return '<b>' + (xAxisLabels ? xAxisLabels[this.x] : this.x)
        + '</b><br/>'
        // @ts-ignore
        + (this.series.userOptions.label || '') + '<br/>'
        // @ts-ignore
        + this.series.name + ': ' + this.y + '<br/>' +
        // @ts-ignore
        (this.point.stackTotal ? 'Total: ' + this.point.stackTotal : '');
      }
    }
  };
};

/**
 * For bar chart for choices, the options become the x axis.
 */
export const switchChart = (chartResponse: ChartResponse) => {
  const newData = chartResponse.series.map((series) => {
    return {
      x: series.stack || '',
      y: series.y,
      stack: series.x
    };
  });
  return {...chartResponse, series: newData};
};

export const switchNumericCharts = (chartResponse: ChartResponse[], model: ChartModel, locations?: Locations) => {
  interface Series {
    name: string;
    data: number[];
  }
  const switchUp = (categories, seriesData) => {
    const newCategories: string[] = seriesData.map((sd) => sd.name);
    const newSeries: SeriesData[] = [];
    categories.forEach((category, index) => {
      const series: Series = {
        name: category,
        data:[]
      };
      seriesData.forEach( (sd) => {
        series.data.push(sd.data[index]);
      });
      newSeries.push(series);
    });
    return {
      categories: newCategories,
      seriesData: newSeries,
      title: ''
    };
  };
  if (model.combine === 'FIELD') {
    const combined = combineCharts(chartResponse, model);
    return switchUp(combined.categories, combined.seriesData);
  } else if (model.combine === 'LOCATION') {
    const chartGroups: {[key: string]: ChartResponse[]} = {};
    if (model.questions.length > 1) {
      chartResponse.forEach(c => {
        if (c.chartTitle) {
          chartGroups[c.chartTitle] = chartGroups[c.chartTitle] || [];
          chartGroups[c.chartTitle].push(c);
        }
      });
    } else {
      chartGroups[chartResponse[0].chartTitle || 'Chart 1'] = chartResponse;
    }
    const titleKeys = Object.keys(chartGroups);
    const response: any = [];
    titleKeys.forEach((chartGroup) => {
      const { categories, seriesData } = getCombineLocation(chartGroups[chartGroup], locations);
      const newSwicthed = switchUp(categories, seriesData);
      newSwicthed.title = chartGroup;
      response.push(newSwicthed);
    });
    return response;
  }
};

export const cleanSeriesData = (seriesData) => {
  seriesData.forEach((sd) => {
    for (let i = 0; i < sd.data.length; i++) {
      if (!sd.data[i]) {
        sd.data[i] = 0;
      }
    }
  });
  return seriesData;
};

export const getCombinedCharts = (combined, model: ChartModel, xAxisLabels, location?: string) => {
  const title = 'Combined charts ' + (location ? ` - ${location}` : '');
  const charts: any = [];
  if (combined.categories.length > 11) {
    // const length = combined.categories.length;
    const max = 10;
    // let count = 0;
    while (combined.categories.length > 0) {
      const newCat = combined.categories.splice(0, max);
      const seriesTemp = JSON.parse(JSON.stringify(combined.seriesData));
      // const index = count * max;
      combined.seriesData.forEach((series, index) => {
        seriesTemp[index].data = series.data.splice(0, max);
      });
      const chartObject = getBarChartObject(
        model, seriesTemp, newCat, title, '', '', xAxisLabels
      );
      chartObject['id'] = `${model.id}`;
      charts.push(chartObject);
      // count++;
    }
  } else {
    const chartObject = getBarChartObject(
      model, combined.seriesData, combined.categories, title, '', '', xAxisLabels
    );
    chartObject['id'] = model.id;
    charts.push(chartObject);
  }
  return charts;
};

export const groupChartResponsesByLocation = (data: ChartResponse[]) => {
  const groupedResponses: {[key: string]: ChartResponse[]} = {};
  data.forEach(cr => {
    if (cr.grouping) {
      groupedResponses[cr.grouping] = groupedResponses[cr.grouping] || [];
      groupedResponses[cr.grouping].push(cr);
    }
  });
  return groupedResponses;
};

export const getBarChartNoXAxis = (data: ChartResponse[], model: ChartModel, locations?: Locations) => {
  const charts: any = [];
  const seriesData: SeriesData[] = [];
  const categories: string[] = [];
  data.forEach((d) => {
    const l = locations?.find(l => `${l.key}` === `${d.grouping}`);
    if (l) {
      categories.push(l.title);
      d.series.forEach((s, index) => {
        const sd: SeriesData = seriesData[index] || {
          name: s.x,
          data: []
        };
        sd.data.push(s.y);
        if (!seriesData[index]) {
          seriesData[index] = sd;
        }
      });
    }
  });
  if (model.combine === 'LOCATION' && !model.stack && model.questions.length > 1) {
    seriesData.forEach((sd, index) => {
      const readyChart = getBarChartObject(
        model, [sd], categories, sd.name, ''
      );
      readyChart['id'] = data[index]['id'];
      charts.push(readyChart);
    });
  } else {
    const readyChart = getBarChartObject(
      model, seriesData, categories, 'Combined charts', ''
    );
    readyChart['id'] = data[0]['id'];
    charts.push(readyChart);
  }
  return charts;
};

/**
 * When combine field is selected and there is an x-axis defined,
 * this function organizes the data and returned the chart objects
 * @param data - ChartResponse list from the server
 * @param model - ChartModel with parameters
 * @param xAxisLabels - X-Axis labels
 * @param locations - List of Locations
 */
export const getCombinedFieldCharts = (
  data: ChartResponse[], model: ChartModel, xAxisLabels?: JSONInterface, locations?: Locations
) => {
  const charts: any = [];
  if (model.grouping) {
    const groupedResponses: {[key: string]: ChartResponse[]} = groupChartResponsesByLocation(data);
    const keys = Object.keys(groupedResponses);
    keys.forEach((key) => {
      const combined = combineCharts(groupedResponses[key], model);
      const location = locations?.find(l => `${l.key}` === key);
      getCombinedCharts(combined, model, xAxisLabels, location ? location.title : undefined)
        .forEach((chart) => charts.push(chart));
    });
  } else {
    const combined = combineCharts(data, model);
    if (combined.seriesData.length > 10) {
      const max = 10;
      while (combined.seriesData.length > 0) {
        const newSeries = combined.seriesData.splice(0, max);
        getCombinedCharts({
          categories: combined.categories, seriesData: newSeries
        }, model, xAxisLabels).forEach((chart) => charts.push(chart));
      }
    } else {
      getCombinedCharts(combined, model, xAxisLabels).forEach((chart) => charts.push(chart));
    }
  }
  return charts;
};

export const getCharts = (
  model: ChartModel, data: ChartResponse[], form: FormInterface, forms: FormInterface[], locations?: Locations
) => {
  const customX = () => {
    return !model.xaxis && ((model.stack === 'FIELD' && model.grouping) || (model.combine === 'LOCATION'));
  };
  let charts: any = [];
  let xAxisLabels: JSONInterface | undefined;
  if (model.formId.indexOf('-') !== -1) {
    const formUtil = getFormUtils(form);
    const id = model.formId.split('-');
    const question = formUtil.getQuestion(id[1]);
    if (question) {
      const table = question.table;
      if (table) {
        const columns = table.columns;
        if (columns && columns.column) {
          const xAxisLabel = {};
          columns.column[0].question.forEach((qn) => {
            if (!qn.inVisible && !qn.deleted) {
              xAxisLabel[qn.id] = qn.text;
            }
          });
          xAxisLabels = xAxisLabel;
        }
      }
    }
  } else if (form.type === 'TABLE' && form.static) {
    const mainFormId = form.source;
    if (mainFormId) {
      const mainForm = forms.find(f => f.ref === mainFormId);
      if (mainForm) {
        const formUtil = getFormUtils(mainForm);
        if (form.questionId) {
          const rows = formUtil.getRows(form.questionId);
          const xAxisLabel = {};
          rows.forEach(row => {
            if (row.id) {
              xAxisLabel[row.id] = row.value || row.id;
            }
          });
          xAxisLabels = xAxisLabel;
        }
      }
    }
  }
  // if (model.grouping && !)
  if (model.combine === 'FIELD') {
    if (!model.xaxis && model.stack === 'OPTIONS') {
      const categories: string[] = [];
      const seriesData: any = [];
      data.forEach((response, index) => {
        const chartData = stackOptions(response, model);
        categories.push(chartData.categories[0]);
        chartData.seriesData.forEach((sd) => {
          const ind = seriesData.findIndex( sd1 => sd1.name === sd.name);
          if (ind === -1) {
            const tempData: number[] = [];
            tempData[index] = model.average ? getAverage(sd.data[0], response.count) : sd.data[0];
            seriesData.push({
              name: sd.name,
              data: tempData
            });
          } else {
            const sd1 = seriesData[ind];
            sd1.data[index] = model.average ? getAverage(sd.data[0], response.count) : sd.data[0];
          }
        });
      });
      const chartObject = getBarChartObject(
        model, cleanSeriesData(seriesData), categories, '', ''
      );
      chartObject['id'] = model.id;
      // chartObject['responseId'] = chartResponse.id;
      charts.push(chartObject);
    } else {
      charts = charts.concat(getCombinedFieldCharts(data, model, xAxisLabels, locations));
    }
  } else if (customX()) {
    charts = charts.concat(getBarChartNoXAxis(data, model, locations));
  } else if (model.combine === 'LOCATION') {
    const chartGroups: {[key: string]: ChartResponse[]} = {};
    if (model.questions.length > 1) {
      fillMissingDateValues(data).forEach(c => {
        if (c.chartTitle) {
          chartGroups[c.chartTitle] = chartGroups[c.chartTitle] || [];
          chartGroups[c.chartTitle].push(c);
        }
      });
    } else {
      chartGroups[data[0].chartTitle || 'Chart 1'] = data;
    }
    const titleKeys = Object.keys(chartGroups);
    const stackedSeries:any[] = [];
    const mainCategories: string[] = [];
    titleKeys.forEach((chartGroup) => {
      const { categories, seriesData } = getCombineLocation(chartGroups[chartGroup], locations);
      if (!model.stack) {
        getCombinedCharts({ categories, seriesData }, model, xAxisLabels, chartGroup).forEach((chart) => {
          charts.push(chart);
        });
      } else {
        if (mainCategories.length === 0) {
          categories.forEach(c => mainCategories.push(c));
        }
        seriesData.forEach(sd => {
          sd.stack = sd.name;
          sd.name = chartGroup;
          stackedSeries.push(sd);
        });
      }
    });
    if (stackedSeries.length > 0) {
      getCombinedCharts({
        categories: mainCategories, seriesData: cleanSeriesData(stackedSeries)
      }, model, xAxisLabels).forEach((chart) => {
        charts.push(chart);
      });
    }
  } else {
    data.forEach((chartResponse) => {
      let readyChart;
      switch (model.type) {
        case 'BAR_CHOICE':
        case 'BAR_NUMERIC':
          if (model.stack === 'OPTIONS' && !model.xaxis) {
            const chart = stackOptions(chartResponse, model);
            readyChart = getBarChartObject(
              model, chart.seriesData, chart.categories, chartResponse.chartTitle, ''
            );
            readyChart['id'] = chartResponse['id'];
            charts.push(readyChart);
          } else {
            readyChart = getBarChart(model, chartResponse, xAxisLabels, locations);
            charts = charts.concat(readyChart);
          }
          break;
        default: break;
      }
    });
  }
  return charts;
};


export const fillMissingDateValues = (chartResponse: ChartResponse[]) => {
  const xAxisValues: string[] = [];
  chartResponse.forEach(xAxis => {
    xAxis.series.forEach(element => {
      if (xAxisValues.indexOf(element.x) === -1) {
        xAxisValues.push(element.x);
      }
    });
  });
  chartResponse.forEach(xAxis => {
    const tempXAxis = [...xAxisValues];
    xAxis.series.forEach(el => {
      const index = tempXAxis.indexOf(el.x);
      tempXAxis.splice(index, 1);
    });
    tempXAxis.forEach(el => {
      xAxis.series.push({
        x: el, y: 0
      });
    });
    xAxis.series.sort(compare);
  });
  return chartResponse;
};

export const getCombineLocation = (chartResponse: ChartResponse[], locations?: Locations) => {
  const groupedResponses: {[key: string]: ChartResponse[]} =
  groupChartResponsesByLocation(chartResponse);
  const keys = Object.keys(groupedResponses);
  const categories: string[] = [];
  const seriesData: SeriesData[] = [];
  // index would be the data index in the series.
  keys.forEach((key, index) => {
    const location = locations?.find(l => `${l.key}` === key);
    if (location) {
      categories.push(location.title);
      groupedResponses[key].forEach((gr) => {
        // sIndex would be the index in series data
        gr.series.forEach((series, sIndex) => {
          seriesData[sIndex] = seriesData[sIndex] || { name: series.x, data: []};
          seriesData[sIndex].data[index] = series.y;
        });
      });
    }
  });
  return { categories, seriesData };
};

export const getPieChartObject = (chartModel: ChartModel, chartResponse: ChartResponse) => {
  const data = chartResponse.series.map((series) => {
    return {
      name: series.x,
      y: chartModel.average ? getAverage(series.y, chartResponse.count) : series.y
    };
  });
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      text: chartResponse.chartTitle
    },
    subtitle: {
      text: `Data points: ${(chartResponse.count)}`
    },
    tooltip: {
      pointFormat: 'value: {point.y}, <b>{point.percentage:.1f}%</b>'
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        },
        showInLegend: true
      }
    },
    series: [
      {
        name: '',
        colorByPoint: true,
        data: data
      }
    ]
  };
};

export const getSeriesCategories = (model: ChartModel, chartResponse: ChartResponse, cummulative?: boolean) => {
  const stacks = getUniq(chartResponse.series.map((series) => series.stack).filter(s => s));
  const categories: any[] = [];
  const seriesData: any[] = [];
  chartResponse.series.forEach((series, i) => {
    const index = stacks.length === 0 || !series.stack ? 0 : stacks.indexOf(series.stack);
    if (!seriesData[index]) {
      seriesData[index] = {
        name: !series.stack ? chartResponse.chartTitle : series.stack,
        data: []
      };
    }
    const v = model.average ? getAverage(series.y, chartResponse.count) : series.y;
    if (model.scale === 'WEEK') {
      categories[Number(i) + 1] = series.x;
      seriesData[index].data.push([Number(i) + 1, v]);
    } else if (model.scale === 'YEAR') {
      seriesData[index].data.push([Date.parse(`${Number(series.x)}`), v]);
    } else {
      seriesData[index].data.push([Date.parse(series.x), v]);
    }
  });
  console.log(seriesData);
  if (cummulative) {
    for (const series of seriesData) {
      series.data.forEach((value, index) => {
        if (index > 0) {
          value[1] = series.data[index - 1][1] + series.data[index][1];
        }
      });
    }
  }
  return { categories, seriesData };
};

export const getLineChart = (
  model: ChartModel, chartResponse: ChartResponse, cummulative?: boolean, locations?: Locations
) => {
  let title = '';
  if (model.grouping) {
    const l = locations?.find(l => `${l.key}` === `${chartResponse.grouping}`);
    if (l) {
      title = l.title;
    }
  }

  const { categories, seriesData } = getSeriesCategories(model, chartResponse, cummulative);
  return getLineChartObject(
    `${title}`,
    chartResponse.count,
    chartResponse.id,
    seriesData,
    `${chartResponse.chartTitle}`,
    `${chartResponse.xAxisTitle}`,
    categories,
    `${model.scale}`
  );
};

/**
 * This function returns charts with the fields combined into one chart.
 * If there is any grouping to be applied, the fields are combined per group.
 *
 * @param model Chart model with the parameters
 * @param chartResponse Chart response from the server
 * @param cummulative Whether we generate a cummulative chart
 * @param locations List of locations
 */
export const getCombinedLineChart = (
  model: ChartModel,
  chartResponse: ChartResponse[],
  cummulative?: boolean,
  locations?: Locations
) => {
  const charts:any[] = [];
  if (model.grouping) {
    const chartGroup: {[key:string]: ChartResponse[]} = {};
    chartResponse.forEach(response => {
      if (response.grouping) {
        chartGroup[response.grouping] = chartGroup[response.grouping] || [];
        chartGroup[response.grouping].push(response);
      }
    });

    const keys = Object.keys(chartGroup);
    keys.forEach(key => {
      const l = locations?.find(l => `${l.key}` === `${key}`);
      if (l) {
        const { mainSeries, mainCategories } = getMainSeriesCategories(model, chartGroup[key], cummulative);
        charts.push(getLineChartObject(
          `Combined ${l.title}`,
          chartResponse[0].count,
          chartResponse[0].id,
          mainSeries,
          ``,
          ``,
          mainCategories[0],
          `${model.scale}`
        ));
      }
    });
  } else {
    const { mainSeries, mainCategories } = getMainSeriesCategories(model, chartResponse, cummulative);
    charts.push(getLineChartObject(
      `Combined`,
      chartResponse[0].count,
      chartResponse[0].id,
      mainSeries,
      ``,
      ``,
      mainCategories[0],
      `${model.scale}`
    ));
  }
  return charts;
};

export const getMainSeriesCategories = (model: ChartModel, r: ChartResponse[], cummulative?: boolean) => {
  const mainCategories: any[] = [];
  const mainSeries: any[]  = [];
  r.forEach(response => {
    const { categories, seriesData } = getSeriesCategories(model, response, cummulative);
    mainCategories.push(categories);
    mainSeries.push(seriesData[0]);
  });
  return { mainCategories, mainSeries };
};

export const getLineChartObject = (
  title: string, count: number, id: string, series: any[], yAxisTitle: string, xAxisTitle: string, categories: any[],
  scale: string
) => {
  return {
    title: {
      text: `${title}`
    },
    subtitle: {
        text: `Data points: ${(count)}`
    },
    yAxis: {
      title: {
        text: yAxisTitle
      }
    },
    xAxis: {
      type: scale !== 'WEEK' ? 'datetime' : undefined,
      tickInterval: scale === 'MONTH' ? (24 * 3600 * 1000 * 30) : undefined,
      accessibility: {
        rangeDescription: xAxisTitle
      },
      categories: categories.length > 0 ? categories : undefined
    },
    legend: {
      enabled: true
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        // pointStart: 2010
      }
    },
    series: series,
    responseId: id
  };
};

export const getCombinedLocationLineChart = (
  model: ChartModel,
  chartResponse: ChartResponse[],
  cummulative?: boolean,
  locations?: Locations
) => {

  const chartGroups: {[key: string]: ChartResponse[]} = {};
    chartResponse.forEach(c => {
      if (c.chartTitle) {
        chartGroups[c.chartTitle] = chartGroups[c.chartTitle] || [];
        const l = locations?.find(l => `${l.key}` === `${c.grouping}`);
        if (l) {
          const cg = {...c};
          cg.chartTitle = l.title;
          chartGroups[c.chartTitle].push(cg);
        }
      }
    });

    const titleKeys = Object.keys(chartGroups);
    const charts:any[] = [];
    titleKeys.forEach((key) => {
      const chartGroup = chartGroups[key];
      const { mainSeries, mainCategories } = getMainSeriesCategories(model, chartGroup, cummulative);
        charts.push(getLineChartObject(
          `${key}`,
          chartGroup[0].count,
          chartGroup[0].id,
          mainSeries,
          ``,
          ``,
          mainCategories[0],
          `${model.scale}`
        ));
    });
    return charts;
};
