import '../styles/UserQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { Button } from 'react-bootstrap';
import { ConfirmationModal } from '../../../views/Modals/ConfirmationModal';
import { DeleteButtonProps } from './Containers/DeleteButtonContainer';

interface State {
  showAlert: boolean;
}

export default class DeleteButton extends React.Component <DeleteButtonProps, State> {

  constructor(props) {
    super(props);
    this.state = {
      showAlert: false
    };
  }

  @bind
  private onClick() {
    this.setState({ showAlert: true });
  }

  @bind
  private deleteConfirmed() {
    const { dataPoint, unrender } = this.props;
    this.setState({ showAlert: false });
    if (dataPoint.row_id && dataPoint.id && dataPoint.questionnaire_id) {
      this.props.deletePOI(dataPoint.row_id, dataPoint.id, dataPoint.questionnaire_id, unrender);
    }
  }

  @bind
  private closeAlert() {
    this.setState({ showAlert: false });
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        { this.state.showAlert ? (
          <ConfirmationModal
            onConfirm={this.deleteConfirmed}
            onClose={this.closeAlert}
            localizations={
              {
                cancel: 'Cancel',
                confirm:'Delete',
                confirmStyle: 'danger',
                header: (
                  <label>Confirm</label>
                ),
                body: (
                  <div>Delete POI?</div>
                )
              }
            }
          />
        )
        : null }
        <Button size="sm" onClick={this.onClick} variant="danger" id="delete-btn">
          <i className="fa fa-trash" aria-hidden="true" />
        </Button>
      </React.Fragment>
    );
  }
}
