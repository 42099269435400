export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const UPDATE_ALERT = 'UPDATE_ALERT';
export const UPDATE_CONFIRM = 'UPDATE_CONFIRM';

export const SYSTEM = {
  LOAD_PROPERTIES: 'SYSTEM_LOAD_PROPERTIES'
};

export const AJAX_CALL = {
  BEGIN: 'BEGIN_AJAX_CALL',
  END: 'END_AJAX_CALL',
  ERROR: 'AJAX_CALL_ERROR'
};

export const NAVIGATION = {
  ADD_MODAL: 'NAVIGATION_ADD_MODAL',
  REMOVE_MODAL: 'NAVIGATION_REMOVE_MODAL',
  CHANGE_MODAL: 'NAVIGATION_CHANGE_MODAL'
};

export const CLIENT_PERSIST = {
  UPDATE: 'CLIENT_PERSIST_UPDATE',
  UPDATE_FROM_LEGACY: 'CLIENT_PERSIST_FROM_LEGACY_UPDATE',
  RESET: 'CLIENT_PERSIST_RESET',
  SET_LANG: 'CLIENT_PERSIST_SET_LANG'
};

export const USER = {
  LOGOUT: 'USER_LOGOUT',
  UPDATE_PASSWORD_OR_EMAIL: 'USER_UPDATE_PASSWORD_OR_EMAIL',
  UPGRADE_ACCOUNT: 'USER_UPGRADE_ACCOUNT',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS'
};

// Only generic actions here
export const FILTERS_MENU = {
  TOGGLE: 'FILTERS_MENU_TOGGLE',
  RESIZE: 'FILTERS_MENU_RESIZE',
  SELECT_FORM: 'FILTERS_MENU_SELECT_FORM',
  UNSELECT_FORM: 'FILTERS_MENU_UNSELECT_FORM',
  SELECT_ALL_FORMS: 'FILTERS_MENU_SELECT_ALL_FORMS',
  UNSELECT_ALL_FORMS: 'FILTERS_MENU_UNSELECT_ALL_FORMS',
  SELECT_UNSELECT_LOCATIONS: 'FILTERS_MENU_SELECT_UNSELECT_LOCATIONS',
  SELECT_ALL_LOCATIONS: 'FILTERS_MENU_SELECT_ALL_LOCATIONS',
  UNSELECT_ALL_LOCATIONS: 'FILTERS_MENU_UNSELECT_ALL_LOCATIONS',
  SELECT_UNSELECT_USERS: 'FILTERS_SELECT_UNSELECT_USERS',
  SET_SELECTED_DATES: 'SET_SELECTED_DATES'
};

export const FORMS = {
  LOAD: 'FORMS_LOAD',
  LOAD_HIERARCHY_COUNTS: 'LOAD_FORMS_HIERARCHY_COUNTS'
};

export const TOTAL_POI_COUNT = {
  LOAD: 'TOTAL_POI_COUNT_LOAD'
};

export const HIERARCHY = {
  LOAD: 'HIERARCHY_LOAD',
  SET_LOCATION_HIERARCHY: 'HIERARCHY_SET_LOCATION' // TODO REMOVE
};

export const MODULE_SELECTION = {
  TOGGLE: 'MODULE_SELECTION_TOGGLE',
  SET_SINGLE_INSTANCE: 'MODULE_SET_SINGLE_INSTANCE',
  UNSET_SINGLE_INSTANCE: 'MODULE_UNSET_SINGLE_INSTANCE'
};

export const UPDATE_ANSWER = 'UPDATE_ANSWER';

export const USERS = {
  SET_USERS: 'SET_USERS',
  SET_TASK_USERS: 'SET_TASK_USERS'
};

export const DIAGRAMS = {
  LIST_LOADED: 'DIAGRAMS_LIST_LOADED'
};

export const DATAPOINTS = {
  LOADED: 'DATAPOINTS_LOADED',
  UPDATED: 'DATAPOINTS_UPDATED'
};

export const LOCATIONS = {
  LOAD: 'LOCATIONS_LOAD',
  ADD_NEW: 'LOCATIONS_ADD_NEW'
};

export const FEED = {
  LOADING: 'LOADING',
  LOADED: 'FEED_LOADED',
  CLEAR: 'CLEAR_FEED',
  ADD: 'FEED_ADD',
  UPDATE: 'FEED_UPDATE',
  CLEAR_AUTO_UPDATE: 'CLEAR_AUTO_UPDATE',
  DELETE: 'DELETE',
  USER_UPDATE: 'USER_UPDATE',
};
export const REPORTS = {
  LOADED: 'REPORTS_LOADED'
};

export const IMPORT = {
  LOADED: 'IMPORT_MAPPINGS_LOADED'
};

export const GALLERY = {
  SELECT_FORM: 'GALLERY_SELECT_FORM'
};

export const CHARTS = {
  LOADED: 'CHARTS_LOADED',
  SELECT: 'SELECT_CHART',
  UNSELECT: 'UNSELECT_CHART',
  NEW: 'NEW_CHART',
  SAVED: 'CHART_SAVED',
  DELETE: 'DELETE_CHART_REPORT',
  SELECT_SINGLE_INSTANCE_CHART: 'SELECT_SINGLE_INSTANCE_CHART',
  UNSELECT_SINGLE_INSTANCE_CHART: 'UNSELECT__SINGLE_INSTANCE_CHART',
};

export const TABLE = {
  SELECT_TABLE: 'SELECT_TABLE'
};

export const SCHEDULE_ANSWERS = {
  LOAD: 'SCHEDULE_ANSWERS_LOAD'
};


export const SHARE = {
  LOAD_DATA: 'SHARE_LOAD_DATA',
  UNSHARE: 'UNSHARE_DATA'
};
