import { FormInterface } from '../Interfaces/Forms/FormsInterface';
import { fetchPOI } from './pois';
import { actionCreator } from './';
import { SCHEDULE_ANSWERS } from './actionTypes';
import { PayloadType } from '../reducers/scheduleAnswersReducer';
import { getDateFiltersSelector, getLocationHierarchyQuerySelector } from '../reducers/filtersMenuReducer';
import { StateInterface } from '../Interfaces/StateInterface';

export const loadScheduleAnswers = actionCreator<PayloadType>(SCHEDULE_ANSWERS.LOAD);

export const fetchSchedulePOIs = (formRef: FormInterface['ref']) => {
  return async (dispatch, getState: () => StateInterface) => {
    const fields = ['Name', 'id', 'user_id', 'location1', 'location2', 'location3', 'location4', 'questionnaire_id', 'Coordinates', 'created'];
    const state = getState();
    const locationHierarchyQuery = getLocationHierarchyQuerySelector(state);
    const datesQuery = getDateFiltersSelector(state);
    const response = await (dispatch(fetchPOI(
        formRef,
        undefined,
        undefined,
        datesQuery,
        locationHierarchyQuery,
        fields.join(','),
        undefined,
        ['core=true']
    )));
    response.json().then((dataPoints) => {
      dispatch(loadScheduleAnswers({dataPoints, formRef}));
    });
  };
};
