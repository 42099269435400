import { DataPoint } from './DataPoint';
import { FormInterface } from './Forms/FormsInterface';

export enum MODULES {
  FEED = 'FEED',
  MAP = 'MAP',
  TABLE = 'TABLE',
  MAP_AND_TABLE = 'MAP_AND_TABLE',
  GALLERY = 'GALLERY',
  CHARTS = 'CHARTS',
  TASKS = 'TASKS',
  SCHEDULE = 'SCHEDULE',
  SHARED_DATA = 'SHARED_DATA'
}

export interface SingleInstance {
  model: FormInterface;
  dataPoint: DataPoint;
  newAnswer: boolean;
  onSave?: (answerId: string, scheduleDate?: string) => void;
}

export interface ModuleSelectionInterface {
  selected: MODULES;
  singleInstance?: SingleInstance;
}
