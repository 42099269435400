import { CLIENT_PERSIST } from './actionTypes';
import { actionCreator } from './index';
import { ClientPersistInterface, languagesEnum } from '../Interfaces/ClientPersistInterface';

export const updateClientPersist = actionCreator<Partial<ClientPersistInterface>>(CLIENT_PERSIST.UPDATE);

export const setLanguageClientPersist = actionCreator<languagesEnum>(CLIENT_PERSIST.SET_LANG);

export const resetClientPersist = actionCreator(CLIENT_PERSIST.RESET);

// NOTE: ALL Actions below are only to be called from legacy
export const updateClientPersistFromLegacy = actionCreator<Partial<ClientPersistInterface>>
(CLIENT_PERSIST.UPDATE_FROM_LEGACY);

export const getClientPersist = () => {
  // This function is defined so that Backbone
  // can fetch the values from the store
  // DO NOT USE THIS IN REACT!!
  return (dispatch, getState) => {
    return getState().clientPersist;
  };
};
