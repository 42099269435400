import * as React from 'react';
import L from 'leaflet';
import { Icon } from 'leaflet';
import { MapIcons } from './Icons';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import { SingleInstance } from '../../../Interfaces/ModuleSelectionInterface';
import { FiltersMenuInterface } from '../../../Interfaces/FiltersMenuInterface';
import DeleteButtonContainer from '../../SingleInstance/Buttons/Containers/DeleteButtonContainer';
import { globalWindow } from '../../../global/global';
import { keys } from 'lodash-es';
import { getFormUtils } from '../../SingleInstance/utils/FormUtilsHolder';
import { canDelete } from '../../SingleInstance/utils/RolesChecker';
import { canHaveSubQuestions, getOptionTexts } from '../../SingleInstance/utils/utils';
import { isNullOrUndefined } from '../../../utils/utils';

export const xy = (x, y) => {
    const yx = L.latLng;
    return yx(y, x);  // When doing xy(x, y);
};

/*
 * Returns the marker Icon for the given datapoint.
 */
export const getMarkerIcon = (dataPoint, forms) => {
  const form = forms.find( f => f.ref === dataPoint.questionnaire_id);
  if (form) {
    const iconUrl = getIconUrl(forms, form);
    return getIcon(iconUrl);
  }
  return null;
};

export const getIcon = (iconUrl) => {
  return new Icon({
    iconUrl: `${globalWindow.location.origin}${iconUrl}`, iconSize: [32, 32]
  });
};

/*
 * Returns the status icon for the given POI.
 */
export const getStatusIcon = (dataPoint) => {
  switch (dataPoint['Status']) {
    case 'Approved':
      return MapIcons.STATUS_GREEN;
    case 'Needs editing':
    case 'Needs_editing':
      return MapIcons.STATUS_RED;
    default:
      return MapIcons.STATUS_YELLOW;
  }
};

/*
 * Returns the icon url for the given form.
 */
export const getIconUrl = (forms, form) => {
  const index = forms.indexOf(form);
  let iconUrl = form.smallIconUrl;
  if (!iconUrl) {
    iconUrl = `/icons/small/poi_${(index % 20) + 1}.png`;
  }
  return iconUrl;
};

export const getPopupContent = (
  dataPoint, form, clientPersist: ClientPersistInterface, deletePOI, setSingleInstance
) => {
  if (!form) {
    return (
      <div className="leaflet-popup-fixed">
        <table className="table table-striped">
        <tr key={`${name}-${dataPoint['id']}`}>
          <th className="popup-text">
            Name
          </th>
          <td className="popup-text">
            {dataPoint['Name']}
          </td>
        </tr>
        </table>
      </div>
    );
  }
  const formUtils = getFormUtils(form);
  const questions = keys(dataPoint).reverse();

  const popUpContent: (JSX.Element | null)[] = questions.map((id) => {
    const qn = formUtils.getQuestion(id);
    if (qn) {
      if (qn && !isNullOrUndefined(dataPoint[id]) && !Array.isArray(dataPoint[id]) ) {
        return (
          <tr key={`${id}-${dataPoint['id']}`}>
            <th className="popup-text">
              {qn.text}
            </th>
            <td className="popup-text">
              {canHaveSubQuestions(qn) ? getOptionTexts(qn, `${dataPoint[id]}`.split(',')) : dataPoint[id]}
            </td>
          </tr>
        );
      }
    }
    return null;
  });
  const singleInstance: SingleInstance = {
    model: form,
    dataPoint: dataPoint,
    newAnswer: false
  };
  return (
    <React.Fragment>
      <h5>{formUtils.getModel().name}</h5>
      <div className="leaflet-popup-fixed pop-up-view">
        <table className="table table-striped">
          <tbody>
            {popUpContent}
          </tbody>
        </table>
      </div>
      <a className="btn btn-primary btn-sm" href="#" role="button" onClick={() => setSingleInstance(singleInstance)}>
        <i className="fa fa-search" aria-hidden="true" />
      </a>
      {canDelete(dataPoint, clientPersist) &&
        (
        <span className="pull-right">
          <DeleteButtonContainer dataPoint={dataPoint} unrender={deletePOI} bsSize="xsmall"/>
        </span>
        )
      }
    </React.Fragment>
  );
};

/*
 * Should check if filters have changed.
 * If a form has been selected/deselected.
 * If location/user/data filters have changed.
 * if no change in form selection but there is change in other filtres, should return true.
 * if there is a change in form selection, just return true immediately.
 */
export const filtersChanged = (prevFiltersMenu: FiltersMenuInterface, nextFiltersMenu: FiltersMenuInterface) => {
  const forms1 = prevFiltersMenu.selectedForms;
  const forms2 = nextFiltersMenu.selectedForms;
  const a: string[] = [];
  for (const f of forms1) {
    a.push(f.ref);
  }
  for (const f of forms2) {
    const index = a.indexOf(f.ref);
    if (index !== -1) {
      a.splice(index, 1);
    } else {
      a.push(f.ref);
    }
  }
  if (a.length > 0) {
    return true;
  }
  if (prevFiltersMenu.selectedLocations.length !== nextFiltersMenu.selectedLocations.length ||
    prevFiltersMenu.selectedUsers.length !== nextFiltersMenu.selectedUsers.length ||
    prevFiltersMenu.selectedDates.from !== nextFiltersMenu.selectedDates.from ||
    prevFiltersMenu.selectedDates.to !== nextFiltersMenu.selectedDates.to) {
    return true;
  }
  return false;
};
