import * as React from 'react';
import bind from 'bind-decorator';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import './styles/MapComponent.scss';
import { getLocalization } from '../../global/global';
import GenericModal from '../Modals/GenericModal';
import { FeatureGroup, GeoJSON, LayersControl, Map, Marker, Polygon, Polyline, Popup, TileLayer } from 'react-leaflet';
import Control from 'react-leaflet-control';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { DataPoint } from '../../Interfaces/DataPoint';
import { EditControl } from 'react-leaflet-draw';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import MapboxTileLayer from './layers/MapboxTileLayer';
import MapQuestTileLayer from './layers/MapQuestTileLayer';
import { MapPropsInterface } from './MapContainer';
import { globalWindow } from '../../global/global';
import { Icon } from 'leaflet';
import { filtersChanged, getIcon, getMarkerIcon, getPopupContent, getStatusIcon } from './utils/utils';
import { SingleInstance } from 'Interfaces/ModuleSelectionInterface';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import MapPrintComponent from './MapPrintComponent';
import { Button } from 'react-bootstrap';
import { LoadingComponent } from '../components/LoadingComponent';
import axios from 'axios';
import { getRequestConfig } from '../../api';
import ReactLeafletKml from 'react-leaflet-kml';
import { getFormUtils } from '../SingleInstance/utils/FormUtilsHolder';
import { processGeocodedData } from '../SingleInstance/utils/utils';
// import omnivore from '@mapbox/leaflet-omnivore';
// import L from 'leaflet';

interface State {
  diagramName: string | undefined;
  maxZoom: number;
  zoomLoaded: boolean;
  bounds: number[] | null;
  filtersMenu: FiltersMenuInterface;
  mapData: DataPoint[];
  showAddPoiModal: boolean;
  showMapPrintModal: boolean;
  loading: boolean;
  overlays: JSX.Element[];
}

export default class MapComponent extends React.Component <MapPropsInterface, State> {
  private drawnItems = React.createRef<FeatureGroup>();
  private diagramCenter: number[] = [65, -45];
  private icons: {[key: string]: Icon} = {};
  private tempDataPoint: DataPoint | undefined;
  private map = React.createRef<Map>();
  private abortController: AbortController = new AbortController();
  private singlePoiAbortController: AbortController = new AbortController();
  constructor(props) {
    super(props);
    this.drawnItems = React.createRef();
    this.map =  React.createRef();
    if (globalWindow.L.mapquest) {
      globalWindow.L.mapquest.key = 'uZQeSzBpsHHVezXicXfHrUApxQTfCCje'; // 'Fmjtd|luurn9u12d,a5=o5-9wzgda';
    }
    const { clientPersist } = props;
    this.map =  React.createRef();
    this.state = {
      diagramName: props.diagramName, // props.diaramName
      maxZoom: props.diagramName ? -1 : `${clientPersist.fullZoom}` === 'true' ? 20 : 17,
      zoomLoaded: false,
      bounds: null,
      filtersMenu: props.filtersMenu,
      mapData: [],
      showAddPoiModal: false,
      showMapPrintModal: false,
      loading: false,
      overlays: []
    };

    // hackish way, override a function from leaflet.draw to work better in touch screens
    // @ts-ignore
    if (parseFloat(L.drawVersion) <= 1.0 && !L._patched) {
      // @ts-ignore
      L._patched = true;
      // @ts-ignore
      L.Draw.Marker.prototype.addHooks = function() {
        // @ts-ignore
        L.Draw.Feature.prototype.addHooks.call(this);

        if (this._map) {
          this._tooltip.updateContent({text: this._initialLabelText});
          this._tooltip.updatePosition(this._map.getCenter()); // this line is added

          // Same mouseMarker as in Draw.Polyline
          if (!this._mouseMarker) {
            // @ts-ignore
            this._mouseMarker = L.marker(this._map.getCenter(), {
              // @ts-ignore
              icon: L.divIcon({
                className: 'leaflet-mouse-marker',
                iconAnchor: [20, 20],
                iconSize: [40, 40]
              }),
              opacity: 0,
              zIndexOffset: this.options.zIndexOffset
            });
          }

          this._mouseMarker
            .on('click', this._onClick, this)
            .addTo(this._map);

          this._map.on('mousemove', this._onMouseMove, this);
          this._map.on('click', this._onTouch, this);
        }
      };
    }
  }

  @bind
  private getMaxZoom() {
    if (this.state.diagramName) {
      this.props.getTileMapResource(this.state.diagramName, this.updateMaxZoom);
    }
  }

  @bind
  private updateMaxZoom(maxZoom: number, bounds: number[] | null) {
    this.setState({ maxZoom: maxZoom, zoomLoaded: true, bounds: bounds });
  }

  @bind
  private onFeatureAdded(e) {
    this.drawnItems.current.leafletElement.clearLayers();
    const { onFeatureAdded, id, addPoiForms } = this.props;
    if (id === 'global-map') {
      const dp = {};
      const latlng = e.layer.getLatLng();
      dp['Coordinates'] = latlng.lat + ' ' + latlng.lng;
      this.tempDataPoint = dp;
      const validForms = addPoiForms.filter((f) => f.hasCoordinates && !f.isChild);
      if (validForms.length > 1) {
        this.setState({ showAddPoiModal: true });
      } else if (validForms.length === 1) {
        this.onFormSelected(validForms[0]);
      }
    }
    if (onFeatureAdded) {
      onFeatureAdded(e);
    }
  }

  @bind
  private onFormSelected(form: FormInterface) {
    this.hideModal();
    if (this.tempDataPoint) {
      const singleInstance: SingleInstance = {
        model: form,
        dataPoint: {...this.tempDataPoint, questionnaire_id: form.ref, formId: form.id},
        newAnswer: true
      };
      if (form.includeAddress) {
        const values = this.tempDataPoint.Coordinates.split(' ');
        this.props.reverseGeoCode(
          values[0], values[1], (geoCodedData) => this.processReverseGeocode(geoCodedData, form)
        );
      } else {
        this.props.setSingleInstance(singleInstance);
      }
    }
  }

  @bind
  private processReverseGeocode(geoCodedData, form: FormInterface) {
    const singleInstance: SingleInstance = {
      model: form,
      dataPoint: {...this.tempDataPoint, questionnaire_id: form.ref, formId: form.id},
      newAnswer: true
    };
    if (geoCodedData.data) {
      const formUtils = getFormUtils(form);
      const address = processGeocodedData(geoCodedData, formUtils).data;
      this.props.setSingleInstance({...singleInstance, dataPoint: {...singleInstance.dataPoint, ...address}});
    } else {
      this.props.setSingleInstance(singleInstance);
    }
  }

  @bind
  private getAddPoiSelection() {
    const { forms } = this.props;
    return (
      <ol className="add-poi-list">
        {forms.map((f) => {
          return f.hasCoordinates && !f.isChild && (
            <li key={`add-poi-${f.ref}`}>
              <a href="#" onClick={() => this.onFormSelected(f)}>{f.name}</a>
            </li>
          );
        })}
      </ol>
    );
  }

  @bind
  private getPosition(model) {
    if (model.Coordinates) {
      let value = model.Coordinates;
      if (value.indexOf('POINT(') !== -1) {
        value = value.replace('POINT(', '');
        value = value.replace(')', '');
      }
      return value.split(' ');
    }
    return null;
  }

  @bind
  private deletePOI(dataPoint) {
    const newMapData = this.state.mapData.filter((dp) => {
      return dp.id !== dataPoint.id;
    });
    this.setState({ mapData: newMapData });
  }

  @bind
  private getMarkers() {
    const { forms, dataPoint, clientPersist, setSingleInstance, propsDataPoints } = this.props;
    const routes: any[] = [];
    const areas: any[] = [];
    const routeAreaMarkers: Marker = [];
    const submittedMarkers: Marker = [];
    const approvedMarkers: Marker = [];
    const needsEditingMarkers: Marker = [];

    const formsHolder = {};
    const getForm = (ref) => {
      if (formsHolder[ref]) {
        return formsHolder[ref];
      }
      const form = forms.find( f => f.ref === ref);
      formsHolder[ref] = form;
      return form;
    };
    const createMarkers = (dp) => {
      let position = this.getPosition(dp);
      if (dp.questionnaire_id) {
        let icon;
        const form = getForm(dp.questionnaire_id);
        if (form && form.includeStatus && position) {
          icon = getStatusIcon(dp);
          switch (dp['Status']) {
            case 'Approved':
              approvedMarkers.push(
                <Marker key={`${dp.row_id}_${dp.questionnaire_id}`} position={position} icon={icon}>
                  <Popup>
                    {getPopupContent(
                      dp, getForm(dp.questionnaire_id), clientPersist, () => this.deletePOI(dp), setSingleInstance
                    )}
                  </Popup>
                </Marker>
              );
              break;
            case 'Needs editing':
            case 'Needs_editing':
              needsEditingMarkers.push(
                <Marker key={`${dp.row_id}_${dp.questionnaire_id}`} position={position} icon={icon}>
                  <Popup>
                    {getPopupContent(
                      dp, getForm(dp.questionnaire_id), clientPersist, () => this.deletePOI(dp), setSingleInstance
                    )}
                  </Popup>
                </Marker>
              );
              break;
            default:
              submittedMarkers.push(
                <Marker key={`${dp.row_id}_${dp.questionnaire_id}`} position={position} icon={icon}>
                  <Popup>
                    {getPopupContent(
                      dp, getForm(dp.questionnaire_id), clientPersist, () => this.deletePOI(dp), setSingleInstance
                    )}
                  </Popup>
                </Marker>
              );
          }
          position = null;
        } else {
          if (this.icons[dp.questionnaire_id]) {
            icon = this.icons[dp.questionnaire_id];
          } else {
            icon = getMarkerIcon(dp, forms);
            this.icons[dp.questionnaire_id] = icon;
          }
        }
        if (dp['ROUTE']) {
          const route = this.getRoute(dp);
          if (route) {
            routes.push([route]);
            routeAreaMarkers.push((
              <Marker
                key={`route_${dp.row_id}_${dp.questionnaire_id}`}
                position={route[0]}
                icon={icon}
              />
            ));
          }
        }
        if (dp['AREA']) {
          const area = this.getArea(dp);
          if (area) {
            areas.push([area]);
            routeAreaMarkers.push((
              <Marker
                key={`area_${dp.row_id}_${dp.questionnaire_id}`}
                position={area[0]}
                icon={icon}
              />
            ));
          }
        }
        if (position) {
          // icon={icon}
          return (
            <Marker key={`${dp.row_id}_${dp.questionnaire_id}`} position={position} icon={icon}>
              <Popup>
                {getPopupContent(
                  dp, getForm(dp.questionnaire_id), clientPersist, () => this.deletePOI(dp), setSingleInstance
                )}
              </Popup>
            </Marker>
          );
        }
      }
      return null;
    };

    const propsDatapointMarkers = propsDataPoints && propsDataPoints.map((dp) => {
      return createMarkers(dp);
    });

    if (dataPoint) {
      routeAreaMarkers.push(createMarkers(dataPoint));
    }
    const allMarkers = submittedMarkers.concat(approvedMarkers).concat(needsEditingMarkers)
      .concat(propsDatapointMarkers || []);
    const serverSideMarkers = this.getServerSideMarkers();
    return (
      <React.Fragment>
        {routes.length > 0 || serverSideMarkers.routes.length > 0 ?
          (<Polyline positions={routes.concat(serverSideMarkers.routes)} />) : null}
        {areas.length > 0 || serverSideMarkers.routes.length > 0 ?
          (<Polygon positions={areas.concat(serverSideMarkers.areas)} />) : null}
        {this.clusterMarkers(allMarkers.concat(serverSideMarkers.markers).concat(routeAreaMarkers))}
      </React.Fragment>
    );
  }

  @bind
  private getServerSideMarkers() {
    const { mapData } = this.state;
    const { forms, clientPersist, setSingleInstance } = this.props;
    const formsHolder = {};
    const getForm = (ref) => {
      if (formsHolder[ref]) {
        return formsHolder[ref];
      }
      const form = forms.find( f => f.ref === ref);
      formsHolder[ref] = form;
      return form;
    };
    const routes: string[][] = [];
    const areas: string[][] = [];
    const routeAreaMarkers: JSX.Element[] = [];
    const markers = mapData.map((dp) => {
      const icon = dp.row_id ? getMarkerIcon(dp, forms) : getIcon(dp.iconUrl);
      if (dp['ROUTE']) {
        const route = this.getRoute(dp);
        if (route) {
          routes.push([route]);
          routeAreaMarkers.push((
            <Marker
              key={`route_${dp.row_id}_${dp.questionnaire_id}`}
              position={route[0]}
              icon={icon}
            />
          ));
        }
      }
      if (dp['AREA']) {
        const area = this.getArea(dp);
        if (area) {
          areas.push([area]);
          routeAreaMarkers.push((
            <Marker
              key={`area_${dp.row_id}_${dp.questionnaire_id}`}
              position={area[0]}
              icon={icon}
            />
          ));
        }
      }
      if (dp.row_id) {
        return (
          <Marker
            key={`${dp.row_id}_${dp.questionnaire_id}`}
            position={this.getPosition(dp)}
            icon={getMarkerIcon(dp, forms)}
          >
            <Popup>
              {getPopupContent(
                dp, getForm(dp.questionnaire_id), clientPersist, () => this.deletePOI(dp), setSingleInstance
              )}
            </Popup>
          </Marker>
        );
      }

      if (dp.coordinates) {
        return (
          <Marker
            key={`${dp.id}_${dp.formId}`}
            position={[dp.coordinates[1], dp.coordinates[0]]}
            onclick={() => dp.formId ? this.loadSinglePoi(dp) : null}
            icon={getIcon(dp.iconUrl)}
          >
            <Popup>
              {<p>{dp['popupContent']}</p>}
            </Popup>
          </Marker>
        );
      }
      return null;
    });
    return {
      markers: markers.concat(routeAreaMarkers),
      routes,
      areas
    };
  }

  @bind
  private loadSinglePoi(dp) {
    this.singlePoiAbortController.abort();
    this.singlePoiAbortController = new AbortController();
    const request = this.props.fetchPOI(dp.formId, this.singlePoiAbortController.signal, Number(dp.id));
    this.onPOILoaded(request);
  }

  @bind
  private onPOILoaded(request) {
    request.then(response => response.json()).then((json) => {
      if (json.length === 1) {
        const newMapData = this.state.mapData.map((dp) => {
          if (dp.id === json[0].row_id) {
            return json[0];
          }
          return dp;
        });
        this.setState({ mapData: newMapData });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  /*
   * Given a datapoint, this functions returns the points for the route polyline.
   */
  @bind
  private getRoute(dataPoint) {
    let route = dataPoint['ROUTE'];
    route = route.substring(route.indexOf('(') + 1, route.indexOf(')'));
    let points = route.split(',');
    points = points.splice(0, points.length - 1);
    points.forEach((point, i) => {
      point = point.split(' ');
      points[i] = [point[0], point[1]];
    });
    return points;
  }

  /*
   * Given a datapoint, this function returns the points for the area polygon
   */
  @bind
  private getArea(dataPoint) {
    let area = dataPoint['AREA'];
    area = area.substring(area.lastIndexOf('(') + 1, area.indexOf(')'));
    let points = area.split(',');
    points = points.splice(0, points.length - 1);
    points.forEach((point, i) => {
      point = point.split(' ');
      points[i] = [point[0], point[1]];
    });
    return points;
  }

  /*
   * Clusters the markers.
   */
  @bind
  private clusterMarkers(markers) {
    return (
      <MarkerClusterGroup>
        {markers}
      </MarkerClusterGroup>
    );
  }

  /*
   * This function is used to set map data for forms which user server side pagination.
   * Since all the data cannot be loaded to the client we only load the coordinates & name.
   */
  @bind
  private setMapData(request) {
    if (request) {
      request.then(response => response.json()).then((json) => {
        this.setState({ mapData: json, loading: false });
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  @bind
  private hideModal() {
    this.setState({ showAddPoiModal: false });
  }

  @bind
  private setMapPrintModalVisibility(showMapPrintModal: boolean) {
    this.setState({ showMapPrintModal });
  }

  /**
   *  This function initiates the loading of map data.
   *  It would first cancel any pending requests before initiating a new one.
   */
  @bind
  private fetchMapData() {
    this.abortController.abort();
    this.abortController = new AbortController();
    const request = this.props.fetchMapData(this.abortController.signal);
    this.setState({ loading: true });
    this.setMapData(request);
  }

  /*
   * For global map, when the component is mounted, we load map data.
   */
  public componentDidMount() {
    if (this.props.id === 'global-map') {
      this.addOverlays();
      if (this.state.filtersMenu.selectedForms.length > 0) {
        this.setState({ loading: true }, () => this.fetchMapData());
      }
    }
  }
  /*
   * If this component is for the map area (not single instance), we check if we should load map data
   * for server side rendering.
   */
  public componentDidUpdate(prevProps, prevState) {
    if (this.props.id === 'global-map' && filtersChanged(prevProps.filtersMenu, this.props.filtersMenu)) {
      this.fetchMapData();
    }
  }

  public componentWillUnmount() {
    this.abortController.abort();
    this.singlePoiAbortController.abort();
  }

  public static getDerivedStateFromProps(props: MapPropsInterface, state: State) {
    if (props.diagramName !== state.diagramName) {
      return { diagramName: props.diagramName, maxZoom: -1, zoomLoaded: false };
    }
    if (props.filtersMenu.selectedLocations !== state.filtersMenu.selectedLocations) {
      return { filtersMenu: props.filtersMenu };
    }
    return null;
  }

  @bind
  private getMapCenter() {
    const { locationHierarchy, diagramName, dataPoint } = this.props;
    const mapCenter = locationHierarchy.centre ? locationHierarchy.centre.split(',') : [0, 0];
    return diagramName ? this.diagramCenter :
          dataPoint && dataPoint.Coordinates ? this.getPosition(dataPoint) : mapCenter;
  }

  @bind
  private getMapPrintButton() {
    const { dataPoint, id } = this.props;
    const { diagramName} = this.state;
    return diagramName && id.startsWith('diagram_map') && dataPoint && dataPoint['row_id'] && (
      <Control position="topleft" >
          <Button
            className="map-print-custom-control"
            size="sm"
            onClick={() => this.setMapPrintModalVisibility(true)}
          >
            <i className="fa fa-print" />
          </Button>
        </Control>
    );
  }

  @bind
  private getMapPrintUrl() {
    const { diagramName, dataPoint, id, clientPersist, dataPoints } = this.props;
    if (dataPoints && dataPoint) {
      const zoom = this.map.current.leafletElement.getZoom();
      const center = this.map.current.leafletElement.getCenter();
      const centerJSON = {
        lat: center.lat,
        lng: center.lng
      };
      const bounds = this.map.current.leafletElement.getBounds();
      const boundJSON = {
        neLat: bounds._northEast.lat,
        neLng: bounds._northEast.lng,
        seLat: bounds._southWest.lat,
        seLng: bounds._southWest.lng
      };

      const pois = dataPoints; // this.getSubformPOIs();
      const ids: string[] = [];
      for (const subForm of pois) {
        if (subForm['row_id']) {
          ids.push(`${subForm['row_id']}`);
        }
      }

      const params: string[] = [];
      params.push(`bounds=${encodeURIComponent(JSON.stringify(boundJSON))}`);
      params.push(`zoom=${zoom}`);
      params.push(`center=${encodeURIComponent(JSON.stringify(centerJSON))}`);
      params.push(`listName=false`);
      params.push(`showFirstLetter=true`);
      params.push(`diagramName=${diagramName}`);
      params.push(`row_ids=${ids.join(',')}`);
      params.push(`account=${clientPersist.instance}`);
      params.push(`userid=${clientPersist.userName}`);
      params.push(`key=${globalWindow.pwrd }`);
      const diagramDiv = document.getElementById(id);
      if (diagramDiv) {
        params.push(`width=${diagramDiv.offsetHeight}`);
        params.push(`height=${diagramDiv.offsetHeight}`);
      }
      return `/json/app/data/printMap/${dataPoint['questionnaire_id']}/${dataPoint['row_id']}?${params.join('&')}`;
    }
    return undefined;
  }

  @bind
  private addOverlays() {
    const getFileUrl = async (fileId: string) => {
      const headers = getRequestConfig();
        const response = await axios.get(`/json/file/${fileId}`, {
          headers: {
            'userid': headers.headers.userid,
            'key': headers.headers.key
          }
        });
        return response.data;
    };
    this.props.groupDiagrams.forEach(async (gd) => {
      if (gd['type'] === 'geojson') {
        const url = await getFileUrl(`${gd['imageFileId']}`);
        const geoJson = await axios.get(url);
        const { overlays } = this.state;
        this.setState({ overlays: overlays.concat([(
          <LayersControl.Overlay key={`gd-${gd.id}`} name={gd.name || gd.filename} checked={false}>
            <GeoJSON
              data={geoJson.data}
            />
          </LayersControl.Overlay>
        )])});
      } else if (gd['type'] === 'kml') {
        const url = await getFileUrl(`${gd['imageFileId']}`);
        const kmlResponse = await axios.get(url);
        const { overlays } = this.state;
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmlResponse.data, 'text/xml');
        this.setState({ overlays: overlays.concat([(
          <LayersControl.Overlay key={`gd-${gd.id}`} name={gd.name || gd.filename} checked={false}>
            <ReactLeafletKml kml={kml} />
          </LayersControl.Overlay>
        )])});
      } else if (gd['type'] === 'tiff') {
        const baseUrl = 'http://poimapper-uploads.s3.amazonaws.com';
        this.setState((prevState, prevProps) => {
          return {
            ...prevState,
            overlays: prevState.overlays.concat([(
              <LayersControl.Overlay key={`gd-${gd.id}`} name={gd.name || gd.filename} checked={false}>
                <TileLayer
                  url={`${baseUrl}/poimapper.${globalWindow.db}/diagrams/${gd['identifier']}-folder/{z}/{x}/{y}.png`}
                  opacity={0.7}
                  attribution={'&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
                />
              </LayersControl.Overlay>
            )])
          };
        });
      }
    });
  }

  public render(): JSX.Element {
    const { dataPoint, onFeatureAdded, id } = this.props;
    const { diagramName, maxZoom, zoomLoaded, showMapPrintModal, loading, overlays } = this.state;
    const editOpts = id === 'global-map' ? {featureGroup: null, edit: false, remove: false} : undefined;
    const addPoint = onFeatureAdded || id === 'global-map' ? (
      <FeatureGroup ref={this.drawnItems}>
        <EditControl
          position="topleft"
          onCreated={this.onFeatureAdded}
          edit={editOpts}
          draw={{
            polygon: false,
            polyline: false,
            rectangle: false,
            circle: false,
            circlemarker: false
          }}
        />
      </FeatureGroup>
    ) : null;

    const baseUrl = 'http://poimapper-uploads.s3.amazonaws.com';
    const mapLayer = diagramName ? (
      <TileLayer
        attribution={'Poimapper map service'}
        url={`${baseUrl}/poimapper.${globalWindow.db}/diagrams/${diagramName}-folder/{z}/{x}/{y}.png`}
        noWrap={true}
      />
    ) : globalWindow.L.mapquest ? (
      <MapQuestTileLayer
        attribution={''}
      />
    ) : (
      <MapboxTileLayer />);
    if (diagramName && maxZoom === -1 && !zoomLoaded) {
      this.getMaxZoom();
    }
    const addPoiView = this.state.showAddPoiModal && (
      <GenericModal
        visible={this.state.showAddPoiModal}
        body={this.getAddPoiSelection()}
        confirmText={getLocalization('cancel')}
        onConfirm={this.hideModal}
        title={getLocalization('selectform')}
      />
    );

    const mapPrintComponent = showMapPrintModal && (
      <MapPrintComponent
        dataPoint={dataPoint}
        url={this.getMapPrintUrl()}
        onClose={this.setMapPrintModalVisibility}
      />
    );
    return (
      <React.Fragment>
        <Map
          center={this.getMapCenter()}
          zoom={diagramName ? 2 : 5}
          id={this.props.id}
          maxZoom={maxZoom === -1 ? 5 : maxZoom}
          minZoom={diagramName ? 1 : 2}
          ref={this.map}
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked={true} name="Map">
              {mapLayer}
            </LayersControl.BaseLayer>
            {overlays}
          </LayersControl>
          {mapPrintComponent}
          {addPoiView}
          {addPoint}
          {mapPrintComponent}
          {this.getMarkers()}
          {this.getMapPrintButton()}
        </Map>
        {loading && (<LoadingComponent />)}
      </React.Fragment>
    );
  }
}
