import FormUtils from './FormUtils';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { uniq } from 'lodash-es';
import { updateScriptObject } from './ScriptUtils';
import { DataPoint } from '../../../Interfaces/DataPoint';

export const filterForms = (formUtils: FormUtils, dataPoint: DataPoint, forms: LooseObject[],
                            question, pois: LooseObject) => {
  const allVariables: string[] = uniq(question.script.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));
  const obj: LooseObject = {}; // mapping for _spss -> value
  const localSPSSToId: LooseObject = {}; // mapping for _spss -> id
  for (const v of allVariables) {
    if (v.indexOf('.') !== -1) { // this may be summing a table, a lookup value.
      const prefix = v.substr(0, v.indexOf('.'));
      const questionId = formUtils.getQuestionId(prefix);
      localSPSSToId[v] = questionId;
    } else {
      const id = formUtils.getQuestionId(v);
      localSPSSToId[v] = id;
    }
  }

  // go through all the datapoints referenced by the lookup question
  // set the question value to match each datapoint one-by-one
  const origQuestionValue = dataPoint[question.id];
  const formPois: LooseObject[] = pois[question.lookupValue] as LooseObject[];
  const ret: LooseObject[] = [];
  for (const poi of formPois) {
    dataPoint[question.id] = poi.id;
    const response = updateScriptObject(
      localSPSSToId, obj, formUtils, dataPoint, forms, [], [], undefined, undefined, pois
    );
    const {vars} = response;

    let scriptValue = null;
    try {
      scriptValue = eval('(function() {' + vars.join(' ') + question.script + '})();');
    } catch (e) {
      // empty
    }
    if (scriptValue) {
      ret.push(poi);
    }
  }
  dataPoint[question.id] = origQuestionValue;
  return ret;
};
