import { DataPoint } from '../Interfaces/DataPoint';
import { LooseObject } from '../Interfaces/LooseObject';
import { toast } from 'react-toastify';
import * as POIApi from '../api/pois';
import { dataURItoBlob, extractFiles, getMapFetchParams, getPOIRowId, prepareFiles } from './utils/pois';
import { getLocalization, globalWindow } from '../global/global';
import { DATAPOINTS } from './actionTypes';
import { deleteFeedItems } from './feed';
import { StateInterface } from '../Interfaces/StateInterface';
import { getLocationHierarchyQuerySelector, QueryFiltersInterface } from '../reducers/filtersMenuReducer';
import { galleryMenuSelector } from '../reducers/galleryMenuReducer';
import { FormInterface } from '../Interfaces/Forms/FormsInterface';
import { JSONInterface } from 'Interfaces/JsonInterface';

export const fetchPOI = (
    formRef: FormInterface['ref'],
    signal?: AbortSignal,
    rowId?: number,
    filter?: QueryFiltersInterface[],
    query?: LooseObject,
    fields?: string,
    sort?: LooseObject,
    requestParams?: string[]
) => (dispatch, getState): Promise<Response> => {
  const params: any = requestParams ? requestParams : [];
  const queryParams = query || {};
  if (rowId) {
    queryParams['row_id'] = rowId;
  }
  if (fields) {
    params.push(`fields=${fields}`);
  }
  if (queryParams) {
    params.push(`query=${encodeURIComponent(JSON.stringify(queryParams))}`);
  }
  if (filter) {
    params.push(`filter=${encodeURIComponent(JSON.stringify(filter))}`);
  }
  if (sort) {
    const sortParam = {
      sortdatafield : sort.dataField,
      sortorder: sort.order
    };
    params.push(`sort=${encodeURIComponent(JSON.stringify(sortParam))}`);
  }
  const url = `json/app/answer/${formRef}?version=6&from=portal&${params.join('&')}`;
  return POIApi.getPOI(
      url, signal
  );
};

export const fetchGalleryPOIs = (subFormIds: string[], requestParams?: string[]
) => {
  return  (dispatch, getState: () => StateInterface): Promise<Response> => {
    return new Promise(async (resolve, reject) => {
      const state = getState();
      const locationHierarchyQuery = getLocationHierarchyQuerySelector(state);
      const selectedForm = galleryMenuSelector(state).selectedForm;
      const fields = ['Name', 'id', 'user_id', 'location1', 'location2', 'location3', 'location4', 'questionnaire_id', 'file'];
      if (selectedForm) {
        const response = await (dispatch(fetchPOI(
            selectedForm,
            undefined,
            undefined,
            undefined,
            locationHierarchyQuery,
            [...fields, ...subFormIds].join(','),
            undefined,
            requestParams
        )));
        response.json().then((json) => {
          dispatch(dataPointsLoaded(json, selectedForm));
          resolve(new Response(JSON.stringify({ loaded: true, count: json.length })));
        });
      } else {
        reject(new Response(JSON.stringify({ loaded: false })));
      }
    });
  };
};


export const dataPointsLoaded = (dataPoints: DataPoint[], formRef: FormInterface['ref']) => (
  { type: DATAPOINTS.LOADED, dataPoints, formRef }
);

export const dataPointUpdated = (dataPoint, formId) => (
  { type: DATAPOINTS.UPDATED, dataPoint, formId }
);

export const deletePOI = (rowId: number, id: string, formId: string, callBack: () => void) => {
  return (dispatch, getState) => {
    const deletePromise = POIApi.doDeletePOI(rowId, id, formId);
    deletePromise.then(response => response.json()).then((json) => {
      if (json.status === 'OK') {
        dispatch(deleteFeedItems([id]));
        callBack();
      }
    }).catch((error) => {
      console.log(error);
    });
  };
};

export const sharePOI = (id: string) => {
  return (dispatch, getState) => {
    const sharePromise = POIApi.doSharePOI(id);
    sharePromise.then(response => response.json()).then((json) => {
      console.log(json);
    }).catch((error) => {
      console.log(error);
    });
  };
};

/*export const saveComment = (id: string, userName: string, comment: LooseObject) => {
  return (dispatch, getState) => {
    const savePromise = POIApi.doSaveComment(id, userName, comment);
    savePromise.then(response => response.json()).then((json) => {
      console.log(json);
    }).catch((error) => {
      console.log(error);
    });
  };
};*/

export const saveComment = (id: string, userName: string, comment: LooseObject) =>
  (dispatch, getState): Promise<Response> => {
  return new Promise((resolve, reject) => {
    const request = POIApi.doSaveComment(id, userName, comment);
    request.then(response => resolve(response)).catch((error) => {
      reject(error); // console.log(error);
    });
  });
};

export const deleteComment = (id: string) => {
  return (dispatch, getState) => {
    const deletePromise = POIApi.doDeleteComment(id);
    deletePromise.then(response => response.json()).then((json) => {
      console.log(json);
    }).catch((error) => {
      console.log(error);
    });
  };
};

export const deletePOIs = (ids: string[], formId: string, callBack?: (ids: string[]) => void) => {
  return (dispatch, getState) => {
    const deleteToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(`Deleting ${ids.length} POIs`, {
      toastId: deleteToastId, type: toast.TYPE.INFO, autoClose: false, closeButton: false, hideProgressBar: true,
      closeOnClick: false
    });
    const deletePromise = POIApi.doDeletePOIs(ids, formId);
    deletePromise.then(response => response.json()).then((json) => {
      if (json.status === 'OK') {
        toast.update(deleteToastId, {
          type: toast.TYPE.SUCCESS,
          render: 'POIs deleted.'
        });
        setTimeout(() => toast.dismiss(deleteToastId), 3000);
        dispatch(deleteFeedItems(ids));
        if (callBack) {
          callBack(ids);
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  };
};

export const fetchHistory = (
  answerTable: string, formId: string, poiId: string, callBack: (dataPoint: DataPoint[]) => void
) => {
  return (dispatch, getState) => {
    const savePromise = POIApi.doFetchHistory(answerTable, formId, poiId);
    savePromise.then(response => response.json()).then((json) => {
      if (json.length > 0) {
        callBack(json);
      } else {
        toast(`No history available`, {
          type: toast.TYPE.INFO, autoClose: 3000, closeButton: false, hideProgressBar: true,
          closeOnClick: false
        });
        callBack([]);
      }
    }).catch((error) => {
      console.log(error);
    });
  };
};

export const sendReminders = (pois) => {
  return (dispatch, getState) => {
    const deleteToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('sendingReminders'), {
      toastId: deleteToastId, type: toast.TYPE.INFO, autoClose: false, closeButton: false, hideProgressBar: true,
      closeOnClick: false
    });
    const remindersPromise = POIApi.doSendReminders(pois);
    remindersPromise.then(response => response.json()).then((json) => {
      if (json.status === 'OK') {
        toast.update(deleteToastId, {
          type: toast.TYPE.SUCCESS,
          render: getLocalization('remindersSent')
        });
      } else {
        toast.update(deleteToastId, {
          type: toast.TYPE.ERROR,
          render: getLocalization('errorSendingReminders')
        });
      }
      setTimeout(() => toast.dismiss(deleteToastId), 3000);
    }).catch((error) => {
      console.log(error);
    });
  };
};

/*
 * This function is used to load server side map data.
 */
export const fetchMapData = (signal: AbortSignal) => {
  return (dispatch, getState): Promise<Response> => {
    return new Promise((resolve, reject) => {
      const state = getState();
      const request = POIApi.doFetchMapData(
        getMapFetchParams(state.filtersMenu, state.forms.collection), signal
      );
      request.then(response => resolve(response)).catch((error) => {
        reject(error); // console.log(error);
      });
    });
  };
};

export const savePOI = (dataPoint: DataPoint, parameters?: JSONInterface) =>
  (dispatch, getState): Promise<Response> => {
  return new Promise((resolve, reject) => {
    const groupedData = extractFiles(Object.assign({}, dataPoint));
    let url = 'json/app/answer/';
    const config = {};
    // we are updating an existing POI
    if (dataPoint.id) {
      config['method'] = 'PUT';
      url = url + `${dataPoint.questionnaire_id}/${dataPoint.id}`;
    } else {
      config['method'] = 'POST';
      url = url + `${dataPoint.questionnaire_id}`;
    }
    config['credentials'] = 'same-origin';
    config['body'] = JSON.stringify(groupedData.dataPoint);

    const poiFiles = groupedData.files;
    const saveToastId = Date.now() + Math.floor(Math.random() * 100);
    toast('Saving data', {
      toastId: saveToastId, type: toast.TYPE.INFO, autoClose: false, closeButton: false, hideProgressBar: true,
      closeOnClick: false
    });
    if (parameters) {
      const keys = Object.keys(parameters);
      url = url + '?' + keys.map((key) => {
        return `${key}=${parameters[key]}`;
      }).join('&');
    }
    const promise = POIApi.doSavePOI(url, config);
    promise.then(response => response.json()).then((json) => {
      if (json.status === 'OK') {
        toast.update(saveToastId, {
          type: toast.TYPE.SUCCESS,
          render: 'Data saved.'
        });
        const newRowId = getPOIRowId(dataPoint, json);
        const newId = json.id;
        setTimeout(() => toast.dismiss(saveToastId), 3000);

        if (poiFiles.length > 0) {
          const preparedFiles = prepareFiles(poiFiles, json, newRowId);

          const { files, diagrams, updateFiles } = preparedFiles;

          const fileConfig = {};
          fileConfig['method'] = 'POST';
          fileConfig['credentials'] = 'same-origin';
          fileConfig['body'] = JSON.stringify(files);

          if (files.length > 0) {
            const filePrepId = Date.now() + Math.floor(Math.random() * 100);
            toast('Preparing files...', {
              toastId: filePrepId, type: toast.TYPE.INFO, autoClose: false, closeButton: false, hideProgressBar: true
            });
            const filesPromise = POIApi.doSaveFiles(fileConfig);
            filesPromise.then(response => response.json()).then((json) => {
              let i = 0;
              toast.update(filePrepId, {
                type: toast.TYPE.SUCCESS,
                render: 'File prepared.'
              });
              setTimeout(() => toast.dismiss(filePrepId), 3000);
              for (const f of json) {
                let fileObj;
                if (f.thumbnail) {
                  fileObj = dataURItoBlob(f.thumnailsrc);
                } else {
                  fileObj = poiFiles[files[i].fileObj].file;
                }
                const fileUploadId = Date.now() + Math.floor(Math.random() * 100);
                toast('Uploading file...', {
                  toastId: fileUploadId, type: toast.TYPE.INFO
                });
                // const uploadPromise = POIAPi.uploadToPoimapper(fileObj, f.id, f.thumbnail);
                const uploadPromise = POIApi.uploadToS3(fileObj, f.url);
                uploadPromise.then(resp => {
                  toast.update(fileUploadId, {
                    type: toast.TYPE.SUCCESS,
                    render: 'File uploaded.'
                  });
                  setTimeout(() => toast.dismiss(fileUploadId), 3000);
                }).catch((error) => {
                  console.log(error);
                });
                i++;
              }
              // setTimeout(() => callBack(newRowId), 2000);
              resolve(new Response(JSON.stringify({ rowId: newRowId, id: newId })));
            }).catch((error) => {
              console.log(error);
            });
          }
          if (updateFiles.length > 0) {
            const fileUpdateId = Date.now() + Math.floor(Math.random() * 100);
            toast('Updating files...', {
              toastId: fileUpdateId, type: toast.TYPE.INFO, autoClose: false, closeButton: false, hideProgressBar: true
            });

            fileConfig['method'] = 'PUT';
            fileConfig['body'] = JSON.stringify(updateFiles);
            const filesPromise = POIApi.doSaveFiles(fileConfig);
            filesPromise.then((response) => {
              toast.update(fileUpdateId, {
                type: toast.TYPE.SUCCESS,
                render: 'File updated.'
              });
              setTimeout(() => toast.dismiss(fileUpdateId), 3000);
              if (files.length === 0) {
                resolve(new Response(JSON.stringify({ rowId: newRowId, id: newId })));
                // setTimeout(() => callBack(newRowId), 2000);
              }
            }).catch((error) => {
              console.log(error);
            });
          }
          if (diagrams.length > 0) {
            fileConfig['method'] = 'POST';
            const diagramPromise = POIApi.doUploadFile(
              `/json/app/uploadDiagram/${diagrams[0].rowId}/${diagrams[0].questionId}/${globalWindow.groupID}`,
              fileConfig,
              diagrams[0].file
            );
            diagramPromise.then(response => response.json()).then((json) => {
              console.log(json);
            }).catch((error) => {
              console.error(error);
            });
          }
        } else {
          resolve(new Response(JSON.stringify({ rowId: newRowId, id: newId })));
          // setTimeout(() => callBack(newRowId), 2000);
        }
      } else {
        toast.update(saveToastId, {
          type: toast.TYPE.ERROR,
          render: 'Error saving data.'
        });
        setTimeout(() => toast.dismiss(saveToastId), 3000);
        resolve(new Response(JSON.stringify({ rowId: -1, error: json.error })));
      }
    }).catch((error) => {
      toast.update(saveToastId, {
        type: toast.TYPE.ERROR,
        render: error.message || getLocalization('saveErrorAlert')
      });
      setTimeout(() => toast.dismiss(saveToastId), 3000);
      reject(error);
    });
  });
};
