import * as React from 'react';
import { globalWindow } from '../../../global/global';

interface Props {
  view?: JSX.Element;
  url: string;
}

const MediaPreview = (props: Props) => {
  return (
    <React.Fragment>
      <div className="container">
        <img src={props.url} className="img-responsive"/>
      </div>
      <div className="row text-center">
        <button
          className="btn btn-default btn-sm action-btn rotate-right"
          onClick={() => globalWindow.open(props.url, '_blank')}
        >
          <i className="fa fa-download" />
        </button>
      </div>
    </React.Fragment>
  );
};

export default MediaPreview;
