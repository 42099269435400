import { Action } from 'typescript-fsa';
import { FORMS } from '../actions/actionTypes';
import ReducerBuilder from './ReducerBuilder';
import { FormInterface, FormsInterface, sortFormsByName } from '../Interfaces/Forms/FormsInterface';
import { StateInterface } from '../Interfaces/StateInterface';
import { createSelector } from 'reselect';
import { filtersMenuSelectedFormsSelector } from './filtersMenuReducer';
import { FiltersMenuInterface, filtersSelectedFormType } from '../Interfaces/FiltersMenuInterface';
import { QuestionPageInterface } from '../Interfaces/Forms/QuestionPageInterface';
import { QuestionInterface, questionTypes } from '../Interfaces/Forms/QuestionInterface';
import { languageSelector } from './clientPersistReducer';

export const INITIAL_STATE: FormsInterface = {
  loaded: false,
  collection: []
};

export interface LoadFormHierarchyPayload {
  ref: FormInterface['ref'];
  hierarchyCounts: FormInterface['hierarchyCounts'];
}

export type PayloadType = FormInterface[] | LoadFormHierarchyPayload;

function loadForms(state: FormsInterface, {payload}): FormsInterface {
  return {
    loaded: true,
    collection: payload
  };
}

function loadFormHierarchyPayload(state: FormsInterface, {payload}): FormsInterface {
  let form = state.collection.find((form) => form.ref === payload.ref);
  if (form) {
    const collection = state.collection.filter((form) => form.ref !== payload.ref);
    form = {...form, hierarchyCounts: payload.hierarchyCounts};
    return {
      ...state,
      collection: [...collection, form]
    };
  }
  return state;
}

export default new ReducerBuilder<FormsInterface, Action<PayloadType>>()
    .setInitialState(INITIAL_STATE)
    .addReducer(FORMS.LOAD, loadForms)
    .addReducer(FORMS.LOAD_HIERARCHY_COUNTS, loadFormHierarchyPayload)
    .build();

// Selectors
export const formsSelector = (state: StateInterface): FormsInterface => state.forms;

export const uniqueFormsIDsSelector = createSelector<StateInterface, FormsInterface, string[]>(formsSelector, forms => {
  const ids: string[] = [];
  if (forms.collection) {
    forms.collection.forEach((form: FormInterface) => {
      if (ids.indexOf(form.ref) === -1) {
        ids.push(form.ref);
      }
    });
  }
  return ids;
});

export const uniqueFormsSelector = createSelector<
  StateInterface,
  string,
  FormsInterface,
  string[],
  FormInterface[]
>(languageSelector, formsSelector, uniqueFormsIDsSelector, (lang, forms, ids) => {
  const filteredForms = ids.map(id => {
    let form: FormInterface | undefined;
    let anyForm: FormInterface | undefined;
    forms.collection.forEach(f => {
      if (f.ref === id) {
        // if there is a form with no view language we set it as the form to be used.
        if ((f.lang === '' || !f.lang) && !form) {
          form = f;
        } else if (f.lang === lang) {
          // if we encounter a form which has a view that matches the language set we set it to be used.
          form = f;
        } else {
          // here we just assign any form found to this temp variable. if no form matches the language.
          // whichever form will be held by this variable is what will be used.
          anyForm = f;
        }
      }
    });
    if (form) {
      return form;
    }
    return anyForm;
  });
  const cleanedForms: any[] = filteredForms.filter(f => f !== undefined);
  return cleanedForms;
});


export const nonChildFormsSelector = createSelector<StateInterface, FormsInterface, FormInterface[]>(
    formsSelector,
    ({collection}) => collection ? collection.filter(form => !form.isChild) : []
);

export const nonTableTypeFormsSelector = createSelector<StateInterface, FormsInterface, FormInterface[]>(
    formsSelector,
    ({collection}) => collection ? collection.filter(form => form.type !== 'TABLE') : []
);

export const formsForFiltersSelector = createSelector<
    StateInterface,
    FormInterface[],
    FormInterface[],
    FormInterface[],
    FormInterface[]
    >(
    uniqueFormsSelector,
    nonChildFormsSelector,
    nonTableTypeFormsSelector,
    (nonChildForms, nonTableForms) => nonChildForms
        .filter(form => nonTableForms.includes(form))
        .sort(sortFormsByName));

export const addPoiFormsSelector = createSelector<StateInterface, FormInterface[], FormInterface[]>(
  formsForFiltersSelector, (forms) => forms.filter(f => !f.dataReadOnly)
);

export const formsWithGPSSelector = createSelector<StateInterface, FormsInterface, FormInterface[]>(
  formsSelector,
  ({collection}) => collection ? collection.filter(form => !form.isChild && form.hasCoordinates === true) : []
);

export const addPoiFormsWithGpsSelector = createSelector<StateInterface, FormInterface[], FormInterface[]>(
  formsWithGPSSelector, (forms) => forms.filter(f => !f.dataReadOnly)
);

export const taskFormsSelector = (state: StateInterface): FormInterface[] =>
state.forms.collection ? state.forms.collection.filter(f => f.type === 'TASKFORM') : [];

// Returns a list of forms that are selected from the checklist
export const selectedFormsSelector = createSelector<
    StateInterface,
    FormInterface[],
    FiltersMenuInterface['selectedForms'],
    FormInterface[]
    >(
    formsForFiltersSelector,
    filtersMenuSelectedFormsSelector,
    (formsList, selectedForms) => {
      const formReferences = selectedForms.map(({ref}) => ref);
      return formsList
          .filter(form => formReferences.includes(form.ref));
    });

// returns forms that are used for filters and contain images
const filterFormsWithImagesSelector = createSelector<
    StateInterface,
    FormInterface[],
    FormsInterface,
    FormInterface[]
    >(
    formsForFiltersSelector,
    formsSelector,
    (filterForms, {collection}) => {
      let formsWithImages: FormInterface[] = [];
      filterForms.forEach((filterForm) => {
        let hasImages = filterForm.includeImages;
        if (!hasImages) {
          const questionPages: FormInterface['questionPage'] = filterForm.questionPage;
          questionPages.forEach((page: QuestionPageInterface) => {
            page.question.forEach((pageQuestion: QuestionInterface) => {
              if (!pageQuestion.deleted) {
                // Check if Subforms have TableQuestion
                if ([
                  questionTypes.PART_OF_QUESTION,
                  questionTypes.TASK_QUESTION
                ].includes(pageQuestion.type)) {
                  const subForm = collection
                      .find(form => form.ref === pageQuestion.listItems?.relation[0].ref);
                  if (subForm) {
                    if (subForm.includeImages) {
                      hasImages = true;
                    } else {
                      subForm.questionPage.forEach((subFormPage) => {
                        // Some pages do not have questions
                        if (subFormPage.question) {
                          subFormPage.question.forEach((question) => {
                            if (!question.deleted
                                && question.includeFile
                                && question.accept
                                && question.accept.includes('image')
                            ) {
                              hasImages = true;
                            }
                          });
                        }
                      });
                    }
                  }
                } else if (pageQuestion.attachImage
                    || (pageQuestion.includeFile
                        && pageQuestion.accept
                        && pageQuestion.accept.includes('image')
                    )
                ) {
                  hasImages = true;
                }
              }
            });
          });
        }
        if (hasImages) {
          formsWithImages = [
            ...formsWithImages,
            filterForm
          ];
        }
      });
      return formsWithImages;
    }
);

// Returns forms that have been filtered and has images
export const selectedFormsWithImagesSelector = createSelector<
    StateInterface,
    FormInterface[],
    FiltersMenuInterface['selectedForms'],
    filtersSelectedFormType[]
    >(
    filterFormsWithImagesSelector,
    filtersMenuSelectedFormsSelector,
    (filterFormsWithImages, selectedForms) => {
      return selectedForms.filter((selectedForm) => {
        return filterFormsWithImages.some((form) => form.ref === selectedForm.ref);
      });
    }
);
