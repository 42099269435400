import { ModalInterface } from '../Interfaces/ModalInterface';
import { NAVIGATION } from '../actions/actionTypes';
import { NavigationInterface } from '../Interfaces/NavigationInterface';
import ReducerBuilder from './ReducerBuilder';
import { Action } from 'typescript-fsa';

export const INITIAL_STATE: NavigationInterface = {
  modals: []
};

type PayloadType = ModalInterface | string;

function navigateAddModal(state: NavigationInterface, {payload}): NavigationInterface {
  const {modals} = state;
  return {...state, modals: [...modals, payload]};
}

function navigateRemoveModal(state: NavigationInterface, {payload}): NavigationInterface {
  const {modals} = state;
  return {...state, modals: modals.filter(({component}) => component !== payload)};
}

export default new ReducerBuilder<NavigationInterface, Action<PayloadType>>()
    .setInitialState(INITIAL_STATE)
    .addReducer(NAVIGATION.ADD_MODAL, navigateAddModal)
    .addReducer(NAVIGATION.REMOVE_MODAL, navigateRemoveModal)
    .build();
