import * as React from 'react';
import bind from 'bind-decorator';
import { LooseObject } from '../../../Interfaces/LooseObject';

interface State {
  value: string;
  disabled: boolean;
  name: string;
  type: string;
  active: boolean;
}

interface Props {
  type?: string;
  onChange: (value: string | number, valid?: boolean) => void;
  name: string;
  value?: string | number;
  disabled?: boolean;
  extraAttrs?: LooseObject;
  extraClass?: string;
  placeholder?: string;
}

/*
  This is a utility class for displaying text inputs.
  The value is only updated within the input as the user types and it is only
  propagated to external listeners when the user stops typing.
  This is very key in situations when changes to the value causes other updates.
  This approach is very scalable.
*/
export default class TextInputComponent extends React.Component <Props, State> {
  private input = React.createRef<HTMLInputElement>();
  private typingTimer: any;
  private readonly pattern: any;

  constructor(props) {
    super(props);

    if (props.type === 'tel') {
      this.pattern = { pattern: '^[0-9-+\\s()]*$' };
    }

    this.state =  {
      value: props.value ? props.value : '',
      disabled: props.disabled,
      name: props.name,
      type: props.type ? props.type : 'text',
      active: false
    };
  }

  @bind
  private handleChange(e) {
    const value = e.target.value;
    const active = true;
    const callBack = this.state.type === 'number' ? this.doneTyping : undefined;

    this.setState({ value, active }, callBack);
  }

  @bind
  private handleKeyUp() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(this.doneTyping, 1000);
  }

  @bind
  private handleKeyDown() {
    clearTimeout(this.typingTimer);
    this.typingTimer = null;
  }

  @bind
  private doneTyping() {
    const { value } = this.state;
    const { onChange } = this.props;
    const valid = this.checkValidity();

    if (onChange) {
      this.setState({ active: false }, () => onChange(value, valid));
    }
  }

  @bind
  public checkValidity(): boolean {
    if (this.input.current) {
      return this.input.current.checkValidity();
    }
    return false;
  }

  /*
    This is a lifecycle react method. https://reactjs.org/docs/react-component.html#static-getderivedstatefromprops
    If any value that affects the skip has changed, we evaluate the scripta nd set the new value to the state.
  */
  public static getDerivedStateFromProps(props: Props, state: State) {
    if (props.value !== null && props.value !== undefined && !state.active) {
      return { value: props.value };
    }
    return null;
  }

  public shouldComponentUpdate(nextProps: Props, nextState: State) {
    return this.state.value !== nextState.value;
  }

  public render(): JSX.Element {
    const { name, value, disabled, type } = this.state;
    const { extraAttrs, extraClass } = this.props;
    return (
      <input
        type={type}
        name={name}
        className={`form-control ${extraClass ? extraClass : ''}`}
        value={value}
        disabled={disabled}
        ref={this.input}
        onKeyUp={this.handleKeyUp}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleChange}
        placeholder={this.props.placeholder}
        {...this.pattern}
        {...extraAttrs}
      />
    );
  }
}
