import '../styles/CommentButton.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { Button } from 'react-bootstrap';
import GenericModal from '../../../views/Modals/GenericModal';
import { CommentButtonProps } from './Containers/CommentsButtonContainer';
import { getLocalization, globalWindow } from '../../../global/global';
import { LooseObject } from 'Interfaces/LooseObject';

interface State {
  showModal: boolean;
  comment: string;
  hasError: boolean;
  comments: LooseObject[];
}

export default class CommentButton extends React.Component <CommentButtonProps, State> {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      comment: '',
      hasError: false,
      comments: props.dataPoint['comments'] || []
    };
  }

  @bind
  private onClick() {
    this.setState({ showModal: true });
  }

  @bind
  private handleChange(e) {
    const newState = {
      comment: e.target.value
    };
    this.setState(newState);
  }

  @bind
  private closeModal() {
    this.setState({ showModal: false });
  }

  @bind
  private saveComment() {
    if (this.state.comment.trim() === '') {
      this.setState({ hasError: true });
      return;
    }
    const comment: LooseObject = {
      date: new Date().getTime(),
      comment: this.state.comment.replace(/(?:\r\n|\r|\n)/g, '<br />'),
      username: globalWindow.userName
    };

    const { dataPoint } = this.props;
    if (dataPoint.id) {
      const request = this.props.saveComment(dataPoint.id, globalWindow.userName, comment);
      request.then(response => response.json()).then(json => {
        if (json.status === 'OK') {
          const { comments } = this.state;
          comment.date = new Date(comment.date).toISOString();
          this.setState({ comments: [comment].concat(comments)});
        }
      });
    }
    this.closeModal();
  }

  @bind
  private getCommentBody(): JSX.Element {
    return (
      <div>
        <div className={`form-group ${this.state.hasError ? 'has-error' : ''}`}>
          <textarea className="form-control" value={this.state.comment} onChange={this.handleChange}/>
        </div>
        <div>
          <button className="btn btn-sm btn-primary" onClick={this.saveComment}>
            {`${getLocalization('add')} ${getLocalization('comment')}`}
          </button>
        </div>
        <div className="comments">
          {this.getCommentList()}
        </div>
      </div>
    );
  }

  @bind
  private getCommentList() {
    const { comments } = this.state;
    return comments.map((comment) => {
      return (
        <div key={`comment-${comment['id']}`}>
          <p>{comment['comment']}</p>
          <p><span><small><i>by {comment['username']} on {new Date(comment['date']).toString()}</i></small></span></p>
        </div>
      );
    });
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        { this.state.showModal ? (
          <GenericModal
            visible={this.state.showModal}
            onConfirm={this.closeModal}
            body={this.getCommentBody()}
            title={getLocalization('comments')}
            confirmText={getLocalization('close')}
          />
        )
        : null }
        <Button size="sm" onClick={this.onClick} id="comments-btn">
          <i className="fa fa-comments" aria-hidden="true" />
          {` ${getLocalization('comments')}`}
        </Button>
      </React.Fragment>
    );
  }
}
