import * as React from 'react';
import { Elements } from '../elements';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import MapContainer from '../../Map/MapContainer';
import FormUtils from './FormUtils';
import { isTaskQuestionVisible } from './TaskUtils';
import { canEdit, canView } from './RolesChecker';
import { getDiagram, getSubformDiagrams } from './DiagramUtils';
import { getSubformMainDiagramValues } from './utils';
import { forEach } from 'lodash-es';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';

/*
  This function returns the table element for rendering.
  places here to reduce cyclomatic complexity just incase you are wondering if the dev
  who wrote it was nuts or high on weed or some other lethal substances.
*/
export const getTableElement = (
  question: LooseObject, dataPoint: DataPoint, forms: LooseObject[], updateAnswer,
  formUtils: FormUtils, clientPersist: ClientPersistInterface, parentDataPoint?: LooseObject,
  parentModel?: LooseObject): JSX.Element | null => {
  const tableForm = forms.find( f => f.ref === question.table.columns.relation[0].ref);
  const subForm = question.type === 'ReferenceTableQuestion' && question.value ?
    forms.find(f => f.ref === question.value) : undefined;
  return tableForm ? (
    <Elements.TableQuestion
      question={question}
      dataPoint={dataPoint}
      tableForm={tableForm}
      subForm={subForm}
      key={question.id}
      updateAnswer={updateAnswer}
      formUtils={formUtils}
      forms={forms}
      parentModel={parentModel}
      parentDataPoint={parentDataPoint}
    />
  ) : null;
};

export const getSubFormElement = (
  question: LooseObject, dataPoint: DataPoint, forms: LooseObject[], updateAnswer,
  formUtils: FormUtils, clientPersist: ClientPersistInterface, parentModel?: LooseObject
  ): JSX.Element | null => {
    if (parentModel) {
      return (
        <Elements.SubFormQuestion
          question={question}
          dataPoint={dataPoint}
          updateAnswer={updateAnswer}
          forms={forms}
          formUtils={formUtils}
          key={question.id}
          parentModel={formUtils.getModel().isChild ? formUtils.getModel() : parentModel}
          clientPersist={clientPersist}
        />
      );
    }
    return null;
};
/*
  Returns the question element for the given question object.
*/
/* tslint:disable:cyclomatic-complexity */
export const getQuestionElement = (
  question: LooseObject, dataPoint: DataPoint, isTable: boolean, forms: LooseObject[], updateAnswer,
  formUtils: FormUtils, newAnswer: boolean, clientPersist: ClientPersistInterface, parentModel?: LooseObject,
  parentDataPoint?: LooseObject, parentQuestion?: LooseObject, isSubQuestion?: boolean, disabled?: boolean
): JSX.Element | null => {
    switch (question.type) {
      case 'IntQuestion':
      case 'FloatQuestion':
        return (
          <Elements.IntQuestion
            question={question}
            dataPoint={dataPoint}
            edit={canEdit(question, clientPersist, disabled)}
            key={question.id}
            updateAnswer={updateAnswer}
            formUtils={formUtils}
            isSubQuestion={isSubQuestion}
          />
        );
      case 'TimeOfDayQuestion':
        return (
            <Elements.TimeQuestion
              question={question}
              value={dataPoint[question.id]}
              dataPoint={dataPoint}
              key={question.id}
              updateAnswer={updateAnswer}
              formUtils={formUtils}
              edit={canEdit(question, clientPersist, disabled)}
              isSubQuestion={isSubQuestion}
            />
        );
      case 'DateQuestion':
        return (
          <Elements.DateQuestion
            dataPoint={dataPoint}
            question={question}
            value={dataPoint[question.id]}
            key={question.id}
            updateAnswer={updateAnswer}
            formUtils={formUtils}
            edit={canEdit(question, clientPersist, disabled)}
            isSubQuestion={isSubQuestion}
            clientPersist={clientPersist}
          />
        );
      case 'SelectMultipleQuestion':
      case 'SelectOneQuestion':
      case 'BooleanQuestion':
      case 'LikertScaleQuestion':
      case 'DigitalSignatureQuestion':
      case 'StatusQuestion':
        return (
            <Elements.SelectQuestion
              question={question}
              dataPoint={dataPoint}
              key={question.id}
              isTable={isTable}
              updateAnswer={updateAnswer}
              formUtils={formUtils}
              forms={forms}
              newAnswer={newAnswer}
              parentModel={parentModel}
              parentDataPoint={parentDataPoint}
              isSubQuestion={isSubQuestion}
              clientPersist={clientPersist}
              edit={canEdit(question, clientPersist, disabled)}
            />
        );
      case 'SelectUserQuestion':
      case 'UserQuestion':
        return (
          <Elements.UserQuestion
            question={question}
            dataPoint={dataPoint}
            key={question.id}
            updateAnswer={updateAnswer}
            formUtils={formUtils}
            edit={disabled ? false : canEdit(question, clientPersist)}
            isSubQuestion={isSubQuestion}
          />
        );
      case 'TableQuestion':
      case 'ReferenceTableQuestion':
        return getTableElement(
          question, dataPoint, forms, updateAnswer, formUtils, clientPersist, parentDataPoint, parentModel
        );
      case 'SkipQuestion':
        return (
          <Elements.SkipQuestion
            question={question}
            dataPoint={dataPoint}
            key={question.id}
            updateAnswer={updateAnswer}
            formUtils={formUtils}
            forms={forms}
            newAnswer={newAnswer}
            parentModel={parentModel}
            parentQuestion={parentQuestion}
            parentDataPoint={parentDataPoint}
            isSubQuestion={isSubQuestion}
            clientPersist={clientPersist}
          />
        );
      case 'LookupValuesQuestion':
        return (
          <Elements.LookupValuesQuestion
            question={question}
            dataPoint={dataPoint}
            key={question.id}
            updateAnswer={updateAnswer}
            formUtils={formUtils}
            parentQuestion={parentQuestion}
            forms={forms}
            isSubQuestion={isSubQuestion}
            parentDataPoint={parentDataPoint}
            parentModel={parentModel}
            edit={canEdit(question, clientPersist, disabled)}
          />
        );
      case 'PicturesQuestion':
      case 'FileQuestion':
        return (
          <Elements.FileQuestion
            question={question}
            dataPoint={dataPoint}
            key={question.id}
            updateAnswer={updateAnswer}
            formUtils={formUtils}
            accept={question.accept}
            isSubQuestion={isSubQuestion}
          />
        );
      case 'GPSQuestion':
        if (formUtils.showCoordinates()) {
          return (
            <Elements.GPSQuestion
              question={question}
              dataPoint={dataPoint}
              key={question.id}
              updateAnswer={updateAnswer}
              formUtils={formUtils}
              parentDataPoint={parentDataPoint}
              forms={forms}
            />
          );
        }
        break;
      case 'Label':
        return (
          <Elements.LabelQuestion
            question={question}
            dataPoint={dataPoint}
            updateAnswer={updateAnswer}
            key={question.id}
            formUtils={formUtils}
            isSubQuestion={isSubQuestion}
          />
        );
      case 'Part_of_Question':
      case 'TaskQuestion':
        return getSubFormElement(question, dataPoint, forms, updateAnswer, formUtils, clientPersist, parentModel);
      case 'StringQuestion':
        if (question.id === 'address') {
          return (
            <Elements.AddressQuestion
              question={question}
              dataPoint={dataPoint}
              key={question.id}
              updateAnswer={updateAnswer}
              edit={canEdit(question, clientPersist, disabled)}
              formUtils={formUtils}
              isSubQuestion={isSubQuestion}
            />
          );
        }
        return (
          <Elements.StringQuestion
            question={question}
            dataPoint={dataPoint}
            key={question.id}
            updateAnswer={updateAnswer}
            edit={canEdit(question, clientPersist, disabled)}
            formUtils={formUtils}
            isSubQuestion={isSubQuestion}
          />
        );
      case 'NameQuestion':
        if (question.script && question.script.length > 0) {
          return (
            <Elements.CalculatedValueQuestion
              question={question}
              key={question.id}
              updateAnswer={updateAnswer}
              dataPoint={dataPoint}
              formUtils={formUtils}
              forms={forms}
              parentQuestion={parentQuestion}
              isSubQuestion={isSubQuestion}
              parentModel={parentModel}
              parentDataPoint={parentDataPoint}
            />
          );
        }
        return (
          <Elements.StringQuestion
            question={question}
            dataPoint={dataPoint}
            key={question.id}
            updateAnswer={updateAnswer}
            edit={canEdit(question, clientPersist, disabled)}
            formUtils={formUtils}
            isSubQuestion={isSubQuestion}
          />
        );
      case 'CalculatedValueQuestion':
        return (
          <Elements.CalculatedValueQuestion
            question={question}
            key={question.id}
            updateAnswer={updateAnswer}
            dataPoint={dataPoint}
            formUtils={formUtils}
            forms={forms}
            parentModel={parentModel}
            parentDataPoint={parentDataPoint}
            parentQuestion={parentQuestion}
            isSubQuestion={isSubQuestion}
          />
        );
      case 'PhoneNumberQuestion':
        return (
          <Elements.StringQuestion
            question={question}
            dataPoint={dataPoint}
            key={question.id}
            updateAnswer={updateAnswer}
            edit={canEdit(question, clientPersist, disabled)}
            formUtils={formUtils}
            isSubQuestion={isSubQuestion}
            type="tel"
          />
        );
      case 'EmailQuestion':
        return (
          <Elements.StringQuestion
            question={question}
            dataPoint={dataPoint}
            key={question.id}
            updateAnswer={updateAnswer}
            edit={canEdit(question, clientPersist, disabled)}
            formUtils={formUtils}
            isSubQuestion={isSubQuestion}
            type="email"
          />
        );
      case 'LayoutTableQuestion':
        return (
          <Elements.LayoutTableQuestion
            question={question}
            dataPoint={dataPoint}
            formUtils={formUtils}
            updateAnswer={updateAnswer}
            forms={forms}
            clientPersist={clientPersist}
          />
        );
      case 'ValidationRuleQuestion':
        return (
          <Elements.ValidationRuleQuestion
            question={question}
            dataPoint={dataPoint}
            key={question.id}
            updateAnswer={updateAnswer}
            formUtils={formUtils}
            forms={forms}
            newAnswer={newAnswer}
            parentModel={parentModel}
            parentQuestion={parentQuestion}
            parentDataPoint={parentDataPoint}
            isSubQuestion={isSubQuestion}
            clientPersist={clientPersist}
          />
        );
      default:
        return null;

    }
    return null;
};
/* tslint:enable:cyclomatic-complexity */

export const isShown = (
  question: LooseObject, formUtils: FormUtils, newAnswer: boolean, dataPoint: DataPoint,
  clientPersist: ClientPersistInterface
): boolean => {
  if (calculatedSkipHideSingleInstance(question)) {
    return true;
  }
  if (!canView(question, clientPersist)) {
    return false;
  }
  if (!question.inVisible && !question.deleted && !question.hideInSingleInstance) {
    if ((!isTaskQuestionVisible(question, dataPoint))
      || question.id === 'parentRowId' || question.id === 'subformID' || question.id === 'parentId') {
      return false;
    } else if (!formUtils.getModel().isChild && question.id === 'BackgroundDiagram') {
      if (!newAnswer || formUtils.getModel().addToDiagram) {
        return false;
      }
    } else if (newAnswer && question.type === 'DigitalSignatureQuestion') {
      return false;
    } else if (question.id === 'BackgroundDiagram' && formUtils.getModel().addToDiagram) {
      return false;
    }
    return true;
  }
  return false;
};

export const calculatedSkipHideSingleInstance = (question) => {
  // Calculated & Skip questions should be rendered at least in virtual dom.
  if (question.hideInSingleInstance &&
    (question.type === 'CalculatedValueQuestion' ||
    question.type === 'SkipQuestion' ||
    question.type === 'ReferenceTableQuestion')
  ) {
    return true;
  }
  return false;
};

export const getTableColumn = (question, el) => {
  if (calculatedSkipHideSingleInstance(question)) {
    return el;
  }
  return (<td key={`table_${question.id}`}>{el}</td>);
};

export const canViewLocationHierarchy = (form: FormInterface | LooseObject, clientPersist: ClientPersistInterface) => {
  const { roles } = clientPersist;
  if (roles.includes('admin') || roles.includes('modeler')) {
    return true;
  }
  if (form.viewLocations && form.viewLocations.includes(roles)) {
    return true;
  }
  return false;
};

export const canEditLocationHierarchy = (form: FormInterface | LooseObject, clientPersist: ClientPersistInterface) => {
  const { roles } = clientPersist;
  if (roles.includes('admin') || roles.includes('modeler')) {
    return true;
  }
  if (form.editLocations && form.editLocations.includes(roles)) {
    return true;
  }
  return false;
};

/*
  Creates an array of question elemsnts for the given question array.
*/
export const renderQuestions = (
  questions: LooseObject[], dataPoint: DataPoint, isTable: boolean, forms: LooseObject[], updateAnswer,
  formUtils: FormUtils, newAnswer: boolean, clientPersist: ClientPersistInterface,
  parentModel?: LooseObject, parentDataPoint?: LooseObject, parentQuestion?: LooseObject, isSubQuestion?: boolean,
  disabled?: boolean
): JSX.Element[] => {
  const elements: JSX.Element[] = [];
  for (const question of questions) {
    if (!question) {
      elements.push(isTable ? (<td key={`table_empty_cell`}/>) : <div/>);
      continue;
    }
    if (question.id === 'subfrmfld' && parentQuestion) {
      elements.push(
        (<Elements.SubFormField key={`${parentQuestion.id}`} question={question} parentQuestion={parentQuestion}/>));
    } else if (isShown(question, formUtils, newAnswer, dataPoint, clientPersist)) {
      const el = getQuestionElement(
        question, dataPoint, isTable, forms, updateAnswer, formUtils, newAnswer, clientPersist,
        parentModel, parentDataPoint, parentQuestion, isSubQuestion, disabled
      );
      if (el) {
        elements.push(isTable ? getTableColumn(question, el) : el);
      }
    }

    if (question.type === 'NameQuestion') {
      if (formUtils.showLocationHierarchy() && canViewLocationHierarchy(formUtils.getModel(), clientPersist)) {
        elements.splice(elements.length - 1, 0, (
          <Elements.LocationHierarchyComponent
            dataPoint={dataPoint}
            key={'location-hierarchy'}
            updateAnswer={updateAnswer}
            formUtils={formUtils}
            edit={canEditLocationHierarchy(formUtils.getModel(), clientPersist)}
          />
        ));
      }
      let mainFormDiagramName;
      if (formUtils.getModel().backgroundDiagram && !formUtils.getModel().isChild) {
        mainFormDiagramName = getDiagram(dataPoint['files']);
        if (mainFormDiagramName) {
          elements.push((
            <div className="col-md-12 map">
              <MapContainer
                id={`diagram_map`}
                dataPoint={dataPoint}
                diagramName={mainFormDiagramName}
                propsDataPoints={getSubformMainDiagramValues(dataPoint, formUtils, mainFormDiagramName)}
              />
            </div>
          ));
        }
      }
      // if diagram is same as main form no need to show it differently.
      const diagrams = getSubformDiagrams(dataPoint, formUtils);
      if (mainFormDiagramName && diagrams[mainFormDiagramName]) {
        delete diagrams[mainFormDiagramName];
      }
      forEach(diagrams, (value, key) => {
        elements.push((
          <div className="col-md-12 map diagram_map" key={`diagram_map_${key}`}>
            <MapContainer
              id={`diagram_map_${key}`}
              dataPoint={dataPoint}
              diagramName={key}
              propsDataPoints={value}
            />
          </div>
        ));
      });
    }
  }
  return elements;
};
