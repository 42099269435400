import * as React from 'react';
import bind from 'bind-decorator';
import { uniq } from 'lodash-es';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { CalculatedPropsInterface } from '../Containers/CalculatedValueQuestionContainer';
import QuestionLabel from './QuestionLabel';
import { globalWindow } from '../../../global/global';
import { updateScriptObject } from '../utils/ScriptUtils';
import { calculateValue } from '../utils/utils';
import { canView } from '../utils/RolesChecker';

interface State {
  value: null | string | number;
  obj: LooseObject;
  localSPSSToId: LooseObject;
}

export default class CalculatedValueQuestion extends React.Component <CalculatedPropsInterface, State> {

  constructor(props) {
    super(props);
    const { dataPoint, question } = this.props;
    const componentState: State = {
      value : dataPoint[question.id] ? dataPoint[question.id] : question.default ? question.default : '',
      obj: {} as LooseObject,
      localSPSSToId: {} as LooseObject
    };
    this.setObjValues(componentState);
    this.state = componentState;
  }

  /*
    When doing the script evaluation, we will need to set the object with _spss -> value mapping.
    This function sets the _spss -> value mapping and also the _spss -> id mapping.
    _spss -> id mapping allows for easier retrieving of values from the data model.
  */
  @bind
  private setObjValues(state: State) {
    const { formUtils, forms, parentQuestion, question } = this.props;
    const obj: LooseObject = {}; // mapping for _spss -> value
    const localSPSSToId: LooseObject = {}; // mapping for _spss -> id
    if (parentQuestion && parentQuestion.type === 'ReferenceTableQuestion') {
      const subForm = forms.find( f => f.ref === parentQuestion.value);
      if (subForm && question.script.indexOf(subForm.referenceName) !== -1) {
        state.obj = obj;
        state.localSPSSToId = localSPSSToId;
        return state;
      }
    }
    const allVariables: string[] = uniq(question.script.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));

    for (const v of allVariables) {
      if (v === '_editor' || v === '_username') {
        obj[v] = globalWindow.userName;
        localSPSSToId[v] = v;
      } else if (v === '_parntfld') {
        obj[v] = '';
        localSPSSToId[v] = 'subfrmfld';
      } else if (v.indexOf('.') !== -1) { // this may be summing a table, a lookup value.
        const prefix = v.substr(0, v.indexOf('.'));
        const questionId = formUtils.getQuestionId(prefix);
        localSPSSToId[v] = questionId;
      } else {
        const id = formUtils.getQuestionId(v);
        localSPSSToId[v] = id;
      }
    }

    state.obj = obj; // response.updatedObj;
    state.localSPSSToId = localSPSSToId;
    return state;
  }

  /*
    This is a lifecycle react method. https://reactjs.org/docs/react-component.html#static-getderivedstatefromprops
    If any value that affects the skip has changed, we evaluate the scripta nd set the new value to the state.
  */
  public static getDerivedStateFromProps(props: CalculatedPropsInterface, state: State) {
    const { obj, localSPSSToId, value } = state;
    const { dataPoint, formUtils, updateAnswer, forms, locationHierarchy, parentModel, parentDataPoint, parentQuestion,
      question, pois, users } = props;
    if (parentQuestion && parentQuestion.type === 'ReferenceTableQuestion') {
      const subForm = forms.find( f => f.ref === parentQuestion.value);
      if (subForm && question.script.indexOf(subForm.referenceName) !== -1) {
        return null;
      }
    }
    const response = updateScriptObject(
      localSPSSToId,
      obj,
      formUtils,
      dataPoint,
      forms,
      locationHierarchy,
      users,
      parentModel,
      parentDataPoint,
      pois,
      question,
      parentQuestion
    );

    const { updated, updatedObj, vars } = response;
    const calc = calculateValue(updated, question, dataPoint);
    if (updated || (calc) || (question.runOnCreate && !value )) {
      const { question } = props;
      let script = question.script;

      const calculate = () => {
        try {
          script = script.trim().startsWith('return') || script.trim().indexOf('return') ? script.replace('return ', '')
            : script;
          return eval(vars.join(' ') + script);
        } catch (e) {
          return null;
        }
      };
      let result = calculate();
      result = (Number.isNaN(result) || result === null) ? '' : result;
      result = (result === '') && question['default'] ? question['default'] : result;
      if (question.numericOnly) {
        result = Number(result);
      }
      if (result === Infinity || result === -Infinity) {
        result = 0;
      }
      const round = (val) => {
        if (`${val}`.indexOf('.') !== -1) {
          const decimals = `${val}`.substring(`${val}`.indexOf('.') + 1);
          if (decimals.length > 5) {
            val = Math.round(Number(val) * 1000000) / 1000000;
          }
        }
        return val;
      };
      if (typeof result === 'number') {
        result = round(result);
        // result = Math.round(Number(result) * 100) / 100;
      }
      if  (dataPoint[question.id] !== result && state.value !== result && `${state.value}` !== `${result}`) {
        const newValue = {};
        newValue[question.id] = result;
        updateAnswer(newValue);
      }
      return { obj : updatedObj, value : result };
    }
    return null;
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value;
  }

  public render(): JSX.Element | null {
    const { value } = this.state;
    const { question, formUtils, isSubQuestion, dataPoint, clientPersist } = this.props;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    if (question.hideInSingleInstance || !canView(question, clientPersist)) {
      return null;
    }
    const renderValue = typeof value === 'number' && value % 1 > 0 ? `${Math.round(Number(value) * 100) / 100}` : `${value}`;
    return (
      <div className={`form-group ${!isSubQuestion && formUtils.getResponsiveView(question)}`}>
        <div className="row container-fluid">
          <QuestionLabel question={question} dataPoint={dataPoint} formUtils={formUtils}>
            {required}
          </QuestionLabel>
        </div>
        <span className="text-info" dangerouslySetInnerHTML={{__html: renderValue ? `${renderValue}` : ''}} />
      </div>
    );
  }
}
