import '../styles/SubFormQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import FormUtils from '../utils/FormUtils';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import FormComponent from '../FormComponent';
const uuid = require('uuid/v4');
import { generateName, getQuestionText, initDataPoint } from '../utils/utils';
/* import { renderQuestions } from '../utils/qnrenderer'; */
import { getLocalization, globalWindow } from '../../../global/global';
import GenericModal from '../../Modals/GenericModal';
import { ConfirmationModal } from '../../Modals/ConfirmationModal';
import { validateSubForms } from '../utils/Validator';

export interface Props {
  question: LooseObject;
  forms: LooseObject[];
  updateAnswer: (value: LooseObject) => void;
  dataPoint: DataPoint;
  formUtils: FormUtils;
  parentModel: LooseObject;
  clientPersist: ClientPersistInterface;
  isSubQuestion?: boolean;
}

interface State {
  value: LooseObject[];
  subFormDataPoint: DataPoint;
  currentIndex: number;
  showSubForm: boolean;
  formName: string;
  subForm: LooseObject | undefined;
  confirmRemove: boolean;
  invalid: boolean;
}

export default class SubFormQuestion extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    const { dataPoint, question, forms, formUtils } = this.props;
    const subForm = forms.find( f => f.ref === question.listItems.relation[0].ref);
    if (subForm) {
      subForm.responsiveLayout = formUtils.getModel().responsiveLayout;
    }
    this.state = {
      value: dataPoint[question.id] ? this.sortSubforms(dataPoint[question.id]) : [],
      subFormDataPoint: {} as DataPoint,
      currentIndex: -1,
      showSubForm: false,
      formName: subForm ? subForm.name : question.listItems.relation[0].text,
      subForm: subForm,
      confirmRemove: false,
      invalid: false
    };
  }

  @bind
  private updateSubFormAnswer(answer: LooseObject) {

    this.setState((prevState, props) =>
    ({ subFormDataPoint: {...prevState.subFormDataPoint, ...answer} }));
  }

  @bind
  private saveSubFormInstance() {
    const { currentIndex, subFormDataPoint, value } = this.state;
    const newValue = Object.assign([], value);
    if (subFormDataPoint) {
      const data = {...subFormDataPoint};
      if (data['Name'] === undefined) {
        data['Name'] = generateName();
      }
      if (currentIndex === -1) {
        newValue.push(data);
      } else {
        newValue.splice(currentIndex, 1,  data);
      }
      const { question, clientPersist, forms } = this.props;
      const invalidObj = validateSubForms(question, newValue, forms, clientPersist);
      const invalid = invalidObj !== undefined;
      this.setState({
          invalid,
          currentIndex: -1,
          subFormDataPoint: {} as DataPoint,
          value: this.sortSubforms(newValue),
          showSubForm: false
        },
        this.setAnswer
      );
    }
  }

  @bind
  private setAnswer() {
    const { updateAnswer, question } = this.props;
    const { value } = this.state;
    const answer = {};
    answer[question.id] = value;
    updateAnswer(answer);
  }

  @bind
  private showSubForm(index: number) {
    const { value, subForm } = this.state;
    const { question, forms, formUtils } = this.props;
    let subFormDataPoint;
    if (index === -1 ) {
      subFormDataPoint = {};
      subFormDataPoint = initDataPoint(
        subFormDataPoint, subForm ? subForm : {}, undefined, undefined, question, forms
      );
      subFormDataPoint.id = uuid();

      if (subForm) {
        subFormDataPoint['subfrmfld'] = `${formUtils.getModel().ref}_${question.id}`;
        if (question.type === 'TaskQuestion') {
          subFormDataPoint['parentId'] = `${formUtils.getModel().ref}`;
          subFormDataPoint['parentRowId'] = '';
        }
      }
    } else {
      subFormDataPoint = value[index];
    }

    this.setState({ subFormDataPoint, currentIndex: index, showSubForm: true });
  }

  @bind
  private cancel() {
    this.setState({ subFormDataPoint: {} as DataPoint, currentIndex: -1, showSubForm: false });
  }

  @bind
  private getSubFormModal() {
    const { forms, dataPoint, parentModel, question, clientPersist } = this.props;
    const { subForm, currentIndex } = this.state;

    const subFormDataPoint = this.state.subFormDataPoint;
    return (
      <GenericModal
        visible={this.state.showSubForm}
        cancel={this.cancel}
        onConfirm={this.saveSubFormInstance}
        cancelText={'Cancel'}
        confirmText={'Save'}
        // title={formName}
        dialogClassName={'large-modal'}
        body={(
          <FormComponent
            model={subForm !== undefined ? subForm : {}}
            forms={forms}
            dataPoint={subFormDataPoint}
            updateAnswer={this.updateSubFormAnswer}
            parentDataPoint={dataPoint}
            parentModel={parentModel}
            parentQuestion={question}
            newAnswer={currentIndex === -1}
            clientPersist={clientPersist}
          />
        )}
      />
    );
  }

  @bind
  private removeSubFormInstance() {
    const { value, currentIndex } = this.state;
    const newValue = [...value];
    newValue.splice(currentIndex, 1);
    const { question, clientPersist, forms } = this.props;
    const invalidObj = validateSubForms(question, value, forms, clientPersist);
    const invalid = invalidObj !== undefined;
    this.setState({ invalid, value: this.sortSubforms(newValue), currentIndex: -1 }, this.setAnswer);
    this.cancelModal();
  }

  @bind
  private confirmSubformRemove(index: number) {
    this.setState({ currentIndex: index, confirmRemove: true });
  }

  @bind
  private cancelModal() {
    this.setState({ confirmRemove: false });
  }

  @bind
  private sortSubforms(value) {
    if (globalWindow.db !== 'fortum') {
      return value;
    }
    return value.sort((a, b) => {
      if (a.Name < b.Name) {
        return -1;
      }
      if (a.Name > b.Name) {
        return 1;
      }
      return 0;
    });
  }

  public render(): JSX.Element {
    const { value, formName } = this.state;
    const { question, formUtils, isSubQuestion } = this.props;
    const required = question.optional ? null : (<span className="text-danger">*</span>);
    const instances = !question.static && value.length > 0 ? (
      <ul className="saved-part-of-forms">
        { value.map((v, i) => {
          return (
            <li key={v.id}>
              <a onClick={() => this.showSubForm(i)}>{v.Name}</a>
              <span className="text-danger" onClick={() => this.confirmSubformRemove(i)}>&times;</span>
            </li>
          );
        }) }
      </ul>
    ) : null;
    const label = !question.static ? (
      `${getLocalization('add')} ${formName}`
    ) : question.text.replace(/\n|\r/g, '<br />');
    const index = question.static && value.length === 1 ? 0 : -1;
    const clearBtn = question.static && value.length === 1 ? (
      <button
        className="clear-subform-btn"
        onClick={() => this.confirmSubformRemove(0)}
        title={getLocalization('clearSubformTitle')}
      >
        <i className="fa fa-times-circle" aria-hidden="true" />
      </button>
    ) : null;

    const confirmRemove = this.state.confirmRemove ? (
      <ConfirmationModal
        onConfirm={this.removeSubFormInstance}
        onClose={this.cancelModal}
        localizations={{
          cancel: getLocalization('cancel'),
          confirm: getLocalization('yes'),
          confirmStyle: 'danger',
          header: (<h4>{getLocalization('confirm')}</h4>),
          body: (
              <p>
              {question.static ? getLocalization('confirmClearStaticSubform') : getLocalization('confirmSubformRemove')}
              </p>
            )
        }}
      />
    ) : null;
    return (
      <div className={`${'form-group'} ${!isSubQuestion && formUtils.getResponsiveView(question)}`}>
        {this.state.showSubForm ? this.getSubFormModal() : null}
        {
          !question.static && (
            <>
              <p>
                {getQuestionText(question.text.replace(/\n|\r/g, '<br />'))}
              </p>
              {required}
            </>
          )
        }
        {instances}
        <button
          type="button"
          className={`btn btn-primary part-of-btn ${question.static && value.length === 0 ? 'btn-empty-static' : '' }`}
          onClick={() => this.showSubForm(index)}
          dangerouslySetInnerHTML={{__html: label}}
        />
        {clearBtn}
        {confirmRemove}
        {this.state.invalid && (<p><span className="required">Mandatory fields missing</span></p>)}
      </div>
    );
  }
}
