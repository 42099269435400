import Papa from 'papaparse';
import { compact } from 'lodash-es';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { FormInterface } from '../../../Interfaces/Forms/FormsInterface';
import { ListItem, QuestionInterface, questionTypes } from '../../../Interfaces/Forms/QuestionInterface';

export default class FormUtils {
  // Using LooseObject really hurts, and Types are impossible to fix
  // for such utility files which are ubiquitous
  private lookupOptions: LooseObject;
  private model: FormInterface | LooseObject;
  private idToSPSS: LooseObject;
  private questions: {[key: string]: QuestionInterface};
  private subFormQuestionIds: string[];
  private mappedLocations: any;
  private layoutTableIds: string[] = [];

  constructor(model: FormInterface | LooseObject) {
    this.model = model;
    this.questions = {};
    //
    this.subFormQuestionIds = [];
    this.generateIdToSPSS = this.generateIdToSPSS.bind(this);
    this.setLookupOptions = this.setLookupOptions.bind(this);
    /*
      Here we store lookup options for file based lookup.
      The object is of the form questionId -> Lookup options
        Lookup options have the first option as the key.
    */
    this.lookupOptions = {} as LooseObject;
    /*this.idToSPSSMemoized = this.idToSPSSMemoized.bind(this);*/
    this.setIdToSPSS = this.setIdToSPSS.bind(this);
    this.getQuestion = this.getQuestion.bind(this);
    this.getOptionCodes = this.getOptionCodes.bind(this);
    this.getOptionIds = this.getOptionIds.bind(this);
    this.idToSPSS = this.generateIdToSPSS();
    this.idToSPSS['_area'] = 'areasqm';
    this.idToSPSS['_location1'] = 'location1';
    this.idToSPSS['_location2'] = 'location2';
    this.idToSPSS['_location3'] = 'location3';
    this.idToSPSS['_location4'] = 'location4';
    this.idToSPSS['_phoneet'] = 'phoneEditTime';
    this.idToSPSS['_portalet'] = 'portalEditTime';
    this.idToSPSS['_editor'] = 'userId';
  }
  /*
    This function initializes _spss -> id mapping object.
    This is used in Skip & Calculated values.
  */
  private generateIdToSPSS(): LooseObject {
    const idToSPSS = {} as LooseObject;
    for (const page of this.model.questionPage) {
      // Setting values of variables, by passing them into other functions
      // is DANGEROUS and UNPREDICTABLE
      this.setIdToSPSS(page.question, idToSPSS);
    }
    if (this.model.question) {
      this.setIdToSPSS(this.model.question, idToSPSS);
    }
    return idToSPSS;
  }

  private setIdToSPSS(questions: QuestionInterface[], idToSPSS: LooseObject): LooseObject {
    for (const question of questions) {
      if (!question.deleted && !question.inVisible) {
        idToSPSS[`_${question.spssvariable}`] = question.id;
        this.questions[question.id] = question;
        if (question.type === questionTypes.PART_OF_QUESTION
            || question.type === questionTypes.TASK_QUESTION) {
          this.subFormQuestionIds.push(question.id);
        } else if (question.type === questionTypes.LAYOUT_TABLE_QUESTION) {
          const table = question.table;
          this.layoutTableIds.push(question.id);
          if (table) {
            const columns = table.columns;
            if (columns && columns.column) {
              columns.column.forEach((col, index) => {
                const questions = col.question;
                this.setIdToSPSS(questions, idToSPSS);
              });
            }
          }
        }
        if (question.triggerValues && question.triggerValues.triggerValue) {
          for (const triggerValue of question.triggerValues.triggerValue) {
            if (triggerValue.action && triggerValue.action.subQuestions) {
              this.setIdToSPSS(triggerValue.action.subQuestions.question, idToSPSS);
            }
          }
        }
        if (question.type === questionTypes.LOOKUP_VALUES_QUESTION) {
          this.setLookupOptions(question);
        }
      } else {
        if (question.id === 'phoneEditTime' || question.id === 'portalEditTime' || question.id === 'countQuestion') {
          this.questions[question.id] = question;
        }
      }
    }
    return idToSPSS;
  }

  public idToSPSSMemoized() {
    /*memoize(this.generateIdToSPSS());*/
  }

  public getQuestion(id: string): QuestionInterface {
    return this.questions[id];
  }

  /*
    Given the question id, this function returns the corresponding option codes.
    Used by Skip questions.
  */
  public getOptionCodes(qnId: string, optIds: string[]): string {
    const qn = this.questions[qnId];
    if (qn.listItems) {
      const li = qn.listItems.listItem;
      const codes = li.map(opt => {
        if ((opt.id && optIds.indexOf(opt.id) !== -1) || optIds.indexOf(opt.value) !== -1) {
          if (opt.code) {
            return opt.code;
          } else if (opt.value) {
            return opt.value;
          }
        }
        return null;
      }).filter( code => !!code);
      return codes.join(',');
    }
    return '';
  }

  public getOptionIds(qnId: string, optCodes: string[]): string {
    const qn = this.questions[qnId];
    if (qn.listItems) {
      const li = qn.listItems.listItem;
      const ids = li.map(opt => {
        if ((opt.code && optCodes.indexOf(opt.code) !== -1) || optCodes.indexOf(opt.value) !== -1) {
          if (opt.id) {
            return opt.id;
          }
          return opt.value;
        }
        return null;
      }).filter( code => !!code);
      return ids.join(',');
    }
    return '';
  }

  public getQuestionId(spssVariable: string): string {
    return this.idToSPSS[spssVariable];
  }

  /*
    This function goes through lookup file and creates the csv as an object.
    We only need to do this when creating the form.
  */
  public setLookupOptions(question: LooseObject) {
    let lookupOptions: LooseObject | undefined;
    if (question.lookupValue && question.lookupValue.split(';').length > 1) {
      lookupOptions = {};
      const items = question.lookupValue.split(';');
      let first = true;
      for (const item of items) {
        const data = Papa.parse(item.replace(/\'/g, '"')); // .replace(/\'/g, '"')
        if (first) {
          lookupOptions['$headers'] = data.data[0];
          first = false;
        } else {
          const part = data.data[0];
          const cleaned: string[] = compact(part);
          if (cleaned.length > 0 && part[0]) { /* makes sure we do not keep empty rows. */
            lookupOptions[part[0]] = part;
          }
        }
      }
    }
    this.lookupOptions[question.id] = lookupOptions;
  }

  public getSchemaVersion(): number {
    return Number(this.model.schemaVersion);
  }

  public getLookupOptions(questionId: string): LooseObject {
    return this.lookupOptions[questionId];
  }

  /*
    Returns the model associated with this Class.
  */
  public getModel(): FormInterface | LooseObject {
    return this.model;
  }

  /*
    Returns if LH should be displayed.
  */
  public showLocationHierarchy(): boolean {
    if (!this.model.isChild && this.model.hasLocationHierarchy) {
      return true;
    }
    return false;
  }

  /*
    This function checks if we should use responsive view and returns the class name.
    If it should not be used it returns an empty string.
  */
  public getResponsiveView(question: LooseObject): string {
    if ((question.text && question.text.endsWith('---')) || question.type === 'LikertScaleQuestion') {
      return 'col-lg-12 col-md-12 col-sm-12 form-question';
    }
    if (this.model.type === 'TASKFORM' || (this.model.responsiveLayout)) {
      return 'col-lg-4 col-md-4 col-sm-12 form-question';
    }
    return 'col-lg-12 col-md-12 col-sm-12 form-question';
  }

  public showCoordinates(): boolean {
    if (this.model.hasCoordinates) {
      return true;
    }
    return false;
  }

  public getSubFormQuestionIds(): string[] {
    return this.subFormQuestionIds;
  }

  public getQuestions(): {[key: string]: QuestionInterface} {
    return this.questions;
  }

  public setMappedLocations(mappedLocations) {
    this.mappedLocations = mappedLocations;
  }

  public getMappedLocations() {
    return this.mappedLocations;
  }

  public getRows(questionId: string): ListItem[] {
    const question = this.questions[questionId];
    if (question) {
      const table = question.table;
      if (table && table.rows) {
        return table.rows.listItem;
      }
    }
    return [];
  }

  public getLayoutTables() {
    return this.layoutTableIds;
  }
}
