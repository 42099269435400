import { Action } from 'typescript-fsa';
import { TOTAL_POI_COUNT } from '../actions/actionTypes';
import ReducerBuilder from './ReducerBuilder';

export const INITIAL_STATE: number = 0;

type PayloadType = number;

function loadTotalPOICount(state: number, {payload}): number {
  return payload;
}

export default new ReducerBuilder<number, Action<PayloadType>>()
    .setInitialState(INITIAL_STATE)
    .addReducer(TOTAL_POI_COUNT.LOAD, loadTotalPOICount)
    .build();
