/*
 * Given a data point object, this function will convert it to a FeedItem object.
 */
export const getFeedItem = (dataPoint, users, forms) => {
  const getUserName = (userId) => {
    const user = users.find(u => `${u.id}` === `${userId}`);
    if (user) {
      return user.name;
    }
  };
  const getIcon = (formId) => {
    const form = forms.find( f => f.ref === formId);
    if (form) {
      const index = forms.indexOf(form);
      let iconUrl = form.smallIconUrl;
      if (!iconUrl) {
        iconUrl = `/icons/small/poi_${(index % 20) + 1}.png`;
      }
      return iconUrl;
    }
    return '';
  };
  const feedItem = {
    name: dataPoint.name ? dataPoint.name : dataPoint.Name || 'NO NAME',
    editor: getUserName(dataPoint.user_id),
    modified: new Date(dataPoint.modified).toString().substr(0, 33),
    createdOn: new Date(dataPoint.created).toString().substr(0, 33),
    createdBy: dataPoint.createdbyUsername ? dataPoint.createdbyUsername : '',
    status: dataPoint.status ? dataPoint.status : '',
    formid: dataPoint.questionnaire_id,
    rowId: dataPoint.row_id,
    id: dataPoint.id,
    iconUrl: getIcon(dataPoint.questionnaire_id)
  };
  return feedItem;
};
