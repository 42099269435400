import * as React from 'react';
import { connect } from 'react-redux';
import FileQuestion from '../elements/FileQuestion';
import FormUtils from '../utils/FormUtils';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { StateInterface } from '../../../Interfaces/StateInterface';
import { ModalComponentNames, ModalInterface } from '../../../Interfaces/ModalInterface';
import { navigateAddModal, navigateRemoveModal } from '../../../actions/navigationAction';
import { getFileUrl, rotateImage } from '../../../actions/file';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';

export interface Props {
  dataPoint: DataPoint;
  updateAnswer: (value: LooseObject) => void;
  question: LooseObject;
  accept: string | undefined;
  formUtils?: FormUtils;
  isSubQuestion?: boolean;
}

export interface StateProps {
  groupDiagrams: LooseObject[];
}

export interface ActionProps {
  navigateAddModal: (modal: ModalInterface) => void;
  navigateRemoveModal: (modal: ModalComponentNames) => void;
  getFileUrl: (fileId: string, callBack: (url: string) => void) => void;
  rotateImage: (angle: string, fileId: number, callBack: (id: number) => void) => void;
}

export type FileQuestionPropsInterface = Props & StateProps & ActionProps;

const FileQuestionContainer = (props: FileQuestionPropsInterface) => {
  return (
    <FileQuestion {...props} />
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    groupDiagrams: state.groupDiagrams // read state from parent if there?
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    navigateAddModal: (modal: ModalInterface) => {
      dispatch(navigateAddModal(modal));
    },
    navigateRemoveModal: (modalName: ModalComponentNames) => {
      dispatch(navigateRemoveModal(modalName));
    },
    getFileUrl: (fileId, callBack) => {
      dispatch(getFileUrl(fileId, callBack));
    },
    rotateImage: (angle: string, fileId: number, callBack: (id: number) => void) => {
      dispatch(rotateImage(angle, fileId, callBack));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileQuestionContainer);
