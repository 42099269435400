import * as React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { BaseModalInterface } from '../../Interfaces/ModalInterface';
import { getLocalization } from '../../global/global';
import { connect } from 'react-redux';
import { StateInterface } from '../../Interfaces/StateInterface';
import bind from 'bind-decorator';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { ThunkDispatch } from 'redux-thunk';
import { updateClientPersist } from '../../actions/clientPersistActions';
import { changePasswordOrEmail } from '../../actions/userActions';
import { ChangePasswordInterface } from '../../Interfaces/ChangePasswordInterface';
import { PasswordInput } from 'views/components/PasswordInput';

interface IStateProps {
  userEmail: string;
}

interface IActionProps {
  actions: {
    updateClientPersist: (clientPersist: Partial<ClientPersistInterface>) => void;
    changePassword: (payload: ChangePasswordInterface) => void;
  };
}

type validationState = 'success' | 'warning' | 'error' | null;

interface IError {
  state: validationState;
  message?: string;
}

interface IOwnState {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  userEmail: string | null;
  errors: {
    currentPassword: IError;
    newPassword: IError;
    confirmPassword: IError;
    userEmail: IError;
  };
}

const className = 'ChangePasswordModal';

export class ChangePasswordModalClass extends React.Component<IStateProps &
    IActionProps & BaseModalInterface, IOwnState> {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      userEmail: Boolean(props.userEmail) && props.userEmail !== '' ? props.userEmail : null,
      errors: {
        currentPassword: {
          state: null,
        },
        newPassword: {
          state: null,
        },
        confirmPassword: {
          state: null
        },
        userEmail: {
          state: Boolean(props.userEmail) && props.userEmail !== '' ? 'success' : null
        }
      }
    };
  }

  private static isValidEmail(email: string): boolean {
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  @bind
  private handleChange({target}) {
    const copyState: IOwnState = Object.assign({}, this.state);
    const {name, value}:
        {name: 'currentPassword' | 'newPassword' | 'confirmPassword' | 'userEmail',
          value: string} = target;
    const errors = {...copyState.errors};
    errors[name].state = 'success';
    if (['currentPassword', 'newPassword', 'confirmPassword'].includes(name.toString())) {
      if (value === '') {
        errors[name.toString()].state = 'error';
        errors[name.toString()].message = 'required';
      } else if (['newPassword', 'confirmPassword'].includes(name.toString())) {
        if (name.toString() === 'newPassword') {
          if ((/[A-Z]/.test(value) === false
              || /[a-z]/.test(value) === false
              || /\d/.test(value) === false
              || /[\W_]/.test(value) === false
          ) && value.length < 10) {
            errors.newPassword.state = 'error';
            errors.newPassword.message = 'The password is too weak. A valid password should have' +
                ' at least one Upper case character, lower case character(s), a number and one of these' +
                ' special characters: !$^&*()_+-=:";\'?,/  . or be at least 10 characters long';
          } else if (value.length < 8) {
            errors.newPassword.state = 'error';
            errors.newPassword.message = 'The password is too short. A valid password should at ' +
                'least be 8 characters long.';
          }
          if (this.state.newPassword.length > 0 && this.state.newPassword !== value) {
            errors.confirmPassword.state = 'error';
            errors.confirmPassword.message = 'The password fields do not match. Please enter passwords again.';
          }
        } else if (value !== this.state.newPassword) {
          errors.confirmPassword.state = 'error';
          errors.confirmPassword.message = 'The password fields do not match. Please enter passwords again.';
        }
      }
    } else if (Boolean(this.state.userEmail) && name.toString() === 'userEmail') {
      if (!ChangePasswordModalClass.isValidEmail(value as string)) {
        errors[name.toString()].state = 'error';
        errors[name.toString()].message = 'The email provided is not valid.';
      }
    }
    copyState[name] = value;
    copyState.errors = errors;
    this.setState({...copyState});
  }

  @bind
  private async onSave() {
    const {currentPassword, newPassword, confirmPassword, userEmail} = this.state;
    await this.props.actions.changePassword({
      currentPassword,
      newPassword,
      confirmPassword,
      userEmail,
    });
    this.props.onClose();
  }

  private FieldGroup(
      {id, label, isValid, type, onChange, ...props }
      : {id: string, label: string, isValid: IError, type: string, [key: string]: any}): JSX.Element {
    return (
        <Form.Group
          controlId={id}
        >
          <Form.Label>{label}</Form.Label>
          {type === 'password' ? (
            <PasswordInput
              {...props}
              onChange={onChange}
              value={props.value}
              name={props.name}
              passwordError={isValid.state === 'error'}
            />
          ) : (<Form.Control {...props} isInvalid={isValid.state === 'error'}/>)}
          {isValid.state === 'error' && <Form.Control.Feedback type="invalid">{isValid.message}</Form.Control.Feedback>}
        </Form.Group>
    );
  }

  private getBody(): JSX.Element {
    const FieldGroup = this.FieldGroup;
    return (
      <form>
        <FieldGroup
            id={`${className}-current`}
            label={`${getLocalization('currentPassword')}:`}
            type="password"
            name="currentPassword"
            className="mandatory"
            value={this.state.currentPassword}
            onChange={this.handleChange}
            placeholder={`${getLocalization('currentPassword')}`}
            isValid={this.state.errors.currentPassword}
        />
        <FieldGroup
            id={`${className}-newPassword`}
            label={`${getLocalization('newPassword')}:`}
            type="password"
            name="newPassword"
            className="mandatory"
            value={this.state.newPassword}
            onChange={this.handleChange}
            placeholder={`${getLocalization('newPassword')}`}
            isValid={this.state.errors.newPassword}
            required={true}
        />
        <FieldGroup
            id={`${className}-confirmPassword`}
            label={`${getLocalization('confirmPassword')}:`}
            type="password"
            name="confirmPassword"
            className="mandatory"
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            placeholder={`${getLocalization('confirmPassword')}`}
            isValid={this.state.errors.confirmPassword}
            required={true}
        />
        {Boolean(this.state.userEmail) && (
            <FieldGroup
                id={`${className}-userEmail`}
                label={`${getLocalization('changePasswordEmail')}:`}
                type="text"
                name="userEmail"
                className="mandatory"
                value={this.state.userEmail}
                onChange={this.handleChange}
                placeholder={`${getLocalization('changePasswordEmail')}`}
                isValid={this.state.errors.userEmail}
            />
        )}
      </form>
    );
  }
  public render(): JSX.Element {
    const hasError = Object.keys(this.state.errors).every((key) => {
      if (!Boolean(this.state.userEmail) && key === 'userEmail') {
        // Exception since userEmail might not be needed
        return true;
      }
      return this.state.errors[key].state === 'success';
    });
    return (
        <Modal
            show={true}
            onHide={this.props.onClose}
            backdrop={true}
            className={className}
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>
              {getLocalization('changepasswd')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.getBody()}
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="primary" onClick={this.props.onClose} id={'change-password-cencel-btn'}>
              {getLocalization('cancel')}
            </Button>
            <Button
                size="sm"
                variant={'primary'}
                onClick={this.onSave}
                disabled={!hasError}
                id={'change-password-btn'}
            >
              {getLocalization('changepasswd')}
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  const {
    useremail: userEmail
  } = state.clientPersist;
  return {
    userEmail
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IActionProps => {
  return {
    actions: {
      updateClientPersist: (clientPersist: Partial<ClientPersistInterface>) => {
        dispatch(updateClientPersist(clientPersist));
      },
      changePassword: (payload: ChangePasswordInterface) => {
        dispatch(changePasswordOrEmail(payload));
      }
    }
  };
};

export const ChangePasswordModal = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModalClass);
