import '../styles/StringQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import TextInputComponent from './TextInputComponent';
import TextAreaComponent from './TextAreaComponent';
import QuestionLabel from './QuestionLabel';
import { Elements } from './index';
import { filter, isNil, map } from 'lodash-es';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { LooseObject } from '../../../Interfaces/LooseObject';
import FormUtils from '../utils/FormUtils';

interface Props {
  question: LooseObject;
  dataPoint: DataPoint;
  edit: boolean;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  isSubQuestion?: boolean;
  type?: string;
}

interface State {
  value: string;
  edit: boolean;
  itemList: string[] | null;
  error: boolean;
  hasError: boolean;
}

export default class StringQuestion extends React.Component <Props, State> {
  private abortController: AbortController = new AbortController();
  constructor(props) {
    super(props);
    const { question, edit, dataPoint } = this.props;

    this.state = {
      value: dataPoint[question.id] ? dataPoint[question.id] : question.default ? question.default : '',
      edit: edit,
      itemList: [],
      error: false,
      hasError: false
    };
  }

  @bind
  private handleChange(value: string) {
    const { question, updateAnswer } = this.props;
    let error = false;
    if (question.regexp) {
      let regex = question.regexp;
      if (regex.indexOf('/') !== -1) {
        regex = regex.substring(regex.indexOf('/') + 1, regex.lastIndexOf('/'));
      }
      const valid = new RegExp(regex).test(value);
      if (!valid) {
        error = true;
        this.setState({ error });
        return;
      }
    }

    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = value;
      updateAnswer(newAns);
    }
    this.setState({ value, error });
  }

  @bind
  private doneTyping(value: any) {
    const { question } = this.props;

    const inputLength = value.length;
    const vlist = question.validationlist.split(';');
    const newList = inputLength === 0 ? vlist :  filter(vlist, ((val) =>
      val.toLowerCase().search(value.toLowerCase()) !== -1
    ));
    let itemList;
    if (newList.length < 100) {
      itemList = map(newList, ((item, index) => (
        <li
          key={`${question.id}_${ index }`}
          className="list-item"
          onClick={() => this.itemSelected(item)}
        >
         {item}
        </li>
      )));
    }
    this.setState({ itemList, value });
  }

  @bind
  private doneTypingWithValidity(value: any, valid = true) {
    const { question, updateAnswer } = this.props;
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = value;
      updateAnswer(newAns);
    }
    this.setState({ value, error: !valid});
  }

  @bind
  private renderIncludeFile(): JSX.Element[] | undefined {
    const { question, dataPoint, updateAnswer } = this.props;
    if (question.includeFile) {
      const types = question.accept.split(',');
      const fileInputs = types.map(type => {
        return (
          <Elements.FileQuestion
            key={`${question.id}_${type}`}
            accept={type}
            question={question}
            dataPoint={dataPoint}
            updateAnswer={updateAnswer}
          />
        );
      });
      return fileInputs;
    }
    return undefined;
  }

  @bind
  private renderIncludeImage(): JSX.Element | undefined {
    const { question, dataPoint, updateAnswer } = this.props;
    if (question.includeImage) {
      return (
        <Elements.FileQuestion
          question={question}
          dataPoint={dataPoint}
          updateAnswer={updateAnswer}
          accept={'image/*'}
        />
      );
    }
    return undefined;
  }

  @bind
  private itemSelected(item: string) {
    const { updateAnswer, question } = this.props;
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = item;
      updateAnswer(newAns);
    }
    this.setState({ value : item, itemList : [] });
  }

  @bind
  private renderStringInput(): JSX.Element {
    const { value, error, hasError } = this.state;
    const { question, formUtils, dataPoint, isSubQuestion } = this.props;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    let salesForceURL;
    if (question.salesForceURL && question.salesForceId && value && value !== '') {
      salesForceURL = <a href={question.salesForceURL + '/' + value} target="_blank">Sales force {value}</a>;
    }
    let hasErrorSpan;
    if (error) {
      const errorMsg = question.triggerValues.triggerValue[0].action.notification.text;
      hasErrorSpan = (<span className="regexperror">{errorMsg}</span>);
    }
    const hasErrorClass = (required && dataPoint.validate && this.state.value === '') || hasError ? 'has-error' : '';
    const className =
      `form-group ${hasErrorClass} ${!isSubQuestion && formUtils.getResponsiveView(question)}`;
    return (
      <div className={className} >
        <QuestionLabel question={question} dataPoint={dataPoint} formUtils={formUtils}>
          {required}
        </QuestionLabel>
        <TextAreaComponent
          name={question.id}
          readOnly={!this.state.edit}
          value={value}
          onChange={this.handleChange}
        />
        {hasErrorSpan}
        {salesForceURL}
        {this.renderIncludeFile()}
        {this.renderIncludeImage()}
      </div>
    );
  }

  @bind
  private renderValidationInput(): JSX.Element {
    const { value, itemList } = this.state;
    const { question, formUtils, dataPoint, isSubQuestion } = this.props;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    const className =
      `form-group ${this.state.hasError ? 'has-error' : ''} ${!isSubQuestion && formUtils.getResponsiveView(question)}`;
    return (
      <div className={className}>
        <QuestionLabel question={question} dataPoint={dataPoint} formUtils={formUtils}>
          {required}
        </QuestionLabel>
        <TextInputComponent name={question.id} value={value} onChange={this.doneTyping} />
        <div>
          <ul id="itemList" className="validation-list">
            {itemList}
          </ul>
        </div>
        {this.renderIncludeFile()}
        {this.renderIncludeImage()}
      </div>
    );
  }

  @bind
  private renderStringInputTyped(): JSX.Element {
    const { value } = this.state;
    const { question, formUtils, dataPoint, isSubQuestion, type } = this.props;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    const className =
      `form-group ${this.state.error ? 'has-error' : ''} ${!isSubQuestion && formUtils.getResponsiveView(question)}`;
    return (
      <div className={className}>
        <QuestionLabel question={question} dataPoint={dataPoint} formUtils={formUtils}>
          {required}
        </QuestionLabel>
        <TextInputComponent name={question.id} value={value} onChange={this.doneTypingWithValidity} type={type} />
        {this.renderIncludeFile()}
        {this.renderIncludeImage()}
      </div>
    );
  }

  /*
    This is a lifecycle react method. https://reactjs.org/docs/react-component.html#static-getderivedstatefromprops
    If any value that affects the skip has changed, we evaluate the scripta nd set the new value to the state.
  */
 public static getDerivedStateFromProps(props: Props, state: State) {
    const { question, dataPoint, updateAnswer } = props;
    const { value } = state;
    // Set the default value if the datamodel has no value
    if (!dataPoint[question.id] && question.default && value === question.default) {
      const newValue = {};
      newValue[question.id] = question.default;
      updateAnswer(newValue);
      return { value: question.default };
    }

    if (!isNil(dataPoint[question.id]) && dataPoint[question.id] !== value) {
      return { value: dataPoint[question.id] };
    } else if (dataPoint['validate'] && !question.optional && value === '') {
      return { hasError: true };
    }
    return null;
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public shouldComponentUpdate(nextProps, nextState) {
    let ret = this.state.value !== nextState.value || this.state.edit !== nextState.edit
      || this.state.error !== nextState.error || nextProps.dataPoint.validate !== this.props.dataPoint.validate
      || this.props.dataPoint.files !== nextProps.dataPoint.files;
    const { question } = this.props;
    if (question.validatefield && question.validationlist) {
      ret = ret || this.state.itemList !== nextState.itemList;
    }
    return ret;
  }

  public render(): JSX.Element {
    const { question, type } = this.props;
    if (question.validatefield && question.validationlist) {
      return this.renderValidationInput();
    } else if (type) {
      return this.renderStringInputTyped();
    } else {
      return this.renderStringInput();
    }
  }
}
