import '../UserDateFilter.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { DateFilterProps } from './DatesContainer';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { DateFilters } from 'Interfaces/FiltersMenuInterface';
import { getLocalization } from '../../../global/global';

export default class DateComponent extends React.Component<DateFilterProps> {

  @bind
  private handleDateValueChange(date, name) {
    const { selectedDates } = this.props;
    const newDates: Partial<DateFilters> = {};
    newDates[name] = date;
    this.props.setSelectedDates({...selectedDates, ...newDates});
  }

  @bind
  private clearFilters() {
    this.props.setSelectedDates({
      from: '', to: ''
    });
  }

  public render(): JSX.Element {
    return (
      <div className="FilterBoxContainer col">
        <div className={`FilterBoxContainer__header`}>
          <label>{getLocalization('filterdate')}</label>
          <Button variant="link" onClick={this.clearFilters}>Clear</Button>
        </div>
        <div className="FilterBoxContainer__body">
          <Form.Group as={Row} className="DateFilterFormGroup">
            <Form.Label column={true} sm={2} className="date-filter-form-label">From</Form.Label>
            <Col sm={10}>
              <Form.Control
                as="input"
                type="date"
                size="sm"
                onChange={(e) => this.handleDateValueChange((e.target as HTMLInputElement).value, 'from')}
                value={this.props.selectedDates['from'] || ''}
                max={this.props.selectedDates['to'] || undefined}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="DateFilterFormGroup">
            <Form.Label column={true} sm={2} className="date-filter-form-label">To</Form.Label>
            <Col sm={10}>
              <Form.Control
                as="input"
                type="date"
                size="sm"
                onChange={(e) => this.handleDateValueChange((e.target as HTMLInputElement).value, 'to')}
                value={this.props.selectedDates['to'] || ''}
                min={this.props.selectedDates['from'] || undefined}
              />
            </Col>
          </Form.Group>
        </div>
      </div>
    );
  }
}
