import redux_immutable_state_invariant from 'redux-immutable-state-invariant';
import { applyMiddleware, bindActionCreators, compose, createStore, Dispatch, Store } from 'redux';
import { History } from 'history';
import { createRootReducer } from '../reducers';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { storeMiddleWare } from '../middleware/';
import { StateInterface } from '../Interfaces/StateInterface';
import { persistStore } from 'redux-persist';
import * as clientPersistAcions from '../actions/clientPersistActions';
import * as windowActions from '../actions/systemActions';
import * as locationHierarchyActions from '../actions/hierarchyActions';
import { loadHierarchy } from '../actions/hierarchyActions';
import { setClientPersist, setUsers } from '../actions/userActions';
import * as diagramActions from '../actions/diagrams';
import { Action } from 'typescript-fsa';
import { Persistor } from 'redux-persist/es/types';
import { routerMiddleware } from 'connected-react-router';

export type StoreInterface = Store<StateInterface, Action<any>>;
let middlewares = [thunk as ThunkMiddleware<StateInterface, any>, storeMiddleWare];
if (process.env.NODE_ENV !== 'production') {
  middlewares = [...middlewares, redux_immutable_state_invariant({
    ignore: [
      'clientPersist'
    ]
  })];
}

export const configureStore = (
    history: History,
    initialState?: StateInterface
): {store: StoreInterface, persistedStore: Persistor} => {
  middlewares.push(routerMiddleware(history));

  const appliedMiddleware = applyMiddleware(...middlewares);
  let composeMiddlewares;
  if (process.env.NODE_ENV !== 'production') {
    composeMiddlewares = composeWithDevTools(appliedMiddleware);
  } else {
    composeMiddlewares = compose(appliedMiddleware);
  }
  const store = createStore<StateInterface, any, {}, {}>(
      createRootReducer(history),
      initialState,
      composeMiddlewares
  );
  createBridgeToLegacy(store);
  if (module.hot) {
    module.hot.accept('Reducers', () => {
      const nextReducer = require('Reducers');
      store.replaceReducer(nextReducer);
    });
  }
  return {store, persistedStore: persistStore(store)};
};

// The actions below can be used by legacy
const createBridgeToLegacy = (store: StoreInterface) => {
  if (window) {
    window['ReactAppBridge'] = {};
    (window as any).ReactAppBridge.Dispatch = action => {
      action.meta = { middlewareReducedAction: true };
      return store.dispatch(action);
    };
    const ActionCreators = {
      ...clientPersistAcions,
      ...locationHierarchyActions,
      ...diagramActions,
      setClientPersist,
      setUsers,
      ...windowActions,
      loadHierarchy
    };
    (window as any).ReactAppBridge.ReduxActions = bindActionCreators(ActionCreators, (store.dispatch as Dispatch));
  }
};
