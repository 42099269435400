import * as React from 'react';
import { connect } from 'react-redux';
import { DataPoint } from '../../../../Interfaces/DataPoint';
import DeleteButton from '../DeleteButton';
import { deletePOI } from '../../../../actions/pois';

interface Props {
  dataPoint: DataPoint;
  unrender: () => void;
  bsSize?: 'xs' | 'xsmall' | 'sm' | 'small';
}

interface ActionProps {
  deletePOI: (rowId: number, id: string, formId: string, callBack: () => void) => void;
}

export type DeleteButtonProps = Props & ActionProps;

export class DeleteButtonContainer extends React.Component <DeleteButtonProps> {

  constructor(props) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <DeleteButton {...this.props} />
    );
  }
}

const mapDispatchToProps = (dispatch): ActionProps => {
  return {
    deletePOI: (rowId: number, id: string, formId: string, callBack: () => void) => {
      dispatch(deletePOI(rowId, id, formId, callBack));
    }
  };
};

export default connect(null, mapDispatchToProps)(DeleteButtonContainer);
