import '../styles/DateQuestion.scss';
import 'react-datepicker/dist/react-datepicker.css';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import bind from 'bind-decorator';
import FormUtils from '../utils/FormUtils';
import { DataPoint } from '../../../Interfaces/DataPoint';
import QuestionLabel from './QuestionLabel';
import { LooseObject } from '../../../Interfaces/LooseObject';

interface Props {
  question: LooseObject;
  value: null | number;
  dataPoint: DataPoint;
  edit: boolean;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  isSubQuestion?: boolean;
}

interface State {
  value: string;
  edit: boolean;
  isSafari: boolean;
}

export default class TimeQuestion extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    const { value, question } = this.props;
    this.state = {
      value: value ? value : question.default ? question.default : '',
      edit: props.edit, // props.edit ? props.edit : false
      isSafari: navigator.userAgent.indexOf('Mac') !== -1 &&
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    };
  }

  @bind
  private handleChange(e) {
    const { question, updateAnswer } = this.props;
    const value = e.target.value;
    this.setState({ value });
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = value;
      updateAnswer(newAns);
    }
  }

  @bind
  private handleSelectedTime(date) {
    const pad = (v) => {
      return v < 10 ? `0${v}` : v;
    };
    const { question, updateAnswer } = this.props;
    const value = `${date.getHours()}:${pad(date.getMinutes())}`;
    this.setState({ value });
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = value;
      updateAnswer(newAns);
    }
  }
  public shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value || this.state.edit !== nextState.edit ||
      nextProps.dataPoint.validate !== this.props.dataPoint.validate;
  }

  public render(): JSX.Element {
    const { question, formUtils, dataPoint, isSubQuestion } = this.props;
    const { value, edit, isSafari } = this.state;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    const hasError = required && dataPoint.validate && this.state.value === '' ? 'has-error' : '';

    const getDate = () => {
      const d = new Date();
      const times = value.split(':');
      d.setHours(Number(times[0]));
      d.setMinutes(Number(times[1]));
      return d;
    };

    const input = isSafari ? (
      <DatePicker
        className="form-control date-question"
        wrapperClassName="date-picker"
        onChange={this.handleSelectedTime}
        selected={value !== '' ? getDate() : undefined}
        disabled={!edit}
        showTimeSelect={true}
        showTimeSelectOnly={true}
        timeIntervals={1}
        dateFormat="h:mm aa"
      />
    ) : (
      <input
        type="time"
        name={question.id}
        className="form-control date-question"
        value={this.state.value}
        disabled={!edit}
        onChange={this.handleChange}
      />
    );

    return (
      <div className={`${'form-group'} ${hasError} ${!isSubQuestion && formUtils.getResponsiveView(question)}`}>
        <QuestionLabel question={question} dataPoint={dataPoint} formUtils={formUtils}>
          {required}
        </QuestionLabel>
        {input}
      </div>
    );
  }
}
