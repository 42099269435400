import * as React from 'react';
import bind from 'bind-decorator';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import { Dropdown } from 'react-bootstrap';
import { getLocalization } from '../../../global/global';
import GenericModal from '../../Modals/GenericModal';

interface Props {
  clientPersist: ClientPersistInterface;
  uploadMapImport: (file, diagramName: string, srs: string) => void;
}

interface State {
  showModal: false;
  diagramName: string;
  sourceSRS: string;
  file: any;
  validate: boolean;
}

export default class ImportMapComponent extends React.Component<Props, State> {
  private fileInput = React.createRef<HTMLInputElement>();
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      diagramName: '',
      sourceSRS: '',
      file: null,
      validate: false
    };
    this.fileInput = React.createRef();
  }

  @bind
  private showImportModal(visible) {
    this.setState({ showModal: visible });
  }

  @bind
  private handleChange(e) {
    const state = {...this.state};
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  @bind
  private handleFileChange(e) {
    if (e.target.files.length > 0) {
      this.setState({ file: e.target.files[0] });
    }
  }

  @bind
  private importData() {
    console.log('import data');
    const { file, diagramName, sourceSRS } = this.state;
    if (!file || diagramName === '') {
      this.setState({ validate: true });
      return;
    }
    this.props.uploadMapImport(file, diagramName, sourceSRS);
  }

  @bind
  private onFileSelectClick() {
    if (this.fileInput.current) {
      this.fileInput.current.click();
    }
  }

  @bind
  private getModalContent() {
    const error = this.state.validate ? 'has-error' : '';
    return (
      <div>
        <div className={`form-group ${this.state.diagramName === '' && error}`}>
          <label>{getLocalization('name')}</label>
          <input
            type="text"
            name="diagramName"
            value={this.state.diagramName}
            className="form-control input input-sm"
            autoComplete="off"
            onChange={this.handleChange}
          />
        </div>
        <p className="text-info">{getLocalization('importCustomMapInfo')}</p>
        <div className={`form-group ${!this.state.file && error}`}>
          <button className="btn btn-sm btn-default" onClick={this.onFileSelectClick}>
            {getLocalization('selectImportCustomMap')}
          </button>
          <input
            ref={this.fileInput}
            type="file"
            name="importFileSelect"
            accept="application/pdf, image/*, .mbtiles, .kml, .dxf"
            className="d-none"
            onChange={this.handleFileChange}
          />
          <span id="import-file-name">
            {this.state.file && this.state.file.name}
          </span>
        </div>
        {this.state.file && this.state.file.name.endsWith('.dxf') && (
          <div className={`form-group ${this.state.sourceSRS === '' && error}`}>
            <label>{getLocalization('sourceSRS')}</label>
            <input
              type="text"
              className="form-control input input-sm"
              autoComplete="off"
              onChange={this.handleChange}
              name="sourceSRS"
              value={this.state.sourceSRS}
            />
          </div>
        )}
      </div>
    );
  }

  public render(): JSX.Element {
    const modal = this.state.showModal ? (
      <GenericModal
        visible={this.state.showModal}
        body={this.getModalContent()}
        onConfirm={this.importData}
        confirmText={getLocalization('uploadDiagram')}
        title={getLocalization('importCustomMapTitle')}
        cancel={() => this.showImportModal(false)}
        cancelText={getLocalization('cancel')}
        dialogClassName=""
      />
    ) : null;

    return (
      <React.Fragment>
        {modal}
        <Dropdown.Item
          eventKey="2"
          onClick={() => this.showImportModal(true)}
          id="import-map-btn"
        >
          {getLocalization('customMaps')}
        </Dropdown.Item>
      </React.Fragment>
    );
  }
}
