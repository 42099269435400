// Script extracted from https://github.com/dangerisgo2021/reducer-builder/blob/master/src/index.js

import { Action, Reducer } from 'redux';

function isEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export default class ReduxReducerBuilder<T, V> {
  private initialState: T;
  private typeToReducer: object;
  private keyToReducer: any;
  constructor() {
    this.initialState = {} as T;
    this.typeToReducer = {};
    this.keyToReducer = {};
  }

  public setInitialState(initialState: T) {
    this.initialState = initialState;
    return this;
  }

  // should you be able to add multiple reducers for the same type?
  public addReducer(type: string, reducer: (state: T, payload: V) => T) {
    this.typeToReducer[type] = reducer;
    return this;
  }

  public combine(key: string, reducer) {
    this.keyToReducer[key] = reducer;
    return this;
  }

  public build(): Reducer<T, Action> {

    const self = this;

    if (isEmpty(self.keyToReducer)) {

      return (state = self.initialState, action) => {
        const reducer = self.typeToReducer[action.type];
        return reducer ? reducer(state, action) : state;
      };

    } else {

      return (state = self.initialState, action) => {

        const rootReducer = self.typeToReducer[action.type];

        const nextRootState = rootReducer ? rootReducer(state, action) : state;

        let hasChanged = state === nextRootState;

        const nextCombinedReducersState = Object.keys(self.keyToReducer)
            .reduce((nextState, key) => {
              const reducer = self.keyToReducer[key];
              const stateForKey = state[key];
              const nextStateForKey = reducer ? reducer(stateForKey, action) : stateForKey;

              nextState[key] = nextStateForKey;
              hasChanged = hasChanged || nextStateForKey !== stateForKey;

              return nextState;
            }, {});

        return hasChanged ? { ...nextRootState, ...nextCombinedReducersState } : state;
      };
    }
  }
}
