import './HeaderContainer.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { logout } from '../../actions/userActions';
import CollapseMenu from './CollapseMenu';
import { updateClientPersist } from '../../actions/clientPersistActions';
import { ModalComponentNames, ModalInterface } from '../../Interfaces/ModalInterface';
import { navigateAddModal, navigateRemoveModal } from '../../actions/navigationAction';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { StateInterface } from '../../Interfaces/StateInterface';
import { Navbar } from 'react-bootstrap';
import Brand from './Brand';
import RightDropdown from './RightDropdown';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { filtersMenuSelector } from '../../reducers/filtersMenuReducer';
import { RouteComponentProps, withRouter } from 'react-router';
import { RouterState } from 'connected-react-router';
import { Parameters } from 'enzyme';

interface IStateProps {
  clientPersist: ClientPersistInterface;
  filtersMenu: FiltersMenuInterface;
  routerLocation: RouterState['location'];
  singleInstanceVisible: boolean;
}

interface IActionProps {
  actions: {
    logout: (...args: Parameters<typeof logout>) => void;
    updateClientPersist: (clientPersist: Partial<ClientPersistInterface>) => void;
    navigateAddModal: (modal: ModalInterface) => void;
    navigateRemoveModal: (modalName: ModalComponentNames) => void;
  };
}

export type IExternalProps = ReactCookieProps & RouteComponentProps<{}>;

export type HeaderPropsInterface = IStateProps & IActionProps & IExternalProps;

export class HeaderContainerClass extends React.Component<HeaderPropsInterface> {

  public render(): JSX.Element | null {
    const { clientPersist } = this.props;
    const bg = clientPersist.instance !== 'mondelez' ? 'dark' : 'light';
    if (this.props.singleInstanceVisible) {
      return null;
    }
    return (
        <Navbar
          variant={bg}
          bg={bg}
          collapseOnSelect={true}
          expand="lg"
          className={'HeaderContainer'}
        >
          <Brand
            instance={this.props.clientPersist.instance}
            navigateAddModal={this.props.actions.navigateAddModal}
            navigateRemoveModal={this.props.actions.navigateRemoveModal}
          />
          <Navbar.Toggle aria-controls="header-navbar-nav" />
          <CollapseMenu
            clientPersist={this.props.clientPersist}
            navigateAddModal={this.props.actions.navigateAddModal}
            navigateRemoveModal={this.props.actions.navigateRemoveModal}
            routerLocation={this.props.routerLocation}
            history={this.props.history}
          >
            <RightDropdown
              clientPersist={this.props.clientPersist}
              cookies={this.props.cookies}
              navigateAddModal={this.props.actions.navigateAddModal}
              navigateRemoveModal={this.props.actions.navigateRemoveModal}
              updateClientPersist={this.props.actions.updateClientPersist}
              logout={this.props.actions.logout}
            />
          </CollapseMenu>
        </Navbar>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  return {
    clientPersist: state.clientPersist,
    filtersMenu: filtersMenuSelector(state),
    routerLocation: state.router.location,
    singleInstanceVisible: !!state.moduleSelection.singleInstance
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): IActionProps => {
  return {
    actions: {
      logout: async (cookies) => {
        dispatch(logout(cookies));
      },
      updateClientPersist: (clientPersist: Partial<ClientPersistInterface>) => {
        dispatch(updateClientPersist(clientPersist));
      },
      navigateAddModal: (modal: ModalInterface) => {
        dispatch(navigateAddModal(modal));
      },
      navigateRemoveModal: (modalName: ModalComponentNames) => {
        dispatch(navigateRemoveModal(modalName));
      }
    }
  };
};

export const Header = withCookies<{}>(
    withRouter(connect<
        IStateProps, IActionProps, ReactCookieProps & RouteComponentProps<{}>, StateInterface
        >(mapStateToProps, mapDispatchToProps)(HeaderContainerClass))
);
