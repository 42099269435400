import * as React from 'react';
import { connect } from 'react-redux';
import { navigateRemoveModal } from '../../actions/navigationAction';
import { NavigationInterface } from '../../Interfaces/NavigationInterface';
import { ModalComponentNames, ModalInterface } from '../../Interfaces/ModalInterface';
import ModalsList from './ModalsList';
import { ThunkDispatchAction } from '../../actions';

interface IStateProps {
  navigation: NavigationInterface;
}

interface IActionProps {
  navigateRemoveModal: (modal: ModalComponentNames) => void;
}

class ReactModalsClass extends React.Component<IStateProps & IActionProps> {
  public render(): JSX.Element | null {
    const {navigation: {modals}} = this.props;
    if (!modals.length) {
      return null;
    }
    return (
        <div className="ReactModals">
          {modals.map(({component, props}: ModalInterface, index) => {
            const ModalComponent = ModalsList[component];
            return (
                <ModalComponent
                    {...props}
                    key={index}
                />
            );
          })}
        </div>
    );
  }
}

const mapStateToProps = ({navigation}): IStateProps => {
  return {navigation};
};

const mapDispatchToProps = (dispatch: ThunkDispatchAction): IActionProps => {
  return {
    navigateRemoveModal: (modal) => dispatch(navigateRemoveModal(modal))
  };
};

export const ReactModals = connect(mapStateToProps, mapDispatchToProps)(ReactModalsClass);
