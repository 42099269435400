import * as React from 'react';
import { MoreTextComponent } from './MoreTextComponent';

// tests fail when you use import with moment. this will do for now.
const  moment = require('moment');
import NameCellRenderer from './NameCellRenderer';
import TaskParentCellRenderer from './TaskParentCellRenderer';

export const cellTemplate = (props) => {
  return (
    <div className="table-cell">
      {props.value}
    </div>
  );
};

export const nameCellRenderer = (cell, row) => {
  return cellTemplate({ value: <NameCellRenderer cell={cell} row={row} /> });
};

export const taskParentCellRenderer = (cell, row) => {
  return cellTemplate({ value: <TaskParentCellRenderer cell={cell} row={row} /> });
};

export const saleforceCellRenderer = (cell) => {
  return cellTemplate({
    value: <a href={`https://emea.salesforce.com/${cell}`} target="_blank">{`Sales force (${cell})`}</a>
  });
};

export const textCellRenderer = (cell, row, question) => {
  if (cell && cell.length > 50) {
    return cellTemplate({
      value: <span>{cell.substring(0, 50)} <MoreTextComponent value={cell} row={row} question={question} /></span>
    });
  }
  return cellTemplate({
    value: cell
  });
};

export const dateCellRenderer = (cell) => {
  const value = cell ? moment(cell).format('L') : '';
  return cellTemplate({ value: `${value}` });
};

export const timeCellRenderer = (cell) => {
  const value = cell ? moment(cell).format('llll') : '';
  return cellTemplate({ value: `${value}` });
};

export const locationRenderer = (row, columnfield, value, defaulthtml, columnProperties) => {
  return cellTemplate({ value: `${value}` });
};

/*export const groupNameCellRenderer = (row, columnfield, value, defaulthtml, columnProperties) => {
  return cellTemplate({ value: `${ !value ? 0 : value}s` });
};*/

export const getStringCellRenderer = (question) => {
  if (question.type === 'NameQuestion') {
    return nameCellRenderer;
  }
  if (question.salesForceId && question.salesForceId !== '') {
    return saleforceCellRenderer;
  }
  return (cell, row) => textCellRenderer(cell, row, question);
};

export const numericTimeRenderer = (cell) => {
  const value = cell ? cell : 0;
  return cellTemplate({ value: `${value}s`});
};

export const getNumericCellRenderer = (question) => {
  if (question.id === 'phoneEditTime' || question.id === 'portalEditTime') {
    return numericTimeRenderer;
  }
  if (question.id === 'parentRowId') {
    return taskParentCellRenderer;
  }
  return undefined;
};
