import './GalleryItemComponent.scss';
import * as React from 'react';
import { DataPoint, FileInterface } from '../../Interfaces/DataPoint';
import { SingleInstance } from '../../Interfaces/ModuleSelectionInterface';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { globalWindow } from '../../global/global';

export interface IExternalProps {
  file: FileInterface;
  dataPoint: DataPoint;
  form?: FormInterface;
  imageIndex: number;
  confirmDelete: (file: FileInterface, dataPoint: DataPoint) => void;
  setSingleInstance: (singleInstance: SingleInstance) => void;
  showViewer: (id: number) => void;
}

const className = 'GalleryItemComponent';

export const GalleryItemComponent = (props: IExternalProps) => {
  const { file, dataPoint, form, imageIndex } = props;
  let singleInstance: SingleInstance;
  if (form) {
    singleInstance = {
      model: form,
      dataPoint: {...dataPoint, formId: form.id },
      newAnswer: false
    };
  }
  const onClick = () => {
    if (singleInstance) {
      props.setSingleInstance(singleInstance);
    }
  };
  const url = file.urlThumbnail ? file.urlThumbnail : file.url;
  return (
    <div
        className={`${className}`}
    >
      <img
          src={`${url}?userid=${globalWindow.userName}&key=${globalWindow.pwrd}`}
          className={`${className}__img`}
          onClick={() => props.showViewer(imageIndex)}
          alt={dataPoint.Name}
      />
      <div className={`${className}-overlay`}>
        <div className={`${className}-overlay__description`}>
          {dataPoint.Name}
        </div>
        <div className={`${className}-overlay-actions`}>
          <a
              className={`${className}-overlay-actions__action`}
              href={`${file.url}?userid=${globalWindow.userName}&key=${globalWindow.pwrd}`}
              target="_blank"
          >
            <i className="fa fa-download fa-3x" />
          </a>
          <button
              className={`${className}-overlay-actions__action btn`}
              onClick={() => onClick()}
          >
            <i className="fa fa-list fa-3x" />
          </button>
          <button
              className={`${className}-overlay-actions__action btn`}
              onClick={() => props.confirmDelete(file, dataPoint)}
          >
            <i className="fa fa-trash fa-3x" />
          </button>
        </div>
      </div>
    </div>
  );
};
