import './FeedComponent.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { getLocalization } from '../../global/global';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { FeedProps } from './FeedContainer';
import { LoadingComponent } from '../components/LoadingComponent';
import FeedItemComponent from './FeedItemComponent';
import TextInputComponent from '../SingleInstance/elements/TextInputComponent';
import { FeedItem } from '../../Interfaces/FeedItem';
import { filtersChanged } from './utils';

interface State {
  offSet: number;
  rowId: number;
  filter: string;
  feed: FeedItem[];
  filteredFeed: FeedItem[];
  loading: boolean;
  autoUpdatedFeed: FeedItem[];
  currentFilters: FiltersMenuInterface; // filters
  formsLoaded: boolean;
  lastScroll: number;
}

export default class FeedComponent extends React.Component <FeedProps, State> {
  // private lastScrollTop = 0;
  private abortController: AbortController = new AbortController();
  constructor(props) {
    super(props);
    this.state = {
      rowId: -1,
      offSet: 0,
      filter: '',
      feed: [],
      filteredFeed: [],
      loading: false,
      autoUpdatedFeed: [],
      currentFilters: props.filtersMenu,
      formsLoaded: props.forms.loaded,
      lastScroll: 0
    };
  }

  public componentDidMount() {
      const { feed } = this.props.feed;
      // Initial fetch
      if (feed.length === 0) {
        // this.setState((prevState, prevProps) => ({ loading: true }));
        this.props.getFeed(-1, 0, this.abortController.signal, this.state.filter);
      }
  }

  @bind
  private getFeedItems() {
    const { filteredFeed } = this.state;
    return filteredFeed.map(f =>
      (
        <FeedItemComponent
          key={f.rowId}
          feedItem={f}
          setSingleInstance={this.props.setSingleInstance}
          forms={this.props.forms.collection}
          readOwn={this.props.clientPersist.readOwn}
        />
      )
    );
  }

  @bind
  private handleFilterChange(value) {
    // const filteredFeed = filterFeed(this.state.feed, value);
    // this.setState({ filter: value, filteredFeed });
    this.setState({
      loading: true,
      filter: value,
      lastScroll: 0,
      rowId: -1,
      filteredFeed: [],
      feed: []
    });

    this.abortController.abort();
    this.abortController = new AbortController();
    this.props.getFeed(-1, 0, this.abortController.signal, value);
  }

  @bind
  private getSearchFilter() {
    return (
      <div className="form-group">
        <TextInputComponent
          name={'feed-filter'}
          value={this.state.filter}
          onChange={this.handleFilterChange}
          extraAttrs={{ placeholder: getLocalization('filterFeed') }}
        />
      </div>
    );
  }

  @bind
  private showNewDataPoints() {
    this.props.clearAutoUpdatedFeed(this.state.autoUpdatedFeed);
    // this.setState({ autoUpdatedFeed: [] })
  }

  @bind
  private getNewFeedItemsAlert() {
    return (
      <p className="bg-success pointer feed-alert" onClick={this.showNewDataPoints}>
        {`${this.state.autoUpdatedFeed.length} new datapoints`}
      </p>
    );
  }

  @bind
  private onScroll(e) {
    const { loading, filter } = this.state;
    const st = e.target.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    // we are at the bottom
    if (st + 600 >= e.target.scrollHeight && !loading) {
      this.setState((prevState, props) => (
        { loading: true, lastScroll: (st <= 0 ? 0 : st) }
      ), () => this.props.getFeed(this.state.rowId, 0, this.abortController.signal, filter));
    }
    // this.lastScrollTop = st <= 0 ? 0 : st;
  }

  public componentDidUpdate(prevProps, prevState) {
    if (
      this.props.forms.loaded !== prevState.formsLoaded ||
      (this.props.feed === null && !prevState.loading) ||
      filtersChanged(this.props.filtersMenu, prevState.currentFilters)
    ) {
      // newState = newState || {};
      this.setState({
        loading: true,
        rowId: -1,
        formsLoaded: this.props.forms.loaded,
        currentFilters: this.props.filtersMenu,
        feed: [],
        filteredFeed: []
      });
      this.abortController.abort();
      this.abortController = new AbortController();
      this.props.getFeed(-1, 0, this.abortController.signal, this.state.filter);
    }
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public static getDerivedStateFromProps(props: FeedProps, state: State) {
    const newState: Partial<State> = {};
    const { feed } = props.feed;
    // if props has more feed than component, they have been loaded so we add them.
    if (feed.length > 0 && feed.length > state.feed.length) {
      newState.feed = feed;
      newState.filteredFeed = feed;
      let minRowId = feed[0].rowId;
      feed.forEach((feedItem) => {
        if (feedItem.rowId < minRowId) {
          minRowId = feedItem.rowId;
        }
      });
      newState.rowId = minRowId;
      newState.loading = false;
    } else if (!feed) { // if props feed is null, means we are loading new feed.
      newState.feed = [];
      newState.filteredFeed = [];
    }
    if (props.autoUpdateFeed.length !== state.autoUpdatedFeed.length) {
      newState.autoUpdatedFeed = props.autoUpdateFeed;
    }
    // when props feed length is 0 & feed is 0, set loading to false?
    if (feed.length === 0 && state.loading) {
      newState.feed = [];
      newState.loading = false;
    }

    // If we have new feed items and we have filter text then we filter the feed.
    /*if (newState && newState.feed && newState.feed.length > 0 && state.filter !== '') {
      newState.filteredFeed = filterFeed(newState.feed, state.filter);
    }*/
    newState.loading = props.feed.loading;
    return newState;
  }

  public render(): JSX.Element {
    const { filteredFeed, filter, loading, autoUpdatedFeed } = this.state;
    const { readOwn } = this.props.clientPersist;
    const feedItems = filteredFeed.length > 0 ? this.getFeedItems() : null;
    return (
      <React.Fragment>
        {autoUpdatedFeed.length > 0 && this.getNewFeedItemsAlert()}
        <div className="feed-component" onScroll={this.onScroll}>
          {(filteredFeed.length > 0 || filter !== '') && this.getSearchFilter()}
          <table className="table table-fixed">
            <thead>
              <tr>
                <th scope="col-4">
                  {getLocalization('name')}
                  <span className="badge badge-light">({
                    filteredFeed.length > 0 ? filteredFeed[0].feedCount : 0
                  })</span>
                </th>
                {!readOwn && (<th scope="col">{getLocalization('editor')}</th>)}
                <th scope="col">{getLocalization('modifiedOn')} </th>
                {!readOwn && (<th scope="col">{getLocalization('createdOn')} </th>)}
                {!readOwn && (<th scope="col">{getLocalization('createdBy')} </th>)}
              </tr>
            </thead>
            <tbody>
              {feedItems}
            </tbody>
          </table>
          {loading ? (<LoadingComponent />) : null}
        </div>
      </React.Fragment>
    );
  }
}
