import '../styles/UserQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { getLocalization, globalWindow } from '../../../global/global';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { ReportsButtonProps } from './Containers/ReportsButtonContainer';
import { forOwn } from 'lodash-es';
import { getFormUtils } from '../utils/FormUtilsHolder';

interface State {
  tables: string[] | null;
  reports: LooseObject[] | null;
}

export default class ReportsButton extends React.Component <ReportsButtonProps, State> {

  constructor(props) {
    super(props);
    this.state = {
      tables: null,
      reports: null
    };
  }

  @bind
  private onReportClick(reportId) {
    const report = this.props.reports.find(r => r.report_id === reportId);
    if (report) {
      const rowId = this.props.dataPoint.row_id;
      globalWindow.open(`${report.url}&row_id=${rowId}`, '_blank');
    }
  }

  public componentDidMount() {
    if (this.props.reports.length > 0) {
      const newState: Partial<State> = {};
      const tables: string[] = [];
      const subFormList = {};
      const findTables = (form) => {
        const formUtils = getFormUtils(form);
        const questions = formUtils.getQuestions();
        forOwn(questions, (value) => {
          if (value.type === 'TableQuestion' && value.table && value.table.columns) {
            tables.push(value.table.columns.relation[0].ref);
          } else if (value.type === 'Part_of_Question' && value.listItems) {
            const ref = value.listItems.relation[0].ref;
            if (!subFormList[ref]) {
              const subForm = this.props.forms.find(f => f.ref === ref);
              if (subForm) {
                findTables(subForm);
                subFormList[ref] = true;
              }
            }
          }
        });
      };
      if (!newState.tables && this.props.model) {
        findTables(this.props.model);
        newState.tables = tables;
      }
      if (this.props.reports.length > 0 && newState.tables) {
        const reports = this.props.reports.filter((report) => {
          return newState.tables && newState.tables.indexOf(report.form) !== -1;
        });
        newState.reports = reports;
      }
      return this.setState((prevState, prevProps) => {
        return {...prevState, ...newState};
      });
    }
  }

  public render(): JSX.Element | null {
    const { reports } = this.state;
    return reports && reports.length > 0 ? (
      <SplitButton
        variant={'primary'}
        title={getLocalization('reports')}
        id={'reports-btn'}
        size="sm"
        alignRight={true}
      >
        {reports.map((report) => {
          return (
            <Dropdown.Item
              key={report.report_id}
              as="a"
              onClick={() => this.onReportClick(report.report_id)}
            >
              {report.report_name}
            </Dropdown.Item>
          );
        })}
      </SplitButton>
    ) : null;
  }
}
