import '../styles/FileQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { getLocalization, globalWindow } from '../../../global/global';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { FileQuestionPropsInterface } from '../Containers/FileQuestionContainer';
import { ModalComponentNames } from '../../../Interfaces/ModalInterface';
import GenericModal from '../../../views/Modals/GenericModal';
import MediaPreview from './MediaPreview';
import { getFileAnswer, mimeMatch, updateAnswerDiagrams } from '../utils/DiagramUtils';
import { FileInterface } from '../../../Interfaces/DataPoint';

interface State {
  value: LooseObject[];
  accept: string;
  label: string;
  preview: boolean;
  previewUrl: string;
  previewType: string; // DIAGRAM || MEDIA
  selectedDiagram: string;
}

export default class FileQuestion extends React.Component <FileQuestionPropsInterface, State> {
  private input = React.createRef<HTMLInputElement>();
  constructor(props) {
    super(props);
    const { question, accept } = this.props;
    const stateAccept = question.type === 'PicturesQuestion' ?
      'image/*;capture=camera' : accept ? accept : 'application/pdf, image/*;capture=camera, video/*;capture=camcorder';
    this.state = {
      value: this.getValue(stateAccept),
      label: this.getLabel(),
      accept: stateAccept,
      preview: false,
      previewUrl: '',
      selectedDiagram: '',
      previewType: ''
    };
    this.input = React.createRef();
  }

  @bind
  private getValue(stateAccept: string) {
    const { dataPoint, question } = this.props;
    const files = dataPoint.files ? dataPoint.files : [];
    return files.filter(f => {
      if (f.questionId === '' || !f.questionId) {
        const isImage = f.mimeType.indexOf('image') === 0;
        if (isImage && question.id === 'Pictures') {
          return true;
        }/* else if (question.id === 'File') {
          return true;
        }*/
      } else if (f.questionId.toLowerCase().indexOf(question.id.toLowerCase()) === 0
        && mimeMatch(f.mimeType, stateAccept)) {
        return true;
      } else if (f.questionId.toLowerCase() === question.id.toLowerCase() && mimeMatch(f.mimeType, stateAccept)) {
        return true;
      }
      return false;
    });
  }

  /*
    Returns the label to be used for the question
  */
  @bind
  private getLabel(): string {
    const { question, accept } = this.props;
    if (question.type === 'PicturesQuestion') {
      return getLocalization('addimage');
    } else if (question.id === 'BackgroundDiagram') {
      return 'Add Background Diagram';
    } else if (question.id !== 'file') {
      return `Add File (${accept})`;
    } else if (question.id === 'File') {
      const acc = accept ? accept : '';
      return `Add File (${acc})`;
    }
    return getLocalization('addimage');
  }

  @bind
  private handleChange(e) {
    const value: LooseObject[] = Object.assign([], this.state.value);
    if (e.target.files && e.target.files[0]) {
      for (const f of e.target.files) {
        if (f.type.indexOf('image') === 0) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const fileItem: FileInterface  = {
              url: e.target.result, mimeType: f.type,  file: f, fileSize: f.size, fileName: f.name,
              tid: Math.floor(Math.random() * 1000000)
            };
            value.push(fileItem);
            this.updateAnswer(fileItem);
            this.setState({value});
            e.target.value = '';
          };
          reader.readAsDataURL(e.target.files[0]);
        } else {
          const fileItem: FileInterface = { mimeType: f.type, file: f, fileSize: f.size, fileName: f.name,
            tid: Math.floor(Math.random() * 1000000)
          };
          value.push(fileItem);
          this.updateAnswer(fileItem);
          this.setState({value});
          e.target.value = '';
        }
      }
    }
  }

  /*
    This function updates the main POI files item.
  */
  @bind
  private updateAnswer(fileItem: FileInterface) {
    const { dataPoint, updateAnswer, question } = this.props;
    if (question.id !== 'Pictures') {
      fileItem.questionId = question.id;
    }
    const newAnswer = dataPoint['files'] ? [...dataPoint['files']] : [];
    newAnswer.push(fileItem);
    updateAnswer({ files: newAnswer });
    if (this.input.current) {
      this.input.current.value = '';
    }
  }

  @bind
  private onFileBtnClick() {
    if (this.input.current) {
      this.input.current.click();
    }
  }

  @bind
  private removeFile(i: number) {
    const value: LooseObject[] = Object.assign([], this.state.value);
    const fileItem = value[i];
    value.splice(i, 1);
    this.setState({ value });
    const { dataPoint, updateAnswer } = this.props;
    const files =  dataPoint.files && dataPoint['files'].filter( f => {
      return (f.tid && f.tid !== fileItem.tid) || (f.id && f.id !== fileItem.id);
    });
    updateAnswer({ files: files });
  }

  /* Removes an image from the list. */
  @bind
  private confirmRemoveFile(i: number) {
    this.props.navigateAddModal({
      component: ModalComponentNames.ConfirmationModal,
      props: {
        onClose: () => this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal),
        onConfirm: () => {
          this.removeFile(i);
          this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal);
        },
        localizations: {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('yes'),
          confirmStyle: 'danger',
          header: (
            <p>
              {getLocalization('confirm')}
            </p>
          ),
          body: (
            <p>
              {getLocalization('confirmFileDelete')}
            </p>
          )
        }
      }
    });
  }

  @bind
  private previewMedia(view, url) {
    this.setState({ preview: true, previewUrl: url, previewType: 'MEDIA' });
  }

  @bind
  private getFileUrl(f) {
    // f.urlThumbnail ? f.urlThumbnail : f.url
    if (f.id) {
      if (f.thumbnail) {
        return `/json/file/download/thumbnail/${f.id}?userid=${globalWindow.userName}&key=${globalWindow.pwrd}`;
      }
      return `/json/file/download/${f.id}?userid=${globalWindow.userName}&key=${globalWindow.pwrd}`;
    }
    return f.url;
  }
  /*
    Renders the files.
  */
  @bind
  private getFiles(): JSX.Element[] {
    const { question } = this.props;
    const { value } = this.state;
    const files = value.map((f, i) => {
      let view;
      let showPreview = false;
      const { userName, pwrd, open } = globalWindow;
      if (f.mimeType.indexOf('image') === 0) {
        const refresh = f.rotated ? `&refresh=${f.tid}` : '';
        const src = `${this.getFileUrl(f)}${refresh}`;
        view = (
          <img
            src={src}
            height={'200'}
            width={'100%'}
          />
        );
        showPreview = f.id ? true : false;
      } else if (f.mimeType.indexOf('video') === 0 ) {
        view = (
          <video src={this.getFileUrl(f)} controls={true} className="video">
            Your browser does not support the <code>video</code> element.
          </video>
        );
        showPreview = f.id ? true : false;
      } else if (f.mimeType.indexOf('audio') === 0) {
        view = (
          <audio src={this.getFileUrl(f)} controls={true}>
            Your browser does not support the <code>audio</code> element.
          </audio>
        );
      } else if (f.mimeType.indexOf('pdf') !== -1) {
        view = (
          <React.Fragment>
            <img src="/res/images/pdf_icon.png" height="170" />
            <p>{f.name || f.fileName}</p>
          </React.Fragment>
        );
      } else {
        view = (
          <p>{f.name || f.fileName}</p>
        );
      }
      if (question.type === 'DigitalSignatureQuestion') {
        return (
          <div key={`file_ds_${question.id}${f.tid ? f.tid : ''}`}>
            {view}
          </div>
        );
      }

      const rotateBtns = f.mimeType.indexOf('image') === 0 && f.id ? (
        <React.Fragment>
          <button
            className="btn btn-default btn-sm action-btn rotate-right"
            onClick={() => this.rotateImage('90', f.id)}
          >
            <i className="fa fa-rotate-right" />
          </button>
          <button
            className="btn btn-default btn-sm action-btn rotate-left"
            onClick={() => this.rotateImage('-90', f.id)}
          >
            <i className="fa fa-rotate-left" />
          </button>
        </React.Fragment>
      ) : null;

      const downloadBtn = f.id && f.url ? (
        <React.Fragment>
          <button
            className="btn btn-default btn-sm action-btn rotate-right"
            onClick={() => open(`/json/file/download/${f.id}?userid=${userName}&key=${pwrd}`, '_blank')}
          >
            <i className="fa fa-download" />
          </button>
        </React.Fragment>
      ) : null;
      const onPreviewClick = showPreview ? (
        () => this.previewMedia(view, f.url)
      ) : undefined;
      return (
        <div className="saved-file" key={`file_${question.id}_${i}${f.tid ? f.tid : ''}`}>
          <div className="thumbnail pointer" onClick={onPreviewClick}>
            {view}
          </div>
          <div>
            {rotateBtns}
            {downloadBtn}
            <button className="btn btn-danger btn-sm action-btn remove-btn" onClick={() => this.confirmRemoveFile(i)}>
              <span className="fa fa-trash" />
            </button>
          </div>
        </div>
      );
    });
    return files;
  }

  /*
    When a diagram is selected, we need to show a preview for the user to confirm they want to use the diagram.
  */
  @bind
  private handleDiagramSelect(e) {
    this.setState({ selectedDiagram: e.target.value });
    if (e.target.value === 'globalMap') {
      this.updateAnswerDiagrams([]);
    } else {
      this.props.getFileUrl(e.target.value, this.diagramUrlLoaded);
    }
  }

  /*
    Callback function when a diagram image url has been loaded.
    This function sets initializes the rendering of the image for preview and confirmation.
  */
  @bind
  private diagramUrlLoaded(url: string) {
    this.setState({ preview: true, previewUrl: url, previewType: 'DIAGRAM' });
  }

  /*
    Rotates an image
  */
  @bind
  private rotateImage(angle: string, fileId: number) {
    const { rotateImage } = this.props;
    rotateImage(angle, fileId, this.imageRotated);
  }

  @bind
  private imageRotated(fileId: number) {
    const newValues = this.state.value.map((f) => {
      if (f.id === fileId) {
        if (f.urlThumbnail) {
          f.urlThumbnail = `${f.urlThumbnail}#refresh=${Date.now()}`;
          f.rotated = true;
        } else {
          f.url = `${f.url}#refresh=${Date.now()}`;
        }
        return {...f};
      }
      return f;
    });
    this.setState({ value: newValues });
  }

  private getMediaPreview() {
    return (
      <MediaPreview url={this.state.previewUrl} />
    );
  }

  @bind
  private getDiagramPreviewContent() {
    const { previewUrl } = this.state;
    return (
      <div>
        <img className="diagram-img" src={previewUrl} />
        <div>Use this diagram</div>
      </div>
    );
  }

  @bind
  private onConfirmDiagram() {
    const { groupDiagrams } = this.props;
    const { selectedDiagram } = this.state;
    const files = getFileAnswer(groupDiagrams, selectedDiagram);
    if (files) {
      this.updateAnswerDiagrams(files);
    }
    this.closeModals(false);
  }

  @bind
  private updateAnswerDiagrams(diagrams: FileInterface[]) {
    const { dataPoint, updateAnswer } = this.props;
    const newAnswer = updateAnswerDiagrams(dataPoint, diagrams);
    updateAnswer({ files: newAnswer });
    if (this.input.current) {
      this.input.current.value = '';
    }
  }

  @bind
  private closeModals(resetDiagram = true) {
    const newState = {...this.state};
    if (resetDiagram) {
      newState.selectedDiagram = '';
    }
    newState.preview = false;
    this.setState(newState);
  }

  /*
    Renders the view for selecting diagrams.
  */
  @bind
  private renderBackgroundDiagram() {
    const { label } = this.state;
    const { groupDiagrams, formUtils } = this.props;
    const options = groupDiagrams.map(diagram => {
      if (diagram.type === 'diagram') {
        return (
          <option key={diagram.identifier} value={diagram.imageFileId}>
            {diagram.name ? diagram.name : diagram.filename}
          </option>
        );
      }
      return null;
    });
    let selectLabel = '';
    if (formUtils) {
      if (formUtils.getModel().isChild) {
        selectLabel = getLocalization('selectDiagramSubform');
      } else {
         selectLabel = getLocalization('selectDiagram');
      }
    }
    return (
      <div className="row background-diagram">
        <div className="col-md-6">
          <label>
            {selectLabel}
          </label>
          <select className="form-control" onChange={this.handleDiagramSelect} value={this.state.selectedDiagram}>
            <option key="globalMap" value="globalMap">
              {getLocalization('globalMap')}
            </option>
            {options}
          </select>
        </div>
        {
          formUtils && !formUtils.getModel().isChild ? (
            <div className="col-md-6 d-flex align-items-center">
              <button className="btn btn-primary" onClick={this.onFileBtnClick}>
                {label}
              </button>
            </div>
          ) : null
        }
      </div>
    );
  }

  /*
    Renders the button for selecting a file.
    For Digital Signature Questions, no file selection can be done in DV.
  */
  @bind
  private renderFileSelect(): JSX.Element | undefined {
    const { question } = this.props;
    if (question.type === 'DigitalSignatureQuestion') {
      return undefined;
    }
    const { label } = this.state;

    return (
      <div className="form-group new-file">
        <button className="btn btn-primary btn-sm" onClick={this.onFileBtnClick}>
          {label}
          {question.optional === false && (
            <span className="required">{` * `}</span>
          )}
        </button>
      </div>
    );
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value ||
      this.state.preview !== nextState.preview ||
      this.state.selectedDiagram !== nextState.selectedDiagram;
  }

  public render(): JSX.Element {
    const { accept, preview, previewType } = this.state;
    const { question } = this.props;
    const diagramPreview = previewType === 'DIAGRAM' ? true : false;
    const previewModal = preview ? (
      <GenericModal
        visible={this.state.preview}
        body={diagramPreview ? this.getDiagramPreviewContent() : this.getMediaPreview()}
        title={'Preview'}
        onConfirm={diagramPreview ? this.onConfirmDiagram : this.closeModals}
        cancel={diagramPreview ? this.closeModals : undefined}
        cancelText={'Cancel'}
        confirmText={'OK'}
        dialogClassName={'large-modal'}
      />
    ) : null;
    return (
      <div className="container-fluid">
        {previewModal}
        {question.id === 'BackgroundDiagram' ? this.renderBackgroundDiagram() : this.renderFileSelect()}
        <input
          type="file"
          name={question.id}
          className="form-control d-none"
          ref={this.input}
          accept={accept}
          onChange={this.handleChange}
        />
        <div className="row">
          {question.id === 'BackgroundDiagram' ? null : this.getFiles()}
        </div>
      </div>
    );
  }
}
