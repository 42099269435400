import { TileLayer as LeafletTileLayer } from 'leaflet';
import { withLeaflet } from 'react-leaflet';
import { GridLayer } from 'react-leaflet';
import { globalWindow } from '../../../global/global';

export type LeafletElement = LeafletTileLayer;
interface Props { mapType: string; }
/*
  This component renders the mapquest later.
  This requires the Mapquest js to have been included for it to work out.
*/
class MapQuestTileLayer extends GridLayer<LeafletTileLayer, Props> {
  public createLeafletElement(props: Props): LeafletTileLayer {
    return globalWindow.L.mapquest.tileLayer('map');
  }

  public updateLeafletElement(fromProps: Props, toProps: Props) {
    super.updateLeafletElement(fromProps, toProps);
  }
}

export default withLeaflet(MapQuestTileLayer);
