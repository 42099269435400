import * as React from 'react';
import bind from 'bind-decorator';
import { LooseObject } from '../../../../Interfaces/LooseObject';
import { find } from 'lodash-es';

interface Props {
  getSubQuestions: (option: string, value: string) => JSX.Element[] | null;
  checked: boolean;
  option: LooseObject;
  handleChange: (e) => void;
  question: LooseObject;
  version: number;
  isTable: boolean;
  inputType: string;
  likertClass: string;
  namePrefix: string;
  edit: boolean;
}

interface State {
  showSubQuestions: boolean;
}

export default class SelectQuestionOption extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      showSubQuestions: this.props.checked
    };
  }

  @bind
  private hasVisibleSubQuestions(option: string): boolean {
    const { question } = this.props;
    if (question.triggerValues && question.triggerValues.triggerValue) {
      for (const triggerValue of question.triggerValues.triggerValue) {
        if (triggerValue.value === option) {
          // if at least one non-hidden subquestion found, then return true
          if (find(triggerValue.action.subQuestions.question, (question) => {
            return !question.inVisible && !question.hideInSingleInstance && !question.hideInPortal;
          })) {
            return true;
          }
        }
      }
    }
    return false;
  }

  @bind
  private hasRenderableSubQuestions(option: string): boolean {
    const { question } = this.props;
    if (question.triggerValues && question.triggerValues.triggerValue) {
      for (const triggerValue of question.triggerValues.triggerValue) {
        if (triggerValue.value === option) {
          // if at least one non-hidden or calculatedvalue or lookupvalues subquestion found, then return true
          if (find(triggerValue.action.subQuestions.question, (question) => {
            return !question.inVisible && (!(question.hideInSingleInstance || question.hideInPortal) ||
              question.type === 'CalculatedValueQuestion' || question.type === 'LookupValuesQuestion');
          })) {
            return true;
          }
        }
      }
    }
    return false;
  }

  @bind
  private handleChange(e) {
    if (!this.props.checked && !this.state.showSubQuestions) {
      this.setState({ showSubQuestions: true });
    }
    this.props.handleChange(e);
  }

  @bind
  private onClick(e) {
    if (this.props.inputType === 'radio' && this.props.checked) {
      // only when unselecting selected radio button
      // this.handleChange is not called in that case but is called in all other cases
      this.props.handleChange(null);
    }
  }

  /*
   * Returns the option media element.
   */
  @bind
  private getOptionMedia(option) {
    if (option.imageId || (option.mimeType && option.mimeType.indexOf('image') !== -1)) {
      return (
        <img
          src={`/atool/app/getMedia?action=optionMedia&id=${option.imageId || option.mediaId}`}
          alt=""
          className="option-media"
        />
      );
    } else if (option.mimeType && option.mimeType.indexOf('video') !== -1) {
      return (
        <video
          controls={true}
          src={`/atool/app/getMedia?action=optionMedia&id=${option.mediaId}`}
          className="option-media"
        />
      );
    } else if (option.mimeType && option.mimeType.indexOf('audio') !== -1) {
      return (
        <audio
          controls={true}
          src={`/atool/app/getMedia?action=optionMedia&id=${option.mediaId}`}
          className="option-media"
        />
      );
    }
    return null;
  }

  public render(): JSX.Element | null {
    const { option, question, version, inputType, isTable, namePrefix, likertClass, checked, edit } = this.props;
    const { showSubQuestions } = this.state;
    /*
      Set the media div if available.
    */
    const mediaSrc = this.getOptionMedia(option);
    const mediaEl = mediaSrc ? (
      <div className="row col-md-12">
        {mediaSrc}
      </div>
      ) : null;

    const v = version >= 2 ? option.id : option.value;
    // calculatedvalue, lookupvalues need to be 'rendered' even if hidden (to calculate their value)
    // but they are not shown in UI
    const sqs = !showSubQuestions || !this.hasRenderableSubQuestions(option.value)
      ? null : this.props.getSubQuestions(option.value, v);
    const subQuestions = sqs ? (
      <div className="container-fluid subquestions" key={`SubQuestion_${question.id}_${option.id}_$(checked)`}>
        {sqs}
      </div>
    ) : null;

    if (question.type === 'DigitalSignatureQuestion') {
      if (sqs) {
        return (
          <React.Fragment>
            {sqs}
          </React.Fragment>
        );
      }
      return null;
    }
    // Subquestion indicator
    const indicator = isTable || !this.hasVisibleSubQuestions(option.value) ? null : sqs ? (
      <img
        src="/res/images/triangle-down.png"
        className="subquestion-indicator pointer"
        onClick={() => this.setState({ showSubQuestions: false })}
      />
    ) : (
      <img
        src="/res/images/triangle-right.png"
        className="subquestion-indicator pointer"
        onClick={() => this.setState({ showSubQuestions: true })}
      />
    );
    const id = `question-${question.id}-option-${option.id ? option.id : option.value}`;
    return (
      <React.Fragment key={`${question.id}_${option.id}`}>
        <div className={`form-check ${inputType} ${likertClass}`}>
          <label>
            <input
              id={id}
              className="form-check-input"
              type={inputType}
              value={v}
              name={namePrefix}
              data-code={option.code}
              onChange={this.handleChange}
              onClick={this.onClick}
              checked={checked}
              disabled={!edit}
            />
              {option.value}
          </label>
          {indicator}
          {mediaEl}
          {subQuestions}
        </div>
      </React.Fragment>
    );
  }
}
