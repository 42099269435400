import * as React from 'react';
import { ChartModel, ChartResponse } from 'Interfaces/ChartInterface';
import { JSONInterface } from 'Interfaces/JsonInterface';
import * as ChartUtils from './utils';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface Props {
  loadBarChart: (chartModel: ChartModel) => Promise<Response>;
  chartModel: ChartModel;
  form: FormInterface;
  forms: FormInterface[];
  rowId: number;
}

export const SingleInstanceChart = (props: Props) => {
  const [chartData, setChartData] = React.useState<ChartResponse[] | null>(null);
  const [charts, setCharts] = React.useState<JSONInterface[]>([]);

  React.useEffect(() => {
    if (chartData === null) {
      const response = props.loadBarChart({...props.chartModel, rowId: props.rowId});
      response.then(response => response.json()).then(json => {
        setChartData(json);
      }).catch(error => {
        // this.setState({ loading: false });
        console.log(error);
      });
    }
  }, [props]);

  React.useEffect(() => {
    if (chartData !== null) {
      const charts = ChartUtils.getCharts(props.chartModel, chartData, props.form, props.forms).map((chart) => {
        chart.chart.height = '400px';
        return chart;
      });
      setCharts(charts);
    }
  }, [chartData]);

  return (
    <div className="container-fluid">
      {charts && charts.length > 0 && (
        charts.map((chart, index) => {
          return (
            <div
              className="col-6"
              key={`single-instance-chart-${props.chartModel.id}-${index}`}
            >
              <HighchartsReact
                highcharts={Highcharts}
                options={chart}
              />
            </div>
          );
        })
      )}
    </div>
  );
};
