import { FiltersMenuInterface } from '../Interfaces/FiltersMenuInterface';
import { LooseObject } from '../Interfaces/LooseObject';

export const validEmail = (email) => {
  const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
};


export const validPhoneNumber = (value: string) => {
  const regex = /^\+?\d+$/;
  return regex.test(value);
};

export const getQueryParams = (qs): {[key: string]: string} => {
  qs = qs.split('+').join(' ');
  const params = {};
  const re = /[?&]?([^=]+)=([^&]*)/g;
  let tokens = re.exec(qs);
  while (tokens) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    tokens = re.exec(qs);
  }
  return params;
};

export const pad = (val) => {
  if (val < 10) {
    return `0${val}`;
  }
  return val;
};

export const resetHash = () => {
  window.location.hash = '';
};

export const createRequestFilters = (filtersMenu: FiltersMenuInterface): any[] => {
  const filters: any = [];
  if (filtersMenu.selectedUsers.length > 0) {
    const filter: LooseObject = {};
    filter.condition = 'IN';
    filter.field = 'users';
    filter.value = filtersMenu.selectedUsers.map(u => u.id).join(',');
    filter.operator = '0';
    filter.fieldOperator = '1';
    filters.push(filter);
  }
  let fromFilter: LooseObject | undefined;
  if (filtersMenu.selectedDates.from && filtersMenu.selectedDates.from !== '') {
    fromFilter = {};
    fromFilter.condition = 'GREATER_THAN_OR_EQUAL';
    fromFilter.field = 'modified';
    fromFilter.value = filtersMenu.selectedDates.from;
    fromFilter.operator = '0';
    fromFilter.fieldOperator = '1';
    filters.push(fromFilter);
  }
  if (filtersMenu.selectedDates.to && filtersMenu.selectedDates.to !== '') {
    const filter: LooseObject = {};
    filter.condition = 'LESS_THAN_OR_EQUAL';
    filter.field = 'modified';
    filter.value = filtersMenu.selectedDates.to;
    filter.operator = '0';
    filter.fieldOperator = '1';
    if (fromFilter) {
      fromFilter.condition2 = filter;
    } else {
      filters.push(filter);
    }
  }
  return filters;
};

export const isNullOrUndefined = (value) => {
  return value === null || value === undefined;
};

export const getLocationHierachyDepth = (locations) => {
  let depth = 0;
  locations.forEach((loc) => {
    if (Number(loc.level) > depth) {
      depth = Number(loc.level);
    }
  });
  return depth;
};

export const generateRandomId = () => {
  const possible = 'abcdefghijkmnpqrstuvwxyz0123456789';
  let i = 0;
  const generated: string[] = [];
  while (i < 10) {
    const index = Math.floor(Math.random() * (35 + 1));
    generated.push(possible.charAt(index));
    i++;
  }
  return generated.join('');
};
