import { getRequestConfig } from './index';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import { ShareUser } from 'Interfaces/User';

export const doSendActionEmail = (signal: AbortSignal, config: JSONInterface) => {
  const headers = getRequestConfig();
  return fetch(`/json/app/action/send`, {...headers, ...config, signal: signal });
};

export const doCheckUser = (email: string, id: string, signal: AbortSignal) => {
  const config = getRequestConfig();
  return fetch(`/json/app/share/check/user`, Object.assign({}, {
    method: 'POST', body: JSON.stringify({ email, id })
  } , config, signal));
};

export const doShareDataPoint = (shareDetails: {user: ShareUser; dataPoint: DataPoint}, signal: AbortSignal) => {
  const config = getRequestConfig();
  return fetch(`/json/app/share/user`, Object.assign({}, {
    method: 'POST', body: JSON.stringify(shareDetails)
  } , config, signal));
};

export const doGetSharerUser = (id: string, signal: AbortSignal) => {
  const config = getRequestConfig();
  return fetch(`/json/app/share/sharer/user/${id}`, Object.assign({} , config, signal));
};

export const doLoadSharedData = (signal: AbortSignal) => {
  const config = getRequestConfig();
  return fetch(`/json/app/share/list/datapoints`, Object.assign({} , config, signal));
};

export const doUnSharedData = (ids: DataPoint[], signal: AbortSignal) => {
  const config = getRequestConfig();
  return fetch(`/json/app/share/remove`, Object.assign({} , {
    method: 'POST', body: JSON.stringify(ids)
  }, config, signal));
};
