import * as React from 'react';
import bind from 'bind-decorator';
import { getLocalization } from '../../global/global';
import { Button, Dropdown, Form, ProgressBar } from 'react-bootstrap';
import { ConfirmationModal } from '../Modals/ConfirmationModal';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { forOwn } from 'lodash-es';
import { getFormUtils } from '../SingleInstance/utils/FormUtilsHolder';
import { Column, QuestionInterface } from 'Interfaces/Forms/QuestionInterface';
import GenericModal from '../../views/Modals/GenericModal';
import { ChartModel } from 'Interfaces/ChartInterface';

interface Props {
  form: FormInterface;
  model: ChartModel;
  loadChartData: (url: string, callBack: (data) => void) => void;
  loadBarChart: (chartModel: ChartModel) => Promise<Response>;
  locationLabels: string[];
  chartLoaded: (data, params) => void;
  filtersMenu: FiltersMenuInterface;
  onDone: (url: string) => void;
}

interface State {
  questions: string[];
  stackRows: string;
  stackFields: string;
  excludenull: string;
  combinenumericfields: string;
  line_average: string; // line_average
  orientation: string; // orientation
  legendid: string;
  locationLevels: string; // grouping
  chartReady: boolean;
  showModal: boolean;
  showDropdown: boolean; // This is used to control the question dropdown visibility.
  questionList: QuestionInterface[];
  timeseriestype: string;
  combinelocationsfields: string;
  showAlert: boolean;
  message?: JSX.Element;
  dateQuestions: QuestionInterface[];
  xAxisQuestions: QuestionInterface[];
  layoutTableColumns: Column[];
  model: ChartModel;
  loading: boolean;
  isDateSelected: boolean;
}

export default class ChartOptionsComponent extends React.Component<Props, State> {
  private initialState: State = {
    questions: [],
    excludenull: '0',
    stackRows: '0',
    stackFields: '0',
    combinenumericfields: '0',
    line_average: '0',
    orientation: '0',
    legendid: '',
    locationLevels: '0',
    chartReady: false,
    showModal: false,
    showDropdown: false,
    questionList: [],
    timeseriestype: 'DAY',
    combinelocationsfields: '0',
    showAlert: false,
    message: undefined,
    dateQuestions: [],
    xAxisQuestions: [],
    layoutTableColumns: [],
    model: {
      id: '',
      formId: '',
      questions: [],
      type: 'BAR_NUMERIC',
      name: '',
      scale: 'DAY',
      average: false,
      grouping: ''
    },
    loading: false,
    isDateSelected: false
  };

  constructor(props) {
    super(props);
    const initState = {...this.initialState, model: {...props.model}};
    this.state = initState;
  }

  public componentDidMount(): void {
    this.initQuestions();
  }

  @bind
  private initQuestions() {
    const { form, model } = this.props;
    if (model.formId.indexOf('-') !== -1) {
      return this.getLayoutTableColumns();
    }
    const formUtils = getFormUtils(form);
    const questions = formUtils.getQuestions();
    const list: QuestionInterface[] = [];
    const dateQuestions: QuestionInterface[] = [];
    const xAxisQuestions: QuestionInterface[] = [];

    const addDateQuestions = (question) => {
      if (
        `${model.type}` === 'LINE_CHOICE' ||
        `${model.type}` === 'LINE_NUMERIC' ||
        model.type === 'BAR_CHOICE' ||
        model.type === 'BAR_NUMERIC'
      ) {
        return true;
      }
      return false;
    };

    forOwn(questions, (question: QuestionInterface) => {
      switch (question.type) {
        case 'IntQuestion':
        case 'FloatQuestion':
        case 'CalculatedValueQuestion':
          if (question.id.toLowerCase() === 'countquestion' || (
            (question.type !== 'CalculatedValueQuestion' || question.numericOnly) && !question.inVisible)
          ) {
            // add the question if this is not a pie chart
            if (`${model.type}` !== 'PIE' && `${model.type}` !== 'BAR_CHOICE' && `${model.type}` !== 'LINE_CHOICE') {
              list.push(question);
            }
          }
          break;
        case 'SelectOneQuestion':
        case 'SelectMultipleQuestion':
        case 'BooleanQuestion':
        case 'LikertScaleQuestion':
        case 'StatusQuestion':
          // these questions are available in all chart types.
          if (model.type !== 'BAR_NUMERIC' && model.type !== 'LINE_NUMERIC') {
            list.push(question);
          }
          xAxisQuestions.push(question);
          break;
        case 'StringQuestion':
          if (question.validationlist && question.validationlist.length > 0) {
            if (model.type === 'BAR_CHOICE') {
              list.push(question);
            } else if (model.type === 'BAR_NUMERIC') {
              xAxisQuestions.push(question);
            }
          }
          break;
        case 'DateQuestion':
          if (addDateQuestions(question)) {
            dateQuestions.push(question);
          }
          break;
        default:
          break;
      }
    });
    this.setState({questionList: list, dateQuestions, xAxisQuestions });
  }

  @bind
  private getLayoutTableColumns() {
    const { form, model } = this.props;
    const formUtil = getFormUtils(form);
    const id = model.formId.split('-');
    const question = formUtil.getQuestion(id[1]);
    let layoutTableColumns: Column[] = [];
    if (question) {
      const table = question.table;
      if (table) {
        const columns = table.columns;
        if (columns && columns.column) {
          const newCols = [...columns.column];
          newCols.splice(0, 1);
          layoutTableColumns = newCols;
        }
      }
    }
    this.setState({ layoutTableColumns });
  }

  @bind
  private handleQuestionCheck(e) {
    const questions = [...this.state.questions];
    const model = {...this.state.model};
    if (e.target.checked) {
      questions.push(e.target.value);
      model.questions = questions;
      this.setState({questions, model});
    } else {
      const newQuestions = questions.filter( id => id !== e.target.value);
      model.questions = questions;
      this.setState({ questions: newQuestions, model });
    }
    return false;
  }

  @bind
  private onToggle(isOpen, event, metadata) {
    if (metadata.source === 'select') {
      this.setState({ showDropdown: true });
    } else {
      this.setState({ showDropdown: isOpen });
    }
  }

  /*
   * Returns the questions dropdown.
   */
  @bind
  private getQuestionsDropdown() {
    return (
      <Dropdown className="chart-content-spacing" onToggle={this.onToggle} show={this.state.showDropdown}>
        <Dropdown.Toggle variant="primary" size="sm" id="chart-select-questions">
          <i className="fa fa-list-ol" />
          Select questions
        </Dropdown.Toggle>
        <Dropdown.Menu className="chart-questions-drop-down">
          {this.state.questionList.map((question) => {
            return question.text && question.text !== '' && (
              <Dropdown.Item as="div" key={`${question.id}-chart-qn`}>
                <Form.Check
                  id={`chart-question-down-checkbox-${question.id}`}
                  type={'checkbox'}
                  label={question.text}
                  onChange={this.handleQuestionCheck}
                  value={question.id}
                  checked={this.state.model.questions.indexOf(question.id) !== -1}
                />
              </Dropdown.Item>
            );
          })}
          {this.state.layoutTableColumns.map((column) => {
            return (
              <Dropdown.Item as="div" key={`${column.id}-chart-qn`}>
                <Form.Check
                  id={`chart-question-down-checkbox-${column.id}`}
                  type={'checkbox'}
                  label={column.name}
                  onChange={this.handleQuestionCheck}
                  value={column.id}
                  checked={this.state.questions.indexOf(column.id) !== -1}
                />
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  /*
   * Returns the x-axix options for Area and Line charts.
   */
  @bind
  private getXAxisOptionsForAreaLine() {
    const dateQuestions = this.state.dateQuestions.map(question => {
      return (
        <option
          key={`${question.id}-select-option`}
          value={question.id}
        >
          {question.text}
        </option>
      );
    });
    return [
      // <option value="modifieddaytime" key="modifieddaytime">Time of day when updated (24 hours)</option>,
      <option value="created" key="createdday">Day when Created</option>
    ].concat(dateQuestions);
  }

  private getXAxisScale() {
    return (
      <div className="form-inline chart-x-axis chart-content-spacing">
        <label>Scale:</label>
        <Form.Control
          as="select"
          size="sm"
          name="scale"
          value={this.state.model.scale}
          onChange={this.onSelectChange}
        >
          <option value="DAY" key="modifiedday">Date</option>,
          <option value="WEEK" key="modifiedweek">Week</option>,
          <option value="MONTH" key="modifiedmonth">Month</option>,
          <option value="YEAR" key="modifiedyear">Year</option>
        </Form.Control>
      </div>
    );
  }

  private getXAxisDateScale() {
    return (
      <div className="form-inline chart-x-axis chart-content-spacing">
        <Form.Control
          as="select"
          size="sm"
          name="scale"
          value={this.state.model.scale}
          onChange={this.onSelectChange}
        >
          <option value="month" key="modifiedday">Month</option>,
          <option value="quarter" key="modifiedweek">Quarter</option>,
          <option value="year" key="modifiedyear">Year</option>
        </Form.Control>
      </div>
    );
  }
  /*
   * Returns the x-axis options for Bar charts.
   */
  @bind
  private getXAxisOptionsForBar() {
    const { form, locationLabels } = this.props;
    const locationLevel = form.type === 'POI' && form.hasLocationHierarchy && locationLabels.length > 0 ? (
      locationLabels.map((label, index) => {
        return label !== '' ? (
          <option key={`${location}-select-option`} value={`location${index + 1}`}>{label}</option>
        ) : null;
      })) : [];
    const questionsOptionList = this.state.xAxisQuestions
        .map(question => {
          return (
              <option
                key={`${question.id}-select-option`}
                value={question.id}
              >
                {question.text}
              </option>
          );
        });
    return locationLevel
        .concat(questionsOptionList)
        .concat(this.state.dateQuestions.map((question) => {
          return (
            <option
              key={`${question.id}-select-option`}
              value={question.id}
            >
              {question.text}
            </option>
          );
        }))
        .concat([
          <option value="year-when-updated" key="modifiedyear">Year when updated</option>,
          <option value="year-when-created" key="createdyear">Year when created</option>,
          <option value="month-when-updated" key="modifiedmonth">month when updated</option>,
          <option value="month-when-created" key="createdmonth">Month when created</option>,
          <option value="quarter-when-updated" key="modifiedquarter">Quarter when updated</option>,
          <option value="quarter-when-created" key="createdquarter">Quarter when created</option>
        ]);
  }

  /*
   * Returns the dropdown select for x-axis fields.
   */
  @bind
  private getXAxisSelect() {
    const { model, form } = this.props;
    if ((form.type === 'TABLE' && form.static) || this.state.layoutTableColumns.length > 0) {
      return null;
    }
    const barCharts = ['BAR_NUMERIC', 'BAR_CHOICE'];
    // When no questions have been selected and it is a bar chart do not show this select.
    if (barCharts.indexOf(`${model.type}`) !== -1 && this.state.model.questions.length === 0) {
      return null;
    }
    return (
      <div className="form-inline chart-x-axis chart-content-spacing col-3">
        <label>X axis:</label>
        <Form.Control
          as="select"
          size="sm"
          value={this.state.model.xaxis}
          onChange={this.onSelectChange}
          name="legendid"
          /** Roses are red, violets are blue, hacks make the world a better place */
          style={{width: 'calc(100% - 50px)'}}
        >
          <option value="">{getLocalization('selone')}</option>
          {barCharts.indexOf(`${model.type}`) !== -1 ? this.getXAxisOptionsForBar() : this.getXAxisOptionsForAreaLine()}
        </Form.Control>
      </div>
    );
  }

  /*
   * Select change handler.
   */
  @bind
  private onSelectChange(e) {
    const state = {};
    state[e.target.name] = e.target.value;
    const modelNames = ['stack', 'combine', 'legendid', 'scale', 'grouping'];
    if (modelNames.indexOf(e.target.name) !== -1) {
      const model = {...this.state.model};
      if (e.target.name === 'legendid') {
        model.xaxis = e.target.value;
      } else {
        model[e.target.name] = e.target.value;
      }
      if (e.target.name === 'combine' && e.target.value === 'FIELD' && model.type === 'BAR_CHOICE') {
        model['stack'] = 'OPTIONS';
      }
      state['model'] = model;
    }
    if (e.target.name === 'legendid') {
      if (state['model'].type === 'BAR_NUMERIC' || state['model'].type === 'BAR_CHOICE') {
        const isDate = this.state.dateQuestions.find(q => q.id === e.target.value);
        if (isDate) {
          state['isDateSelected'] = true;
          state['model'].scale = 'quarter';
        } else {
          state['isDateSelected'] = false;
          state['model'].scale = '';
        }
      }
      if (e.target.value.startsWith('location')) {
        state['model'].grouping = '';
      }
    }
    this.setState(state);
  }

  /* @bind
  private getGroupingSelect() {
    const { form, locationLabels } = this.props;
    const { legendid } = this.state;
    const maxIndex = legendid === '' || Number.isNaN(Number(legendid)) ? 4 : Number(legendid) - 1;
    const locationLevel = form.type === 'POI' && form.hasLocationHierarchy && locationLabels.length > 0 ? (
      locationLabels.map((label, index) => {
        return index < maxIndex ? (
          <option key={`location-grouping-select-option-${index}`} value={index + 1}>{label}</option>
        ) : null;
      })) : [];
    return (
      <div className="form-inline form-group chart-x-axis chart-content-spacing">
        <Form.Control
          as="select"
          size="sm"
          name="locationLevels"
          value={this.state.locationLevels}
          onChange={this.onSelectChange}
        >
          <option value="0">Select Grouping</option>
          {locationLevel}
          {['year-when-created', 'year-when-updated', 'month-when-created',
            'month-when-updated', 'quarter-when-created',
            'quarter-when-updated'].indexOf(this.state.legendid) !== -1 && [
            'Q1', 'Q2', 'Q3', 'Q4',
            // 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
            // 'H1', 'H2'
          ].map((grouping) => {
            return (<option value={grouping} key={grouping}>{grouping}</option>);
          })}
        </Form.Control>
      </div>
    ); {{['year-when-created', 'year-when-updated'].indexOf(this.state.legendid) !== -1 && [}
  }*/

  @bind
  private getCombineSelect() {
    const { model } = this.state;
    return (
      <div className="form-inline chart-x-axis chart-content-spacing">
        <Form.Control
          as="select"
          size="sm"
          name="combine"
          value={this.state.model.combine}
          onChange={this.onSelectChange}
        >
          <option value="">Select Combination</option>
          <option value="FIELD">Combine fields</option>
          {(model.type === 'BAR_NUMERIC' || model.type === 'LINE_NUMERIC') && model.grouping && (
            <option value="LOCATION">Combine Locations</option>
          )}
          {/* <option value="GROUP">Combine group</option> */}
        </Form.Control>
      </div>
    );
  }

  @bind
  private getGroupByLocation() {
    const { form, locationLabels } = this.props;
    const { model } = this.state;
    // const maxIndex = legendid === '' || Number.isNaN(Number(legendid)) ? 4 : Number(legendid) - 1;
    const locationLevels = form.type === 'POI' && form.hasLocationHierarchy && locationLabels.length > 0 ? (
      locationLabels.map((label, index) => {
        return (
          <option key={`location-grouping-select-option-${index}`} value={index + 1}>{label}</option>
        );
      })) : [];
    if (locationLevels.length === 0) {
      return null;
    }
    return model.xaxis?.startsWith('location') ? null : (
      <div className="form-inline chart-x-axis chart-content-spacing">
        <Form.Control
          as="select"
          size="sm"
          name="grouping"
          value={this.state.model.grouping}
          onChange={this.onSelectChange}
        >
          <option value="">Group by</option>
          {locationLevels}
          {/* <option value="GROUP">Combine group</option> */}
        </Form.Control>
      </div>
    );
  }

  @bind
  private getStackSelect() {
    const { model, layoutTableColumns } = this.state;
    const { form } = this.props;
    if ((form.type === 'TABLE' && form.static) || layoutTableColumns.length > 0) {
      return null;
    }
    return (
      <div className="form-inline chart-x-axis chart-content-spacing">
        <Form.Control
          as="select"
          size="sm"
          name="stack"
          value={this.state.model.stack}
          onChange={this.onSelectChange}
        >
          <option value="">Select stacking</option>
          {/*<option value="XAXIS">Stack X-Axis</option>*/}
          {model.type === 'BAR_NUMERIC' && (<option value="FIELD">Stack Fields</option>)}
          {/*model.type === 'BAR_NUMERIC' && model.grouping && (<option value="LOCATION">Stack Locations</option>)*/}
          {model.type === 'BAR_CHOICE' && (<option value="OPTIONS">Stack Options</option>)}
        </Form.Control>
      </div>
    );
  }

  @bind
  private handleOptionCheck(e) {
    const newState = {};
    const model = {...this.state.model};
    if (e.target.checked) {
      newState[e.target.name] = '1';
      if (e.target.name === 'combinelocationsfields' && this.state.combinenumericfields === '1') {
        newState['combinenumericfields'] = '0';
      } else if (e.target.name === 'combinenumericfields' && this.state.combinelocationsfields === '1') {
        newState['combinelocationsfields'] = '0';
      }
      if (e.target.name === 'vericalBars') {
        model.verticalBars = '1';
      } else if (e.target.name === 'average') {
        model.average = true;
      } else if (e.target.name === 'excludenull') {
        model.excludeNull = '1';
      }
    } else {
      newState[e.target.name] = '0';
      if (e.target.name === 'vericalBars') {
        model.verticalBars = undefined;
      } else if (e.target.name === 'average') {
        model.average = false;
      } else if (e.target.name === 'excludenull') {
        model.excludeNull = undefined;
      }
    }
    newState['model'] = model;
    this.setState(newState);
  }

  /*
   * The settings dropsdown.
   */
  @bind
  private getSettingsDropDown() {
    const { model } = this.props;
    const barChartOptions = () => {
      const options: JSX.Element[] = [(
        <Dropdown.Item as="div">
            <Form.Check
                id={`chart-settings-down-checkbox-vertical-bars`}
                type={'checkbox'}
                label="Vertical Bars?"
                onChange={this.handleOptionCheck}
                name={'vericalBars'}
                checked={this.state.model.verticalBars === '1'}
            />
        </Dropdown.Item>
        )];
      return options;
    };
    return (
        <Dropdown className="chart-content-spacing">
          <Dropdown.Toggle variant="primary" id="chart-settings-dropdown" size="sm">
            <i className="fa fa-cog" />
          </Dropdown.Toggle>
          <Dropdown.Menu rootCloseEvent="click" className="chart-questions-drop-down">
            {/* All charts */}
            <Dropdown.Item as="div">
              <Form.Check
                  id={`chart-settings-down-checkbox-excludenull`}
                  type={'checkbox'}
                  label="Exclude Non-Response Values"
                  onChange={this.handleOptionCheck}
                  name={'excludenull'}
                  checked={this.state.model.excludeNull === '1'}
              />
            </Dropdown.Item>
            {/* Not for pie chart*/}
            {/*`${model.type}` !== 'PIE' && model.type !== 'BAR_CHOICE' &&
            (
                <Dropdown.Item as="div">
                  <Form.Check
                      id={`chart-settings-down-checkbox-combine-numeric-fields`}
                      type={'checkbox'}
                      label="Combine all numeric values into one chart"
                      onChange={this.handleOptionCheck}
                      name={'combinenumericfields'}
                      checked={this.state.combinenumericfields === '1'}
                  />
                </Dropdown.Item>
            )*/}
            {/* Line charts */}
            {/*`${model.type}` === 'LINE' &&
            (
                <Dropdown.Item as="div">
                  <Form.Check
                      id={`chart-settings-down-checkbox-combine-locations-fields`}
                      type={'checkbox'}
                      label="Combine locations into one chart"
                      onChange={this.handleOptionCheck}
                      name={'combinelocationsfields'}
                      checked={this.state.combinelocationsfields === '1'}
                  />
                </Dropdown.Item>
            )*/}
            {/* Line & Bar Chart */}
            {(`${model.type}` === 'LINE' || `${model.type}` === 'BAR_NUMERIC') &&
            (
                <Dropdown.Item as="div">
                  <Form.Check
                      id={`chart-settings-down-checkbox-line-average`}
                      type={'checkbox'}
                      label="Show Average"
                      onChange={this.handleOptionCheck}
                      name={'average'}
                      checked={this.state.model.average === true}
                  />
                </Dropdown.Item>
            )}
            {/* Bar Chart only */}
            {(`${model.type}` === 'BAR_NUMERIC' || `${model.type}` === 'BAR_CHOICE') &&
            barChartOptions()}
            {/* Bar Chart only */}
            {/*(`${model.type}` === 'BAR_NUMERIC' || `${model.type}` === 'BAR_CHOICE') && `${form.type}` === 'POI' &&
            (
                <Dropdown.Item as="div">
                  <Form.Check
                      id={`chart-settings-down-checkbox-stack-fields-as-bars`}
                      type={'checkbox'}
                      label="Stack fields as bars"
                      onChange={this.handleOptionCheck}
                      name={'stackFields'}
                      checked={this.state.stackFields === '1'}
                  />
                </Dropdown.Item>
            )*/
            }
            {/* Bar Chart only */}
            {/*(`${model.type}` === 'BAR_NUMERIC' || `${model.type}` === 'BAR_CHOICE') && form.static === true ?
                (
                    <Dropdown.Item as="div">
                      <Form.Check
                          id={`chart-settings-down-checkbox-stack-rows-as-bars`}
                          type={'checkbox'}
                          label="Stack rows as bars"
                          onChange={this.handleOptionCheck}
                          name={'stackRows'}
                          checked={this.state.stackRows === '1'}
                      />
                    </Dropdown.Item>
                )
                :<React.Fragment/>
                */}
          </Dropdown.Menu>
        </Dropdown>
    );
  }

  @bind
  private getUrl() {
    const { model, form, filtersMenu } = this.props;
    const params: any = {...this.state};
    delete params['chartData'];
    delete params['chartReady'];
    delete params['charts'];
    delete params['questionList'];
    delete params['dateQuestions'];
    /*if (model.chartType === '1' || model.chartType === '2') {
      params.legendid = 'modified';
      switch (this.state.legendid) {
        case 'modifiedday':
          params.timeseriestype = 'DAY';
          break;
        case 'modifiedweek':
          params.timeseriestype = 'WEEK';
          break;
        case 'modifiedmonth':
          params.timeseriestype = 'MONTH';
          break;
        case 'modifiedyear':
          params.timeseriestype = 'YEAR';
          break;
        case 'modifieddaytime':
          params.timeseriestype = 'HOUR';
          break;
        default:Render
          break;
      }
    }*/
    params.newdata = '1';
    params.includelocation = '0';
    params.type = 'SUM';
    params.includetimestamp = '0';
    params.includeUsers = '';
    params.format = 'json';
    params.locationLevelDataFilter = '';
    params.subgroup = '';
    params.rowquestionid = '';
    params.reportType = this.state.locationLevels !== '0' ? 1 : 0;

    const urlParams: string[] = [];
    for (const key in params) {
      if (Array.isArray(params[key])) {
        urlParams.push(`${key}=${params[key].join(',')}`);
      } else {
        urlParams.push(`${key}=${params[key]}`);
      }
    }
    urlParams.push(`locs=${filtersMenu.selectedLocations.map(l => l.key).join(',')}`);
    urlParams.push(`levels=${filtersMenu.selectedLocations.map(l => Number(l.level) + 1).join(',')}`);
    return `/json/app/jasper/${form.ref}/0/1/${model.type}/SUM?${urlParams.join('&')}`;
  }
  /*
   * Build chart url params.
   */
  @bind
  private renderChart() {
    if (this.state.combinelocationsfields === '1' && this.state.locationLevels === '0') {
      this.setState({ showAlert: true, message: (
        <p>Select grouping</p>
      )});
      return;
    }
    const model = {...this.state.model};
    const { filtersMenu } = this.props;
    const locations: string[] = [];
    const levels: string[] = [];
    filtersMenu.selectedLocations.forEach(location => {
      locations.push(`${location.key}`);
      levels.push(`${Number(location.level) + 1}`);
    });
    model.locations = locations.join(',');
    model.locationLevels = levels.join(',');
    model.fromDate = filtersMenu.selectedDates.from || '';
    model.toDate = filtersMenu.selectedDates.to || '';
    model.users = filtersMenu.selectedUsers.map(u => u.id).join(',');
    this.setState({ loading: true });
    const loadPromise = this.props.loadBarChart(model);
    loadPromise.then(response => response.json()).then(json => {
      this.setState({ chartReady: true, loading: false });
      this.props.chartLoaded(json, this.state.model);
    }).catch(error => {
      this.setState({ loading: false });
      console.log(error);
    });
  }

  /*
   * When the chart data is loaded, render the chart.
   *
  @bind
  private chartLoaded(data) {
    this.setState({ chartReady: true });
    this.props.chartLoaded(data, this.state);
  }*/

  @bind
  private onDone(visible) {
    this.setState({ showModal: visible });
  }

  @bind
  private confirmDone() {
    this.props.onDone(this.getUrl());
    this.onDone(false);
  }

  @bind
  private closeAlert() {
    this.setState({ showAlert: false });
  }

  public render() {
    if (this.state.loading) {
      return (
        <div>
          <ProgressBar
            max={100}
            striped={true}
            now={100}
            animated={true}
          />
          <label>Loading chart...</label>
        </div>
      );
    }
    const { model } = this.props;
    const modal = this.state.showModal ? (
      <ConfirmationModal
        visible={this.state.showModal}
        onConfirm={this.confirmDone}
        onClose={() => this.onDone(false)}
        localizations={{
          cancel : getLocalization('cancel'),
          confirm: getLocalization('done'),
          confirmStyle: 'success',
          header: (<label>{getLocalization('confirm')}</label>),
          body: (<p>{getLocalization('confirmChartDone')}</p>)
        }}
      />
    ) : null;
    const showAlert = this.state.showAlert ? (
      <GenericModal
        visible={this.state.showAlert}
        onConfirm={this.closeAlert}
        body={this.state.message || (<p>Select all options</p>)}
        confirmText={'OK'}
        title={'Error'}
      />
    ) : null;
    const barChart = model.type === 'BAR_CHOICE' || model.type === 'BAR_NUMERIC';
    const lineChart = model.type === 'LINE_CHOICE' || model.type === 'LINE_NUMERIC';
    return (
      <div className="row">
        {modal}
        {showAlert}
        <div className="btn-group col">
          {this.getQuestionsDropdown()}
        </div>
        {`${model.type}` !== 'PIE' && this.getXAxisSelect()}
        <div className="col-7">
          <div className="btn-group">
            {this.getSettingsDropDown()}
          </div>
          {(
            lineChart
          ) && this.getXAxisScale()}
          {(barChart) && (
            <>
              {this.state.isDateSelected  && this.getXAxisDateScale()}
            </>
          )}
          {(barChart || (lineChart && model.type !== 'LINE_CHOICE')) && (
            <>
              {this.getGroupByLocation()}
              {this.getCombineSelect()}
            </>
          )}
          {barChart && this.getStackSelect()}
          {/*`${model.type}` !== 'PIE' && this.getGroupingSelect()*/}
          <div className="btn-group">
            <Button
              variant="success"
              size="sm"
              onClick={this.renderChart}
              id={'chart-render-btn'}
            >
              Render
            </Button>
          </div>
          <div className="btn-group float-right">
            <Button
              variant={this.state.chartReady ? 'info' : 'light'}
              size="sm"
              disabled={!this.state.chartReady}
              onClick={() => this.onDone(true)}
            >
              Done
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
