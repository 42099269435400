import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { addFeed, updateFeed, userUpdate } from './feed';
import { getFeedItem } from './utils/websocket';
import { StateInterface } from 'Interfaces/StateInterface';
import { showAlert } from 'actions';

export const initWS = () => {
  return (dispatch, getState: () => StateInterface) => {
    const socket = new SockJS('/json/ws');
    const client = Stomp.over(socket);
    console.log('connect to client...');
    client.connect('', '', (frame) => {
      client.subscribe('/user/queue/automaticupdate', (message) => {
        const response = JSON.parse(message.body);
        const users = getState().users;
        const forms = getState().forms.collection;
        const userId = getState().clientPersist.user_id;
        if (response.type && response.type === 'notification') {
          dispatch(showAlert({ visible: true,  message: response.message }));
        } else {
          const feedItem = getFeedItem(response, users, forms);
          if (response.user_id === Number(userId)) {
            dispatch(userUpdate([feedItem]));
            /*if (response.action === 'add') {
              dispatch(feedLoaded([feedItem]));
            } else if (response.action === 'edit') {
              dispatch(updateFeed(feedItem));
            }*/
          } else {
            if (response.action === 'add') {
              dispatch(addFeed(feedItem));
            } else if (response.action === 'edit') {
              dispatch(updateFeed(feedItem));
              dispatch(addFeed(feedItem));
            }
          }
        }
      });
    });
  };
};
