import { Action } from 'typescript-fsa';
import { HIERARCHY } from '../actions/actionTypes';
import ReducerBuilder from './ReducerBuilder';
import { HierarchyInterface } from '../Interfaces/HierarchyInterface';

export const INITIAL_STATE: HierarchyInterface = {
  name: '',
  centre: '',
  zoom: 0,
  geoLat: '',
  geoLon: '',
  id: 0
};

type PayloadType = HierarchyInterface;

function loadHierarchy(state: HierarchyInterface, {payload}): HierarchyInterface {
  return {...state, ...payload};
}

export default new ReducerBuilder<HierarchyInterface, Action<PayloadType>>()
    .setInitialState(INITIAL_STATE)
    .addReducer(HIERARCHY.LOAD, loadHierarchy)
    .build();
