import * as React from 'react';
import { LooseObject } from '../../../Interfaces/LooseObject';
import FormUtils from '../utils/FormUtils';
import { DataPoint } from '../../../Interfaces/DataPoint';
import QuestionLabel from './QuestionLabel';
import { Elements } from './index';
import { hasScript } from '../utils/utils';
import bind from 'bind-decorator';

interface Props {
  question: LooseObject;
  dataPoint: DataPoint;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  isSubQuestion?: boolean;
}

interface State {
  hasScript: boolean;
}

export default class LabelQuestion extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      hasScript: hasScript(props.question)
    };
  }

  /*
    renders include file.
  */
  @bind
  private renderIncludeFile(): JSX.Element[] | undefined {
    const { question, dataPoint, updateAnswer } = this.props;
    if (question.includeFile) {
      const types = question.accept.split(',');
      const fileInputs = types.map((type, index) => {
        return (
          <Elements.FileQuestion
            key={`${question.id}-file-${index}`}
            accept={type}
            question={question}
            dataPoint={dataPoint}
            updateAnswer={updateAnswer}
          />
        );
      });
      return fileInputs;
    }
    return undefined;
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return this.props.dataPoint.files !== nextProps.dataPoint.files || this.state.hasScript;
  }

  public render(): JSX.Element {
    const { question, formUtils, dataPoint, isSubQuestion } = this.props;
    return (
      <div className={`form-group ${!isSubQuestion && formUtils.getResponsiveView(question)}`}>
        <QuestionLabel question={question} dataPoint={dataPoint} formUtils={formUtils} />
        {this.renderIncludeFile()}
      </div>
    );
  }
}
