export enum languagesEnum {
  fi = 'fi',
  en = 'en',
  es = 'es',
  fr = 'fr',
  de = 'de'
}
export interface ClientPersistInterface {
  chartVid: string;
  clusterMap: boolean;
  disableMobileFeatures: boolean;
  epaymentUser: boolean;
  forms: string[];
  fromDate: string;
  instance: string | null;
  lang: languagesEnum;
  lastLogin: string;
  locations: (number | string)[];
  new_data_notification: boolean;
  organization: string | null;
  package: string;
  paymentMode: string;
  rememberFilterSelections: boolean;
  roles: string;
  showDataAutomatically: boolean;
  toDate: string;
  updated_created_data_notification: boolean;
  updated_data_notification: boolean;
  userName: string | null;
  user_id: string | null;
  useremail: string;
  users: string[];
  userLevel: string;
  userLocations: string;
  locationLabels: string[];
  payingCustomer: string;
  groupId: number | null;
  readOwn: boolean | null;
  googleAccessToken: boolean | null;
  key: string;
  fullZoom: string | null;
  shareDataUnregisteredUsers: boolean;
  onlyAccessSharedData: boolean;
  canDelete: boolean | null;
}
