
import * as React from 'react';
import { getLocalization } from '../../../global/global';
import { pad } from '../../../utils/utils';

interface Props {
  createdbyUsername: any;
  created: any;
}

export const CreatedByTimeComponent = (props: Props): JSX.Element => {
  const date = new Date(props.created);
  const dateStr = `${pad(date.getDate())}-${pad(date.getMonth() + 1)}-${date.getFullYear()}`;
  return (
    <div className="form-group row container-fluid">
      <div className="col-6">
        <label>{`${getLocalization('createdBy')} ${props.createdbyUsername}`}</label>
      </div>
      <div className="col-6">
        <label>{`${getLocalization('createdOn')} ${dateStr}`}</label>
      </div>
    </div>
  );
};
