import './Brand.scss';
import * as React from 'react';
import { HeaderPropsInterface } from './HeaderContainer';
import { getLocalization } from '../../global/global';
import { Button, Navbar } from 'react-bootstrap';
import { ModalComponentNames } from '../../Interfaces/ModalInterface';
import bind from 'bind-decorator';

interface IExternalProps {
  instance: HeaderPropsInterface['clientPersist']['instance'];
  navigateAddModal: HeaderPropsInterface['actions']['navigateAddModal'];
  navigateRemoveModal: HeaderPropsInterface['actions']['navigateRemoveModal'];
}

const className = 'Brand';
class BrandClass extends React.Component <IExternalProps> {
  @bind
  private upgradeAccount() {
    this.props.navigateAddModal({
      component: ModalComponentNames.UpgradeAccountModal,
      props: {
        onClose: () => this.props.navigateRemoveModal(ModalComponentNames.UpgradeAccountModal)
      }
    });
  }

  /*
   * Returns the logo to be displayed based on the account.
   */
  private getLogo() {
    switch (this.props.instance) {
      case 'vurugumapper':
        return (
            <img
                src={'/res/images/vurugumapper.png'}
                className="logo"
                alt="VuruguMapper"
                width="200"
                height="25"
            />
        );
      case 'trademapper':
      case 'somena':
        return (<img src={'/res/images/trademapper.jpg'} className="logo" alt="TradeMapper" width="138" height="30" />);
      case 'tnplc':
        return (<img src={'/res/images/tnplc_logo.png'} className="tnplc_logo" alt="tnplc" width="250" height="40" />);
      case 'mondelez':
        return (
            <img
                src={'/res/images/logo-mondelez.png'}
                className="tnplc_logo"
                alt="tnplc"
                width="129"
                height="20"
            />
        );
      default:
        return (<img src={'/res/images/logo.png'} className="logo" alt="Poimapper" width="129" height="19" />);
    }
  }

  public render(): JSX.Element {
    const upgrade = this.props.instance === 'poimapper' ? (
        <Button
            variant="primary"
            size="sm"
            className={`${className}__upgradeButton`}
            onClick={this.upgradeAccount}
        >
          {getLocalization('upgrade')}
        </Button>
    ) : null;
    const logo = this.props.instance === 'poimapper' ?
        (<a href={'introduction.jsp'}>{this.getLogo()}</a>)
        :
        (<a href="#">{this.getLogo()}</a>);
    return (
        <div className={className}>
          <Navbar.Brand>
            {logo}
          </Navbar.Brand>
          {upgrade}
        </div>
    );
  }
}

export default BrandClass;
