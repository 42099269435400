import './FiltersMenuContainer.scss';
import * as React from 'react';
import { Dispatch } from 'redux';
import { StateInterface } from 'Interfaces/StateInterface';
import { connect } from 'react-redux';
import { FiltersMenuInterface } from 'Interfaces/FiltersMenuInterface';
import { resizeFilterMenu } from '../../actions/filtersMenuActions';
import FormsContainer from './Form/FormsContainer';
import { filtersMenuOpenSelector, filtersMenuSettingsSelector } from '../../reducers/filtersMenuReducer';
import { LocationsContainer } from './Locations/LocationsContainer';
import UsersContainer from './User/UsersContainer';
import DatesContainer from './Date/DatesContainer';
import { Accordion } from 'react-bootstrap';
import { Resizable } from 're-resizable';

interface IStateProps {
  openFiltersMenu: FiltersMenuInterface['open'];
  panelHeight: FiltersMenuInterface['settings']['height'];
}

interface IActionProps {
  actions: {
    resizeFilterMenu: (height: number) => void;
  };
}

const className = 'FiltersMenuContainer';
export type FiltersMenuContainerPropsInterface = IStateProps & IActionProps;

export class FiltersMenuContainerClass extends React.Component<FiltersMenuContainerPropsInterface> {
  public render(): JSX.Element {
    let accordionProps = {};
    if (this.props.openFiltersMenu) {
      accordionProps = {activeKey: '1'};
    }
    accordionProps = {...accordionProps, className};
    return (
        <Accordion
            {...accordionProps}
        >
          <Accordion.Collapse
              eventKey={'1'}
          >
            <Resizable
                className={`${className}__row card`}
                size={{
                  width: '100%',
                  height: this.props.panelHeight
                }}
                minHeight={'20vh'}
                minWidth={'100%'}
                enable={{
                  top:false, right:false, bottom:true, left:false,
                  topRight:false, bottomRight:false, bottomLeft:false, topLeft:false
                }}
                onResizeStop={(e, direction, ref, d) => {
                  this.props.actions.resizeFilterMenu(
                      this.props.panelHeight + d.height
                  );
                }}
            >
              <FormsContainer />
              {this.props.openFiltersMenu && <LocationsContainer />}
              <UsersContainer />
              <DatesContainer />
            </Resizable>
          </Accordion.Collapse>
        </Accordion>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  return {
    openFiltersMenu: filtersMenuOpenSelector(state),
    panelHeight: filtersMenuSettingsSelector(state).height
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IActionProps => {
  return {
    actions: {
      resizeFilterMenu: (height) => {
        dispatch(resizeFilterMenu(height));
      }
    }
  };
};

export const FiltersMenuContainer = connect<IStateProps, IActionProps, {}, StateInterface>(
    mapStateToProps, mapDispatchToProps
)(FiltersMenuContainerClass);
