import './TableComponent.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { getLocalization } from '../../global/global';
import { TableProps } from './TableContainer';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
// import overlayFactory from 'react-bootstrap-table2-overlay';
import { LooseObject } from '../../Interfaces/LooseObject';
import { ConfirmationModal } from '../Modals/ConfirmationModal';
import HistoryTableContainer from './HistoryTableContainer';
import { initColumnDataFields } from './Columns';
import ColumnVisibilityComponent from './ColumnVisibilityComponent';
// import { getTableFilters, getTableSorts } from './utils';
// import { createRequestFilters, getLocationQuery } from '../../utils/utils';
import { isNullOrUndefined } from '../../utils/utils';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { filtersChanged } from './utils';
import { LoadingComponent } from '../components/LoadingComponent';

interface State {
  columns: LooseObject[];
  data: any;
  clientPaginate: boolean;
  selected: string[];
  confirmDelete: boolean;
  showHistory: boolean;
  page: number;
  sizePerPage: number;
  totalSize: number;
  filtersMenu: FiltersMenuInterface;
  sort: Sort | undefined;
  loading: boolean;
  // source: any;
}

interface Sort {
  dataField: string;
  order: string;
}

const className = 'TableComponent';

export default class TableComponent extends React.Component<TableProps, State> {
  private grid = React.createRef<BootstrapTable>();
  private fields: string[] = ['draft_poi'];
  private filters = undefined;
  private abortController: AbortController = new AbortController();
  // private showHideDropDownSource = [];
  // private columns;
  // private dataAdapter: any;
  constructor(props) {
    super(props);
    const columnDataFields = initColumnDataFields(
      props.model, props.users, props.clientPersist, props.locationHierarchy
    );
    // this.columns = columnDataFields;
    // this.getFields(columnDataFields);
    // this.initShowHideColumns(columnDataFields.columns);

    this.state = {
      columns: columnDataFields,
      data: [],
      clientPaginate: this.isClientPaginate(columnDataFields), // props.model.clientPaginate
      selected: [],
      confirmDelete: false,
      showHistory: false,
      page: 1,
      sizePerPage: 10,
      totalSize: 0,
      filtersMenu: props.filtersMenu,
      sort: undefined,
      loading: false
    };
  }

  @bind
  private isClientPaginate(columnDataFields) {
    const { model } = this.props;

    const estimateCount = columnDataFields.length * model.count;
    return !(estimateCount > 25000);
  }

  /*@bind
  private getFields(columns) {
    for (const column of columns) {
      let field;
      if (column.dataField) {
        if (column.dataField.endsWith('Name') && column.dataField.length > 4) {
          field = column.dataField.substring(0, column.dataField.length - 4);
        } else if (column.dataField.endsWith('_name') && column.dataField.length > 4) {
          field = column.dataField; // #.substring(0, col.name.length - 5)
        } else {
          field = column.dataField;
        }
        this.fields.push(field);
      }
    }
  }*/

  /*@bind
  private dataLoaded(data) {
    const { clientPaginate } = this.state;

    if (clientPaginate) {
      this.setState((prevState, prevProps) => ({ data: [...prevState.data, ...data] }));
    } else {
      let size = 0;
      if (data && data.length > 0 && data[0].totalCount) {
        size = data[0].totalCount;
      }
      this.setState((prevState, prevProps) => ({
        data: data, totalSize: size, page: this.currentPage, sizePerPage: this.currentPageSize
      }));
    }
  }*/

  public componentDidMount() {
    const { model } = this.props;
    if (model.type === 'TASKFORM') {
      this.loadTasks();
    } else {
      const { clientPaginate } = this.state;
      if (clientPaginate) {
        this.loadData();
      } else {
        this.loadData(0, 10);
      }
    }
  }

  public componentDidUpdate(prevProps, prevState) {
    if (filtersChanged(prevProps.filtersMenu, this.props.filtersMenu)) {
      this.abortController.abort();
      this.abortController = new AbortController();
      const { model } = this.props;
      if (!this.state.clientPaginate) {
        this.loadData(0, this.state.sizePerPage);
      } else if (model.type === 'TASKFORM') {
        this.loadTasks();
      } else {
        this.loadData();
      }
      this.setState({ data: [] });
    }
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  @bind
  private loadTasks() {
    const { forms } = this.props;
    forms.forEach(form => {
      if (form.type === 'TASKFORM') {
        const fetchDataRequest = this.props.fetchPOI(
          form.ref,
          this.abortController.signal,
          undefined,
          undefined,
          this.props.locationHierarchyQuery
        );
        this.processFetchData(fetchDataRequest, true);
      }
    });
  }

  @bind
  private loadData(offset?: number | undefined, limit?: number | undefined) {
    const { model } = this.props;
    const { filtersMenu } = this.state;
    const requestParams: string[] = [`tableView=1`];
    if (!isNullOrUndefined(offset) && !isNullOrUndefined(limit)) {
      requestParams.push(`off=${offset}`);
      requestParams.push(`limit=${limit}`);
    }

    const filters: any = [];
    const users = filtersMenu.selectedUsers.map((u) => u.id);
    if (users.length > 0) {
      filters.push({ condition : 'IN', field : 'users', value : users.join(','), operator : '0', fieldOperator : '1' });
    }
    if (filtersMenu.selectedDates.from && filtersMenu.selectedDates.from !== '') {
      filters.push({
        condition : 'GREATER_THAN_OR_EQUAL',
        field: 'modified',
        value: filtersMenu.selectedDates.from,
        operator: '0',
        fieldOperator: '1'
      });
    }
    if (filtersMenu.selectedDates.to && filtersMenu.selectedDates.to !== '') {
      filters.push({
        condition : 'LESS_THAN_OR_EQUAL',
        field: 'modified',
        value: filtersMenu.selectedDates.to,
        operator: '0',
        fieldOperator: '1'
      });
    }
    // getLocationHierarchyQuerySelector()
    const request = this.props.fetchPOI(
      model.ref,
      this.abortController.signal,
      undefined,
      this.filters ? filters.concat(this.filters) : filters,
      this.props.locationHierarchyQuery,
      this.fields.join(','),
      this.state.sort,
      requestParams.length > 0 ? requestParams : undefined
    );
    this.setState({ loading : true });
    this.processFetchData(request, false);
  }

  @bind
  private processFetchData(request, taskForm: boolean) {
    request.then(response => response.json()).then((json) => {
      if (json && json.length > 0) {
        const totalSize = json[0].totalCount;
        if (!taskForm) {
         // this.setState({ data: json });
          this.setState({ data: json, totalSize, loading: false });
        } else {
          const data = this.state.data.concat(json);
          this.setState({ data, loading: false });
        }
      } else {
        this.setState({ loading: false });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  @bind
  private showHistory(value) {
    this.setState({ showHistory: value });
  }

  @bind
  private deleteRows() {
    this.cancelDelete();
    const { model } = this.props;
    const { selected } = this.state;
    this.props.deletePOIs(selected, model.ref, this.poisDeleted);
  }

  @bind
  private poisDeleted(ids: string[]) {
    const newData = this.state.data.filter((poi) => {
      return ids.indexOf(poi.id) === -1;
    });
    // this.grid.current!.clearselection();
    this.setState({ data: newData });
  }

  @bind
  private cancelDelete() {
    this.setState({ confirmDelete: false });
  }

  @bind
  private confirmRowsDelete() {
    this.setState({ confirmDelete: true });
  }

  @bind
  private getHistory() {
    /*
    columnDataFields={{ columns: this.state.columns, dataFields: this.source.datafields }}*/
    if (this.state.selected.length === 1) {
      const selected = this.state.selected[0]; // this.grid.current!.selectionContext.selected;
      const data = this.state.data.find(d => d.id === selected);
      return (
        <HistoryTableContainer
          closeHistory={this.showHistory}
          model={this.props.model}
          poiId={data.id}
          poiName={data.Name}
          columns={this.state.columns}
        />
      );
    }
    return null;
  }

  @bind
  private sendReminders() {
    const { forms } = this.props;
    const { data } = this.state;
    const answerTables = {};
    forms.forEach(form => {
      answerTables[form.ref] = form.answerTable;
    });
    const { selected } = this.state;
    const reminders: any[] = data.map((poi) => {
      if (selected.indexOf(poi.id) !== -1) {
        if (poi.taskstatus === 'assigned' || poi.taskstatus === 'done') {
          return {
            rowId : poi.row_id,
            questionnaireId : poi.questionnaire_id,
            answerTable : answerTables[poi.questionnaire_id],
            parentRowId : poi.parentRowId,
            parentId : poi.parentId
          };
        }
      }
      return null;
    }).filter( d => d !== null);
    if (reminders.length > 0) {
      this.props.sendReminders({ customDataPointList: reminders });
    }
  }

  /**
   * Table Events
   *
   */
  @bind
  private onRowSelect(row, isSelect) {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.id)
      }));
    }
  }

  /**
   *  When select all is un/checked
   *
   */
  @bind
  private onSelectAll(isSelect, rows) {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  }

  @bind
  private onTableChange(type, newState) {
    let page = this.state.page;
    if (type === 'sort') {
      page = 1;
      const sort: Sort = { dataField: newState.sortField, order: newState.sortOrder };
      if (sort.dataField.endsWith('Name') && sort.dataField.length > 4) {
        sort.dataField = sort.dataField.substring(0, sort.dataField.length - 4);
      }
      this.setState({ sort, page: 1 }, () => {
        this.loadData((page - 1) * this.state.sizePerPage, this.state.sizePerPage);
      });
      return;
    } else if (type === 'filter') {
      this.setState({ page : 1 });
      page = 1;
      const tempFilters: any = [];
      const filters = newState.filters;
      for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
          const filter = filters[key];
          const tempFilter = {
            field: key,
            value: encodeURIComponent(filter.filterVal),
            condition: filter.comparator,
            operator: 1
          };
          if (filter.filterType === 'DATE') {
            tempFilter.value = encodeURIComponent(filter.filterVal.join(','));
          }
          if (Array.isArray(filter.filterVal)) {
            tempFilter.value = filter.filterVal.join(',');
          }
          tempFilters.push(tempFilter);
        }
      }
      this.filters = tempFilters;
    } else if (type === 'pagination') {
      return;
    }
    this.loadData((page - 1) * this.state.sizePerPage, this.state.sizePerPage);
  }

  @bind
  private onSizePerPageChange(sizePerPage, page) {
    this.setState({ page, sizePerPage }, () => {
      if (!this.state.clientPaginate) {
        this.loadData((this.state.page - 1) * this.state.sizePerPage, this.state.sizePerPage);
      }
    });
  }

  @bind
  private onPageChange(page, sizePerPage) {
    this.setState({ page, sizePerPage }, () => {
      if (!this.state.clientPaginate) {
        this.loadData((this.state.page - 1) * this.state.sizePerPage, this.state.sizePerPage);
      }
    });
  }

  @bind
  private updateColumns(columns) {
    this.setState({ columns });
  }

  @bind
  private beforeSaveCell(oldValue, newValue, row, column, done) {
    const { clientPersist } = this.props;
    // @ts-ignore
    if ((isNaN(oldValue) && isNaN(newValue) && oldValue !== newValue) || (Number(oldValue) !== Number(newValue))) {
      const newDataPoint = {...row};
      newDataPoint[column.dataField] = newValue;
      delete newDataPoint['modified'];
      delete newDataPoint['needs_editing'];
      newDataPoint['user_id'] = Number(clientPersist.user_id);
      const parameters = { copyComponents: 1 };
      const promise = this.props.savePOI(newDataPoint, parameters);
      this.onSaved(promise, row, done);
    } else {
      done(false);
    }
  }

  @bind
  private onSaved(savePromise: Promise<Response>, dataPoint, done) {
    savePromise.then(response => response.json()).then(json => {
      if (json.rowId) {
        const newDataPoint = {...dataPoint, row_id: json.rowId};
        const newStateData = this.state.data.map((dp) => {
          if (dp.row_id === dataPoint.row_id) {
            return newDataPoint;
          }
          return dp;
        });
        this.setState({ data: newStateData }, () => done(true));
      }
    }).catch(error => {
      console.log(error);
      done(false);
    });
  }

  @bind
  private renderToolbar(): any {
    const { model, clientPersist } = this.props;
    // const { columns } = this.state;
    const deleteButton = clientPersist.canDelete && (
      <button
        className="btn btn-danger btn-sm btn-delete"
        onClick={this.confirmRowsDelete}
        disabled={!(this.state.selected.length > 0)}
      >
        <i className="fa fa-trash" aria-hidden="true" />
      </button>
    );

    const historyButton = (
      <button
        className="btn btn-success btn-sm btn-history"
        onClick={() => this.showHistory(true)}
        disabled={!(this.state.selected.length === 1)}
      >
        <i className="fa fa-history" aria-hidden="true" />
      </button>
    );

    const remindersButton = model.type === 'TASKFORM' ? (
      <button
        className="btn btn-success btn-sm btn-reminders"
        onClick={this.sendReminders}
        disabled={!(this.state.selected.length > 0)}
      >
        <i className="fa fa-envelope" aria-hidden="true"/>{` ${getLocalization('sendReminders')}`}
      </button>
    ) : null;

    return (
        <div className="table-toolbar">
          <span className="table-toolbar-name">
            {model.type === 'TASKFORM' ? 'Tasks' : model.name}
          </span>
          <div className="table-toolbar-buttons">
            <ColumnVisibilityComponent columns={this.state.columns} updateColumns={this.updateColumns}/>
            {historyButton}
            {deleteButton}
            {remindersButton}
          </div>
        </div>
    );
  }

  public static getDerivedStateFromProps(props: TableProps, state: State) {
    if (filtersChanged(props.filtersMenu, state.filtersMenu)) {
      return { filtersMenu: props.filtersMenu };
    }
    return null;
  }

  public shouldComponentUpdate(nextProps, nextState) {
    if (this.state.data.length === nextState.data.length) {
      let difference = 0;
      const length = this.state.data.length;
      for (let i = 0; i < length; i++) {
        const dp = this.state.data[i];
        const dp1 = nextState.data[i];
        if (dp.row_id !== dp1.row_id) {
          difference++;
        }
        if (difference > 1) {
          return true;
        }
      }
      if (difference === 1) {
        return false;
      }
    }
    return true;
  }

  @bind
  private getTable() {
    const { clientPaginate, page, sizePerPage, totalSize, sort, loading } = this.state;
    const sizePerPageList = [5, 10, 20, 25, 30];
    const selectRow = {
      mode: 'checkbox',
      // clickToSelect: true,
      clickToEdit: true,
      selected: this.state.selected,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll
    };
    return clientPaginate ? (
        <BootstrapTable
          ref={this.grid}
          keyField="id"
          bootstrap4={true}
          data={this.state.data}
          columns={this.state.columns}
          condensed={true}
          wrapperClasses={`table-responsive table-view ${className}__table`}
          selectRow={selectRow}
          noDataIndication={'Table is Empty'}
          sort={sort}
          filter={filterFactory()}
          pagination={paginationFactory({
            page,
            sizePerPage,
            sizePerPageList,
            totalSize,
            onSizePerPageChange: this.onSizePerPageChange,
            onPageChange: this.onPageChange
          })}
          cellEdit={cellEditFactory({
            mode: 'dbclick', blurToSave: true, beforeSaveCell: this.beforeSaveCell
          })}
        />
    ) : (
        <BootstrapTable
          ref={this.grid}
          keyField="id"
          bootstrap4={true}
          data={this.state.data}
          columns={this.state.columns}
          condensed={true}
          wrapperClasses={`table-responsive table-view ${className}__table`}
          selectRow={selectRow}
          noDataIndication={'Table is Empty'}
          sort={sort}
          filter={filterFactory()}
          pagination={paginationFactory({
            page,
            sizePerPage,
            totalSize,
            sizePerPageList,
            onSizePerPageChange: this.onSizePerPageChange,
            onPageChange: this.onPageChange
          })}
          remote={true}
          loading={loading}
          /*overlay={overlayFactory({
            spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(50, 184, 196, 0.5)'}) }
          })}*/
          onTableChange={this.onTableChange}
          cellEdit={cellEditFactory({
            mode: 'dbclick', blurToSave: true, beforeSaveCell: this.beforeSaveCell
          })}
        />
    );
  }

  public render(): JSX.Element {
    const { confirmDelete, loading } = this.state;
    const confirmDeleteModal = confirmDelete ? (
      <ConfirmationModal
        onConfirm={this.deleteRows}
        onClose={this.cancelDelete}
        localizations={
          {
            cancel: 'Cancel',
            confirm:'Delete',
            confirmStyle: 'danger',
            header: (
              <label>Confirm</label>
            ),
            body: (
              <div>Delete the selected POIs?</div>
            )
          }
        }
      />
    ) : null;

    const table = this.getTable();
    return (
      <div className={className}>
        {confirmDeleteModal}
        {this.renderToolbar()}
        {table}
        {this.state.showHistory && this.getHistory()}
        {loading && (<LoadingComponent />)}
      </div>
    );
  }
}
