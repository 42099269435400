import './App.scss';
import 'react-toastify/scss/main.scss';
import * as React from 'react';
import { LoginContainer } from './views/Login/LoginContainer';
import { ReactModals } from './views/Modals/ReactModals';
import { ToastContainer } from 'react-toastify';
import ProgressContainer from './views/Progress/ProgressContainer';
import AlertContainer from './views/Alert/AlertContainer';
import ConfirmContainer from './views/Confirm/ConfirmContainer';

interface ExternalProps {
  children?: object;
}

export default class App extends React.Component<ExternalProps> {
  public render(): JSX.Element {
    return (
        <div className={'ReactAppLogin'}>
          <LoginContainer />
          <ProgressContainer />
          <AlertContainer />
          <ConfirmContainer />
          {this.props.children}
          <ReactModals />
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={true}
              closeOnClick={true}
              rtl={false}
              pauseOnFocusLoss={true}
              draggable={true}
              pauseOnHover={true}
          />

        </div>
    );
  }
}
