import { ComponentClass, FunctionComponent, NamedExoticComponent } from 'react';
import { EmailNotificationModal } from './EmailNotificationModal';
import { ChangePasswordModal } from './ChangePasswordModal';
import { UpgradeAccountModal } from './UpgradeAccountModal';
import { ShowVideoModal } from './ShowVideoModal';
import { ConfirmationModal } from './ConfirmationModal';
import { ScheduleDayInfoModal } from './ScheduleDayInfoModal';
import { ScheduleUserSelectionModal } from './ScheduleUserSelectionModal';
import { ModalComponentNames } from 'Interfaces/ModalInterface';

/**
 * NOTE: Also add the name in enum ModalComponentNames in ~./Interfaces/ModalInterface
 * @type {{EmailNotificationModal: EmailNotificationModal}}
 */
const ModalsList: Record<ModalComponentNames,
    NamedExoticComponent<any>
    | ComponentClass<any, any>
    | FunctionComponent<any>> = {
  EmailNotificationModal: EmailNotificationModal,
  ChangePasswordModal,
  UpgradeAccountModal,
  ShowVideoModal,
  ConfirmationModal,
  ScheduleDayInfoModal,
  ScheduleUserSelectionModal
};

export default ModalsList;
