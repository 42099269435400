import { doGeoCodeAddress, doReverseGeoCode } from '../api/geocode';
import { processReverseGeoCode } from './utils/reverseGeoCode';
import { LooseObject } from '../Interfaces/LooseObject';

export const reverseGeoCode = (lat: number, lng: number, callBack?: (geoCodedData: LooseObject) => void) => {
  return (dispatch, getState) => {
    const promise = doReverseGeoCode(lat, lng, getState().clientPersist.user_id);

    promise.then(response => response.json()).then((json) => {
      if (json.length > 0) {
        const geoCodedData = processReverseGeoCode(json[0]);
        if (callBack) {
          callBack(geoCodedData);
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  };
};

export const geocodeAddress = (address: string, signal: AbortSignal) => (dispatch, getState): Promise<Response> => {
  return new Promise((resolve, reject) => {
    const request = doGeoCodeAddress(
      address, getState().clientPersist.user_id, signal
    );
    request.then(response => resolve(response)).catch((error) => {
      reject(error); // console.log(error);
    });
  });
};
