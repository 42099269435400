import { getRequestConfig } from './index';
import { LooseObject } from '../Interfaces/LooseObject';

export const doSavePOI = (url: string, config: LooseObject) => {
  const headers = getRequestConfig();
  return fetch(url, Object.assign({}, headers, config));
};

export const doSaveFiles = (config: LooseObject) => {
  const headers = getRequestConfig();
  return fetch('/json/file?version=2', Object.assign({}, headers, config));
};

export const doUploadFile = (url, config, file) => {
  const headers = getRequestConfig();
  delete headers.headers['Content-Type']; // 'multipart/form-data';
  const formData = new FormData();
  formData.set('file', file);
  const newConfig = Object.assign({}, headers, config);
  newConfig.body = formData;
  return fetch(url, newConfig);
};

export const uploadToS3 = (file: any, url: string) => {
  const conf: LooseObject = {};
  conf.body = file;
  conf.method = 'PUT';
  return fetch(url, conf);
};

export const getPOI = (url, signal?): Promise<Response> => {
  const headers = getRequestConfig();
  return fetch(url, {...headers, signal: signal });
};

export const doDeletePOI = (rowId: number, id: string, qnIdentifier: string) => {
  const headers = getRequestConfig();
  headers['method'] = 'POST';
  return fetch(`/json/app/delete/${rowId}/${id}/${qnIdentifier}`, headers);
};

export const doSharePOI = (id: string) => {
  const headers = getRequestConfig();
  return fetch(`/json/app/share/${id}`, headers);
};

export const doSaveComment = (id: string, userName: string, comment: LooseObject) => {
  const headers = getRequestConfig();
  headers['body'] = JSON.stringify(comment);
  headers['method'] = 'POST';
  return fetch(`/json/app/data/addComment/${id}/${userName}`, headers);
};

export const doDeleteComment = (id: string) => {
  const headers = getRequestConfig();
  return fetch(`/json/app/data/deleteComment/${id}`, headers);
};

export const uploadToPoimapper = (file: any, fileId: string, thumbnail) => {
  const headers = getRequestConfig();
  delete headers.headers['Content-Type']; // 'multipart/form-data';
  const formData = new FormData();
  formData.set('file', file);
  const newConfig = Object.assign({}, headers, { method: 'PUT' });
  // @ts-ignore
  newConfig.body = formData;
  return fetch(`/json/file/create/${thumbnail ? 'thumbnail/' : ''}${fileId}`, newConfig);
};

export const doDeletePOIs = (rowIds: string[], qnIdentifier: string) => {
  const headers = getRequestConfig();
  headers['method'] = 'POST';
  headers['body'] = JSON.stringify({ arguments : rowIds });
  return fetch(`/json/app/delete/multiple/${qnIdentifier}`, headers);
};

export const doFetchHistory = (answerTable: string, formId: string, poiId: string) => {
  const headers = getRequestConfig();
  return fetch(`/json/app/answer/history/${answerTable}/${formId}/${poiId}`, headers);
};

export const doSendReminders = (pois) => {
  const headers = getRequestConfig();
  headers['method'] = 'POST';
  headers['body'] = JSON.stringify(pois);
  return fetch(`/json/app/reminders/send`, headers);
};

export const doFetchMapData = (params: string[], signal: AbortSignal) => {
  const headers = getRequestConfig();
  return fetch(`/json/app/map/answer?version=6&from=portal&${params.join('&')}`, {...headers, signal: signal });
};
