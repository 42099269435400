import * as React from 'react';
import { FeedItem } from '../../Interfaces/FeedItem';
import { SingleInstance } from '../../Interfaces/ModuleSelectionInterface';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { DataPoint } from 'Interfaces/DataPoint';

interface Props {
  feedItem: FeedItem;
  readOwn: boolean | null;
  setSingleInstance: (singleInstance: SingleInstance) => void;
  forms: FormInterface[];
}

const getSingleInstance = (dataPoint: DataPoint, forms) => {
  const form = forms.find(f => f.ref === dataPoint.questionnaire_id);
  if (form) {
    return {
      model: form,
      dataPoint: {...dataPoint, formId: form.id },
      newAnswer: false
    };
  }
  return undefined;
};

const FeedItemComponent = (props: Props) => {
  const { feedItem } = props;
  const icon = feedItem.iconUrl ? (<img src={feedItem.iconUrl} alt="icon" />) : null;
  const showSingleInstance = () => {
    const dataPoint = { row_id: feedItem.rowId, questionnaire_id: feedItem.formid, id: feedItem.id };
    const singleInstance = getSingleInstance(dataPoint, props.forms);
    if (singleInstance) {
      props.setSingleInstance(singleInstance);
    }
  };

  return (
    <tr>
      <td scope="col-4" >
        <span className="feed-icon" title={feedItem.formName}>
          {icon}
        </span>
        <a onClick={showSingleInstance} href={`#${feedItem.formid}/${feedItem.id}/${feedItem.rowId}/false`}>
          <strong>{feedItem.name}</strong>
        </a>
        <i> {feedItem.status}</i>
      </td>
      {!props.readOwn && (<td scope="col">{feedItem.editor}</td>)}
      <td scope="col">{feedItem.modified}</td>
      {!props.readOwn && (<td scope="col">{feedItem.createdOn}</td>)}
      {!props.readOwn && (<td scope="col">{feedItem.createdBy}</td>)}
    </tr>
  );
};
export default FeedItemComponent;
