import * as React from 'react';
import { createRef, FunctionComponent, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ScheduleInterface, SchedulesInterface } from '../../Interfaces/ScheduleInterface';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { scheduleFormAnswersSelector } from '../../reducers/scheduleAnswersReducer';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../../Interfaces/User';
import { navigateAddModal, navigateRemoveModal } from '../../actions/navigationAction';
import { ModalComponentNames, ModalInterface } from '../../Interfaces/ModalInterface';
import { DayScheduleInterface, IScheduleDayInfoModalProps } from '../Modals/ScheduleDayInfoModal';
import { IScheduleUserSelectionModalProps } from '../Modals/ScheduleUserSelectionModal';
import EventApi from '@fullcalendar/core/api/EventApi';
import View from '@fullcalendar/core/View';

interface IExternalProps {
  baseClassName: string;
  selectedForm: FormInterface;
  schedules: SchedulesInterface;
  addNewSchedule: (schedule: Omit<ScheduleInterface, 'id'>) => Promise<void>;
  deleteSchedule: (id: ScheduleInterface['id']) => Promise<void>;
}

interface ICalendarEvents {
  title: string;
  id: string;
  start: Date;
}

const eventsStyling = {
  eventColor: '#c3e6cb',
  eventTextColor: '#155724'
};

export const ScheduleCalendar: FunctionComponent<IExternalProps> = (props) => {
  const dispatch = useDispatch();
  const calendarComponentRef = createRef<FullCalendar>();
  const {selectedForm, schedules} = props;
  const [calendarEvents, setCalendarEvents] = useState<ICalendarEvents[]>([]);

  const formAnswersSelector = scheduleFormAnswersSelector(selectedForm.ref);
  const answers = useSelector(formAnswersSelector);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    const newCalendarEvents = schedules
        .filter((schedule) => schedule.date >= today.valueOf())
        .map((schedule) => {
          const answerName = answers?.find((answer) => answer.id === schedule.answerId)?.Name;
          return {
            title: answerName || `1 POI`,
            id: `${schedule.answerId}__${schedule.id}`,
            start: new Date(schedule.date),
            allDay: true,
            extendedProps: {schedule}
          };
        });
    setCalendarEvents(newCalendarEvents);
  }, [schedules, answers]);

  const refreshEvents = () => {
    const calendarApi = calendarComponentRef.current!.getApi();
    calendarApi.removeAllEvents();
    calendarApi.removeAllEventSources();
    calendarApi.addEventSource(calendarEvents);
  };

  useEffect(() => {
    refreshEvents();
  }, [calendarEvents]);

  const addSchedule = async (answerId: string, selectedDate: number | string, user: User) => {
    await props.addNewSchedule({
      answerId,
      formId: selectedForm.ref,
      userId: user.id,
      date: selectedDate,
      initialized: false
    });
  };

  const addNewSchedule = (answerId: string, selectedDate: number) => {
    const modalProps: ModalInterface<IScheduleUserSelectionModalProps> = {
      component: ModalComponentNames.ScheduleUserSelectionModal,
      props: {
        onClose: () => {
          dispatch(navigateRemoveModal(ModalComponentNames.ScheduleUserSelectionModal));
        },
        addNewSchedule: (user: User) => addSchedule(answerId, selectedDate, user)
      }
    };
    dispatch(navigateAddModal(modalProps));
  };

  const eventReceive = ({event}) => {
    // when new event is added, saves it to backend
    const selectedDate = event.start.setHours(0, 0, 0, 0);
    const answerId = event.id;
    addNewSchedule(answerId, selectedDate);
  };

  const eventAllow = (dropInfo, draggedEvent): boolean => {
    // https://fullcalendar.io/docs/eventAllow
    const selectedDate = dropInfo.start.valueOf();
    if (selectedDate < today) {
      // cannot schedule new events in the past
      return false;
    }
    const id = draggedEvent.id;
    const existingSchedule = schedules.find((schedule) => {
      // cannot schedule a new event if its already scheduled for that date
      return schedule.date === selectedDate && schedule.answerId === id;
    });
    return !existingSchedule;
  };

  const openDayModal = (selectedDate: number) => {
    if (selectedDate < today.valueOf()) {
      return;
    }
    const calendarApi = calendarComponentRef.current!.getApi();
    const daySchedules: DayScheduleInterface[] = calendarApi
        .getEvents()
        .filter((event) => {
          return event.start?.setHours(0, 0, 0, 0) === selectedDate;
        })
        .map((event) => {
          const {title, extendedProps: {schedule: {id, answerId, date, userId}}} = event;
          return {id, title, answerId, date, userId};
        });

    const modalProps: ModalInterface<IScheduleDayInfoModalProps> = {
      component: ModalComponentNames.ScheduleDayInfoModal,
      props: {
        onClose: () => dispatch(navigateRemoveModal(ModalComponentNames.ScheduleDayInfoModal)),
        deleteSchedule: props.deleteSchedule,
        daySchedules,
        selectedForm,
        selectedDate,
        addNewSchedule: (answerId: string, user: User, scheduleDate?: string) =>
          addSchedule(answerId, scheduleDate || selectedDate, user)
      }
    };
    dispatch(navigateAddModal(modalProps));
  };

  const dateClick = (info: {
    date: Date;
    dateStr: string;
    allDay: boolean;
    resource?: any;
    dayEl: HTMLElement;
    jsEvent: MouseEvent;
    view: View;
  }) => {
    const selectedDate = info.date.setHours(0, 0, 0, 0).valueOf();
    openDayModal(selectedDate);
  };

  const eventClick = (info: {
    el: HTMLElement;
    event: EventApi;
    jsEvent: MouseEvent;
    view: View;
  }) => {
    const selectedDate = info.event.start?.setHours(0, 0, 0, 0).valueOf();
    if (selectedDate) {
      openDayModal(selectedDate);
    }
  };

  return (
      <FullCalendar
          defaultView="dayGridMonth"
          height={'parent'}
          themeSystem={'bootstrap'}
          eventColor={eventsStyling.eventColor}
          eventTextColor={eventsStyling.eventTextColor}
          header={{
            left: 'title',
            center: '',
            right: 'today prev,next'
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          ref={calendarComponentRef}
          rerenderDelay={10}
          droppable={true}
          editable={false}
          eventReceive={eventReceive}
          eventAllow={eventAllow}
          dateClick={dateClick}
          eventClick={eventClick}
      />
  );
};
