import { ClientPersistInterface } from '../Interfaces/ClientPersistInterface';
import { globalWindow } from '../global/global';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ChangePasswordInterface } from '../Interfaces/ChangePasswordInterface';
import { UpgradeAccountInterface } from '../Interfaces/UpgradeAccountInterface';
import { getRequestConfig } from './index';
import { LoginRequestInterface } from '../Interfaces/LoginInterface';

export const logout = async (clientPersist: ClientPersistInterface): Promise<boolean> => {
  const toBeSent = {};
  toBeSent['users'] = clientPersist.users.join(',');
  toBeSent['forms'] = clientPersist.forms.join(',');
  if (clientPersist.locations === null || !Array.isArray(clientPersist.locations)) {
    toBeSent['locations'] = [];
  } else {
    toBeSent['locations'] = clientPersist.locations.join(',');
  }
  toBeSent['dates'] = clientPersist.fromDate + ';' + clientPersist.toDate;
  toBeSent['loadSession'] = clientPersist.rememberFilterSelections;
  toBeSent['loadDataAutomatically'] = clientPersist.showDataAutomatically;
  toBeSent['clustermap'] = clientPersist.clusterMap;
  toBeSent['user_id'] = clientPersist.user_id;
  toBeSent['userName'] = clientPersist.userName;
  toBeSent['chartVid'] = clientPersist.chartVid;
  toBeSent['lang'] = clientPersist.lang;
  toBeSent['disableMobileFeatures'] = clientPersist.disableMobileFeatures;
  try {
    const response = await axios.post(
        '/json/auth/logout',
        toBeSent,
        getRequestConfig());
    const {status} = response;
    return status === 200;
  } catch (err) {
    toast.error('Failed to logout');
    return false;
  }
};

export const changePasswordOrEmail = async (payload: ChangePasswordInterface): Promise<boolean> => {
  const request = {
    password: payload.currentPassword,
    newPassword: payload.newPassword,
    confirmPassword: payload.confirmPassword,
    useremail: payload.userEmail
  };
  try {
    const response = await axios.post(
        `/json/app/user/changepassword/${globalWindow.userID}`,
        {
        ...request
        },
        getRequestConfig());
    const {data: {status, message}} = response;
    if (response.status === 200) {
      if (status === 'FAIL') {
        toast.warn(message);
        return false;
      }
      toast.success(message);
      return true;
    }
    toast.error(message);
    return false;
  } catch (err) {
    toast.error('Failed to change password');
    return false;
  }
};

export const upgradeAccount = async (payload: UpgradeAccountInterface): Promise<boolean> => {
  const request = {
    acc_name: payload.accountName,
    acc_package: payload.premiumPackage,
    acc_pricing: payload.pricing,
    acc_organization: payload.organization
  };
  try {
    const response = await axios.post(
        `/json/app/account/upgradeaccount/${globalWindow.userID}`,
        {
          ...request
        },
        getRequestConfig());
    if (response.status === 200) {
      return true;
    }
    toast.error('An error occurred while upgrading. Contact Support.');
    return false;
  } catch (err) {
    toast.error('An error occurred while upgrading. Contact Support.');
    return false;
  }
};

export const reformatResponseDataForLoginSuccess = (
    json: any,
    googleSignIn: boolean,
    clientPersist: ClientPersistInterface): ClientPersistInterface => {
  if (json.dates !== null) {
    const dates = json.dates.split(';');
    json.from = dates.length > 0 && dates[0] !== 'null' && dates[0] !== null ? dates[0] : null;
    json.to = dates.length > 1 && dates[0] !== 'null' && dates[1] !== null ? dates[1] : null;
  }
  json.loadSession = json.loadSession === 'true';
  json.loadDataAutomatically = json.loadDataAutomatically === 'true';
  json.clusterMap = json.clusterMap === 'true';

  if (json.forms !== null) {
    json.forms = json.forms
        .split(',')
        .filter((formId) => formId !== 'undefined');
  } else {
    json.forms = [];
  }

  if (json.users !== null && json.forms !== null) {
    json.users = json.users.split(',');
  } else {
    json.users = [];
  }

  if (json.locations !== null) {
    json.locations = json.locations.split(',');
  } else {
    json.locations = [];
  }

  json.disableMobileFeatures = json.disableMobileFeatures === 'true';
  const data: Partial<ClientPersistInterface> = {
    chartVid: json.chartVid !== null ? json.chartVid : 'true',
    forms: json.forms,
    locations: json.locations,
    users: json.users,
    rememberFilterSelections: json.loadSession,
    showDataAutomatically: json.loadDataAutomatically,
    clusterMap: json.clusterMap,
    lastLogin: json.lastLogin,
    fromDate: json.from,
    toDate: json.to,
    disableMobileFeatures: json.disableMobileFeatures,
    new_data_notification: json.new_data_notification,
    updated_data_notification: json.updated_data_notification,
    updated_created_data_notification: json.updated_created_data_notification,
    useremail: json.useremail,
    instance: json.account,
    epaymentUser: json.epayment_user,
    roles: json.roles,
    userName: json.userName,
    paymentMode: json.paymentMode,
    user_id: json.id,
    organization: json.organization,
    package: json.accountPackage,
    userLevel: json.userLevel ? json.userLevel : '',
    userLocations: json.userLocations,
    locationLabels: json.locationLabels,
    payingCustomer: json.payingCustomer,
    groupId: json.groupId,
    readOwn: json.readOwn,
    googleAccessToken: json.googleAccessToken,
    key: json.key,
    fullZoom: json.fullZoom,
    shareDataUnregisteredUsers: json.shareDataUnregisteredUsers,
    onlyAccessSharedData: json.onlyAccessSharedData,
    canDelete: json.canDelete
  };
  return {...clientPersist, ...data};
};

export const doLogin = async (
    request: LoginRequestInterface,
    clientPersist: ClientPersistInterface
): Promise<{
  status: number;
  data?: ClientPersistInterface;
  error?: {
    errorCode: string;
    errorMessage: string;
  };
}> => {
  try {
    const response = await axios.post(
        '/json/auth/login',
        {
          ...request
        },
        {
          withCredentials: true,
          headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json'
          }
        });
    return {
      status: response.status,
      data: reformatResponseDataForLoginSuccess(response.data, false, clientPersist)
    };
  } catch (e) {
    console.error(e);
    return {
      status: e.response.status,
      error: e.response.data
    };
  }
};

export const doGoogleLogin = async (
    idToken: string,
    clientPersist: ClientPersistInterface
): Promise<{
  status: number;
  data?: ClientPersistInterface;
  error?: {
    errorCode: string;
    errorMessage: string;
  };
}> => {
  try {
    const response = await axios.post(
        '/json/auth/googlelogin',
        {
          idtoken: idToken
        },
        {
          withCredentials: true,
          headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json'
          }
        });
    return {
      status: response.status,
      data: reformatResponseDataForLoginSuccess(response.data, false, clientPersist)
    };
  } catch (e) {
    console.error(e);
    return {
      status: e.response.status,
      error: e.response.data
    };
  }
};

export const getGeoLocationSensor = async (): Promise<{
  status: number;
  data: {
    country: string;
    latitude: number;
    longitude: number;
    countryName: string;
  };
}> => {
  try {
    const response = await axios.get(
        'https://ipapi.co/json/',
        {
          withCredentials: false
        });
    const {country, latitude, longitude, country_name: countryName} = response.data;
    return {
      status: response.status,
      data: {country, latitude, longitude, countryName}
    };
  } catch (e) {
    console.error(e);
    return {
      status: e.response.status,
      data: e.response.data
    };
  }
};

export const checkSAMLLogin = (email) => {
  return fetch(`/json/auth/samlcheck/${email}/`);
};

export const doInit = () => {
  const config = getRequestConfig();
  return fetch('/json/auth/token', Object.assign({}, { method: 'POST'} , config));
};

export const doLoadTaskUsers = (groupId: number) => {
  const config = getRequestConfig();
  return fetch(`/json/app/user/parentgroups/${groupId}`, Object.assign({}, { method: 'POST'} , config));
};

export const doLoadUsers = (groupId: number, account: string) => {
  const config = getRequestConfig();
  return fetch(`/json/app/user/list/${groupId}/${account}`, Object.assign({}, { method: 'POST'} , config));
};

export const doCreatePassword = (otp: string, instance: string, password: string) => {
  const config = getRequestConfig();
  return fetch(`/json/auth/login`, Object.assign({}, { method: 'POST', body: JSON.stringify({
    otp, instance, password
  })} , config));
};
