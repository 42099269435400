import './Brand.scss';
import './RightDropdown.scss';
import * as React from 'react';
import { HeaderPropsInterface } from './HeaderContainer';
import { getLocalization, globalWindow } from '../../global/global';
import { Form, Modal, Nav, NavDropdown } from 'react-bootstrap';
import { ModalComponentNames } from '../../Interfaces/ModalInterface';
import bind from 'bind-decorator';
import { languagesEnum } from '../../Interfaces/ClientPersistInterface';
import NavCheckBox from '../components/NavCheckBox';

export interface IExternalProps {
  clientPersist: HeaderPropsInterface['clientPersist'];
  updateClientPersist: HeaderPropsInterface['actions']['updateClientPersist'];
  navigateAddModal: HeaderPropsInterface['actions']['navigateAddModal'];
  navigateRemoveModal: HeaderPropsInterface['actions']['navigateRemoveModal'];
  logout: HeaderPropsInterface['actions']['logout'];
  cookies: HeaderPropsInterface['cookies'];
}

export interface State {
  show: boolean;
}

export class RightDropdownClass extends React.Component <IExternalProps, State> {

  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }
  @bind
  private onChangeRememberFilterSelections(rememberFilterSelections: boolean) {
    this.props.updateClientPersist({rememberFilterSelections});
  }

  @bind
  private openLink(link, name: string = '_parent') {
    if (link.endsWith('=')) {
      link = link + this.props.clientPersist.lang;
    }
    return () => {
      globalWindow.open(link, name);
    };
  }

  @bind
  private onChangeClusterMap(clusterMap: boolean) {
    this.props.updateClientPersist({clusterMap});
    this.props.navigateAddModal({
      component: ModalComponentNames.ConfirmationModal,
      props: {
        onClose: () => this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal),
        onConfirm: () => {
          this.props.navigateRemoveModal(ModalComponentNames.ConfirmationModal);
          globalWindow.open(window.location.pathname, '_parent');
        },
        localizations: {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('ok'),
          header: (
              <Modal.Title>
                {getLocalization('info')}
              </Modal.Title>
          ),
          body: (
              <React.Fragment>
                {getLocalization('mapClusterReload')}
              </React.Fragment>
          )
        }
      }
    });
  }

  @bind
  private openEmailNotification() {
    this.props.navigateAddModal({
      component: ModalComponentNames.EmailNotificationModal,
      props: {
        onClose: () => this.props.navigateRemoveModal(ModalComponentNames.EmailNotificationModal)
      }
    });
  }

  @bind
  private openChangePassword() {
    this.props.navigateAddModal({
      component: ModalComponentNames.ChangePasswordModal,
      props: {
        onClose: () => this.props.navigateRemoveModal(ModalComponentNames.ChangePasswordModal)
      }
    });
  }

  @bind
  private onChangeLanguage(event) {
    const {target: {value: lang}}: {target: {value: languagesEnum}} = event;
    globalWindow.lang = lang;
    this.props.updateClientPersist({lang});
    globalWindow.open(`${globalWindow.location.pathname}?lang=${lang}`, '_parent');
  }

  @bind
  private handleLogout() {
    this.props.logout(this.props.cookies);
  }

  @bind
  private onToggle(isOpen, event, metadata) {
    console.log(metadata.source);
    if (metadata.source === 'select') {
      this.setState({ show: true });
    } else {
      this.setState({ show: isOpen });
    }
  }

  public render(): JSX.Element {
    const { clientPersist } = this.props;
    const {userName, epaymentUser, paymentMode } = clientPersist;
    const payments = Boolean(epaymentUser) && paymentMode === 'creditcard' && (
      <NavDropdown.Item as={'li'} onClick={this.openLink('/payments.jsp')}>
        {getLocalization('payments')}
      </NavDropdown.Item>
    );
    const hide = globalWindow.mobile && clientPersist.roles.indexOf('enumerator') !== -1;
    return (
      <Nav className={'RightDropdown'}>
        <NavDropdown
          title={!!userName ? userName : ''}
          id="user-nav-dropdown"
          alignRight={true}
          onToggle={this.onToggle}
          show={this.state.show}
          className={''}
        >
          {payments}
          <NavDropdown.Divider />
          {!hide && (
          <React.Fragment>
            <NavCheckBox
              label={getLocalization('filterselections')}
              checked={this.props.clientPersist.rememberFilterSelections}
              onChange={this.onChangeRememberFilterSelections}
            />
            <NavCheckBox
              label={getLocalization('clustermap')}
              checked={this.props.clientPersist.clusterMap}
              onChange={this.onChangeClusterMap}
            />
            <NavDropdown.Divider />
          </React.Fragment>
          )}
          <NavDropdown.Item
            as={'li'}
            onClick={this.openEmailNotification}
          >
            {getLocalization('emailnotification')}
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={this.openChangePassword}
          >
            {getLocalization('changepasswd')}
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item as={'li'}>
            <Form inline={true}>
              <Form.Control
                as="select"
                value={this.props.clientPersist.lang}
                placeholder="select"
                onChange={this.onChangeLanguage}
              >
                <option value={languagesEnum.en}>English</option>
                <option value={languagesEnum.es}>Spanish</option>
                <option value={languagesEnum.fr}>French</option>
                <option value={languagesEnum.de}>German</option>
                <option value={languagesEnum.fi}>Finnish</option>
              </Form.Control>
            </Form>
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            as={'li'}
            onClick={this.handleLogout}
          >
            {getLocalization('logout')}
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    );
  }
}

export default RightDropdownClass;
