import * as React from 'react';
import bind from 'bind-decorator';
import { LooseObject } from '../../Interfaces/LooseObject';
import { Dropdown, Form } from 'react-bootstrap';
import { getLocalization } from '../../global/global';

interface Props {
  columns: LooseObject[];
  updateColumns: (columns: LooseObject[]) => void;
}

interface State {
  show: boolean;
  columns: LooseObject[];
}

export default class ColumnVisibilityComponent extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      columns: props.columns
    };
  }

  @bind
  private handleColumnCheck(e) {
    const columns = this.state.columns.map((column) => {
      if (column.dataField === e.target.value) {
        return {...column, hidden: e.target.checked};
      }
      return column;
    });
    this.setState({ columns });
  }

  @bind
  private onToggle(isOpen, event, metadata) {
    console.log(metadata.source);
    if (metadata.source === 'select') {
      this.setState({ show: true });
    } else {
      this.setState({ show: isOpen });
      if (!isOpen) {
        this.props.updateColumns(this.state.columns);
      }
    }
  }

  public render(): JSX.Element {
    const { columns } = this.state;
    return (
      <Dropdown className="chart-content-spacing" onToggle={this.onToggle} show={this.state.show} alignRight={true}>
        <Dropdown.Toggle
            variant="primary"
            size="sm"
            id="chart-select-questions"
        >
          <i className="fa fa-list-ol" />
          {getLocalization('showHideColumns')}
        </Dropdown.Toggle>
        <Dropdown.Menu className="question-show-hide-component">
          {columns.map((col) => {
            if (col.dataField === 'row_id') {
              return null;
            }
            return (
              <Dropdown.Item as="div" key={`${col.dataField}-table-dropdown-qn`}>
                <Form.Check
                  id={`table-question-down-checkbox-${col.dataField}`}
                  type={'checkbox'}
                  label={col.text && col.text !== '' ? col.text : col.dataField}
                  onChange={this.handleColumnCheck}
                  value={col.dataField}
                  checked={col.hidden}
                />
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
