import * as ReportsApi from '../api/reports';
import { toast } from 'react-toastify';
import { getLocalization } from '../global/global';
import { REPORTS } from './actionTypes';

export const getReports = () => {
  return (dispatch, getState) => {
    const reportResponse = ReportsApi.doGetReports(getState().clientPersist.user_id);
    reportResponse.then(response => response.json()).then(json => {
      dispatch(reportsLoaded(json));
    }).catch((error) => {
      console.log(error);
    });
  };
};

export const reportsLoaded = (reports) => (
  { type: REPORTS.LOADED, reports }
);

export const saveReportTemplate = (reportName: string, parameters: string, reportId: string) => {
  return (dispatch, getState) => {
    const saveToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('savingReportMsg'), {
      toastId: saveToastId, type: toast.TYPE.INFO, autoClose: false, closeButton: false, hideProgressBar: true,
      closeOnClick: false
    });
    const reportResponse = ReportsApi.doSaveReports(reportName, parameters, reportId);
    reportResponse.then(response => response.json()).then(json => {
      if (json.status === 'OK') {
        toast.update(saveToastId, {
          type: toast.TYPE.SUCCESS,
          render: reportId ? getLocalization('savingReportMsg') : getLocalization('updatedReport')
        });
        dispatch(getReports());
      } else {
        toast.update(saveToastId, {
          type: toast.TYPE.SUCCESS,
          render: json.errorMessage
        });
      }
      setTimeout(() => toast.dismiss(saveToastId), 3000);
    }).catch((error) => {
      console.log(error);
      toast.update(saveToastId, {
        type: toast.TYPE.SUCCESS,
        render: 'error saving report.'
      });
      setTimeout(() => toast.dismiss(saveToastId), 3000);
    });
  };
};

export const deleteReport = (reportId: string) => {
  return (dispatch, getState) => {
    const reportResponse = ReportsApi.doDeleteReport(reportId);
    reportResponse.then(response => response.json()).then(json => {
      dispatch(getReports());
    }).catch((error) => {
      console.log(error);
    });
  };
};
