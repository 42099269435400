import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { FeedItem } from '../../Interfaces/FeedItem';
import { difference } from 'lodash-es';

export const getFeedRequestFilters = (clientPersist: ClientPersistInterface, filtersMenu: FiltersMenuInterface) => {
  const params: string[] = [];
  const locations = filtersMenu.selectedLocations.map(l => l.key);
  let defaultLocations: any = [];
  let locationParam: any = [];
  if (clientPersist.userLevel !== '') {
    const userLocations = clientPersist.userLocations.split(',').map(Number);
    // get selected locations that are assigned to the user.
    defaultLocations = locations.filter( loc => userLocations.indexOf(Number(loc)) !== -1);
    if (difference(defaultLocations, locations).length === 0) {
      locationParam = defaultLocations;
    }
  }
  if (locations.length > 0) {
    if (defaultLocations.length > 0) {
      if (difference(defaultLocations, locations).length === 0) {
        locationParam = [];
      }
    } else {
      locationParam = locations.filter( loc => loc !== '0ul0');
    }
  }
  const levels: string[] = [];
  locationParam.forEach(elm => {
    const location = filtersMenu.selectedLocations.find(loc => Number(loc.key) === Number(elm));
    if (location && location.level) {
      levels.push(`${Number(location.level) + 1}`);
    }
  });

  const forms = filtersMenu.selectedForms.map( f => f.id);
  params.push(`forms=${forms.length > 0 ? forms.join(',') : null}`);
  params.push(`locations=${locationParam}`);
  params.push(`undefinedlocs=${locations.indexOf('0ul0') !== -1}`);
  params.push(`location_id=${clientPersist.userLocations}`);
  params.push(`location_level=${clientPersist.userLevel !== '' ? Number(clientPersist.userLevel) + 1 : -1}`);
  params.push(`location_levels=${levels.join(',')}`);

  const users = filtersMenu.selectedUsers;
  const selectedDates = filtersMenu.selectedDates;
  params.push(`users=${users.length > 0 ? users.map(u => u.id).join(',') : null}`);
  params.push(`limit=${30}`);
  params.push(`from=${selectedDates.from && selectedDates.from !== '' ? selectedDates.from : null}`);
  params.push(`to=${selectedDates.to && selectedDates.to !== '' ? selectedDates.to : null}`);
  return params;
};

export const prepareFeed = (forms, feedData) => {
  const icons: {[key: string]: string} = {};
  const newFeed: FeedItem[] = feedData.map((feedItem) => {
    const newFeedItem: FeedItem = {
      name: feedItem.name ? feedItem.name : 'NO NAME',
      editor: feedItem.editor,
      modified: new Date(feedItem.modified).toString().substr(0, 24),
      createdOn: new Date(feedItem.created_on).toString().substr(0, 24),
      createdBy: feedItem.createdBy ? feedItem.createdBy : '',
      status: feedItem.draftPoi ? 'draft' : feedItem.statusField === '0' ? '' : feedItem.statusField,
      formid: feedItem.formid,
      rowId: feedItem.row_id,
      id: feedItem.questionid,
      iconUrl: '',
      formName: feedItem.formname,
      feedCount: feedItem.feedCount
    };
    if (icons[feedItem.formid]) {
      newFeedItem.iconUrl = icons[feedItem.formid];
    } else {
      const form = forms.find( f => f.ref === feedItem.formid);
      if (form) {
        const index = forms.indexOf(form);
        let iconUrl = form.smallIconUrl;
        if (!iconUrl) {
          iconUrl = `/icons/small/poi_${(index % 20) + 1}.png`;
        }
        icons[feedItem.formid] = iconUrl;
        newFeedItem.iconUrl = icons[feedItem.formid];
      }
    }
    return newFeedItem;
  });
  return newFeed;
};
