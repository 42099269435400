import FormUtils from './FormUtils';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { getQuestionFromForm } from '../utils/ScriptUtils';
import { pickBy, uniq } from 'lodash-es';

export const parseScript = (formUtils: FormUtils, question, parentQuestion, dataPoint: DataPoint) => {
  const lookupOptions = formUtils.getLookupOptions(question.id);
  if (question.script && question.script.length > 0) {
    const allVariables: string[] = uniq(question.script.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));
    if (allVariables.length === 1 && allVariables[0] === '_parntref') {
      if (parentQuestion) {
        return getValidOptions(parentQuestion.spssvariable, lookupOptions);
      }
    } else {
      const id = formUtils.getQuestionId(allVariables[0]);
      if (id) {
        const qn = formUtils.getQuestion(id);
        const value = dataPoint[id] ? dataPoint[id] : qn.default ? qn.default : '';
        return getValidOptions(value, lookupOptions);
      }
    }
  }
  const newOptions = {...lookupOptions};
  delete newOptions['$headers'];
  return newOptions;
};

export const getValidOptions = (parentRef, lookupOptions) => {
  const filtered = pickBy(lookupOptions, (value) => {
    return `${value[1]}` === `${parentRef}`;
  });
  return filtered;
};

export const getValue = (key, formUtils, dataPoint, parentModel, parentDataPoint) => {
  let value;
  if (key.indexOf('parent') !== -1 && parentModel && parentDataPoint) {
    const parentRef = key.substr(key.indexOf('.') + 1, key.length);
    const parentQn = getQuestionFromForm(parentRef, parentModel);
    if (parentQn) {
      value = parentDataPoint[parentQn.id];
    }
  } else {
    const qnId = formUtils.getQuestionId(key);
    const qn = formUtils.getQuestion(qnId);
    if (qn) {
      value = dataPoint[qnId];
    }
  }
  return value;
};
