import * as React from 'react';
import bind from 'bind-decorator';
import GenericModal from '../../Modals/GenericModal';
import { Button } from 'react-bootstrap';
import { getLocalization } from '../../../global/global';

interface Props {
  saveReportTemplate: (reportName: string) => void;
  parametersValid: () => boolean;
  reportName: string;
}

interface State {
  showModal: boolean;
  reportName: string;
  validate: boolean;
}

export default class SaveReportButton extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      reportName: props.reportName,
      validate: false
    };
  }

  @bind
  private onChange(e) {
    this.setState({ reportName: e.target.value });
  }

  @bind
  private showModal(visible) {
    this.setState({ showModal: visible });
  }

  @bind
  private saveReportTemplate() {
    if (this.state.reportName) {
      const { reportName } = this.state;
      this.props.saveReportTemplate(reportName);
      this.setState({ reportName: '' }, () => this.showModal(false));
    } else {
      this.setState({ validate: true });
    }
  }

  @bind
  private getModalContent() {
    const error = this.state.validate ? 'has-error' : '';
    return (
      <div className={`form-group ${this.state.reportName === '' && error}`}>
        <label>{getLocalization('reportName')}</label>
        <input
          className="form-control input-sm"
          value={this.state.reportName}
          onChange={this.onChange}
          placeholder={getLocalization('templateNamePlaceholder')}
        />
      </div>
    );
  }

  public getDerivedStateFromProps(props: Props, state: State) {
    if (props.reportName !== state.reportName && !state.showModal) {
      return { reportName: props.reportName };
    }
    return null;
  }

  public render(): JSX.Element {
    const nameModal = this.state.showModal ? (
      <GenericModal
        visible={this.state.showModal}
        body={this.getModalContent()}
        onConfirm={this.saveReportTemplate}
        confirmText={getLocalization('save')}
        title={getLocalization('exportdata')}
        cancel={() => this.showModal(false)}
        cancelText={getLocalization('cancel')}
        dialogClassName="report-menu-modal"
      />
    ) : null;
    return (
      <React.Fragment>
        {nameModal}
        <Button size="sm" variant="primary" onClick={() => this.props.parametersValid() && this.showModal(true)}>
          {getLocalization('savetemplate')}
        </Button>
      </React.Fragment>
    );
  }
}
