import * as React from 'react';
import { connect } from 'react-redux';
import UserQuestion from '../elements/UserQuestion';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { Users } from '../../../Interfaces/User';
import FormUtils from '../utils/FormUtils';
import { StateInterface } from '../../../Interfaces/StateInterface';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { activeTaskUsersSelector } from '../../../reducers/taskUsers';
import { activeUsersSelector } from '../../../reducers/users';

export interface Props {
  question: LooseObject;
  dataPoint: DataPoint;
  edit: boolean;
  updateAnswer: (value: LooseObject) => void;
  userList: Users;
  formUtils: FormUtils;
  isSubQuestion?: boolean;
}

export interface StateProps {
  userList: Users;
  taskUsers: Users;
  clientPersist: ClientPersistInterface;
}

export type UserQuestionProps = Props & StateProps;

const UserQuestionContainer = (props: UserQuestionProps) => {
  return (
    <UserQuestion {...props} />
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    userList: activeUsersSelector(state),
    taskUsers: activeTaskUsersSelector(state),
    clientPersist: state.clientPersist
  };
};

export default connect(mapStateToProps, {})(UserQuestionContainer);
