import { ClientPersistInterface } from '../Interfaces/ClientPersistInterface';
import axios from 'axios';
import { getRequestConfig } from './index';
import { toast } from 'react-toastify';
import { LocationInterface, undefinedLocationKey } from '../Interfaces/LocationInterface';

export const getLocations = async (userId: ClientPersistInterface['user_id']): Promise<LocationInterface[]> => {
  try {
    const url = `/json/app/location/list/${userId}`;
    const response = await axios.get(
        url,
        getRequestConfig());
    const data: LocationInterface[] = response.data;
    return [...data, {
      title: 'Undefined Locations',
      key: undefinedLocationKey,
      parent: '-1',
      level: '0'
    }];
  } catch (err) {
    toast.error('An error occurred while fetching locations list. Please Contact Support.');
    throw err;
  }
};

export const addNewLocation = (id: number, locations: Pick<LocationInterface, 'title' | 'level' | 'parent' | 'identifier'>[]) => {
  const headers = getRequestConfig();
  return fetch(`/json/lh/${id}`, Object.assign({}, headers, { method: 'POST', body: JSON.stringify(locations) }));
};
