import './App.scss';
import 'react-toastify/scss/main.scss';
import 'font-awesome/scss/font-awesome.scss';
import * as React from 'react';
import { Header } from './views/Header/HeaderContainer';
import { ReactModals } from './views/Modals/ReactModals';
import { ToastContainer } from 'react-toastify';
import ProgressContainer from './views/Progress/ProgressContainer';
import { connect } from 'react-redux';
import { ClientPersistInterface, languagesEnum } from './Interfaces/ClientPersistInterface';
import { RouteComponentProps, withRouter } from 'react-router';
import { setLanguageClientPersist } from './actions/clientPersistActions';
import { getQueryParams } from './utils/utils';
import { globalWindow } from './global/global';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'typescript-fsa';
import { Persistor } from 'redux-persist/es/types';
import { persistedStore } from './index';
import { SystemInterface } from './Interfaces/SystemInterface';
import { StateInterface } from './Interfaces/StateInterface';
import { initClientPersist } from './actions/userActions';
import { initWS } from './actions/websocket';
import { initUserData, logout } from './actions/userActions';
import { Cookies } from 'react-cookie';
import AlertContainer from './views/Alert/AlertContainer';
import ConfirmContainer from './views/Confirm/ConfirmContainer';
import Footer from './views/Footer';

interface IStateProps {
  clientPersist: ClientPersistInterface;
  system: SystemInterface;
  persistedStore: Persistor;
}

interface IActionProps {
  setLanguage: (language: languagesEnum) => void;
  initClientPersist: () => void;
  initWS: () => void;
  initUserData: () => void;
  logout: (cookies?: Cookies) => void;
}

interface IExternalProps extends RouteComponentProps<{}> {
  children?: React.ReactNode;
  showHeader: boolean;
}

export type IAppProps = IStateProps & IActionProps & IExternalProps;

export class AppClass extends React.Component<IAppProps> {

  public componentDidMount() {
    const {lang} = getQueryParams(this.props.location.search);
    const loadedLanguage = !!lang ? lang as languagesEnum : languagesEnum.en;
    if (this.props.clientPersist.lang !== loadedLanguage) {
      this.props.setLanguage(loadedLanguage);
    }
    const { instance, userName, user_id } = this.props.clientPersist;
    if (!instance || !userName || !user_id) {
      this.props.initClientPersist();
    } else {
      this.props.initUserData();
    }
    this.props.initWS();
    const params = getQueryParams(this.props.location.search);
    if (params.logout && params.logout === 'true') {
      this.props.logout();
    }
  }

  public async componentDidUpdate(prevProps) {
    const {instance, userName, user_id, lang} = this.props.clientPersist;
    if (prevProps.clientPersist.instance !== instance) {
      if (!instance || !userName || !user_id) {
        // Force redux state to update persistance local storage
        await this.props.persistedStore.flush();
        globalWindow.open(`/login.jsp?lang=${lang}`, '_parent');
      }
    }
  }

  public render(): JSX.Element {
    const { instance, userName, user_id } = this.props.clientPersist;
    let showChildren = true;
    if (!instance || !userName || !user_id) {
      showChildren = false;
    }
    return (
        <div className={'ReactApp'}>
          {this.props.showHeader && (
              <Header />
          )}
          {showChildren && this.props.children}
          <ReactModals />
          <ProgressContainer />
          <AlertContainer />
          <ConfirmContainer />
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={true}
              closeOnClick={true}
              rtl={false}
              pauseOnFocusLoss={true}
              draggable={true}
              pauseOnHover={true}
              closeButton={false}
          />
          <Footer />
        </div>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  return {
    clientPersist: state.clientPersist,
    persistedStore: persistedStore,
    system: state.system
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): IActionProps => {
  return {
    setLanguage: (language) => (dispatch(setLanguageClientPersist(language))),
    initClientPersist: () => (dispatch(initClientPersist())),
    initWS: () => (dispatch(initWS())),
    initUserData: () => (dispatch(initUserData())),
    logout: (cookies?: Cookies) => dispatch(logout(cookies))
  };
};

export const App = connect<IStateProps, IActionProps, IExternalProps, StateInterface>(
    mapStateToProps, mapDispatchToProps
)(AppClass);
export default withRouter(App);
