import * as React from 'react';
import bind from 'bind-decorator';
import { LooseObject } from '../../../Interfaces/LooseObject';
import FormUtils from '../utils/FormUtils';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { keys, uniq } from 'lodash-es';
import { updateScriptObject } from '../utils/ScriptUtils';
import { hasScript, isNumeric } from '../utils/utils';
import { isNullOrUndefined } from '../../../utils/utils';
import { Slimdown } from '../utils/Slimdown';
import { questionTypes } from '../../../Interfaces/Forms/QuestionInterface';

interface Props {
  formUtils: FormUtils;
  dataPoint: DataPoint;
  question: LooseObject;
}

interface State {
  value: string;
  localSPSSToId: LooseObject;
  obj: LooseObject;
  hasScript: boolean;
}

export default class QuestionLabel extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    const initialState = {
      value: props.question.text ? props.question.text : '',
      localSPSSToId: {},
      obj: {},
      hasScript: hasScript(props.question)
    };
    this.state = this.setObjValues(initialState);
  }

  /*
    When doing the script evaluation, we will need to set the object with _spss -> value mapping.
    This function sets the _spss -> value mapping and also the _spss -> id mapping.
    _spss -> id mapping allows for easier retrieving of values from the data model.
  */
  @bind
  private setObjValues(state: State): State {
    const { formUtils, question } = this.props;
    const localSPSSToId: LooseObject = {}; // mapping for _spss -> id
    const obj = {};
    if (question.text) {
      const allVariables: string[] = uniq(question.text.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));
      for (const v of allVariables) {
        if (v.indexOf('.') !== -1) { // this may be summing a table, a lookup value.
          const prefix = v.substr(0, v.indexOf('.'));
          const questionId = formUtils.getQuestionId(prefix);
          localSPSSToId[v] = questionId;
        } else {
          const id = formUtils.getQuestionId(v);
          localSPSSToId[v] = id;
        }
        obj[v] = '';
      }
    }
    state.localSPSSToId = localSPSSToId;
    state.obj = obj;
    return state;
  }

  public static getDerivedStateFromProps(props: Props, state: State) {
    const { obj, localSPSSToId } = state;
    const { dataPoint, formUtils } = props;
    if (!state.hasScript) {
      return null;
    }

    const response = updateScriptObject(localSPSSToId, obj, formUtils, dataPoint, [], [], []);
    const { updated, updatedObj } = response;
    if (updated) {
      const { question } = props;
      // let script = question.script;
      let text = question.text;
      const objKeys = keys(updatedObj);
      for (const key of objKeys) {
        const value = isNullOrUndefined(updatedObj[key]) ? '' : updatedObj[key];
        const renderValue = isNumeric(value) && Number(value) % 1 > 0 ?
          `${Math.round(Number(value) * 100) / 100}` : `${value}`;
        text = text.replace(key, renderValue);
      }
      return { obj : updatedObj, value : text };
    }
    return null;
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value;
  }

  public render(): JSX.Element {
    const { question } = this.props;
    return (
      <div
        className={`control-label ${question.type}`}
      >
        <label
          className={question.type !== questionTypes.LABEL ? 'question-label' : ''}
          dangerouslySetInnerHTML={{__html: Slimdown(this.state.value).replace(/\n|\r/g, '<br />')}}
        />
        {this.props.children ? this.props.children : null}
      </div>
    );
  }
}
