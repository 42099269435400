import { actionCreator } from './';
import * as formsApi from '../api/formsApi';
import { FORMS, TOTAL_POI_COUNT } from './actionTypes';
import { FormInterface } from '../Interfaces/Forms/FormsInterface';
import { ReactCookieProps } from 'react-cookie';
import { loadHierarchy } from './hierarchyActions';
import { filtersSelectedFormType } from '../Interfaces/FiltersMenuInterface';
import { StateInterface } from '../Interfaces/StateInterface';
import { formsForFiltersSelector, LoadFormHierarchyPayload } from '../reducers/formsReducer';
import { loadHierarchyCounts } from '../api/formsApi';

export const loadForms = actionCreator<FormInterface[]>(FORMS.LOAD);

export const loadTotalPOICount = actionCreator<number>(TOTAL_POI_COUNT.LOAD);

export const getFormsAndHierarchyAndPOICount = (cookies: ReactCookieProps['cookies']) => {
  return async (dispatch, getState) => {
    const { clientPersist } = getState();
    if (cookies) {
      const {forms, totalPOICount, hierarchy} = await formsApi.loadForms(clientPersist.user_id, cookies);
      if (forms) {
        dispatch(loadForms(forms));
        dispatch(loadTotalPOICount(totalPOICount));
      }
      if (hierarchy) {
        dispatch(loadHierarchy(hierarchy));
      }
    }
  };
};

export const loadFormHierarchyCounts = actionCreator<LoadFormHierarchyPayload>(FORMS.LOAD_HIERARCHY_COUNTS);

export const fetchFormHierarchy = (selectedForm: filtersSelectedFormType) => {
  return async (dispatch, getState: () => StateInterface) => {
    const form = formsForFiltersSelector(getState()).find((f) => f.ref === selectedForm.ref);
    if (form && !form.hierarchyCounts) {
      const hierarchyCounts = await loadHierarchyCounts(form);
      dispatch(loadFormHierarchyCounts({
        ref: selectedForm.ref,
        hierarchyCounts
      }));
    }
  };
};
