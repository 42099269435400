import * as React from 'react';
import bind from 'bind-decorator';

interface IExternalProps {
  checked: boolean;
  label: string;
  onChange: (value: boolean) => void;
}

interface IOwnState {
  checked: boolean;
}

export default class NavCheckBox extends React.Component<IExternalProps, IOwnState> {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked
    };
  }

  @bind
  private onChange(e) {
    const checked = e.target.checked;
    this.setState({checked});
    this.props.onChange(checked);
  }

  public render(): JSX.Element {
    const {label} = this.props;
    return (
        <a role="presentation" className="dropdown-item">
        <span>
          <label className="pointer">
            <input type="checkbox" onChange={this.onChange} checked={this.state.checked}/>
            {label}
          </label>
        </span>
        </a>
    );
  }
}
