// Also mention the name in the enum, it helps in ide search

export enum ModalComponentNames {
  EmailNotificationModal = 'EmailNotificationModal',
  ChangePasswordModal = 'ChangePasswordModal',
  UpgradeAccountModal = 'UpgradeAccountModal',
  ShowVideoModal = 'ShowVideoModal',
  ConfirmationModal = 'ConfirmationModal',
  ScheduleDayInfoModal = 'ScheduleDayInfoModal',
  ScheduleUserSelectionModal = 'ScheduleUserSelectionModal'
}

export interface BaseModalInterface {
  onClose: () => void;
  [key: string]: any;
}

export interface ModalInterface<T = BaseModalInterface> {
  component: ModalComponentNames;
  props: T;
}
