import { DIAGRAMS } from './actionTypes';
import { LooseObject } from '../Interfaces/LooseObject';
import { actionCreator } from './';
import { doGetGroupDiagrams, doGetTileMapResource } from '../api/diagrams';

export const getGroupDiagrams = () => {
  return (dispatch, getState) => {
    const promise = doGetGroupDiagrams(getState().clientPersist.groupId);
    promise.then(response => response.json()).then((json) => {
      dispatch(setGroupDiagrams(json));
    }).catch((error) => {
      console.log(error);
    });
  };
};

export const setGroupDiagrams = actionCreator<LooseObject[]>(DIAGRAMS.LIST_LOADED);

export const getTileMapResource =
  (diagramName: string, callBack: (maxZoom: number, bounds: number[] | null) => void) => {
  return (dispatch, getState) => {
    const diagramPromise = doGetTileMapResource(diagramName);
    if (diagramPromise) {
      diagramPromise.then(response => response.text()).then(data => {

        if (callBack && data !== '') {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(data, 'text/xml');
          const tileSet = xmlDoc.getElementsByTagName('TileSet');
          const maxZoom = tileSet.length - 1;
          const boundingBox = xmlDoc.getElementsByTagName('BoundingBox');
          let bounds;
          if (boundingBox) {
            const max = boundingBox[0].getAttribute('maxx');
            const min = boundingBox[0].getAttribute('miny');
            if (max && min) {
              const maxX = Math.abs(parseFloat(max));
              const minY = Math.abs(parseFloat(min));
              bounds = [maxX, minY];
            }
          }
          callBack(maxZoom, bounds);
        }
      });
    }
  };
};
