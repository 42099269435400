import { CLIENT_PERSIST, USER } from '../actions/actionTypes';
import {
  ClientPersistInterface,
  languagesEnum
} from '../Interfaces/ClientPersistInterface';
import { Action } from 'typescript-fsa';
import ReducerBuilder from './ReducerBuilder';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';
import { persistAuthorizationKey } from '../global/constants';
import { StateInterface } from '../Interfaces/StateInterface';
import { createSelector } from 'reselect';

const authorizationPersistConfig: PersistConfig<ClientPersistInterface> = {
  key: persistAuthorizationKey,
  storage
};

type PayloadType = ClientPersistInterface | languagesEnum;

// const INITIAL_STATE: ClientPersistInterface = globalWindow.clientPersistModel.attributes;
export const INITIAL_STATE: ClientPersistInterface = {
  chartVid: '',
  showDataAutomatically: true,
  rememberFilterSelections: true,
  forms: [],
  locations: [],
  users: [],
  fromDate: '',
  toDate: '',
  clusterMap: true,
  lang: languagesEnum.en,
  lastLogin: '',
  new_data_notification: false,
  updated_data_notification: false,
  updated_created_data_notification: false,
  useremail: '',
  disableMobileFeatures: false,
  epaymentUser: false,
  instance: null,
  organization: null,
  package: '',
  paymentMode: '',
  roles: '',
  userName: null,
  user_id: null,
  userLevel: '',
  userLocations: '',
  locationLabels: [],
  payingCustomer: '',
  groupId: null,
  readOwn: null,
  googleAccessToken: null,
  key: '',
  fullZoom: null,
  shareDataUnregisteredUsers: false,
  onlyAccessSharedData: false,
  canDelete: false
};

const update = (state: ClientPersistInterface, {payload}): ClientPersistInterface => {
  return {...state, ...payload};
};

const reset = (state: ClientPersistInterface, _): ClientPersistInterface => {
  return {...state, user_id: null, userName: null, instance: null};
};

const setLanguage = (state: ClientPersistInterface, {payload}): ClientPersistInterface => {
  return {...state, lang: payload};
};

const loginSuccess = (state: ClientPersistInterface, {payload}): ClientPersistInterface => {
  return {...state, ...payload};
};

export const clientPersistReducer = new ReducerBuilder<ClientPersistInterface, Action<PayloadType>>()
    .setInitialState(INITIAL_STATE)
    .addReducer(CLIENT_PERSIST.UPDATE, update)
    .addReducer(CLIENT_PERSIST.RESET, reset)
    .addReducer(CLIENT_PERSIST.SET_LANG, setLanguage)
    .addReducer(USER.LOGOUT, reset)
    .addReducer(CLIENT_PERSIST.UPDATE_FROM_LEGACY, update)
    .addReducer(USER.LOGIN_SUCCESS, loginSuccess)
    .build();

export default persistReducer<
    ClientPersistInterface, Action<any>
    >(authorizationPersistConfig, clientPersistReducer);

// Selectors
export const clientPersistSelector = (state: StateInterface): ClientPersistInterface => state.clientPersist;

export const userNameSelector = createSelector<
    StateInterface,
    ClientPersistInterface,
    ClientPersistInterface['userName']
    >([
  clientPersistSelector
], (clientPersist) => clientPersist.userName);

export const languageSelector = createSelector<
    StateInterface,
    ClientPersistInterface,
    string
    >([
  clientPersistSelector
], (clientPersist) => {
  if (clientPersist.instance === 'safercities') {
    let lang: string = clientPersist.lang;
    if (!lang) {
      lang = navigator.language;
      lang = lang.indexOf('-') !== -1 ? lang.substring(0, lang.indexOf('-')) : lang;
    }
    return ['ca', 'es', 'fr', 'nl'].indexOf(lang) !== -1 ? lang : 'en';
  } else {
    return clientPersist.lang;
  }
});
