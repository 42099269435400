import '../styles/AddressQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import QuestionLabel from './QuestionLabel';
import TextAreaComponent from './TextAreaComponent';
import { AddressQuestionProps } from '../Containers/AddressQuestionContainer';
import { Button } from 'react-bootstrap';
import { isNil } from 'lodash-es';
import { processReverseGeoCode } from '../../../actions/utils/reverseGeoCode';
// import { getLocalization, globalWindow } from '../../../global/global';
// import { LocationInterface } from '../../../Interfaces/LocationInterface';

interface State {
  value: string;
  edit: boolean;
  error: boolean;
  hasError: boolean;
  errorAlert: string | undefined;
}

export default class StringQuestion extends React.Component <AddressQuestionProps, State> {
  private abortController: AbortController = new AbortController();
  constructor(props) {
    super(props);
    const { question, edit, dataPoint } = this.props;
    this.state = {
      value: dataPoint[question.id] ? dataPoint[question.id] : question.default ? question.default : '',
      edit: edit,
      error: false,
      hasError: false,
      errorAlert: undefined
    };
  }

  @bind
  private handleChange(value) {
    const { question, updateAnswer } = this.props;
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = value;
      updateAnswer(newAns);
    }
    this.setState({ value });
  }

  @bind
  private geoCodeAddress() {
    if (this.state.value !== '') {
      const geoCodeRequest = this.props.geocodeAddress(this.state.value, this.abortController.signal);
      geoCodeRequest.then(response => response.json()).then((json) => {
        if (json && json.length > 0) {
          const geoCodedData = processReverseGeoCode(json[0]);
          if (geoCodedData.data['Coordinates']) {
            const { updateAnswer } = this.props;
            updateAnswer(geoCodedData.data);
          }
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  public static getDerivedStateFromProps(props: AddressQuestionProps, state: State): Partial<State> | null {
    const { question, dataPoint, updateAnswer } = props;
    const { value } = state;
    // Set the default value if the datamodel has no value
    if (!dataPoint[question.id] && question.default && value === question.default) {
      const newValue = {};
      newValue[question.id] = question.default;
      updateAnswer(newValue);
      return { value: question.default };
    }

    if (!isNil(dataPoint[question.id]) && dataPoint[question.id] !== value) {
      return { value: dataPoint[question.id] };
    } else if (dataPoint['validate'] && !question.optional && value === '') {
      return { hasError: true };
    }
    return null;
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render(): JSX.Element {
    const { value, hasError } = this.state;
    const { question, formUtils, dataPoint, isSubQuestion } = this.props;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    const hasErrorClass = (required && dataPoint.validate && this.state.value === '') || hasError ? 'has-error' : '';
    const className =
      `form-group ${hasErrorClass} ${!isSubQuestion && formUtils.getResponsiveView(question)}`;
    return (
      <div className={className} >
        <QuestionLabel question={question} dataPoint={dataPoint} formUtils={formUtils}>
          {required}
        </QuestionLabel>
        {!this.state.edit ? (
          <span>{value}</span>
        ) : (
          <div className="address-input-area">
            <TextAreaComponent
              name={question.id}
              readOnly={!this.state.edit}
              value={value}
              onChange={this.handleChange}
              extraClass={'address-text-input'}
            />
            <Button onClick={this.geoCodeAddress} className="address-search-btn">
              <i className="fa fa-search" />
            </Button>
          </div>
        )}
      </div>
    );
  }
}
