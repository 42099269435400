import { MODULE_SELECTION } from './actionTypes';
import { actionCreator } from './index';
import { toggleFilterMenu } from './filtersMenuActions';
import { MODULES, SingleInstance } from '../Interfaces/ModuleSelectionInterface';
import * as H from 'history';
import { StateInterface } from '../Interfaces/StateInterface';

export const toggleSelectedModule = actionCreator<MODULES>(MODULE_SELECTION.TOGGLE);

export const setSingleInstanceState = actionCreator<SingleInstance>(MODULE_SELECTION.SET_SINGLE_INSTANCE);

export const unsetSingleInstanceState = actionCreator(MODULE_SELECTION.UNSET_SINGLE_INSTANCE);

export const setSingleInstance = (singleInstance: SingleInstance) => {
  return (dispatch, getState: () => StateInterface) => {
    dispatch(setSingleInstanceState(singleInstance));
    if (getState().filtersMenu.open) {
      dispatch(toggleFilterMenu(false));
    }
  };
};

export const setSingleInstanceWithHistory = (singleInstance: SingleInstance, history: H.History) => {
  return (dispatch, getState: () => StateInterface) => {
    // const {model: {ref: formRef}, dataPoint, newAnswer} = singleInstance;
    dispatch(setSingleInstanceState(singleInstance));
    // history.push(`#${formRef}/${dataPoint.id}/${dataPoint.row_id}/${newAnswer}`);
    if (getState().filtersMenu.open) {
      dispatch(toggleFilterMenu(false));
    }
  };
};
