import '../styles/UserQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { Button } from 'react-bootstrap';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import GenericModal from '../../../views/Modals/GenericModal';
import { getLocalization, globalWindow } from '../../../global/global';
import { SingleInstanceComponentClass } from '../SingleInstanceComponent';

interface Props {
  dataPoint: DataPoint;
  model: LooseObject;
  singleInstance: SingleInstanceComponentClass;
}

interface State {
  showModal: boolean;
  exportType: string;
  includeImages: boolean;
  skipEmpty: boolean;
  zoomLevel: string;
  hasError: boolean;
  reducedImage: boolean;
  confirmSaveExport: boolean;
}

export default class ExportButton extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  @bind
  private getInitialState(): State {
    const { model } = this.props;
    const initialState: State = {
      showModal: false,
      includeImages: false,
      exportType: '',
      skipEmpty: false,
      zoomLevel: 'high',
      hasError: false,
      reducedImage: true,
      confirmSaveExport: false
    };
    if (model.hasWordTemplate) {
      initialState.exportType = 'word_template';
      initialState.includeImages = true;
    } else if (model.hasExcelTemplate) {
      initialState.exportType = 'excel_template';
      initialState.includeImages = true;
    }
    return initialState;
  }

  @bind
  private onClick() {
    const formComponent = this.props.singleInstance.formComponent.current;
    if (formComponent && formComponent.getUpdated()) {
      this.setState({ confirmSaveExport: true });
    } else {
      this.setState({ showModal: true });
    }
  }

  @bind
  private closeModal() {
    this.setState({ showModal: false, confirmSaveExport: false });
  }

  @bind
  private exportWithoutSave() {
    this.setState({ showModal: true, confirmSaveExport: false });
  }

  @bind
  private onConfirmSaveExport() {
    this.closeModal();
    this.props.singleInstance.savePOI('VIEW', true);
  }

  @bind
  public initExport() {
    this.setState({ showModal: true });
  }

  @bind
  private onExport() {
    const { exportType, includeImages, skipEmpty, zoomLevel, reducedImage } = this.state;
    const { dataPoint, model } = this.props;
    const { readOnlyOwn, groupID, userID, pwrd, userName, lang } = globalWindow;
    if (exportType === '') {
      this.setState({ hasError: true });
      return;
    }
    const mainUrl = `/json/app/export/${model.id}/${exportType}/${readOnlyOwn}/${groupID}/${userID}?`;
    const p: string[] = [];
    p.push(`key=${pwrd}`);
    p.push(`userid=${userName}`);
    p.push(`pictures=${includeImages ? 1 : 0}`);
    p.push(`pages=0`);
    p.push(`history=0`);
    p.push(`id=${dataPoint.id}`);
    p.push(`projectid=0`);
    p.push(`dgmimages=${zoomLevel === 'none' ? 0 : 1}`);
    p.push(`zoomLevel=${zoomLevel}`);
    p.push(`skipEmpty=${skipEmpty ? 1 : 0}`);
    p.push(`lang=${lang}`);
    p.push(`reducedImage=${reducedImage ? 1 : 0}`);
    globalWindow.open(mainUrl + p.join('&'), '_blank');
    this.closeModal();
  }

  @bind
  private handleExportTypeChange(e) {
    const { model } = this.props;
    const newState = {};
    newState['exportType'] = e.target.value;
    if (newState['exportType'] === 'word_template') {
      if (model.hasWordTemplate) {
        newState['includeImages'] = true;
      } else {
        newState['includeImages'] = false;
      }
    } else if (newState['exportType'] === 'excel_template') {
      newState['includeImages'] = true;
    } else {
      newState['includeImages'] = false;
    }
    this.setState(newState);
  }

  @bind
  private handleCheckChange(e) {
    const newState = {};
    newState[e.target.name] = e.target.checked ? true : false;
    this.setState(newState);
  }

  @bind
  private handleRadioChange(e) {
    const newState = {};
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }

  @bind
  private getExportBody(): JSX.Element {
    const { model } = this.props;
    const { exportType, hasError } = this.state;
    const noTemplateAlert = exportType === 'word_template' ? (
      <React.Fragment>
        {
          globalWindow.enableWordtemplate !== 'true' ? (
            <div className="form-group">
              <label>{getLocalization('customTemplate')}</label>
            </div>
          ) : null
        }
        {
          globalWindow.enableWordtemplate === 'true' && !model.hasWordTemplate ? (
            <div className="form-group">
              <label>{getLocalization('ownTemplageMsg')}</label>
            </div>
          ) : null
        }
      </React.Fragment>
    ) : null;
    return (
      <form>
        <div className={`form-group ${hasError ? 'has-error' : ''}`}>
          <select
            name="exportType"
            className="form-control"
            value={this.state.exportType}
            onChange={this.handleExportTypeChange}
          >
            <option value="">Select one</option>
            <option value="excel_template">{getLocalization('excelExport')}</option>
            <option value="word_template">{getLocalization('wordExport')}</option>
          </select>
        </div>
        {
          exportType === 'excel_template' || (exportType === 'word_template' && !model.hasWordTemplate) ? (
            <div className="checkbox images_single">
              <label>
                <input
                  type="checkbox"
                  name="includeImages"
                  onChange={this.handleCheckChange}
                  checked={this.state.includeImages}
                />
                {getLocalization('includeimages')}
              </label>
            </div>
          ) : null
        }

        { // check if to show skip empty values selection
          exportType === 'word_template' && !model.hasWordTemplate ? (
            <div className="checkbox skipempty_single">
              <label>
              <input type="checkbox" name="skipEmpty" value="1" onChange={this.handleCheckChange}/>
                {getLocalization('skipemptyrows')}
              </label>
            </div>
          ) : null
        }
        { // check if to show skip empty values selection
          exportType === 'word_template' && model.hasWordTemplate ? (
            <div className="checkbox">
              <label>
              <input
                type="checkbox"
                name="reducedImage"
                value="1"
                onChange={this.handleCheckChange}
                checked={this.state.reducedImage}
              />
                {getLocalization('reducedImage')}
              </label>
            </div>
          ) : null
        }
        { // check if to show background diagram zoom level selection
          exportType === 'word_template' && model.backgroundDiagram ? (
            <div className="form-group zoom_level">
              <div className="checkbox include_generated_images_single">
                <label>
                    {getLocalization('includegeneratedimages')}
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    name="zoomLevel"
                    value="none"
                    checked={this.state.zoomLevel === 'none'}
                    onChange={this.handleRadioChange}
                  />
                    {getLocalization('none')}
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    name="zoomLevel"
                    value="low"
                    checked={this.state.zoomLevel === 'low'}
                    onChange={this.handleRadioChange}
                  />
                  {getLocalization('zoomLow')}
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    name="zoomLevel"
                    value="medium"
                    checked={this.state.zoomLevel === 'medium'}
                    onChange={this.handleRadioChange}
                  />
                    {getLocalization('zoomMedium')}
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    name="zoomLevel"
                    value="high"
                    checked={this.state.zoomLevel === 'high'}
                    onChange={this.handleRadioChange}
                  />
                    {getLocalization('zoomHigh')}
                </label>
              </div>
            </div>
          ) : null
        }
        {noTemplateAlert}
      </form>
    );
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        { this.state.showModal ? (
          <GenericModal
            visible={this.state.showModal}
            onConfirm={this.onExport}
            cancel={this.closeModal}
            body={this.getExportBody()}
            title={getLocalization('exportType')}
            confirmText={getLocalization('exportData')}
            cancelText={getLocalization('cancel')}
          />
        )
        : null }
        { this.state.confirmSaveExport ? (
          <GenericModal
            visible={this.state.confirmSaveExport}
            onConfirm={this.onConfirmSaveExport}
            cancel={this.exportWithoutSave}
            body={<p>{getLocalization('poiExportChangesAlert')}</p>}
            title={getLocalization('info')}
            confirmText={getLocalization('yes')}
            cancelText={getLocalization('no')}
          />
        )
        : null }
        <Button size="sm" onClick={this.onClick} id={'single-instance-export-btn'}>
          <i className="fa fa-download" aria-hidden="true" />
          {` ${getLocalization('exportData')}`}
        </Button>
      </React.Fragment>
    );
  }
}
