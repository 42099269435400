import { Action } from 'typescript-fsa';
import { SYSTEM } from '../actions/actionTypes';
import ReducerBuilder from './ReducerBuilder';
import { SystemInterface } from '../Interfaces/SystemInterface';

export const INITIAL_STATE: SystemInterface = {
  version: '1.0.0',
  debug: false
};

type PayloadType = SystemInterface;

function loadProperties(state: SystemInterface, {payload}): SystemInterface {
  payload.debug = payload.debug === 'true' || payload.debug === true;
  return {...state, ...payload};
}

export default new ReducerBuilder<SystemInterface, Action<PayloadType>>()
    .setInitialState(INITIAL_STATE)
    .addReducer(SYSTEM.LOAD_PROPERTIES, loadProperties)
    .build();
