import { FEED } from '../actions/actionTypes';
import { FeedInterface } from 'Interfaces/FeedInterface';

const cleanFeed = (current, newFeed) => {
  current = current || [];
  newFeed = newFeed || [];
  return current.map(feedItem => {
    for (let i = 0; i < newFeed.length; i++) {
      if (feedItem.id === newFeed[i].id) {
        // const fi = newFeed[i];
        newFeed.splice(i, 1);
        return null;
      }
    }
    return feedItem;
  }).filter( f => f !== null);
};

const INITIAL_STATE: FeedInterface = { loading: false, feed: [] };

const feed = (state: FeedInterface = INITIAL_STATE, action) => {
  switch (action.type) {
    case FEED.LOADING:
      return {...state, loading: action.loading };
    case FEED.LOADED:
      if (!state && action.feed) {
        return { feed: action.feed, loading: false };
      } else if (action.feed) {
        const receivedItems = action.feed;
        const currentFeed =  cleanFeed(state.feed, action.feed);
        return {...state, feed: currentFeed.concat(receivedItems)};
      }
      return state;
    case FEED.USER_UPDATE:
      if (action.feed) {
        const receivedItems = action.feed;
        const currentFeed =  cleanFeed(state.feed, action.feed);
        return {...state, feed: receivedItems.concat(currentFeed)};
      }
      return state;
    case FEED.CLEAR:
      return {...state, feed: []};
    case FEED.UPDATE:
      if (action.feedItem && state) {
        const feed = state.feed.filter((feedItem) => {
          if (feedItem.id === action.feedItem.id) {
            return false;
          }
          return true;
        });
        return {...state, feed };
      }
      return state;
    case FEED.CLEAR_AUTO_UPDATE:
      if (action.feed) {
        return {...state, feed: action.feed.concat(state.feed)};
      }
      return state;
    case FEED.DELETE:
      if (action.pois && state) {
        const deletedPois = [...action.pois];
        const newFeed = [...state.feed];
        let i = newFeed.length - 1;
        while (i >= 0) {
          const feedItem = newFeed[i];
          const index = deletedPois.indexOf(feedItem.id);
          if (index !== -1) {
            deletedPois.splice(index, 1);
            newFeed.splice(i, 1);
            if (deletedPois.length === 0) {
              break;
            }
          }
          i--;
        }
        return {...state, feed: newFeed};
      }
      return state;
    default:
      return state; //
  }
};

export default feed;
