import * as React from 'react';
import './styles/FormComponent.scss';
import bind from 'bind-decorator';
import { renderQuestions } from './utils/qnrenderer';
import FormUtils from './utils/FormUtils';
import { LooseObject } from '../../Interfaces/LooseObject';
import { DataPoint } from '../../Interfaces/DataPoint';
import { Locations } from '../../Interfaces/LocationInterface';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { CreatedByTimeComponent } from './elements/CreatedByTimeComponent';
import { SubFormField } from './elements/SubFormField';
import { validateForm } from './utils/Validator';
import { QuestionPageInterface } from 'Interfaces/Forms/QuestionPageInterface';
import { Form, Pagination } from 'react-bootstrap';
import { getFormUtils } from './utils/FormUtilsHolder';
import { getQuestionPage } from './utils/utils';

interface Props {
  model: LooseObject;
  forms: LooseObject[];
  updateAnswer?: (value: LooseObject) => void;
  dataPoint: DataPoint;
  newAnswer?: boolean;
  parentModel?: LooseObject;
  parentDataPoint?: LooseObject;
  parentQuestion?: LooseObject;
  clientPersist: ClientPersistInterface;
  locationHierarchy?: Locations;

}

interface State {
  dataPoint: DataPoint;
  updated: boolean;
  newAnswer: boolean;
  counter: number;
  page: number;
  pageList: number[];
}

export default class FormComponent extends React.Component <Props, State> {
  private formUtils: FormUtils;
  constructor(props) {
    super(props);
    const { dataPoint, newAnswer, model } = this.props;
    this.formUtils = getFormUtils(model);
    this.state = {
      dataPoint: dataPoint, // newAnswer ? this.initDataPoint(newDataPoint) : newDataPoint,
      updated: false,
      newAnswer: !newAnswer ? false : newAnswer,
      counter: Date.now(),
      page: model.showPerPage ? 0 : -1,
      pageList: model.showPerPage ? model.questionPage.map((page, index) => {
        if (page.question && page.question.length > 0 && !page.deleted) {
          return index;
        }
        return null;
      }).filter(v => v !== null) : []
    };
  }

  /* @bind
  private initDataPoint(dataPoint: DataPoint) {
    const questions = this.formUtils.getQuestions();
    const { parentQuestion } = this.props;
    const ids = keys(questions);
    forEach(ids, (id) => {
      const qn = questions[id];
      if (qn.default) {
        if (qn.type === 'IntQuestion' || qn.type === 'FloatQuestion' ||
          (qn.type === 'CalculatedValueQuestion' && qn.numericOnly)) {
          dataPoint[id] = Number(qn.default);
        } else {
          dataPoint[id] = questions[id].default;
        }
      } else {
        if (qn.type === 'LookupValuesQuestion') {
          const options = qn.lookupValue && qn.lookupValue.split(';').length > 1;
          if (options) {
            const validOptions = parseScript(this.formUtils.getLookupOptions(qn.id), qn, parentQuestion);
            const key = keys(validOptions);
            if (key.length === 1) {
              dataPoint[id] = key[0];
            }
          }
        }
      }
    });
    return dataPoint;
  } */

  @bind
  private updateAnswer(answer: LooseObject) {
    this.setState((prevState, props) =>
    ({ dataPoint: {...prevState.dataPoint, ...answer}, updated: true, counter: Date.now() }));
  }

  @bind
  private getFormElements(): JSX.Element[] {
    const { model, forms, updateAnswer, parentModel, parentDataPoint, parentQuestion, clientPersist } = this.props;
    const { dataPoint, newAnswer, page } = this.state;
    let elements: JSX.Element[] = [];

    const getPageElements = (currentPage: QuestionPageInterface) => {
      if (!model.isChild) {
        elements.push(
          <div key={`page_${currentPage.pageNumber}_${model.ID}`} className="form-group col-md-12 col-lg-12 col-sm-12">
            <hr />
            <h3 id={`${currentPage.pageNumber}`}>{currentPage.name ? currentPage.name : ''}</h3>
          </div>
        );
      }
      const els = renderQuestions(currentPage.question, dataPoint, false, forms, updateAnswer ?
        updateAnswer : this.updateAnswer, this.formUtils,
        newAnswer, clientPersist, parentModel, parentDataPoint, parentQuestion);
      if (els.length > 0) {
        elements = elements.concat(els);
      }
    };

    if (page > -1) {
      getPageElements(model.questionPage[this.state.pageList[page]]);
    } else {
      for (const questionPage of model.questionPage) {
        if (questionPage.question && questionPage.question.length > 0 && !questionPage.deleted) {
          getPageElements(questionPage);
        }
      }
    }
    if (dataPoint.id && !model.isChild) {
      elements.push((
        <CreatedByTimeComponent
          key={`createdby`}
          createdbyUsername={dataPoint.createdbyUsername}
          created={dataPoint.created}
        />
      ));
    }
    return elements;
  }

  /*
    For task form use this function to render the form.
    Visibility
      Status != approved, approvedwithcomments, done
        - HIDE: approveddate, toapprove, approvedby, doneby, donedate

    assigndate, approveddate disabled.

    Status is
      assigned
        Set value
          - assigndate - today
          - donedate, doneby - ''
          -
        Hidden
          - toapprove, donedate, doneby, approvedby

      approved, approvedwithcomments
        approveddate = today

        Visibility
          - approveddate, approvedby = visible

      done
        Set value
          donedate = today
        Visibility
          donedate = visible
      commented
        approveddate, toapprove, approvedby = visible
  */
  private getTaskElements(): JSX.Element[] {
    const { model, forms, updateAnswer, clientPersist, parentQuestion, parentModel, parentDataPoint,  } = this.props;
    const { dataPoint, newAnswer } = this.state;
    let elements: JSX.Element[] = [];
    if (parentQuestion) {
      elements.push(<SubFormField key={`${parentQuestion.id}`} question={{}} parentQuestion={parentQuestion}/>);
    }
    for (const page of model.questionPage) {
      if (!model.isChild) {
        elements.push(
          <div key={`page_${page.pageNumber}_${model.ID}`}>
            <hr />
            <h3 id={page.pageNumber}>{page.name ? page.name : ''}</h3>
          </div>
        );
      }
      if (page.question) {
        const els = renderQuestions(page.question, dataPoint, false, forms, updateAnswer ?
          updateAnswer : this.updateAnswer, this.formUtils, newAnswer, clientPersist, parentModel, parentDataPoint);
        if (els.length > 0) {
          elements = elements.concat(els);
        }
      }
    }
    return elements;
  }

  @bind
  public validateForm() {
    const { dataPoint } = this.state;
    const { model, forms, clientPersist } = this.props;

    const valid = validateForm(model, dataPoint, forms, clientPersist);
    if (!valid.valid) {
      const newDataModel = Object.assign({}, dataPoint, { validate: true });
      this.setState({ dataPoint: newDataModel });
    }
    return valid;
  }

  @bind
  public getDataModel(): DataPoint {
    const { dataPoint } = this.state;
    return dataPoint;
  }

  @bind
  public getUpdated(): boolean {
    return this.state.updated;
  }

  @bind
  public getFormUtils() {
    return this.formUtils;
  }

  public static getDerivedStateFromProps(props: Props) {
    const { model, parentQuestion } = props;
    /**
     * When the form component is opened in a modal, then we need to update the datapoint state
     * with the values passed in the modal.
     * Otherwise we use the values here as is.
     * Also, this applies when datapoint is updated by custom updateAnswer function, not the one in form component.
     * This happens in Sub/Task forms when opened from a parent form.
     */
    if (model.isChild && parentQuestion) {
      return { dataPoint: props.dataPoint };
    }
    return null;
  }

  @bind
  private getPager() {
    const { pageList, page } = this.state;

    const changePage = (change) => {
      const newPage = page + change;
      this.setState({ page: (newPage < 0 || newPage === pageList.length) ? 0 : newPage });
    };

    return (
      <>
        <Pagination.Prev
          onClick={() => changePage(-1)}
        />
        <Pagination.Item
          active={true}
        >
          {`${page + 1}`}
        </Pagination.Item>
        <Pagination.Next
          onClick={() => changePage(1)}
        />
      </>
    );
  }

  @bind
  private getPagerDropDown() {
    const { model } = this.props;
    const { pageList, page } = this.state;

    return (
      <div className="col-12">
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Select page</Form.Label>
          <select
            className="form-control"
            onChange={(e) => this.setState({ page: Number(e.target.value)})}
          >
            {pageList.map((p, index) => {
              return (
                <option
                  selected={`${index}` === `${page}`}
                  value={index}
                  key={`${model.ref}-page-select-${index}`}
                >
                  {model.questionPage[p].name || `Page ${index + 1}`}
                </option>
              );
            })}
          </select>
        </Form.Group>
      </div>
    );
  }

  public componentDidMount() {
    const { model } = this.props;
    if (model.showPerPage) {
      const hash = window.location.hash.split('/');
      if (hash[4]) {
        const qn = this.formUtils.getQuestion(hash[4]);
        if (qn) {
          if (qn.actionPerRow) {
            const page = getQuestionPage(model, hash[4]);
            if (page) {
              const index = model.questionPage.findIndex(p => p.id === page.id);
              this.setState({ page: index });
            }
          }
        }
      }
    }
  }

  public render(): JSX.Element {
    const { model } = this.props;
    const { pageList } = this.state;
    return (
      <div className="formview container-fluid row">
        {pageList.length > 1 && this.getPagerDropDown()}
        {model.type === 'TASKFORM' ? this.getTaskElements() : this.getFormElements()}
        {pageList.length > 1 ? (
          <div className="col-12">
            <Pagination>
              {this.getPager()}
            </Pagination>
          </div>
        ) : null}
      </div>
    );
  }
}
