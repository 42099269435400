import React, { Dispatch, FunctionComponent, SetStateAction, useEffect } from 'react';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { useSelector } from 'react-redux';
import { formsForFiltersSelector, selectedFormsSelector } from '../../reducers/formsReducer';
import { Form } from 'react-bootstrap';
import { getLocalization } from '../../global/global';

interface IExternalProps {
  baseClassName: string;
  selectedForm?: FormInterface;
  setSelectedForm: Dispatch<SetStateAction<FormInterface | undefined>>;
}

export const ScheduleSelectForm: FunctionComponent<IExternalProps> = (props) => {
  const selectedForms = useSelector(selectedFormsSelector);
  const allForms = useSelector(formsForFiltersSelector);
  const forms = selectedForms.length > 0 ? selectedForms : allForms;
  useEffect(() => {
    if (forms.length > 0) {
      props.setSelectedForm(forms[0]);
    }
  }, [forms]);
  if (props.selectedForm) {
    return (
        <Form.Group
            controlId="schedule.FormSelect"
            className={props.baseClassName}
        >
          <Form.Label column={true}>
            {getLocalization('scheduleSelectForm')}
          </Form.Label>
          <Form.Control
              as="select"
              value={props.selectedForm.ref}
              onChange={(e) => {
                const selected = forms.find(form => form.ref === e.currentTarget.value);
                if (selected) {
                  props.setSelectedForm(selected);
                }
              }}
          >
            {forms.map((form) => (
                <option
                    value={form.ref}
                    key={form.ref}
                >
                  {form.name}
                </option>
            ))}
          </Form.Control>
        </Form.Group>
    );
  }
  return null;
};
