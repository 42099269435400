import { toast } from 'react-toastify';
import { getRequestConfig } from './index';
import axios from 'axios';
import { ClientPersistInterface } from '../Interfaces/ClientPersistInterface';
import { FormInterface, sortFormsByName } from '../Interfaces/Forms/FormsInterface';
import { HierarchyInterface } from '../Interfaces/HierarchyInterface';
import { Cookies } from 'react-cookie';
import { getLocalization } from '../global/global';

const generateFormsAndTotalPOICount = (
    qList, poiIdentifier, answerTableList,
    counts, wordTemplates, excelTemplate, cookies: Cookies)
    : {forms: FormInterface[]; totalPOICount: number} => {
  let totalPOICount = 0;
  const forms: FormInterface[] = qList
      .map((form: FormInterface, index): FormInterface => {
        const formPOICount = counts[index];
        totalPOICount = totalPOICount + formPOICount;
        const generatedForm = {
          ...form,
          id: form.id.trim(),
          ref: poiIdentifier[index],
          answerTable: answerTableList[index],
          count: formPOICount,
          hasWordTemplate: wordTemplates[index],
          hasExcelTemplate: excelTemplate[index]
        };
        if (!form.isChild && form.type !== 'TABLE') {
          let smallIconUrl = '';
          if (!!form.smallIcon) {
            const db = cookies.get('db');
            smallIconUrl = `/icons/${db}/small/${form.smallIcon}`;
          } else {
            smallIconUrl = `/icons/small/poi_${(index % 20) + 1}.png`;
          }
          let largeIconUrl = '';
          if (!!form.largeIcon) {
            const db = cookies.get('db');
            largeIconUrl = `/icons/${db}/large/${form.smallIcon}`;
          } else {
            largeIconUrl = `/icons/large/poi_${(index % 20) + 1}.png`;
          }
          return {
            ...generatedForm,
            smallIconUrl,
            largeIconUrl
          };
        }
        return generatedForm;
      }).sort(sortFormsByName);
  return {forms, totalPOICount};
};

const generateHierarchy = (hierarchy: HierarchyInterface, cookies: Cookies): HierarchyInterface => {
  const geo = { geoLat: '', geoLon: ''};
  if (hierarchy) {
    geo.geoLat = hierarchy.geoLat;
    geo.geoLon = hierarchy.geoLon;
  } else {
    geo.geoLat = cookies.get('geo_lat') || geo.geoLat;
    geo.geoLon = cookies.get('geo_lon') || geo.geoLon;
  }
  return {
    ...hierarchy,
    ...geo
  };
};

export const loadForms = async (
    userId: ClientPersistInterface['user_id'],
    cookies: Cookies
): Promise<{
  forms: FormInterface[];
  totalPOICount: number;
  hierarchy: HierarchyInterface;
}> => {
  try {
    const url = `/json/app/formType/listing/${userId}`;
    const response = await axios.post(
        url,
        null,
        getRequestConfig());
    const {status, data} = response;
    if (status !== 200) {
      toast.error('An error occurred. Contact Support.');
      throw new Error('Forms list could not be fetched');
    }
    if (!data.qList) {
      toast.warn(getLocalization('noFormList'));
      const hierarchy = data.hierarchy || {};
      return {
        forms: [], totalPOICount: 0, hierarchy: hierarchy
      };
      // throw new Error('No formlist for the group.');
    }
    const {
      qList,
      poiIdentifier,
      answeTableList: answerTableList,
      counts,
      wordTemplates,
      excelTemplate,
      hierarchy
    } = data;
    return {
      ...generateFormsAndTotalPOICount(
          qList, poiIdentifier, answerTableList,
          counts, wordTemplates, excelTemplate, cookies
      ),
      hierarchy: generateHierarchy(hierarchy, cookies)
    };
  } catch (err) {
    toast.error('An error occurred while fetching forms list. Please Contact Support.');
    throw err;
  }
};

export const loadHierarchyCounts = async (
    form: FormInterface
): Promise<FormInterface['hierarchyCounts']> => {
  try {
    const url = `/json/app/hierarchyCounts/listing/${form.answerTable}`;
    const response = await axios.post<{
      hierarchyCounts: FormInterface['hierarchyCounts'];
      [key: string]: any;
    }>(
        url,
        null,
        getRequestConfig());
    return response.data.hierarchyCounts;
  } catch (err) {
    toast.error('An error occurred while fetching form\'s location hierarchy. Please Contact Support.');
    throw err;
  }
};
