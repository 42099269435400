import { DATAPOINTS } from '../actions/actionTypes';
import { StateInterface } from '../Interfaces/StateInterface';
import { AnswersInterface } from '../Interfaces/Answers/AnswersInterface';
import { DataPoint } from '../Interfaces/DataPoint';
import { FormInterface } from '../Interfaces/Forms/FormsInterface';
import { createSelector } from 'reselect';

type PayloadType = {dataPoints: DataPoint[], formRef: FormInterface['ref']};

export const loadAnswers =  <T = AnswersInterface>(state: T, payload: PayloadType): T => {
  if (payload.dataPoints && payload.formRef) {
    const {formRef, dataPoints} = payload;
    const oldAnswers = state[formRef] ? state[formRef] : [];
    let newDataPoints = [...dataPoints];
    const newAnswers = oldAnswers
        .map(answer => {
          const updatedDataPoint = newDataPoints.find((dp) => dp.id === answer.id);
          if (updatedDataPoint) {
            newDataPoints = newDataPoints.filter((dp) => dp.id !== updatedDataPoint.id);
            return updatedDataPoint;
          }
          return answer;
        });
    const newState = {...state};
    newState[formRef] = newAnswers.concat(newDataPoints);
    return newState;
  }
  return state;
};
/*
  This reducer is for DataPoints.
  The keys is the JSON Object is the Form alpha-numeric ID.
  At the moment this is used for Lookup values.
*/
const answers = (state: AnswersInterface = {} , action) => {
  switch (action.type) {
    case DATAPOINTS.LOADED:
      return loadAnswers(state, {dataPoints: action.dataPoints, formRef: action.formRef});
    case DATAPOINTS.UPDATED:
      if (action.dataPoint && action.formId) {
        const newState = {...state};
        if (state[action.formId]) {
          let found = false;
          newState[action.formId] = newState[action.formId].map((poi) => {
            if (poi.row_id === action.dataPoint.row_id) {
              found = true;
              return action.dataPoint;
            }
            return poi;
          });
          if (!found) {
            newState[action.formId].push(action.dataPoint);
          }
          return newState;
        } else {
          newState[action.formId] = [action.dataPoint];
          return newState;
        }
      }
      return state;
    default:
      return state; //
  }
};

export default answers;

// Selectors
export const answersSelector = (state: StateInterface): AnswersInterface => state.answers;

export const formAnswersSelector = (formRef: FormInterface['ref']) => {
  return createSelector<
      StateInterface,
      AnswersInterface,
      DataPoint[]
      >([
    answersSelector
  ], (answers) => answers[formRef]);
};
