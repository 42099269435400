import hierarchy from './hierarchyReducer';
import { combineReducers } from 'redux';
import navigation from './navigationReducer';
import progress from './progress';
import alert from './alert';
import confirm from './confirm';
import { StateInterface } from '../Interfaces/StateInterface';
import groupDiagrams from './groupDiagrams';
import filtersMenu from './filtersMenuReducer';
import forms from './formsReducer';
import totalPOICount from './totalPOICountReducer';
import clientPersist from './clientPersistReducer';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import system from './systemReducer';
import moduleSelection from './moduleSelectionReducer';
import singleInstanceAnswer from './SingleInstanceAnswer';
import locationHierarchy from './locationHierarchy';
import users from './users';
import answers from './answers';
import scheduleAnswers from './scheduleAnswersReducer';
import locations from './locationsReducer';
import feed from './feed';
import autoUpdateFeed from './autoUpdatedFeed';
import reports from './reports';
import importMappings from './importMappings';
import taskUsers from './taskUsers';
import galleryMenu from './galleryMenuReducer';
import chartsMenu from './chartsMenu';
import tableMenu from './tableMenu';
import { persistDataKey } from '../global/constants';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { FormsInterface } from '../Interfaces/Forms/FormsInterface';
import sharedData from './sharedData';

const routerTransform = createTransform<RouterState, RouterState>(
    // transform state on its way to being serialized and persisted.
    (inboundState, key) => {
      return { ...inboundState };
    },
    // transform state being rehydrated
    (outboundState, key) => {
      return {
        location: outboundState.location,
        action: outboundState.action || 'POP'
      };
    },
    // define which reducers this transform gets called for.
    { whitelist: ['router'] }
);

const collectionTransform = createTransform<FormsInterface, FormsInterface>(
    // transform state on its way to being serialized and persisted.
    (inboundState, key) => {
      return { ...inboundState };
    },
    // transform state being rehydrated
    (outboundState, key) => {
      // set loaded to false for CollectionInterfaces so that values are fetched from server atleast once.
      return {
        collection: outboundState.collection,
        loaded: false
      };
    },
    // define which reducers this transform gets called for.
    { whitelist: ['forms'] }
);

const rootPersistConfig = {
  key: persistDataKey,
  storage,
  // specify store states that should not be stored in localStorage
  blacklist: [
    'clientPersist', 'progress', 'confirm', 'alert', 'answers', 'scheduleAnswers', 'feed', 'galleryMenu', 'chartsMenu', 'navigation',
    'moduleSelection', 'locationHierarchy', 'locations', 'autoUpdateFeed', 'forms', 'sharedData'
  ],
  transforms: [collectionTransform, routerTransform],
  // These transforms can be used to logout the user if token does not exist
  // transforms: [
  //   createTransform(
  //       (state, key) => (sessionStorage.PoimapperSession ? state : null),
  //       (state, key) => {
  //         return state;
  //       }
  //   )
  // ]
};

export const createRootReducer = (history: History) => persistReducer(
    rootPersistConfig,
    combineReducers<StateInterface>({
      clientPersist,
      progress,
      alert,
      confirm,
      navigation,
      router: connectRouter(history),
      filtersMenu,
      forms,
      totalPOICount,
      hierarchy,
      system,
      moduleSelection,
      singleInstanceAnswer,
      groupDiagrams,
      locationHierarchy,
      users,
      answers,
      scheduleAnswers,
      feed,
      autoUpdateFeed,
      taskUsers,
      locations,
      reports,
      importMappings,
      galleryMenu,
      chartsMenu,
      tableMenu,
      sharedData
    })
);
