import * as React from 'react';
import bind from 'bind-decorator';
import './TableComponent.scss';
import { HistoryTableProps } from './HistoryTableContainer';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { LooseObject } from '../../Interfaces/LooseObject';

interface State {
  columns: LooseObject[];
  data: any;
  clientPaginate: boolean;
}

export default class HistoryTableComponent extends React.Component<HistoryTableProps, State> {
  private grid = React.createRef<BootstrapTable>();

  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns,
      data: [],
      clientPaginate: false // props.model.clientPaginate
    };
  }

  @bind
  private dataLoaded(data) {
    if (data && data.length > 0) {
      this.setState(() => ({ data: data }));
    } else {
      this.props.closeHistory(false);
    }
  }

  public componentDidMount() {
    const { model, poiId } = this.props;
    // this.grid.current!.showloadelement();
    this.props.fetchHistory(model.answerTable, model.ref, poiId, this.dataLoaded);
  }

  @bind
  private renderToolbar(): any {
    const closeHistoryButton = (
      <button
        className="close"
        onClick={() => this.props.closeHistory(false)}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    );

    return (
        <div className="row table-toolbar">
          <div className="table-toolbar-name">
            History Trace: {this.props.poiName}
          </div>
          <div className="table-toolbar-buttons">
            {closeHistoryButton}
          </div>
        </div>
    );
  }

  public render(): JSX.Element | null {
    return this.state.data.length > 0 ? (
      <div className="history-table">
        {this.renderToolbar()}
        <BootstrapTable
          ref={this.grid}
          keyField="id"
          data={this.state.data}
          columns={this.state.columns}
          condensed={true}
          wrapperClasses="table-responsive table-view"
          noDataIndication={'Table is Empty'}
          filter={filterFactory()}
          pagination={paginationFactory()}
        />
      </div>
    ) : null;
  }
}
