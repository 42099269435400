import * as React from 'react';
import { BaseModalInterface } from '../../Interfaces/ModalInterface';
import { Button, Modal } from 'react-bootstrap';
import bind from 'bind-decorator';

interface IExternalProps extends BaseModalInterface {
  onConfirm: () => void;
  onCancel?: () => void;
  localizations: {
    cancel: string;
    confirm: string;
    confirmStyle: 'success' | 'danger' | 'primary' | null;
    header: JSX.Element;
    body: JSX.Element;
  };
  onEntered?: () => void;
}

const className = 'ConfirmationModal';
export class ConfirmationModal extends React.Component<IExternalProps> {
  @bind
  private onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.props.onClose();
  }

  public render(): JSX.Element {
    return (
        <Modal
            show={true}
            onHide={this.props.onClose}
            backdrop={true}
            className={className}
            onEntered={this.props.onEntered}
        >
          <Modal.Header closeButton={true}>
            {this.props.localizations.header}
          </Modal.Header>
          <Modal.Body className={`${className}-body`}>
            {this.props.localizations.body}
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="primary" onClick={this.onCancel} id={'confirm-negative-btn'}>
              {this.props.localizations.cancel}
            </Button>
            <Button
              size="sm"
              variant={this.props.localizations.confirmStyle ? this.props.localizations.confirmStyle : 'success'}
              onClick={this.props.onConfirm}
              id={'confirm-positive-btn'}
            >
              {this.props.localizations.confirm}
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }
}
