import { getRequestConfig } from './index';

export const doReverseGeoCode = (lat: number, lng: number, userId: string) => {
  const config = getRequestConfig();
  config['method'] = 'POST';
  config['credentials'] = 'same-origin';
  return fetch(`/json/app/geocode/reverse/${lng}/${lat}/${userId}`, config);
};

export const doGeoCodeAddress = (address: string, userId: string, signal: AbortSignal) => {
  const config = getRequestConfig();
  config['method'] = 'POST';
  config['credentials'] = 'same-origin';
  return fetch(`/json/app/geocode/address/${userId}/${encodeURIComponent(address)}`, {...config, signal: signal });
};
