import { getRequestConfig } from './index';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ChartModel } from 'Interfaces/ChartInterface';

export const doGetCharts = () => {
  const headers = getRequestConfig();
  return fetch(`/json/chart/list`, headers);
};

export const doLoadChartData = (url) => {
  const headers = getRequestConfig();
  return fetch(`${url}&key=${headers.headers.key}`, headers);
};

export const doSaveChart = (chartModel: ChartModel) => {
  const headers = getRequestConfig();
  const method = chartModel.id === '' ? 'POST' : 'PUT';
  return fetch(`/json/chart`, Object.assign({}, headers, { method, body: JSON.stringify(chartModel)}));
};

export const deleteChartReport = async (reportId: string): Promise<boolean> => {
  try {
    const url = `/json/app/deletereport/${reportId}`;
    const response = await axios.delete(
        url,
        getRequestConfig());
    const data = response.data;
    return response.status === 200 && data.status === 'OK';
  } catch (err) {
    toast.error('An error occurred while deleting chart report.');
    throw err;
  }
};

export const doLoadBarChart = (chartModel: ChartModel) => {
  const headers = getRequestConfig();
  return fetch(`/json/chart?chartModel=${encodeURIComponent(JSON.stringify(chartModel))}`, headers);
};
