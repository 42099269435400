import * as React from 'react';
import bind from 'bind-decorator';
import { FormInterface } from '../../../Interfaces/Forms/FormsInterface';
import { getLocalization } from '../../../global/global';
import { getQuestionOptions } from './utils';
import { JSONInterface } from 'Interfaces/JsonInterface';

interface Props {
  form: FormInterface | undefined;
  chartType: string;
  questions: string[];
  onQuestionsChange: (questions: string[]) => void;
}

interface State {
  questions: string[];
  validQuestions: JSONInterface;
  chartType: string;
}

export default class ReportQuestionSelector extends React.Component<Props, State> {
  private source = React.createRef<HTMLSelectElement>();
  private destination = React.createRef<HTMLSelectElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      questions: this.props.questions,
      validQuestions: props.form ? getQuestionOptions(props.form, props.chartType) : {},
      chartType: props.chartType,
    };
    this.source = React.createRef();
    this.destination = React.createRef();
  }

  @bind
  private onMoveQuestion(direction: string) {
    if (this.source.current && this.destination.current) {
      let options;
      if (direction === 'right') {
        options = this.source.current.options;
      } else if (direction === 'left') {
        options = this.destination.current.options;
      }
      let i = 0;
      const selectedOptions: string[] = [];
      while (i < options.length) {
        if (options[i].selected) {
          selectedOptions.push(options[i].value);
        }
        i++;
      }
      const newQuestions = direction === 'right' ? (
        this.state.questions.concat(selectedOptions)
      ) : this.state.questions.filter( q => selectedOptions.indexOf(q) === -1);
      this.setState({ questions: newQuestions });
      this.props.onQuestionsChange(newQuestions);
    }
  }

  @bind
  private onReoroderQuestions(direction: 'up' | 'down') {
    let i = 0;
    if (this.destination.current) {
      const options = this.destination.current.options;
      let index = -1;
      if (options) {
        while (i < options.length) {
          if (options[i].selected) {
            index = i;
            break;
          }
          i++;
        }
      }
      if (index > -1) {
        const state = {...this.state};
        const q = state.questions[index];
        state.questions.splice(index, 1);
        if (direction === 'up') {
          if (index === 0) {
            index = state.questions.length + 1;
          }
          state.questions.splice(index - 1, 0, q);
        } else {
          if (index === state.questions.length - 1) {
            index = 0;
          }
          state.questions.splice(index + 1, 0, q);
        }
        this.setState(state);
      }
    }
  }

  @bind
  private getQuestions(input: 'destination' | 'source') {
    const { questions, validQuestions } = this.state;
      const keys = Object.keys(validQuestions);
      const options: any[] = [];
      if (input === 'source') {
        for (const qId of keys) {
          if (questions.indexOf(qId) === -1) {
            options.push((
              <option key={`${qId}-source`} value={qId}>
                {validQuestions[qId]}
              </option>
            ));
          }
        }
      } else {
        for (const qId of questions) {
          options.push((
            <option key={`${qId}-source`} value={qId}>
              {validQuestions[qId]}
            </option>
          ));
        }
      }
      return options;
  }

  public componentDidUpdate(prevProps, prevState) {
    if (this.props.form !== prevProps.form || this.props.chartType !== prevProps.chartType) {
      if (this.props.form && this.props.chartType) {
        this.setState({
          chartType: this.props.chartType,
          validQuestions: getQuestionOptions(this.props.form, this.props.chartType),
          questions: []
        });
      } else {
        this.setState({ validQuestions: {}, questions: [] });
      }
      this.props.onQuestionsChange([]);
    }
  }

  public render(): JSX.Element {
    return (
      <div className="row">
        <div className="col-5 report-select-multiple">
          <select
            className="form-control report-select-multiple"
            multiple={true}
            name="source"
            ref={this.source}
          >
            {this.getQuestions('source')}
          </select>
        </div>
        <div className="col-2">
          <div className="text-center">
            <button className="btn btn-sm btn-primary" onClick={() => this.onReoroderQuestions('up')}>
              {getLocalization('reportsUp')}
            </button>
          </div>
          <div className="row">
            <button className="btn btn-sm btn-primary" onClick={() => this.onMoveQuestion('right')}>
              {'>>'}
            </button>
            <button className="btn btn-sm btn-primary move-left-btn" onClick={() => this.onMoveQuestion('left')}>
              {'<<'}
            </button>
          </div>
          <div className="text-center">
            <button className="btn btn-sm btn-primary" onClick={() => this.onReoroderQuestions('down')}>
              {getLocalization('reportsDown')}
            </button>
          </div>
        </div>
        <div className="col-5 report-select-multiple">
          <select
            className="form-control report-select-multiple"
            multiple={true}
            name="destination"
            ref={this.destination}
          >
            {this.getQuestions('destination')}
          </select>
        </div>
      </div>
    );
  }
}
