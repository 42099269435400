import './Schedule.scss';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { ScheduleSelectForm } from './ScheduleSelectForm';
import { ScheduleInterface, SchedulesInterface } from '../../Interfaces/ScheduleInterface';
import * as scheduleApi from '../../api/schedulesApi';
import { ScheduleAnswersList } from './ScheduleAnswersList';
import { Col, Row } from 'react-bootstrap';
import { ScheduleCalendar } from './ScheduleCalendar';

const className = 'Schedule';

export const Schedule: FunctionComponent<{}> = () => {
  const [selectedForm, setSelectedForm] = useState<FormInterface>();
  const [schedules, setSchedules] = useState<SchedulesInterface>([]);

  useEffect(() => {
    const getSchedules = async () => {
      if (selectedForm) {
        const data = await scheduleApi.loadSchedules(selectedForm);
        setSchedules(data);
      }
    };
    getSchedules();
  }, [selectedForm?.ref]);

  const getDate = (timestamp: number | string) => {
    const pad = (v) => v < 10 ? `0${v}` : v;
    const date = new Date(timestamp);
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
  };

  const addNewSchedule = async (schedule: Omit<ScheduleInterface, 'id'>) => {
    if (selectedForm) {
      const savedSchedule = await scheduleApi.saveSchedule({...schedule, ...{ date: getDate(schedule.date)}});
      if (savedSchedule.id) {
        setSchedules([
          ...schedules,
          savedSchedule
        ]);
      }
    }
  };

  const deleteSchedule = async (
      id: ScheduleInterface['id']
  ) => {
    await scheduleApi.deleteSchedule(id);
    setSchedules(
        schedules.filter((schedule) => {
          return schedule.id !== id;
        })
    );
  };

  return (
      <div className={className}>
        {/*<div className={`${className}-selection`}>*/}
        {/*  <ScheduleSelectForm*/}
        {/*      baseClassName={`${className}-selection__item`}*/}
        {/*      selectedForm={selectedForm}*/}
        {/*      setSelectedForm={setSelectedForm}*/}
        {/*  />*/}
        {/*</div>*/}
        <Row className={`${className}-main`}>
          <Col
              md={9}
              className={`${className}-main__calendar`}
          >
            {selectedForm && (
                <ScheduleCalendar
                    baseClassName={`${className}-main`}
                    selectedForm={selectedForm}
                    schedules={schedules}
                    addNewSchedule={addNewSchedule}
                    deleteSchedule={deleteSchedule}
                />
            )}
          </Col>
          <Col md={3}>
            <ScheduleSelectForm
                baseClassName={`${className}-main__form`}
                selectedForm={selectedForm}
                setSelectedForm={setSelectedForm}
            />
            {selectedForm && (
                <ScheduleAnswersList
                    baseClassName={`${className}-main`}
                    selectedForm={selectedForm}
                    schedules={schedules}
                />
            )}
          </Col>
        </Row>
      </div>
  );
};
