import * as React from 'react';
import bind from 'bind-decorator';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { renderQuestions } from '../utils/qnrenderer';
import { DataPoint } from '../../../Interfaces/DataPoint';
import FormUtils from '../utils/FormUtils';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import SingleInstanceChartComponent from '../../../views/Charts/SingleInstanceChartComponent';
import SingleInstanceChartsSelector from '../../Charts/SingleInstanceChartsSelector';
import QuestionLabel from './QuestionLabel';
import { QuestionInterface } from '../../../Interfaces/Forms/QuestionInterface';

interface Props {
  question: LooseObject;
  dataPoint: DataPoint;
  formUtils: FormUtils;
  forms: LooseObject[];
  updateAnswer: (value: LooseObject) => void;
  clientPersist: ClientPersistInterface;
}

interface State {
  header: JSX.Element[];
  grid: QuestionInterface[][];
}

export default class LayoutTableQuestion extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    const header = this.getHeader();
    const grid = this.createGrid();
    this.state = {
      header: this.cleanHeader(header, grid),
      grid: grid
    };
  }

  @bind
  private cleanHeader(header: JSX.Element[], grid: QuestionInterface[][]) {
    const newHeader: JSX.Element[] = header.splice(0, 1);
    header.forEach((header, index) => {
      let visible = false;
      for (const row of grid) {
        if (row && row[index]) {
          if (!row[index].hideInSingleInstance) {
            visible = true;
            break;
          }
        }
      }
      if (visible) {
        newHeader.push(header);
      }
    });
    return newHeader;
  }

  @bind
  private getHeader() {
    const { question } = this.props;
    const table = question.table;
    if (table) {
      const columns = table.columns;
      if (columns && columns.column) {
        const headers = [
          <th key={`${question.id}_fixed_table_header`} className="counter">#</th>
        ].concat(columns.column.map((col) => {
          return (
            <th key={`${question.id}_${col.id}`}>
              {col.name}
            </th>
          );
        }));
        return headers;
      }
    }
    return [];
  }

  @bind
  private createGrid() {
    const { question } = this.props;
    const table = question.table;
    const grid: QuestionInterface[][] = [];
    if (table) {
      const columns = table.columns;
      if (columns && columns.column) {
        columns.column.forEach((col, index) => {
          const questions = col.question;
          questions.filter( q => !q.deleted && !q.inVisible).forEach((qn, rowIndex) => {
            if (!grid[rowIndex]) {
              grid[rowIndex] = [];
            }
            grid[rowIndex][index] = qn;
          });
        });
      }
    }
    return grid;
  }

  @bind
  private getRows() {
    const { grid } = this.state;
    const { dataPoint, clientPersist, forms, updateAnswer, formUtils, question } = this.props;
    const rows: JSX.Element[] = [];
    let i = 0;
    for (const row of grid) {
      const qns = renderQuestions(row, dataPoint, true, forms, updateAnswer, formUtils,
      false, clientPersist, undefined, undefined, question);
      qns.splice(0, 0, <td className="count" key={`${formUtils.getModel().id}_${question.id}_${i}`} />);
      rows.push((
          <tr>
          {qns}
        </tr>
      ));
      i++;
    }
    return rows;
  }

  public render() {
    const { question, formUtils, dataPoint } = this.props;
    return (
      <div className="form-group col-md-12 col-lg-12 col-sm-12">
        {dataPoint && dataPoint.row_id && (
          <SingleInstanceChartComponent
            key={`single-instance-chart-component`}
            formId={`${formUtils.getModel().ref}-${question.id}`}
            rowId={dataPoint.row_id}
          />
        )}
        <QuestionLabel
          question={question}
          formUtils={formUtils}
          dataPoint={dataPoint}
        >
          {dataPoint && dataPoint.row_id && (
            <SingleInstanceChartsSelector
              key={`single-instance-chart-selector`}
              formId={`${formUtils.getModel().ref}-${question.id}`}
            />
          )}
        </QuestionLabel>
        <table className="table table-hover table-condensed">
          <thead>
            {this.state.header}
          </thead>
          <tbody>
            {this.getRows()}
          </tbody>
          {/*this.getOperationsFooter()*/}
        </table>
      </div>
    );
  }
}
