/*
 * Renders the chart box with the chart.
 */
import * as React from 'react';
import bind from 'bind-decorator';
import { Button } from 'react-bootstrap';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsExport from 'highcharts/modules/exporting';
import { ChartModel } from 'Interfaces/ChartInterface';

highchartsExport(Highcharts);

interface Props {
  options: Highcharts.Options;
  id: string;
  switchChart: (index, action: boolean, name: string) => void;
  index: number;
  chartType: string;
  addChartSvgs: (svg: SVGElement, id) => void;
  model: ChartModel;
}

interface State {
  cummulative: boolean;
  sort: boolean;
  switch: boolean;
  canSwitch: boolean;
}

export default class ChartPanelComponent extends React.Component<Props, State> {
  private chartRef: React.RefObject<HighchartsReact>;
  // private container = React.createRef<HTMLDivElement>();
  // private chart: any;
  constructor(props) {
    super(props);
    // this.container = React.createRef();
    this.state = {
      cummulative: false,
      sort: false,
      switch: false,
      canSwitch: this.canSwitch()
    };
    this.chartRef = React.createRef();
  }

  private canSwitch() {
    const { model } = this.props;
    if ((model.type === 'BAR_CHOICE' && !model.stack && !model.combine) ||
      (model.type === 'BAR_NUMERIC' && model.combine && model.xaxis)) {
        return true;
      }
      return false;
  }

  public componentDidMount(): void {
    this.updateChartSvgs();
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevProps.options !== this.props.options) {
      this.updateChartSvgs();
      const chart = this.chartRef.current?.chart;
      if (chart) {
        chart.update(this.props.options);
      }
    }
  }

  private updateChartSvgs(): void {
    const chart = this.chartRef.current?.chart;
    const svg = chart?.getSVG() as unknown as SVGElement;
    if (svg) {
      this.props.addChartSvgs(svg, this.props.id);
    }
  }

  @bind
  private onSwitchChart(name) {
    const { id, switchChart } = this.props;
    const state = {};
    state[name] = !this.state[name];
    switchChart(id, !this.state[name], name);
    this.setState(state);
  }

  /*public componentDidMount() {
     this.chart = new Highcharts['Chart'](this.props.id, this.props.options);
     console.log(this.chart);
  }*/

  /*public shouldComponentUpdate(nextProps, nextState) {
    if (this.props.options !== nextProps.options) {
      this.chart = new Highcharts['Chart'](this.props.id, nextProps.options);
      // this.chart.update(nextProps.options);
    }
    return true;
  }*/

  public render() {
    const { chartType, model, options } = this.props;
    return (
      <div className="col-6">
        <div className="container">
          <div className="row justify-content-end">
            {chartType === 'bar' && (
              <Button
                size="sm"
                onClick={() => this.onSwitchChart('sort')}
                variant={this.state.sort ? 'success' : 'light'}
              >
                <i className="fa fa-sort-amount-desc"/>
              </Button>
            )}
            {(model.type === 'LINE_NUMERIC' || model.type === 'LINE_CHOICE') && (
              <Button
                size="sm"
                onClick={() => this.onSwitchChart('cummulative')}
                variant={this.state.cummulative ? 'success' : 'light'}
              >
                <i className="fa fa-line-chart"/>
              </Button>
            )
            }
            {this.state.canSwitch && (
              <Button
                size="sm"
                onClick={() => this.onSwitchChart('switch')}
                variant={this.state.switch ? 'success' : 'light'}
              >
                <i className="fa fa-arrows-h"/>
              </Button>
            )}
          </div>
        </div>
        <div id={this.props.id}>
           <HighchartsReact
              ref={this.chartRef}
              highcharts={Highcharts}
              options={options}
           />
        </div>
        {options['extraTitle'] && !(options.legend && options.legend.enabled) && (
          <ul className="chart-title-list">
            {this.props.options['extraTitle'].map((title, index) => {
              return (
                <li key={`chart-${model.id}-${index}`}>{title}</li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}
