import { LooseObject } from '../../Interfaces/LooseObject';
import { compact, forEach, keys, map, uniq } from 'lodash-es';

export const processReverseGeoCode = (response: LooseObject): LooseObject => {
  let data = {};
  let mappedLocations;
  forEach(response, (value, key) => {
    if (key === 'Locations') {
      const resp = processMappedLocations(value);
      data = Object.assign({}, data, resp.dataPoint);
      if (resp.mappedLocations) {
        mappedLocations = resp.mappedLocations;
      }
    } else {
      data[key] = value;
    }
  });
  return { data, mappedLocations };
};

export const getUniqueCountry = (country) => {
  const uni = {};
  let i = 1;
  while (i <= 4) {
    const l = compact(map(country, (value) => {
      return value[`Location${i}`];
    }));
    if (l.length > 0) {
      const unique = uniq(l);
      if (unique.length === 1 && unique[0]) {
        uni[`Location${i}`] = unique[0];
      }
    }
    i++;
  }
  return uni;
};

export const processMappedLocations = (data) => {
  const dataPoint = {};
  let mappedLocations;
  if (data.length > 0) {
    mappedLocations = data[0];
    // TODO: formView.mappedLocations = mappedLocations.locations;
    if ((mappedLocations.country !== null) && mappedLocations.country.length > 0) {
      const country = mappedLocations.country;
      if (country.length > 0) {
        const mappedCountry = getUniqueCountry(country);
        const countryKeys = keys(mappedCountry);
        forEach(countryKeys, (key) => {
          dataPoint[key.toLowerCase()] = mappedCountry[key];
        });
      }
    } else if (mappedLocations.locations && mappedLocations.locations.length > 0) {
      const locations = mappedLocations.locations;
      if (locations.length === 1) {
        const assignedLoc = locations[0];
        const locationKeys = keys(assignedLoc);
        forEach(locationKeys, (key) => {
          dataPoint[key.toLowerCase()] = assignedLoc[key];
        });
      } else {
        let x = 1;
        let highest: any = [];
        const unique = {};
        while (x <= 4) {
          highest = uniq(compact(map(locations, (value) => {
            return value[`Location${x}`];
          })));

          if (highest.length > 1) {
            break;
          } else if (highest.length === 1 && highest[0]) {
            unique[`Location${x}`] = highest[0];
          }
          x++;
        }
        const mapped = keys(unique);
        forEach(mapped, function(key) {
          dataPoint[key.toLowerCase()] = unique[key];
        });
        if (highest.length > 1) {
          dataPoint[`location${x}`] = null;
        }
      }
    }
  }
  return {
    dataPoint: dataPoint,
    mappedLocations: mappedLocations ? mappedLocations.locations : undefined
  };
};
