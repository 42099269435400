import './CollapseMenu.scss';
import * as React from 'react';
import { HeaderPropsInterface } from './HeaderContainer';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import bind from 'bind-decorator';
import { getLocalization, globalWindow } from '../../global/global';
import { languagesEnum } from '../../Interfaces/ClientPersistInterface';
import { ModalComponentNames } from '../../Interfaces/ModalInterface';
import helpPaths from './helpPaths';
import * as H from 'history';

interface IExternalProps {
  clientPersist: HeaderPropsInterface['clientPersist'];
  navigateAddModal: HeaderPropsInterface['actions']['navigateAddModal'];
  navigateRemoveModal: HeaderPropsInterface['actions']['navigateRemoveModal'];
  routerLocation: HeaderPropsInterface['routerLocation'];
  history: H.History;
}

interface IOwnState {
  alert: null | object;
  currentSelection: 'dataviewer' | 'help';
  showAlert: boolean;
}

const className = 'CollapseMenu';
export default class CollapseMenuClass extends React.Component <IExternalProps, IOwnState> {
  constructor(props) {
    super(props);
    let currentSelection;
    if (helpPaths.includes(props.routerLocation.pathname)) {
      currentSelection = 'help';
    } else {
      if (props.routerLocation.pathname === '/schedule') {
        currentSelection = 'schedule';
      } else {
        currentSelection = 'dataviewer';
      }
    }
    this.state = {
      currentSelection,
      showAlert: false,
      alert: null
    };
  }

  /*
   *  Opens the link passed.
   */
  @bind
  private openLink(link, name: string = '_parent') {
    if (link.endsWith('=')) {
      link = link + this.props.clientPersist.lang;
    }
    return () => {
      globalWindow.open(link, name);
    };
  }

  /*
   * Shows the dataviewer.
   */
  @bind
  private showDataViewer() {
    if (!['schedule', 'dataviewer'].includes(this.state.currentSelection)) {
      this.openLink('/index.jsp')();
    } else if (this.state.currentSelection !== 'dataviewer') {
      this.props.history.push('/');
      this.setState({currentSelection: 'dataviewer'});
    }
  }

  // Shows the video modal and sets the id passed as id of the video to be displayed.
  @bind
  private showVideo(id: string) {
    return () => {
      this.props.navigateAddModal({
        component: ModalComponentNames.ShowVideoModal,
        props: {
          onClose: () => this.props.navigateRemoveModal(ModalComponentNames.ShowVideoModal),
          videoId: id
        }
      });
    };
  }

  private getHelpDropdown(): JSX.Element | null {
    if (this.props.clientPersist.roles.indexOf('enumerator') !== -1) {
      return null;
    }
    const menuItem = (eventKey: number, link: string, locale: string, isVideo: boolean = false): JSX.Element => {
      const openLink = [7.4, 7.5, 7.6].includes(eventKey) ? this.openLink(link, '_blank') : this.openLink(link);
      return (
          <NavDropdown.Item
            as={'li'}
            eventKey="1"
            onClick={isVideo ? this.showVideo(link) : openLink}
          >
            {getLocalization(locale)}
          </NavDropdown.Item>
      );
    };
    const gettingStartedVideo = menuItem(
        7.2, '_QXu4HqMgu0', 'gettingstartedvideo', true
    );
    return (
        <NavDropdown
          title={getLocalization('help')}
          id="basic-nav-dropdown"
          active={this.state.currentSelection === 'help'}
        >
          {this.props.clientPersist.instance === 'poimapper' && menuItem(7.1, '/introduction.jsp', 'introduction')}
          {this.props.clientPersist.lang !== languagesEnum.fi && gettingStartedVideo}
          {menuItem(7.3, 'ZQeX2R8HRok', 'adminvideo', true)}
          {menuItem(7.4, `${window.location.origin}/portalmanual/`, 'dataviewermanual')}
          {menuItem(7.5, `${window.location.origin}/formbuildermanual/`, 'formbuildermanual')}
          {menuItem(7.6, `${window.location.origin}/release-notes.jsp`, 'releaseNotes')}
        </NavDropdown>
    );
  }

  public render(): JSX.Element | null {
    const { clientPersist } = this.props;
    const { roles } = clientPersist;
    const dataViewer = Boolean(roles) && (roles.includes('admin') || roles.includes('modeler')) ? (
      <Nav.Item as={'li'} className={`${this.state.currentSelection === 'dataviewer' ? 'active' : ''}`}>
        <Nav.Link
          eventKey="1"
          onClick={this.showDataViewer}
        >
          {getLocalization('dataviewer')}
        </Nav.Link>
      </Nav.Item>
    ) : '';
    const formBuilder = Boolean(roles) && roles === 'modeler' ? // roles.includes('admin') ||
        (
          <Nav.Item as={'li'}>
            <Nav.Link
              eventKey="2"
              onClick={this.openLink('/atool?locale=')}
            >
              {getLocalization('formbuilder')}
            </Nav.Link>
          </Nav.Item>
        )
        : '';
    const locationHierarchy = Boolean(roles) && roles === 'modeler' && (
      <Nav.Item as={'li'}>
        <Nav.Link
          eventKey="3"
          onClick={this.openLink('/atool/A2?next=locationhierarchy&locale=')}
        >
          {getLocalization('locationhierarchy')}
        </Nav.Link>
      </Nav.Item>
    );
    const admin = Boolean(roles) !== null && roles.includes('admin') && (
      <Nav.Item as={'li'}>
        <Nav.Link
          eventKey="4"
          onClick={this.openLink('/admin?lang=')}
        >
          {getLocalization('admin')}
        </Nav.Link>
      </Nav.Item>
      );
    if (globalWindow.mobile && roles.indexOf('enumerator') !== -1) {
      return (<React.Fragment>{this.props.children}</React.Fragment>);
    }
    return (
        <Navbar.Collapse
          id="header-navbar-nav"
          className={className}
        >
          <Nav className="mr-auto">
            {dataViewer}
            {formBuilder}
            {locationHierarchy}
            {admin}
            {this.getHelpDropdown()}
          </Nav>
          {this.props.children}
        </Navbar.Collapse>
    );
  }
}
