import { isNullOrUndefined, pad } from '../../../utils/utils';
import { globalWindow } from '../../../global/global';
import { LooseObject } from '../../../Interfaces/LooseObject';
import FormUtils from './FormUtils';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import { Locations } from '../../../Interfaces/LocationInterface';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { parseScript } from './LookupFileFilter';
import { forEach, isArray, keys, pick, uniq } from 'lodash-es';
import { FormInterface } from '../../../Interfaces/Forms/FormsInterface';
import { QuestionInterface, questionTypes } from '../../../Interfaces/Forms/QuestionInterface';
import { getDiagram } from './DiagramUtils';
import { getFormUtils } from './FormUtilsHolder';

export const generateName = () => {
  const date = new Date();
  const dateStr = `${pad(date.getDate())}${pad(date.getMonth() + 1)}${date.getFullYear()}`;
  const timeStr = `${pad(date.getHours())}${pad(date.getMinutes())}${pad(date.getSeconds())}`;
  return `${dateStr}-${timeStr}`;
};

/*
  This function goes through the questions given and resets their values in the data point.
  Returns a new datapoint with default or null values for the questions encountered.
*/
export const resetValues = (questions): DataPoint => {
  let dataPoint: DataPoint = {};
  for (const qn of questions) {
    if (!qn.inVisible && !qn.deleted) {
      if (qn.default) {
        if (qn.type === 'IntQuestion' || qn.type === 'FloatQuestion' ||
          (qn.type === 'CalculatedValueQuestion' && qn.numericOnly)) {
          dataPoint[qn.id] = Number(qn.default);
        } else {
          dataPoint[qn.id] = qn.default;
        }
      } else {
        dataPoint[qn.id] = null;
      }
      if (canHaveSubQuestions(qn) && qn.triggerValues && qn.triggerValues.triggerValue) {
        for (const triggerValue of qn.triggerValues.triggerValue) {
          const dp = resetValues(triggerValue.action.subQuestions.question);
          dataPoint = Object.assign({}, dataPoint, dp);
        }
      }
    }
  }
  return dataPoint;
};
/*
  This function resets the values for subquestions under the given option.
*/
export const resetSubQuestionValues = (question: LooseObject, option: string): DataPoint => {
  const dataPoint: DataPoint = {};
  if (question.triggerValues && question.triggerValues.triggerValue) {
    for (const triggerValue of question.triggerValues.triggerValue) {
      if (triggerValue.value === option) {
        return resetValues(triggerValue.action.subQuestions.question);
      }
    }
  }
  return dataPoint;
};

export const initSubquestionValues = (
  question: LooseObject, option: string, formUtils, forms, parentQuestion
): DataPoint => {
  const dataPoint: DataPoint = {};
  if (question.triggerValues && question.triggerValues.triggerValue) {
    for (const triggerValue of question.triggerValues.triggerValue) {
      if (triggerValue.value === option) {
        for (const q of triggerValue.action.subQuestions.question) {
          initQuestion(q, dataPoint, formUtils, forms, parentQuestion);
        }
      }
    }
  }
  return dataPoint;
};

export const skipHasChanged = (oldValue, newValue) => {
  let currentValue: boolean | null = null;
  if (oldValue === true || oldValue === 'true') {
    currentValue = true;
  } else if (oldValue === false || oldValue === 'false') {
    currentValue = false;
  }
  if (currentValue !== newValue) {
    return true;
  }
  return false;
};

export const initDataPoint = (
  dataPoint: DataPoint,
  model: FormInterface | LooseObject,
  clientPersist?: ClientPersistInterface,
  locationHierarchy?: Locations,
  parentQuestion?: LooseObject,
  forms?: LooseObject[]
): DataPoint => {
  const formUtils = new FormUtils(model);
  const newDataPoint = Object.assign({}, dataPoint);
  if (model.type !== 'TABLE') {
    newDataPoint['portalEditEntry'] = Date.now();
    newDataPoint['createdBy'] = globalWindow.userID;
  }
  newDataPoint.questionnaire_id = model.ref;

  delete newDataPoint['modified']; // remove the modified time, this would be reset in backend.
  const questions = formUtils.getQuestions();
  const ids = keys(questions);

  if (formUtils.showLocationHierarchy() && clientPersist && clientPersist.userLevel.trim().length > 0 &&
    clientPersist.userLocations.trim().length > 0) {
    initLocationHierarchy(clientPersist, locationHierarchy, newDataPoint);
  }
  forEach(ids, (id) => {
    const qn: QuestionInterface = questions[id];
    initQuestion(qn, newDataPoint, formUtils, forms, parentQuestion);
  });
  return newDataPoint;
};

export const initQuestion = (qn: LooseObject, newDataPoint: DataPoint, formUtils: FormUtils, forms, parentQuestion) => {
  if (qn.default && !newDataPoint[qn.id]) {
    if (qn.type === questionTypes.INT_QUESTION || qn.type === questionTypes.FLOAT_QUESTION ||
      (qn.type === questionTypes.CALCULATED_VALUE_QUESTION && qn.numericOnly)) {
      newDataPoint[qn.id] = Number(qn.default);
    } else if (qn.type === questionTypes.DATE_QUESTION) {
      const today = new Date();
      today.setDate(today.getDate() + Number(qn['default']));
      newDataPoint[qn.d] = `${today.getFullYear()}-${pad(today.getMonth() + 1)}-${pad(today.getDate())}`;
    } else {
      newDataPoint[qn.id] = qn.default;
    }
  } else {
    if (qn.type === questionTypes.LOOKUP_VALUES_QUESTION) {
      initLookupValues(qn, formUtils, newDataPoint, parentQuestion);
    } else if (qn.type === questionTypes.TABLE_QUESTION) {
      newDataPoint[qn.id] = initTableQuestion(qn, forms);
    } else if (qn.type === questionTypes.STATUS_QUESTION) {
      newDataPoint[qn.id] = 'Submitted';
    }
  }
};

export const initLookupValues = (
  qn: LooseObject, formUtils: FormUtils, dataPoint: DataPoint, parentQuestion?: LooseObject
) => {
  const options = qn.lookupValue && qn.lookupValue.split(';').length > 1;
  if (options) {
    const validOptions = parseScript(formUtils, qn, parentQuestion, dataPoint);
    const key = keys(validOptions);
    if (key.length === 1) {
      dataPoint[qn.id] = key[0];
    }
  }
};

export const initTableQuestion = (question, forms) => {
  const values: DataPoint[] = [];
  if (forms) {
    const tableForm = forms.find(f => f.ref === question.table.columns.relation[0].ref);
    if (tableForm) {
      if (question.table.rows && question.table.rows.listItem) {
        for (const rowItem of question.table.rows.listItem) {
          const tempDataPoint: DataPoint = { Name: rowItem.id ? rowItem.id : rowItem.value };
          const initializedTableDataPoint = initDataPoint(tempDataPoint, tableForm);
          values.push(initializedTableDataPoint);
        }
      } else {
        values[0] = initDataPoint({}, tableForm);
      }
    }
  }
  return values;
};
/*

*/
export const initLocationHierarchy = (clientPersist, locationHierarchy, dataPoint) => {
  if (locationHierarchy.length === 1) {
    return;
  }
  let level = Number(clientPersist.userLevel);
  const locations = clientPersist.userLocations.split(',');

  let parentChanged = false;
  let currentParent;
  if (locations.length === 1) { // ony assigned one location
    const location = locationHierarchy.find(l => Number(l.key) === Number(locations[0]));
    currentParent = location.key;
    // level++;
  } else {
    level--;
    for (const loc of locations) {
      const location = locationHierarchy.find(l => Number(l.key) === Number(loc));
      if (!currentParent) {
        currentParent = location.parent;
      }
      if (currentParent && currentParent !== location.parent) {
        parentChanged = true;
      }
    }
  }

  // Find the highest level with common parent if any.
  if (parentChanged) {
    console.log(parentChanged);
  } else { // set the parent values since assigned locations have the same parent.
    // level--;
    while (level >= 0) {
      dataPoint[`location${level + 1}`] = currentParent;
      const location = locationHierarchy.find(l => Number(l.key) === Number(currentParent));
      currentParent = location.parent;
      level--;
    }
  }
};

export const getQuestionText = (text: string | undefined | null) => {
  if (text && text.endsWith('---')) {
    return text.substring(0, text.indexOf('---'));
  } else if (text) {
    return text;
  }
  return '';
};

/**
 * This function returns the option texts for a gievn set of option ids.
 */
export const getOptionTexts = (qn: LooseObject, values: string[] | string): string => {
  if (qn.type === 'SkipQuestion' || qn.type === 'BooleanQuestion') {
    return getBoolOptionText(values);
  }
  const li = qn.listItems.listItem;
  const optionTexts = li.map(opt => {
    if (values.indexOf(opt.id) !== -1 || values.indexOf(opt.value) !== -1) {
      return opt.value;
    }
    return null;
  }).filter( text => !!text);
  return optionTexts.join(',');
};


/**
 * This function returns the option texts for a gievn set of option ids.
 */
 export const getOptionCode = (qn: LooseObject, values: string[] | string): string => {
   if (isNullOrUndefined(values)) {
     return '';
   }
  if (qn.type === 'SkipQuestion' || qn.type === 'BooleanQuestion') {
    return getBoolOptionText(values);
  }
  const li = qn.listItems.listItem;
  const optionTexts = li.map(opt => {
    if (values.indexOf(opt.id) !== -1 || values.indexOf(opt.value) !== -1) {
      return opt.code;
    }
    return null;
  }).filter( text => !!text);
  return optionTexts.join(',');
};

/**
 * When we have a calculated value question with no spss variable in the script and not set to run on create,
 * we need to check if we should run it. This happens only once unless the value is reset to the default value.
 */
export const calculateValue = (updated: boolean, question: LooseObject, dataPoint: DataPoint): boolean => {
  const allVariables: string[]
   = uniq(question.script.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]* /g));
  let calc = false;
  if (!updated && allVariables.length === 0) {
    const dv = question['default'] ? (
      question.numericOnly ? Number(question['default']) : question['default']
    ) : null;
    calc = !question.runOnCreate && (dataPoint[question.id] === dv || (
      isNullOrUndefined(dataPoint[question.id])) && dv === null
    ) ? true : false;
  }
  if (question.id === 'approvedby') {
    const status = dataPoint['status'];
    if (status === 'approved' || status === 'approvedwithcomments' && !dataPoint['approvedby']) {
      return true;
    }
  }
  return calc;
};

export const getBoolOptionText = (value) => {
  return value === true || value === 'true' ? 'Yes' : value === false || value === 'false' ?
    'No' : '';
};

/**
 * Returns true if a question can have subquestions.
 */
export const canHaveSubQuestions = (question) => {
  switch (question.type) {
    case 'SelectOneQuestion':
    case 'SelectMultipleQuestion':
    case 'BooleanQuestion':
    case 'SkipQuestion':
      return true;
    default:
      return false;
  }
};

/**
 * This function checks if a question text has script or not.
 */
export const hasScript = (question) => {
  if (question.text) {
    const variables = uniq(question.text.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));
    return variables.length > 0;
  }
  return false;
};

/**
 * Given a datapoint, this function returns an array of all the subform instances within it.
 */
export const getSubformValues = (dataPoint: DataPoint, formUtils: FormUtils) => {
  const questionIds = keys(dataPoint);
  let subForms = [];
  forEach(questionIds, (id) => {
    if (isArray(dataPoint[id]) && id !== 'files' && id !== 'drawings') {
      const question = formUtils.getQuestion(id);
      if (question.type === 'Part_of_Question' || question.type === 'TaskQuestion') {
        subForms = subForms.concat(dataPoint[id]);
      }
    }
  });
  return subForms;
};

/**
 * This function retrieves the Subforms within a Mainform that do not have any diagram attached to them directly.
 * @param dataPoint - Main form Datapoint.
 * @param formUtils - FormUtils Object for the Main form.
 */
export const getSubformMainDiagramValues =
  (dataPoint: DataPoint, formUtils: FormUtils, mainFormDiagramName: string) => {
  const questionIds = keys(dataPoint);
  const subForms: DataPoint[] = [];
  forEach(questionIds, (id) => {
    if (isArray(dataPoint[id]) && id !== 'files' && id !== 'drawings') {
      const question = formUtils.getQuestion(id);
      if (question && (question.type === 'Part_of_Question' || question.type === 'TaskQuestion')) {
        const value = dataPoint[id];
        for (const val of value) {
          const diagram = getDiagram(val['files']);
          if (!diagram || diagram === mainFormDiagramName) {
            subForms.push(val);
          }
        }
        // subForms = subForms.concat(dataPoint[id]);
      }
    }
  });
  return subForms;
};

export const isClientPaginate = (form) => {
  const formUtils = getFormUtils(form);
  const questions = formUtils.getQuestions();
  if (questions) {
    const estimateCount = Object.keys(questions).length * form.count;
    return !(estimateCount > 25000);
  }
  return true;
};

export const processGeocodedData = (geoCodedData, formUtils) => {
  if (geoCodedData.mappedLocations) {
    formUtils.setMappedLocations(geoCodedData.mappedLocations);
  }
  if (geoCodedData.data) {
    const locations = pick(geoCodedData.data, ['location1', 'location2', 'location3', 'location4']);
    if (keys(locations).length > 0) {
      let i = 1;
      while (i <= 4) {
        if (!geoCodedData.data[`location${i}`]) {
          geoCodedData.data[`location${i}`] = null;
        }
        i++;
      }
    }
    // updateAnswer(geoCodedData.data);
  }
  return geoCodedData;
};

export const isNumeric = (value) => {
  return !isNaN(Number(value)) && value !== '';
};

export const getQuestionPage = (model, questionId: string) => {
  for (const page of model.questionPage) {
    const found = findQuestion(page.question, questionId);
    if (found) {
      return page;
    }
  }
};

const findQuestion = (questions, questionId: string) => {
  for (const qn of questions) {
    if (qn.id === questionId) {
      return true;
    }
    if (qn.triggerValues && qn.triggerValues.triggerValue) {
      for (const triggerValue of qn.triggerValues.triggerValue) {
        if (triggerValue.action && triggerValue.action.subQuestions) {
          const found = findQuestion(triggerValue.action.subQuestions.question, questionId);
          if (found) {
            return true;
          }
        }
      }
    }
  }
  return false;
};
