import * as React from 'react';
import './styles/SingleInstance.scss';
import bind from 'bind-decorator';
import { ProgressBar } from 'react-bootstrap';
import FormComponent from './FormComponent';
import { LooseObject } from '../../Interfaces/LooseObject';
import { DataPoint } from '../../Interfaces/DataPoint';
import { ButtonGroup } from 'react-bootstrap';
import { getLocalization } from '../../global/global';
import * as scheduleApi from '../../api/schedulesApi';
import DeleteButtonContainer from './Buttons/Containers/DeleteButtonContainer';
import ShareButtonContainer from './Buttons/Containers/ShareButtonContainer';
import CommentsButtonContainer from './Buttons/Containers/CommentsButtonContainer';
import ReportsButtonContainer from './Buttons/Containers/ReportsButtonContainer';
import ExportButton from './Buttons/ExportButton';
import SaveButton, { IOwnState as ISaveButtonState } from './Buttons/SaveButton';
import CopyButton from './Buttons/CopyButton';
import CloseButton from './Buttons/CloseButton';
import GenericModal from '../Modals/GenericModal';
import { getReports } from './utils/Reports';
import { generateName, initDataPoint } from './utils/utils';
import { canCopy, canDelete, canExport, canShare, canViewReport } from './utils/RolesChecker';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { Locations } from 'Interfaces/LocationInterface';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { SingleInstance } from 'Interfaces/ModuleSelectionInterface';
import { RouteComponentProps, withRouter } from 'react-router';
import { QueryFiltersInterface } from '../../reducers/filtersMenuReducer';

interface IExternalProps {
  clientPersist: ClientPersistInterface;
  locationHierarchy: Locations;
  forms: FormInterface[];
  singleInstance: SingleInstance;
  savePOI: (dataPoint: DataPoint) => Promise<Response>;
  fetchPOI: (
      formId: string, signal: AbortSignal, rowId?: number, filter?: QueryFiltersInterface[], query?: LooseObject
  ) => Promise<Response>;
  unsetSingleInstance: () => void;
  setSingleInstance: (singleInstance: SingleInstance) => void;
}

interface State {
  newAnswer: boolean;
  loaded: boolean;
  dataPoint: DataPoint;
  draftPoi: boolean;
  reports: LooseObject[];
  saveType?: ISaveButtonState['saveType'];
  validationAlert: boolean;
  validate?: LooseObject;
  saveError: SaveErrorMessage | null;
  key: string;
}

interface SaveErrorMessage {
  error: string;
}

export class SingleInstanceComponentClass extends React.Component<IExternalProps & RouteComponentProps<any>, State> {
  public formComponent = React.createRef<FormComponent>();
  private exportButton = React.createRef<ExportButton>();
  private exportOnSave: boolean | undefined = undefined;
  private abortController: AbortController = new AbortController();
  constructor(props: IExternalProps & RouteComponentProps<any>) {
    super(props);
    const {model, dataPoint, newAnswer} = props.singleInstance;
    const loaded = !dataPoint.row_id;
    const dp = newAnswer ? initDataPoint(
      dataPoint, model, props.clientPersist, props.locationHierarchy, undefined, props.forms
    ) : dataPoint;
    this.state = {
      newAnswer,
      loaded: loaded,
      dataPoint: dp,
      reports: !newAnswer ? getReports(model, props.forms, []) : [],
      validationAlert: false,
      saveError: null,
      draftPoi: false,
      key: `${dp.id || Math.random()}-${model.ref}-single-instance`
    };
    this.formComponent = React.createRef();
    if (!loaded) {
      this.loadPOI();
    }
  }

  @bind
  public savePOI(saveType: ISaveButtonState['saveType'], exportOnSave?: boolean) {
    const { clientPersist } = this.props;
    if (this.formComponent.current) {
      let dataPoint = this.formComponent.current.getDataModel();
      const deltaTime = Date.now() - dataPoint['portalEditEntry'];
      dataPoint = Object.assign({}, dataPoint, { portalEditTime: deltaTime });
      if (!dataPoint.Name) {
        dataPoint = Object.assign({}, dataPoint, { Name: generateName() });
      }
      if (saveType === 'DRAFT') {
        dataPoint = Object.assign({}, dataPoint, { draft_poi: true });
        this.setState({ dataPoint: dataPoint, saveType: saveType, draftPoi: true });
      } else {
        this.setState({ saveType: saveType, draftPoi: false });
      }
      dataPoint['modified'] = Date.now();
      delete dataPoint['needs_editing'];
      dataPoint['user_id'] = Number(clientPersist.user_id);
      this.exportOnSave = exportOnSave;
      const savePoiPromise = this.props.savePOI(dataPoint);
      this.setState({ saveType: saveType }, () => this.onPOISaved(savePoiPromise, dataPoint));
    }
  }

  @bind
  private onPOISaved(savePoiPromise: Promise<Response>, dp: DataPoint) {
    const { saveType } = this.state;
    const { clientPersist } = this.props;
    const { model, onSave } = this.props.singleInstance;
    const { setSingleInstance, unsetSingleInstance } = this.props;
    savePoiPromise.then(response => response.json()).then(json => {
      if (json.rowId > 0) {
        let dataPoint: DataPoint = { questionnaire_id: model.ref, formId: model.id };
        if (this.state.newAnswer && json.id && onSave) {
          onSave(json.id, dp['scheduleDate']);
        }
        if (!onSave && model.hasScheduledStatus) {
          if (dp['scheduleStatus'] && dp['scheduleStatus'] !== 'Unscheduled' && dp['scheduleDate']) {
            scheduleApi.saveSchedule({
              formId: model.ref,
              answerId: json.id,
              date: dp['scheduleDate'],
              userId: Number(clientPersist.user_id),
              initialized: false
            });
          }
        }
        if (saveType !== 'NEW') {
          dataPoint = {...dataPoint, row_id: json.rowId};
          this.setState({ loaded: false, newAnswer: false}, () => this.reInit(dataPoint));
        } else {
          const si = {
            newAnswer: true,
            model: model,
            dataPoint: dataPoint
          };
          unsetSingleInstance();
          setTimeout(() => setSingleInstance(si), 100);
        }
      } else {
        this.setState({ saveError: { error : json.error }});
      }
    }).catch(error => {
      console.log(error);
    });
  }

  @bind
  public unrender() {
    this.props.unsetSingleInstance();
  }

  @bind
  public reInit(dataPoint: DataPoint) {
    const { model } = this.props.singleInstance;
    let query;
    if (dataPoint.id) {
      query = { id: dataPoint.id };
    }
    const fetchPoiPromise = this.props.fetchPOI(
      model.ref, this.abortController.signal, query ? undefined : dataPoint.row_id, undefined, query
      );
    this.loadedPOI(fetchPoiPromise);
  }

  @bind
  public copyPoi(dataPoint: DataPoint) {
    const { model } = this.props.singleInstance;
    this.setState({
      dataPoint, key: `${Math.random()}-${model.ref}-single-instance`
    });
  }

  /*
    returns the report, export, comment, copy, share and delete buttons.
    if we are adding a POI, returns null.

  */
  @bind
  private getTopButtons(): JSX.Element | undefined {
    const { newAnswer, draftPoi } = this.state;
    if (!newAnswer && !draftPoi) {
      const { clientPersist, singleInstance: {model}, forms } = this.props;
      const { dataPoint } = this.state;
      return (
        <React.Fragment>
          {canViewReport(clientPersist) && (
            <ReportsButtonContainer dataPoint={dataPoint} model={model} forms={forms} />
          )}
          <ButtonGroup>
            {canExport(clientPersist, model) &&
              <ExportButton dataPoint={dataPoint} model={model} singleInstance={this} ref={this.exportButton}/>}
            <CommentsButtonContainer dataPoint={this.state.dataPoint} />
            {canCopy(model) && <CopyButton dataPoint={dataPoint} singleInstance={this} />}
            {canShare(clientPersist) && <ShareButtonContainer dataPoint={dataPoint}/>}
            {
              canDelete(dataPoint, clientPersist) &&
              <DeleteButtonContainer dataPoint={dataPoint} unrender={this.unrender}/>
            }
          </ButtonGroup>
        </React.Fragment>
      );
    }
    return undefined;
  }

  @bind
  private getTopBar(): JSX.Element {
    // if new answer there is only save and close buttons, so use less space
    const style = this.state.newAnswer ? 'col-md-4 col-xs-5' : 'col-md-6 col-xs-12';
    return (
      <div className={style}>
        <div className="btn-toolbar topbar pull-right" role="toolbar">
          <div className="control-btns pull-left">
            <SaveButton
                newAnswer={this.state.newAnswer}
                savePOI={this.savePOI}
                validateForm={this.validateForm}
            />
            {this.getTopButtons()}
          </div>
          <div className="btn-group close-button-holder">
            {/* FIXME Passing around `this` is an anti-pattern and a hack which will spiral out of control*/}
            <CloseButton singleInstance={this} />
          </div>
        </div>
      </div>
    );
  }

  @bind
  public validateForm(): boolean {
    if (this.formComponent.current) {
      const valid = this.formComponent.current.validateForm();
      if (!valid.valid) {
        this.setState({ validationAlert: true, validate: valid });
      }
      return valid.valid;
    }
    return false;
  }

  @bind
  public onCopy(dataPoint: DataPoint) {
    this.setState({ dataPoint, loaded: true });
  }

  @bind
  private loadedPOI(fetchPoiPromise: Promise<Response>) {
    fetchPoiPromise.then(response => response.json()).then(json => {
      if (json.length === 1) {
        const dataPoint = json[0];
        this.setState((prevState, prevProps) => {
          return {...prevState, dataPoint: dataPoint, loaded: true };
        }, () => {
          if (this.exportOnSave && this.exportButton.current) {
            this.exportButton.current.initExport();
            this.exportOnSave = false;
          }
          /*if (
              this.state.newAnswer &&
              this.state.saveType === 'CLOSE' &&
              this.state.dataPoint.id &&
              this.props.singleInstance.onSave
          ) {
            //this.props.singleInstance.onSave(this.state.dataPoint.id);
          }*/
        });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  @bind
  private loadPOI() {
    const { dataPoint } = this.props.singleInstance;
    this.reInit(dataPoint);
    // const fetchPoiResponse = this.props.fetchPOI(model.ref, this.abortController.signal , dataPoint.row_id);
    // this.loadedPOI(fetchPoiResponse);
  }

  @bind
  private cancelModal() {
    this.setState({ validationAlert: false, saveError: null });
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render(): JSX.Element {
    if (!this.state.loaded) {
      return (
        <div>
          <ProgressBar animated={true} now={100} />
          <p>Loading...</p>
        </div>
      );
    }
    const { validationAlert, validate, saveError } = this.state;
    const validationAlertModal = validationAlert ? (
      <GenericModal
        visible={validationAlert}
        body={(
          <React.Fragment>
            <p><strong>{getLocalization('mandatoryDataMissing')}</strong></p>
            <p><strong>Question</strong></p>
            <p>{validate && validate.question.text}</p>
          </React.Fragment>
        )}
        title={getLocalization('info')}
        onConfirm={this.cancelModal}
        confirmText={getLocalization('ok')}
      />
    ) : null;

    const savingAlert = saveError ? (
      <GenericModal
        visible={this.state.saveError ? true : false}
        body={(
          <React.Fragment>
            <p><strong>{getLocalization('errorSavingData')}</strong></p>
          </React.Fragment>
        )}
        title={getLocalization('error')}
        onConfirm={this.cancelModal}
        confirmText={getLocalization('ok')}
      />
    ) : null;
    // if new answer there is only save and close buttons, so use more space for name
    const style = this.state.newAnswer ? 'col-xs-7 col-md-8' : 'col-md-6 col-xs-12';

    return (
      <div className="single-instance">
        <div className="row container-fluid top-bar">
          <div className={style}>
            <h3 className="poi-name">
              <span className="poimapper">
                <img
                  src={this.props.singleInstance.model.smallIconUrl}
                />
              </span>
              <span>
                {this.props.singleInstance.model.name}
              </span>
            </h3>
          </div>
          {this.getTopBar()}
        </div>
        {validationAlertModal}
        {savingAlert}
        <FormComponent
          key={this.state.key}
          model={this.props.singleInstance.model}
          forms={this.props.forms}
          dataPoint={this.state.dataPoint}
          ref={this.formComponent}
          newAnswer={this.state.newAnswer}
          parentDataPoint={this.state.dataPoint}
          parentModel={this.props.singleInstance.model}
          clientPersist={this.props.clientPersist}
          locationHierarchy={this.props.locationHierarchy}
        />
      </div>
    );
  }
}

export default withRouter(SingleInstanceComponentClass);
