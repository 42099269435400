import { loadSharedData, unshare } from '../../actions/shareActions';
import { getLocalization } from '../../global/global';
import { DataPoint } from 'Interfaces/DataPoint';
import { StateInterface } from 'Interfaces/StateInterface';
import * as React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import {
  customFilter, FILTER_TYPES, textFilter
} from 'react-bootstrap-table2-filter';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '../Modals/ConfirmationModal';
import moment from 'moment';
import { Users } from 'Interfaces/User';
import SelectFilter from '../Table/filters/SelectFilter';

interface StateProps {
  data: DataPoint[];
  users: Users;
}

interface ActionProps {
  load: (signal: AbortSignal) => void;
  unshare: (ids: DataPoint[], signal: AbortSignal) => Promise<Response>;
}

type SharedViewProps = ActionProps & StateProps;

let abortController: AbortController | null = new AbortController();

const SharedDataView = (props: SharedViewProps) => {

  const [init, setInit] = React.useState(false);
  const [selected, setSelected] = React.useState<DataPoint[]>([]);
  const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
  const [showUnshareConfirm, setShowUnshareConfirm] = React.useState(false);
  const [sizePerPage, setSizePerPage] = React.useState(10);
  const [totalSize, setTotalSize] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const sizePerPageList = [5, 10, 20, 25, 30];

  React.useEffect(() => {
    if (!init) {
      if (abortController === null) {
        abortController = new AbortController();
      }
      props.load(abortController.signal);
      setInit(true);
    }
    return () => {
      abortController?.abort();
    };
  }, [props]);

  React.useEffect(() => {
    setTotalSize(props.data.length);
  }, [props.data]);

  const columns = React.useMemo(() => {
    const users = props.users.map(u => u.name);
    return [{
        dataField: 'id',
        text: 'ID',
        classes: 'default-column-width',
        headerClasses: 'default-column-width header-cell',
        hidden: true
      }, {
        dataField: 'poi_id',
        text: 'ID',
        classes: 'default-column-width',
        headerClasses: 'default-column-width header-cell',
        hidden: true
      }, {
        dataField: 'name',
        text: 'Name',
        classes: 'default-column-width',
        headerClasses: 'default-column-width header-cell',
        filter: textFilter()
      }, {
        dataField: 'sharedTo',
        text: 'Shared to',
        classes: 'default-column-width',
        headerClasses: 'default-column-width header-cell',
        filter: customFilter({ type: FILTER_TYPES.MULTISELECT }),
        filterRenderer: (onFilter, column) => {
          return (<SelectFilter onFilter={onFilter} column={column} options={users}/>);
        }
      }, {
        dataField: 'sharedBy',
        text: 'Shared by',
        classes: 'default-column-width',
        headerClasses: 'default-column-width header-cell',
        filter: customFilter({ type: FILTER_TYPES.MULTISELECT }),
        filterRenderer: (onFilter, column) => {
          return (<SelectFilter onFilter={onFilter} column={column} options={users}/>);
        }
      }, {
        dataField: 'sharedOn',
        text: 'Shared on',
        classes: 'default-column-width',
        headerClasses: 'default-column-width header-cell',
        formatter: (cell, row) => moment(cell).format('L'),
        // filter: customFilter({ type: FILTER_TYPES.DATE })
      }, {
        dataField: 'sharedUntil',
        text: 'Shared until',
        classes: 'default-column-width',
        headerClasses: 'default-column-width header-cell',
        // filter: customFilter({ type: FILTER_TYPES.DATE })
      }
    ];
  }, [props.users]);
  const unshare = () => {
    if (abortController === null) {
      abortController = new AbortController();
    }
    props.unshare(selected, abortController?.signal).then(response => response.json()).then(json => {
      if (json.status === 'OK') {
        toast('Data unshared', {
          type: toast.TYPE.INFO, autoClose: 3000
        });
        setSelected([]);
      } else {
        toast('Error unsharing data', {
          type: toast.TYPE.ERROR, autoClose: 3000
        });
      }
    });
  };

  const onRowSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected([...selected, row]);
      setSelectedIds([...selectedIds, row.id]);
    } else {
      setSelected(selected.filter(x => x.id !== row.id));
      setSelectedIds(selectedIds.filter(x => x !== row.id));
    }
  };

  const onSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelected(rows);
      setSelectedIds(rows.map(r => r.id));
    } else {
      setSelected([]);
      setSelectedIds([]);
    }
  };

  const onSizePerPageChange = (sizePerPage, page) => {
    setSizePerPage(sizePerPage);
    setPage(1);
  };

  const onPageChange = (page, sizePerPage) => {
    setPage(page);
  };

  const renderToolbar = () => {
    return (
      <div className="table-toolbar">
        <span className="table-toolbar-name">
          {getLocalization('sharedData')}
        </span>
        <div className="table-toolbar-buttons">
          <button
            className="btn btn-success btn-sm btn-history"
            onClick={() => setShowUnshareConfirm(true)}
            disabled={!(selected.length > 0)}
          >
            <i className="fa fa-history" aria-hidden="true" />
          </button>
        </div>
      </div>
    );
  };

  const confirmUnshareModal = showUnshareConfirm ? (
    <ConfirmationModal
      onConfirm={unshare}
      onClose={() => setShowUnshareConfirm(false)}
      localizations={
        {
          cancel: 'Cancel',
          confirm:'Unshare',
          confirmStyle: 'danger',
          header: (
            <label>Confirm</label>
          ),
          body: (
            <div>Unshare selected datapoints?</div>
          )
        }
      }
    />
  ) : null;

  return (
    <div className={`share-data-table`}>
      {renderToolbar()}
      {confirmUnshareModal}
      <BootstrapTable
        keyField="id"
        bootstrap4={true}
        data={props.data}
        columns={columns}
        condensed={true}
        wrapperClasses={`table-responsive table-view share_data__table`}
        selectRow={{
          mode: 'checkbox',
          selected: selectedIds,
          onSelect: onRowSelect,
          onSelectAll: onSelectAll
        }}
        noDataIndication={'No shared data'}
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize,
          sizePerPageList,
          onSizePerPageChange: onSizePerPageChange,
          onPageChange: onPageChange
        })}
        filter={filterFactory()}
      />
    </div>
  );
};

const mapStateToProps = (state: StateInterface) => {
  return {
    data: state.sharedData,
    users: state.users
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (signal: AbortSignal) => dispatch(loadSharedData(signal)),
    unshare: (ids: DataPoint[], signal: AbortSignal) => {
      return dispatch(unshare(ids, signal));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedDataView);
