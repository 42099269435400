import * as React from 'react';
import bind from 'bind-decorator';
import { mapKeys, uniq } from 'lodash-es';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import FormUtils from '../utils/FormUtils';
import { renderQuestions } from '../utils/qnrenderer';
import { updateScriptObject } from '../utils/ScriptUtils';
import { initSubquestionValues, resetSubQuestionValues, skipHasChanged } from '../utils/utils';
import { canView } from '../utils/RolesChecker';

export interface Props {
  updateAnswer: (value: LooseObject) => void;
  question: LooseObject;
  dataPoint: DataPoint;
  formUtils: FormUtils;
  forms: LooseObject[];
  clientPersist: ClientPersistInterface;
  parentModel?: LooseObject;
  parentDataPoint?: LooseObject;
  parentQuestion?: LooseObject;
  newAnswer: boolean;
  isSubQuestion?: boolean;
}

interface State {
  value: null | boolean | string;
  edit: boolean;
  obj: LooseObject;
  localSPSSToId: LooseObject;
}

export default class SkipQuestion extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    const { dataPoint, question } = this.props;
    const componentState: State = {
      value : dataPoint[question.id] ? dataPoint[question.id] : false,
      edit: props.edit,
      obj: {} as LooseObject,
      localSPSSToId: {} as LooseObject
    };
    this.setObjValues(componentState);
    this.state = componentState;
  }

  /*
    When doing the script evaluation, we will need to set the object with _spss -> value mapping.
    This function sets the _spss -> value mapping and also the _spss -> id mapping.
    _spss -> id mapping allows for easier retrieving of values from the data model.
  */
  @bind
  private setObjValues(state: State): State {
    const { formUtils, question } = this.props;
    const allVariables: string[] = uniq(question.script.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));
    const obj: LooseObject = {}; // mapping for _spss -> value
    const localSPSSToId: LooseObject = {}; // mapping for _spss -> id
    for (const v of allVariables) {
      if (v.indexOf('.') !== -1) { // this may be summing a table, a lookup value.
        const prefix = v.substr(0, v.indexOf('.'));
        const questionId = formUtils.getQuestionId(prefix);
        localSPSSToId[v] = questionId;
      } else {
        const id = formUtils.getQuestionId(v);
        localSPSSToId[v] = id;
      }
    }

    state.obj = obj; // response.updatedObj;
    state.localSPSSToId = localSPSSToId;
    return state;
  }

  @bind
  private getSubQuestions(): JSX.Element[] | null {
    const {
      question, dataPoint, formUtils, updateAnswer, forms, newAnswer, parentModel, parentDataPoint,
      parentQuestion, clientPersist
    } = this.props;
    const { value } = this.state;
    const val = value === true || value === 'true' ? 'Yes' : 'No';
    if (question.triggerValues && question.triggerValues.triggerValue) {
      for (const triggerValue of question.triggerValues.triggerValue) {
        if (triggerValue.value === val) {
          const sqs = renderQuestions(triggerValue.action.subQuestions.question, dataPoint, false, forms,
            updateAnswer, formUtils, newAnswer, clientPersist, parentModel, parentDataPoint, parentQuestion);
          return sqs;
        } /* reset in data model the questions that are not visible. */
      }
    }
    return null;
  }

  /*
    This is a lifecycle react method. https://reactjs.org/docs/react-component.html#static-getderivedstatefromprops
    If any value that affects the skip has changed, we evaluate the scripta nd set the new value to the state.
  */
  public static getDerivedStateFromProps(props: Props, state: State) {
    const { obj, localSPSSToId } = state;
    const { dataPoint, formUtils, updateAnswer, parentModel, parentDataPoint, forms, question } = props;

    const response = updateScriptObject(
      localSPSSToId, obj, formUtils, dataPoint, forms, [], [], parentModel, parentDataPoint, undefined, question
    );
    const { updated, updatedObj, vars } = response;
    if (updated) {
      const { question } = props;
      // let script = question.script;
      let newObj = {...updatedObj}; // this is unecessary because the line below returns a new object.
      // we have it just to confuse typescript.
      newObj = mapKeys(newObj, (value, key) => {
        if (key.indexOf('.') !== -1) {
          return key.replace('.', '_');
        }
        value = value;
        return key;
      });
      const skipFn = () => {
        try {
          return eval('(function() {' + vars.join(' ') + question.script + '})();');
        } catch (e) {
          return null;
        }
      };

      // Only update datamodel when the result doesn't match the value in the data model.
      const skipEx = skipFn();
      if  (skipHasChanged(dataPoint[question.id], skipEx)) {
        const newValue = {};
        newValue[question.id] = skipEx;
        const val = skipEx === true || skipEx === 'true' ? 'No' : 'Yes';
        const selVal = skipEx === true || skipEx === 'true' ? 'Yes' : 'No';
        const newObj = resetSubQuestionValues(question, val);
        const newDataPoint = initSubquestionValues(question, selVal, formUtils, forms, undefined);
        updateAnswer(Object.assign({}, newValue, newObj, newDataPoint));
      }
      return { obj : updatedObj, value : skipEx };
    }
    return null;
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return true; // return this.state.value !== nextState.value || this.state.edit !== nextState.edit;
  }

  public render(): JSX.Element | null {
    const { question, clientPersist } = this.props;
    if (question.hideInSingleInstance || !canView(question, clientPersist)) {
      return null;
    }
    const sq = this.getSubQuestions();
    return (
      <div id={`skip-question-${question.id}`} className="row container-fluid form-question">
        {sq}
      </div>
    );
    /*return sq ? (
      <React.Fragment>
        {sq}
      </React.Fragment>
    ) : null;*/
  }
}
