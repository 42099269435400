import IntQuestion from './IntQuestion';
import TimeQuestion from './TimeQuestion';
import DateQuestion from './DateQuestion';
import SelectQuestion from './SelectQuestion';
import TableQuestionContainer from '../Containers/TableQuestionContainer';
import SkipQuestion from './SkipQuestion';
import LookupValueQuestionContainer from '../Containers/LookupValueQuestionContainer';
import FileQuestionContainer from '../Containers/FileQuestionContainer';
import LocationHierarchyContainer from '../Containers/LocationHierarchyContainer';
import CalculatedValueQuestionContainer from '../Containers/CalculatedValueQuestionContainer';
import StringQuestion from './StringQuestion';
import AddressQuestionContainer from '../Containers/AddressQuestionContainer';
import UserQuestionContainer from '../Containers/UserQuestionContainer';
import SubFormQuestion from './SubFormQuestion';
import GPSQuestionContainer from '../Containers/GPSQuestionContainer';
import LabelQuestion from './LabelQuestion';
import { SubFormField } from './SubFormField';
import LayoutTableQuestion from './LayoutTableQuestion';
import ValidationRuleQuestion from './ValidationRuleQuestion';

export const Elements = {
  StringQuestion: StringQuestion,
  IntQuestion: IntQuestion,
  TimeQuestion: TimeQuestion,
  DateQuestion: DateQuestion,
  SelectQuestion: SelectQuestion,
  UserQuestion: UserQuestionContainer,
  TableQuestion: TableQuestionContainer,
  SkipQuestion: SkipQuestion,
  CalculatedValueQuestion: CalculatedValueQuestionContainer,
  LookupValuesQuestion: LookupValueQuestionContainer,
  FileQuestion: FileQuestionContainer,
  LocationHierarchyComponent: LocationHierarchyContainer,
  GPSQuestion: GPSQuestionContainer,
  LabelQuestion: LabelQuestion,
  SubFormQuestion: SubFormQuestion,
  SubFormField: SubFormField,
  AddressQuestion: AddressQuestionContainer,
  LayoutTableQuestion: LayoutTableQuestion,
  ValidationRuleQuestion: ValidationRuleQuestion
};
