import { AnswersInterface } from 'Interfaces/Answers/AnswersInterface';
import { DataPoint } from '../Interfaces/DataPoint';
import ReducerBuilder from './ReducerBuilder';
import { Action } from 'typescript-fsa';
import { SCHEDULE_ANSWERS } from '../actions/actionTypes';
import { FormInterface } from '../Interfaces/Forms/FormsInterface';
import { StateInterface } from '../Interfaces/StateInterface';
import { createSelector } from 'reselect';

export const INITIAL_STATE: AnswersInterface = {};

export type PayloadType = {dataPoints: DataPoint[], formRef: FormInterface['ref']};

function loadScheduleAnswers(state: AnswersInterface, {payload}: {payload: PayloadType}): AnswersInterface {
  const newState = {...state};
  newState[payload.formRef] = payload.dataPoints;
  return newState;
}

export default new ReducerBuilder<AnswersInterface, Action<PayloadType>>()
    .setInitialState(INITIAL_STATE)
    .addReducer(SCHEDULE_ANSWERS.LOAD, loadScheduleAnswers)
    .build();

// Selectors
export const scheduleAnswersSelector = (state: StateInterface): AnswersInterface => state.scheduleAnswers;

export const scheduleFormAnswersSelector = (formRef: FormInterface['ref']) => {
  return createSelector<
      StateInterface,
      AnswersInterface,
      DataPoint[]
      >([
    scheduleAnswersSelector
  ], (answers) => answers[formRef]);
};
