import { StateInterface } from 'Interfaces/StateInterface';
import { createSelector } from 'reselect';
import { USERS } from '../actions/actionTypes';
import { Users } from '../Interfaces/User';

export const taskUsersSelector = (state: StateInterface): Users => state.taskUsers;

export const activeTaskUsersSelector = createSelector<StateInterface, Users, Users>(taskUsersSelector, users => {
  return users.filter(u => u.active);
});

const taskUsers = (state: Users = [] , action) => {
  switch (action.type) {
    case USERS.SET_TASK_USERS:
      if (action.users) {
        return Object.assign([], action.users);
      }
      return state;
    default:
      return state; //
  }
};

export default taskUsers;
