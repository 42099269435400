import { doGetFeed } from '../api/feed';
import { FEED } from './actionTypes';
import { getFeedRequestFilters, prepareFeed } from './utils/feed';
import { StateInterface } from 'Interfaces/StateInterface';

export const getFeed = (rowId: number, offset: number, signal: AbortSignal, filter?: string) => {
  return (dispatch, getState: () => StateInterface) => {
    // Clear the feed if we are loading afresh or a filter has been applied.
    const feed = getState().feed;
    if (rowId === -1 && feed && feed.feed.length > 0) {
      dispatch(clearFeed());
    }
    dispatch(feedLoading(true));
    const params = getFeedRequestFilters(getState().clientPersist, getState().filtersMenu);
    params.push(`row_id=${rowId}`);
    params.push(`offset=${offset}`);
    params.push(`filter=${filter || ''}`);
    const promise = doGetFeed(params, signal);
    promise.then(response => response.json()).then((json) => {
      const preparedFeed = prepareFeed(getState().forms.collection || [], json);
      dispatch(feedLoaded(preparedFeed));
      dispatch(feedLoading(false));
    }).catch((error) => {
      if (error.name === 'AbortError' && error.code === 20) {
        console.log(error);
      } else {
        dispatch(feedLoading(false));
      }
    });
  };
};

export const feedLoading = (loading) => (
  { type: FEED.LOADING, loading }
);

export const feedLoaded = (feed) => (
  { type: FEED.LOADED, feed }
);

export const clearFeed = () => (
  { type: FEED.CLEAR }
);

/*
  When a new data point has been created.
*/
export const addFeed = (feedItem) => (
  { type: FEED.ADD, feedItem }
);

/*
  When a data point has been updated.
*/
export const updateFeed = (feedItem) => (
  { type: FEED.UPDATE, feedItem }
);

export const clearAutoUpdatedFeed = (feed) => (
  { type: FEED.CLEAR_AUTO_UPDATE, feed }
);

export const deleteFeedItems = (pois) => (
  { type: FEED.DELETE, pois }
);

export const userUpdate = (feed) => (
  { type: FEED.USER_UPDATE, feed }
);
