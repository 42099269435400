import * as React from 'react';
import { SYSTEM } from './actionTypes';
import { actionCreator } from './index';
import { SystemInterface } from '../Interfaces/SystemInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { doCheckLicenceValidity } from '../api/systemApi';
import { showAlert } from './index';

export const loadProperties = actionCreator<SystemInterface>(SYSTEM.LOAD_PROPERTIES);

export const checkLicenceValidity = () => {
  return (dispatch, getState: () => StateInterface) => {
    const { clientPersist } = getState();
    const response = doCheckLicenceValidity(Number(clientPersist.user_id));
    response.then(response => response.json()).then((json) => {
      console.log(json);
      if (json.status === 'WARNING' || json.status === 'EXPIRED') {
        dispatch(showAlert({ visible: true, message: (<div dangerouslySetInnerHTML={{__html: json.message }}/>) }));
      }
    }).catch((error) => {
      console.log(error);
    });
  };
};
