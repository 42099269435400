import { actionCreator } from './index';
import { LocationInterface } from '../Interfaces/LocationInterface';
import { LOCATIONS } from './actionTypes';
import * as locationsApi from '../api/locationsApi';
import { StateInterface } from '../Interfaces/StateInterface';

export const loadLocations = actionCreator<LocationInterface[]>(LOCATIONS.LOAD);

export const addLocations = actionCreator<LocationInterface[]>(LOCATIONS.ADD_NEW);

export const getLocations = () => {
  return async (dispatch, getState: () => StateInterface) => {
    const { clientPersist } = getState();
    const locations = await locationsApi.getLocations(clientPersist.user_id);
    dispatch(loadLocations(locations));
  };
};

export const addNewLocation = (locations: Pick<LocationInterface, 'title' | 'level' | 'parent' | 'identifier'>[]) => {
  return (dispatch, getState: () => StateInterface) => {
    const { hierarchy } = getState();
    const promise = locationsApi.addNewLocation(hierarchy.id, locations.map((l) => {
      return {...l, location: l.title };
    }));
    promise.then(response => response.json()).then(json => {
      dispatch(addLocations(json.map(l =>  { return {...l, key: l.id, title: l.location }; })));
    }).catch(error => {
      console.log(error);
    });
  };
};
