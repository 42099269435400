import * as React from 'react';
import { connect } from 'react-redux';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { StateInterface } from '../../../Interfaces/StateInterface';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import { Locations } from '../../../Interfaces/LocationInterface';
import SingleInstanceComponent from '../SingleInstanceComponent';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { fetchPOI, savePOI } from '../../../actions/pois';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { setSingleInstance, unsetSingleInstanceState } from '../../../actions/moduleSelectionActions';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { SingleInstance } from '../../../Interfaces/ModuleSelectionInterface';
import { singleInstanceSelector } from '../../../reducers/moduleSelectionReducer';
import { QueryFiltersInterface } from '../../../reducers/filtersMenuReducer';

interface StateProps {
  clientPersist: ClientPersistInterface;
  locationHierarchy: Locations;
  forms: FormInterface[];
  singleInstance?: SingleInstance;
}

interface ActionProps {
  savePOI: (dataPoint: DataPoint) => Promise<Response>;
  fetchPOI: (
    formId: string, signal: AbortSignal, rowId?: number, filter?: QueryFiltersInterface[], query?: LooseObject
  ) => Promise<Response>;
  unsetSingleInstance: () => void;
  setSingleInstance: (singleInstance: SingleInstance) => void;
}

export type SingleInstanceProps = StateProps & ActionProps;

const SingleInstanceContainerClass = (props: SingleInstanceProps) => {
  if (props.singleInstance) {
    return (
        <SingleInstanceComponent {...props} singleInstance={props.singleInstance} />
    );
  }
  return null;
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    clientPersist: state.clientPersist,
    locationHierarchy: state.locations.collection,
    forms: state.forms.collection,
    singleInstance: singleInstanceSelector(state)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    savePOI: (dataPoint: DataPoint) => {
      return dispatch(savePOI(dataPoint));
    },
    fetchPOI: (
      formId: string, signal, rowId?, filter?, query?
    ) => {
      return dispatch(fetchPOI(formId, signal, rowId, filter, query));
    },
    unsetSingleInstance: () => dispatch(unsetSingleInstanceState()),
    setSingleInstance: (singleInstance) => dispatch(setSingleInstance(singleInstance))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleInstanceContainerClass);
