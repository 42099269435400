import '../styles/LookupValuesQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { filter, keys, map, uniq } from 'lodash-es';
import TextInputComponent from './TextInputComponent';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { LookupPropsInterface } from '../Containers/LookupValueQuestionContainer';
import { getValidOptions, getValue, parseScript } from '../utils/LookupFileFilter';
import { filterForms } from '../utils/LookupFormFilter';
import { createRequestFilters } from '../../../utils/utils';
import { getQuestionText, isClientPaginate } from '../utils/utils';
import { getLocalization } from '../../../global/global';

interface State {
  value: string;
  options: boolean;
  itemList: LooseObject[];
  filteredOptions: string[]; // Options filtered.
  validOptions: LooseObject; // all the options filtered by the script if any.
  pois: LooseObject[];
  scriptFilteredPois: LooseObject[] | undefined;
  input: string;
  obj: LooseObject | undefined;
  clientPaginate: boolean;
  expandOptions: boolean;
}

export default class LookupValuesQuestion extends React.Component <LookupPropsInterface, State> {

  private abortController: AbortController = new AbortController();
  constructor(props) {
    super(props);
    const { dataPoint, question, formUtils, parentQuestion, forms, updateAnswer } = this.props;
    const options = question.lookupValue && question.lookupValue.split(';').length > 1;
    const validOptions = options ? parseScript(formUtils, question, parentQuestion, dataPoint) : [];
    const form = !options && forms.find( f => f.ref === question.lookupValue);
    const pois = props.pois[props.question.lookupValue] || [];
    const filteredPois = pois.length > 0 && question.script ?
      filterForms(formUtils, dataPoint, forms, question, props.pois) : undefined;
    let value = dataPoint[question.id] ? dataPoint[question.id] : question.default ? question.default : '';
    const availablePois = filteredPois || pois;
    let input = value;

    const getValue = () => {
      if (availablePois.length > 0) {
        input = '';
        if (value) {
          const poi = availablePois.find((dp) => dp.id === value);
          if (poi) {
            input = poi.Name;
          }
        } else if (availablePois.length === 1) {
          value = availablePois[0].id;
          input = availablePois[0].Name;

          if (updateAnswer) {
            const newAns = {};
            newAns[question.id] = value;
            updateAnswer(newAns);
          }
        }
      }
      const availableOptions = keys(validOptions);
      if (options && availableOptions.length === 1) {
        value = availableOptions[0];
        if (updateAnswer) {
          const newAns = {};
          newAns[question.id] = value;
          updateAnswer(newAns);
        }
      }
    };
    getValue();

    const filteredOptions = this.getOptionState(options, value, keys(validOptions));
    this.state = {
      value: value,
      options: options,
      filteredOptions: filteredOptions,
      itemList: [],
      pois: pois,
      scriptFilteredPois: filteredPois,
      validOptions: validOptions,
      input: input,
      obj: options && question.script ? this.initializeObj() : undefined,
      clientPaginate: form && isClientPaginate(form) ? true : false,
      expandOptions: false
    };
    if (!options) {
      this.fetchPOIs();
    }
  }

  @bind
  private getOptionState(options, value: string, filteredOptions) {
    if (options && value) {
      return [value];
    }
    return filteredOptions;
  }

  @bind
  private initializeObj() {
    const { question, formUtils, dataPoint, parentQuestion } = this.props;
    const allVariables: string[] = uniq(question.script.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));
    const obj = {};
    for (const v of allVariables) {
      if (v === '_parntref' && parentQuestion) {
        obj[v] = parentQuestion.spssvariable;
      } else {
        const questionId = formUtils.getQuestionId(v);
        obj[v] = dataPoint[questionId];
      }
    }
    return obj;
  }

  @bind
  private handleChange(e) {
    const { question, updateAnswer } = this.props;
    const value = e.target.value;
    const input = value;
    const filteredOptions = [input];
    this.setState({ value, input, filteredOptions });
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = value;
      updateAnswer(newAns);
    }
  }

  /**
   * Fetched POIs when the question is rendered for first time.
   * If we are using client paginate, it would only fetch POIs if at least one location has been selected.
   */
  @bind
  private fetchPOIs() {
    const { question, fetchPOI, filtersMenu, locationHierarchyQuery, locationHierarchy } = this.props;
    const { clientPaginate } = this.state;
    if (!clientPaginate && filtersMenu.selectedLocations.length === 0 && locationHierarchy.length > 0) {
      return;
    }
    const filters = createRequestFilters(filtersMenu);
    const fields = clientPaginate ? undefined : 'id,Name,questionnaire_id';
    if (fetchPOI) {
      this.createAbortController();
      const request = fetchPOI(
        question.lookupValue, this.abortController.signal, undefined, filters, locationHierarchyQuery, fields
      );
      this.loadPOIs(request);
    }
  }

  @bind
  private createAbortController() {
    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();
  }
  /**
   * When Lookup is referring to a form, this is used to set the selected value from the drop down.
   * If the form being used uses client side pagination, we update the data point value.
   * If the form is using server side pagination we load the full poi data.
   */
  @bind
  private itemSelected(item: LooseObject) {
    this.setState({ value : item.id, itemList : [], input: item.Name });
    const { clientPaginate } = this.state;
    if (clientPaginate) {
      const { updateAnswer, question } = this.props;
      if (updateAnswer) {
        const newAns = {};
        newAns[question.id] = item.id;
        updateAnswer(newAns);
      }
    } else {
      this.fetchPoi(item.row_id);
    }
  }

  @bind
  private fetchPoi(rowId) {
    const { question, fetchPOI } = this.props;
    if (fetchPOI) {
      this.createAbortController();
      const request = fetchPOI(question.lookupValue, this.abortController.signal, rowId);
      this.loadPOIs(request);
    }
  }

  /**
   * When the POI data is loaded, we update the local state and also the data point value.
   */
  @bind
  private loadPOIs(request) {
    const { updateAnswer, question } = this.props;
    if (request) {
      request.then(response => response.json()).then((json) => {
        if (json && json.length > 0) {
          if (json.length === 1) {
            if (updateAnswer) {
              const newAns = {};
              newAns[question.id] = json[0].id;
              updateAnswer(newAns);
            }
            this.props.dataPointUpdated(json[0], question.lookupValue);
          } else {
            this.props.dataPointsLoaded(json, question.lookupValue);
            if (this.state.value) {
              const poi = json.find(p => p.id === this.state.value);
              if (poi) {
                this.fetchPoi(poi.row_id);
              }
            }
          }
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  /*
    Filter the dropdown options/selection.
  */
  @bind
  private filterChanged(value: any) {
    const { question } = this.props;
    const { options, validOptions } = this.state;
    const inputLength = value.length;
    if (options) {
      const lookupOptions = keys(validOptions);
      const newList = inputLength === 0 ? lookupOptions : filter(lookupOptions, ((val) =>
        val.toLowerCase().search(value.toLowerCase()) !== -1
      ));
      this.setState({ filteredOptions : newList, input: value, expandOptions: true });
    } else {
      const pois = this.state.scriptFilteredPois || this.state.pois;
      const newList = inputLength === 0 ? [] :  filter(pois, ((poi) =>
        poi.Name && poi.Name.toLowerCase().search(value.toLowerCase()) !== -1
      ));
      let itemList;
      itemList = map(newList, ((item, index) => (
        <li
          key={`${question.id}_${index}`}
          className="list-item"
          onClick={() => this.itemSelected(item)}
        >
         {item.Name}
        </li>
      )));

      this.setState({ itemList, input: value, value: '' });
    }
  }

  /*
    If we have only one item in the lookup list, then we set it as the value.
  */
  public static getDerivedStateFromProps(props: LookupPropsInterface, state: State) {
    if (state.options) {
      const { question, formUtils, dataPoint, updateAnswer, parentDataPoint, parentModel } = props;
      if (question.script && state.obj) {
        const { obj } = state;
        for (const key in obj) {
          if (key !== '_parntref') {
            const value = getValue(key, formUtils, dataPoint, parentModel, parentDataPoint);
            if (value !== obj[key]) {
              const options = getValidOptions(value.trim(), formUtils.getLookupOptions(question.id));
              const newObj = {...obj};
              newObj[key] = value;
              const lookups = keys(options);
              const newAnswer = {};
              if (lookups.length === 1) {
                newAnswer[question.id] = lookups[0];
                updateAnswer(newAnswer);
                return { value: lookups[0], validOptions: options, filteredOptions: lookups, obj: newObj };
              } else {
                newAnswer[question.id] = '';
                updateAnswer(newAnswer);
                return { value: '', validOptions: options, filteredOptions: lookups, obj: newObj };
              }
            }
          }
        }
      }
    }
    if (!state.options && props.pois && props.pois[props.question.lookupValue] && state.pois.length === 0) {
      const { question, formUtils, dataPoint, forms, updateAnswer } = props;
      const pois = props.pois[props.question.lookupValue];
      const filteredPois = pois.length > 0 && question.script ?
        filterForms(formUtils, dataPoint, forms, question, props.pois) : undefined;

      let input = '';
      let value = state.value;
      const availablePois = filteredPois || pois;

      if (state.value) {
        const poi = availablePois.find( (dp) => dp.id === state.value );
        if (poi) {
          input = poi.Name;
        } else {
          value = '';
        }
      } else if (availablePois.length === 1) {
        value = availablePois[0].id;
        input = availablePois[0].Name;

        if (updateAnswer) {
          const newAns = {};
          newAns[question.id] = value;
          updateAnswer(newAns);
        }
      }

      return { pois: pois, scriptFilteredPois: filteredPois, input, value };
    }
    return null;
  }

  /*@bind
  private toggleOptions() {
    const { expandOptions } = this.state;
    this.setState({ expandOptions: expandOptions ? false : true });
  }
  <div className="btn-group pull-right">
            <button className="btn btn-sm btn-default" onClick={this.toggleOptions}>
              <span className={`fa fa-${className}`} aria-hidden="true"/>
            </button>
          </div>
  */
  /*
    This function returns the options after they have been filtered for file-based lookup values.
  */
  @bind
  private getOptions(): JSX.Element {
    const { question } = this.props;
    const { value, filteredOptions } = this.state;
    const optionsDiv = (opts) => {
      // const className = expandOptions ? `minus` : `plus`;
      return (
        <div className="container-fluid">
          <div className="lookup-options-div">
            {opts}
          </div>
        </div>
      );
    };

    /*if (!expandOptions) {
      return optionsDiv(null);
    }*/
    const options: JSX.Element[] = filteredOptions.map(option => {
      const checked = value === option;
      return (
        <React.Fragment key={`${question.id}_${option}`}>
          <div className={`radio`}>
            <label>
              <input
                type={`radio`}
                value={option}
                name={question.id}
                onChange={this.handleChange}
                checked={checked}
                disabled={!this.props.edit}
              />
              {option}
            </label>
          </div>
        </React.Fragment>
      );
    });
    return optionsDiv(options);
  }

  @bind
  public getPOIDropDown() {
    const {itemList} = this.state;
    return (
      <div>
        <ul id="itemList" className="validation-list">
          {itemList}
        </ul>
      </div>
    );
  }

  /*@bind
  private onBlur(e) {
    const { value, input, options } = this.state;
    if (value !== input && options && value !== '') {
      this.setState({ input: value, filteredOptions: [value] });
    }
  }*/

  public shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value ||
      this.state.validOptions !== nextState.validOptions ||
      this.state.itemList !== nextState.itemList ||
      this.state.filteredOptions !== nextState.filteredOptions ||
      this.state.input !== nextState.input ||
      this.state.obj !== nextState.obj ||
      this.state.expandOptions !== nextState.expandOptions;
  }

  public render(): JSX.Element | null {
    const { question, formUtils, isSubQuestion, filtersMenu, locationHierarchy } = this.props;

    if (question.hideInSingleInstance) {
      return null;
    }

    const { options, clientPaginate } = this.state;
    const required = question.optional ? null : (<span className="text-danger">*</span>);
    const loadingAlert = !options && !clientPaginate &&
      (filtersMenu.selectedLocations.length === 0 && locationHierarchy.length > 1) ? (
        <div className={`${'form-group'} ${formUtils.getResponsiveView(question)}`}>
          <label>
            {getQuestionText(question.text)}
          </label>
          <p className="text-info">
            Select location to filter data inorder to view lookup values and try again.
          </p>
        </div>
      ) : null;
    if (loadingAlert) {
      return loadingAlert;
    }
    return (
      <div className={`${'form-group'} ${!isSubQuestion && formUtils.getResponsiveView(question)}`}>
        <label>
          {getQuestionText(question.text)}
        </label>
        {required}
        <TextInputComponent
          name={question.id}
          onChange={this.filterChanged}
          key={`input-filter-${question.id}`}
          value={this.state.input}
          extraAttrs={{placeholder: getLocalization('searchLookupList')}}
          disabled={!this.props.edit}
        />
        {options ? this.getOptions() : this.getPOIDropDown()}
      </div>
    );
  }
}

// // extraAttrs={{onBlur: this.onBlur }}
