import { REPORTS } from '../actions/actionTypes';

const reports = (state = [] , action) => {
  switch (action.type) {
    case REPORTS.LOADED:
      if (action.reports) {
        return Object.assign([], action.reports);
      }
      return state;
    default:
      return state;
  }
};

export default reports;
