import { getExpiryDate } from '../utils/cookies';
import { getLocalization } from '../global/global';
import { ClientPersistInterface } from '../Interfaces/ClientPersistInterface';
import { Cookies } from 'react-cookie';

export const setCookieDataForLoginSuccess = (
    clientPersist: ClientPersistInterface,
    rememberme: boolean,
    googleSignIn: boolean,
    cookies?: Cookies)
    : ClientPersistInterface => {
  if (rememberme) {
    cookies?.set('rememberme', true, {expires: getExpiryDate(7)});
  }
  if (googleSignIn) {
    cookies?.set('googleSignIn', true);
  } else {
    cookies?.remove('googleSignIn');
  }
  cookies?.set('db', clientPersist.instance, {expires: getExpiryDate(20)});
  return {...clientPersist};
};

export const getErrorMessage = (errorCode, defaultErrorMessage?: string | undefined) => {
  let errormsg = '';
  if (errorCode === '1015') {
    errormsg = getLocalization('licenseexpired');
  } else if (errorCode === '1016') {
    errormsg = getLocalization('invalidcredentials');
  } else if (errorCode === '1017') {
    errormsg = getLocalization('accountinactive');
  } else if (errorCode === '1018') {
    errormsg = getLocalization('accountdoesnotexist');
  } else if (errorCode === '1019') {
    errormsg = getLocalization('usernotactive');
  } else if (errorCode === '1020') {
    errormsg = getLocalization('userdoesnotexist');
  } else if (errorCode === '1021') {
    errormsg = getLocalization('userunverified');
  } else if (errorCode === '1023') {
    errormsg = getLocalization('userunassigned');
  } else {
    if (defaultErrorMessage) {
      errormsg = defaultErrorMessage;
    }
  }
  return errormsg;
};
