import { getRequestConfig } from './index';

export const doGetTileMapResource = (diagramName: string) => {
  const headers = getRequestConfig();
  return fetch(`/json/app/data/getTileMapResource/${diagramName}`,  headers);
};

export const doGetGroupDiagrams = (groupId) => {
  const headers = getRequestConfig();
  return fetch(`/json/app/getGroupDiagrams/${groupId}`,  headers);
};
