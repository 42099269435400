// import { IGridColumn, IGridSourceDataFields, jqx } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';
import * as React from 'react';
import {
  customFilter, FILTER_TYPES, numberFilter, textFilter
} from 'react-bootstrap-table2-filter';
import { Type } from 'react-bootstrap-table2-editor';
import { getLocalization } from '../../global/global';
import { Locations } from '../../Interfaces/LocationInterface';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { Users } from '../../Interfaces/User';
import DateFilter from './filters/DateFilter';
import SelectFilter from './filters/SelectFilter';
import * as Renderers from './CellsRenderers';
import MultipleSelectEditor from './editors/MultipleSelectEditor';
import NumericEditor from './editors/NumericEditor';
import { getLocationHierachyDepth, isNullOrUndefined } from '../../utils/utils';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { MoreTextComponent } from './MoreTextComponent';

export const initColumnDataFields = (
  form: FormInterface, users: Users, clientPersist: ClientPersistInterface, locations?: Locations
) => {
  const columns = [{ dataField: 'row_id', hidden: true }];

  const usersDataSource = users.map(user => ({ value: user.id, label: user.name}));

  initCoreFields(form, columns, users, usersDataSource);
  if (form.hasLocationHierarchy && locations && locations.length > 1) {
    initLocationHierarchy(locations, columns, clientPersist.locationLabels);
  }
  for (const page of form.questionPage) {
    addColumnTypes(columns, page.question, form.schemaVersion, usersDataSource);
  }
  if (form.type === 'TASKFORM') {
    return sortTaskColumns(columns);
  }
  return columns;
};

export const headerRenderer = (header) => {
  return `<div class="table-column-header"><strong>${header}</strong></div>`;
};

export const initCoreFields = (form, columns, users, usersDataSource) => {
  const filterOptions = {};
  for (const opt of users) {
    filterOptions[opt.value] = opt.label;
  }
  columns.push({
    dataField: 'user_id',
    text: 'Editor',
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    sort: true,
    hidden: true,
    editable: false,
    formatter: (cell, row, rowIndex, formatExtraData) => {
      const user = users.find( l => l.id === cell);
      return Renderers.cellTemplate({ value: `${user ? user.name : cell}` });
    },
    filter: customFilter({ type: FILTER_TYPES.MULTISELECT }),
    filterRenderer: (onFilter, column) => {
      return (<SelectFilter onFilter={onFilter} column={column} options={usersDataSource}/>);
    }
  });

  if (form.hasCoordinates) {
    columns.splice(1, 0, {
      dataField: 'Coordinates',
      text: 'GPS Coordinates',
      classes: 'default-column-width',
      headerClasses: 'default-column-width header-cell',
      hidden: true
    });
  }
};

export const initLocationHierarchy = (locations, columns, locationLabels) => {
  let i = 1;
  const depth = getLocationHierachyDepth(locations) + 1;
  while (i <= depth) {
    columns.push({
      dataField: `location${i}`,
      text: locationLabels[i - 1] ? locationLabels[i - 1] : `Location ${i}`,
      classes: 'default-column-width',
      headerClasses: 'default-column-width header-cell',
      sort: true,
      editable: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const location = locations.find( l => `${l.key}` === `${cell}`);
        return Renderers.cellTemplate({ value: `${location ? location.title : cell}` });
      }
    });
    i++;
  }
};

export const hasSubquestions = (question): boolean => {
  if (question.triggerValues && question.triggerValues.triggerValue) {
    for (const triggerValue of question.triggerValues.triggerValue) {
      if (
        triggerValue.action &&
        triggerValue.action.subQuestions &&
        triggerValue.action.subQuestions.question.length > 0
      ) {
        return true;
      }
    }
  }
  return false;
};

export const setHeaderTextFormatter = (columnDataField) => {
  if (columnDataField.text && columnDataField.text.length > 50) {
    columnDataField.headerFormatter = (column, colIndex, components) => (
      <React.Fragment>
        <span title={column.text}>{column.text.substring(0, 50)}...</span>
        {components.sortElement}
        {components.filterElement}
      </React.Fragment>
    );
  }
};

/**
 * Sets whether a column is editable or not.
 * A question is not editbale if
 *  - is referred to by a calculated or skip question
 *  - is a calculated (has a script)
 *  - is a lookup which refers to a form.
 * @param question  - the given question
 * @param columnDataField - the column definition.
 */
export const setEditable = (question, columnDataField, schemaVersion, usersDataSource) => {
  if (question.evaluate && question.evaluate.length > 0 && question.evaluate !== 'undefined') {
    columnDataField.editable = false;
    setHeaderTextFormatter(columnDataField);
  } else if (question.script && question.script.length > 0 || hasSubquestions(question)) {
    columnDataField.editable = false;
    setHeaderTextFormatter(columnDataField);
  } else {
    columnDataField.headerFormatter = (column, colIndex, components) => (
      <React.Fragment>
        <span className="fa fa-edit" />
        {column.text.length > 50 ?
          (<span title={column.text}>{column.text.substring(0, 50)}...</span>) : column.text
        }
        {components.sortElement}
        {components.filterElement}
      </React.Fragment>
    );
  }
  switch (question.type) {
    case 'SelectOneQuestion':
    case 'StatusQuestion':
    case 'LikertScaleQuestion':
      const options = getOptionDataSource(question, schemaVersion);
      columnDataField.editor = {
        type: Type.SELECT,
        options: options
      };
      break;
    case 'BooleanQuestion':
      columnDataField.editor = {
        type: Type.SELECT,
        options: [
          { value: null, label: ''},
          { value: true, label: 'Yes'},
          { value: false, label: 'No'}
        ]
      };
      break;
    case 'SelectMultipleQuestion':
      const selectOptions = getOptionDataSource(question, schemaVersion);
      columnDataField.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => (
        <MultipleSelectEditor
          {...editorProps}
          value={value}
          options={selectOptions}
          question={question}
        />
      );
      break;
    case 'SelectUserQuestion':
    case 'UserQuestion':
      columnDataField.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => (
        <MultipleSelectEditor
          {...editorProps}
          value={value}
          options={usersDataSource}
          question={question}
        />
      );
      break;
    case 'DateQuestion':
      columnDataField.editor = {
        type: Type.DATE
      };
      break;
    case 'StringQuestion':
    case 'NameQuestion':
      columnDataField.editor = {
        type: Type.TEXTAREA
      };
      break;
    case 'IntQuestion':
    case 'FloatQuestion':
      columnDataField.editorRenderer = (editorProps, value) => (
        <NumericEditor
          {...editorProps}
          value={value}
          question={question}
        />
      );
      break;
    default:
  }
};

export const addColumnTypes = (
  columns,
  questions,
  schemaVersion: number | undefined,
  usersDataSource) => {
  let columnDataField;
  const inVisibleQuestions = [
    'phoneEditTime', 'portalEditTime', 'portalEditEntry', 'entryTime', 'createdBy', 'ModifiedTime', 'modified'
  ];
  const visibleHiddenField = ['parentRowId'];
  for (const question of questions) {
    if (question.type === 'NameQuestion' || (!question.inVisible && !question.deleted && !question.hideInTable)
      || visibleHiddenField.indexOf(question.id) !== -1) {
      columnDataField = getColumnDefinition(question, schemaVersion, usersDataSource);
      if (columnDataField) {
        setEditable(question, columnDataField, schemaVersion, usersDataSource);
        if (question.type === 'NameQuestion') {
          columnDataField.classes = 'name-column-width';
          columnDataField.headerClasses = 'name-column-width header-cell';
          columns.splice(0, 0, columnDataField);
          continue;
        } else if (question.type === 'LookupValuesQuestion' && columnDataField.dataField.endsWith('_name')) {
          const lookupColumn = defineStringColumn(question);
          // @ts-ignore
          lookupColumn.hidden = true;
          columns.push(lookupColumn);
        }
        columns.push(columnDataField);
      }
      if (question.triggerValues && question.triggerValues.triggerValue) {
        for (const triggerValue of question.triggerValues.triggerValue) {
          if (triggerValue.action.subQuestions) {
            // if a question has subquestions, it should not be editable.
            if (columnDataField) {
              columnDataField.editable = false;
            }
            addColumnTypes(
              columns, triggerValue.action.subQuestions.question, schemaVersion, usersDataSource
            );
          }
        }
      }
    } else if (inVisibleQuestions.indexOf(question.id) !== -1) {
      columnDataField = getColumnDefinition(question, schemaVersion, usersDataSource);
      if (columnDataField) {
        columnDataField.hidden = true;
        columnDataField.editable = false;
        if (question.id === 'modified' || question.id === 'ModifiedTime') {
          columnDataField.dataField = 'modified';
          // columnDataField.dataField.name = 'modified';
          columnDataField.text = getLocalization('lastmodified');
        }
        columns.push(columnDataField);
      }
    }
  }
};

export const getColumnDefinition = (question, schemaVersion, usersDataSource) => {
  let columnDataField;
  switch (question.type) {
    case 'StringQuestion':
    case 'CalculatedValueQuestion':
    case 'NameQuestion':
      columnDataField = defineStringColumn(question);
      break;
    case 'IntQuestion':
    case 'FloatQuestion':
      columnDataField = defineNumericColumn(question, usersDataSource, null, null);
      break;
    case 'TimeQuestion':
    case 'DateQuestion':
      columnDataField = defineDateTimeColumn(question);
      break;
    case 'BooleanQuestion':
      columnDataField = defineBooleanColumn(question);
      break;
    case 'SelectOneQuestion':
    case 'StatusQuestion':
    case 'LikertScaleQuestion':
      const dataSource = getOptionDataSource(question, schemaVersion);
      columnDataField = defineRadioColumn(question, dataSource);
      break;
    case 'SelectMultipleQuestion':
      const multipleSelectDataSource = getOptionDataSource(question, schemaVersion);
      columnDataField = defineCheckboxColumn(question, multipleSelectDataSource);
      break;
    case 'SelectUserQuestion':
    case 'UserQuestion':
      columnDataField = defineCheckboxColumn(question, usersDataSource);
      break;
    case 'LookupValuesQuestion':
      const lookUpValue = question.lookupValue ? question.lookupValue : '';
      const parts = lookUpValue.split(';');
      columnDataField = defineStringColumn(question);
      if (parts.length < 2) {
        columnDataField.dataField = `${question.id}_name`;
      }
      break;
    default:
      columnDataField = undefined;
  }
  return columnDataField;
};

export const getTableHeaderText = (question) => {
  return question.text ? question.text.replace(/(#)*(\**)*/g, '') : question.id;
};
/*
 * Defines a numeric column
 */
export const defineNumericColumn = (question, users, cellRenderer?, renderer?) => {
  if (question.id === 'createdBy') {
    return defineCheckboxColumn(question, users);
  }

  const column = {
    dataField: question.id,
    text: getTableHeaderText(question),
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    formatter: Renderers.getNumericCellRenderer(question),
    sort: true,
    filter: numberFilter({ numberStyle: { width: 'calc(100% - 10px)'}})
  };
  return column;
};

/*
 * Define a date column.
 */
export const defineDateTimeColumn = (question) => {
  const width = question.type === 'DateQuestion' ? 'date-column-width header-cell' : 'default-column-width header-cell';
  const formatter = question.type === 'DateQuestion' ? Renderers.dateCellRenderer :
    Renderers.timeCellRenderer;

  const column = {
    dataField: question.id,
    text: getTableHeaderText(question),
    classes: width ,
    headerClasses: width,
    sort: true,
    formatter: formatter,
    filter: customFilter({ type: FILTER_TYPES.DATE }),
    filterRenderer: (onFilter, column) => {
      return (<DateFilter onFilter={onFilter} column={column} />);
    }
  };

  return column;
};

export const defineStringColumn = (question) => {
  const column = {
    dataField: question.id,
    text: getTableHeaderText(question),
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    sort: true,
    formatter: Renderers.getStringCellRenderer(question),
    filter: textFilter()
  };
  return column;
};

export const defineBooleanColumn = (question) => {
  const options = [
    { value: true, label: 'Yes'},
    { value: false, label: 'No'}
  ];
  const column =  {
    dataField: question.id,
    text: getTableHeaderText(question),
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    options: options,
    sort: true,
    formatter: (cell) => {
      const text = cell === true || cell === 'true' ? 'Yes' : cell === false || cell === 'false' ? 'No' : '';
      return Renderers.cellTemplate({ value: `${text}` });
    },
    filter: customFilter({ type: FILTER_TYPES.MULTISELECT }),
    filterRenderer: (onFilter, column) => {
      return (<SelectFilter onFilter={onFilter} column={column} options={options}/>);
    }// selectFilter({ options: options })
  };
  /**/
  return column;
};

export const defineCheckboxColumn = (question, dataSource) => {
  const filterOptions = {};
  for (const opt of dataSource) {
    filterOptions[opt.value] = opt.label;
  }
  const column = {
    dataField: question.id,
    text: getTableHeaderText(question),
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    sort: true,
    formatter: (cell, row) => {
      if (cell) {
        const values = `${cell}`.split(',').map(v => v.trim());
        const selectedValues = dataSource.filter(
          d => values.indexOf(d.value) !== -1 || values.indexOf(`${d.value}`) !== -1
        );
        if (selectedValues) {
          const renderedText = selectedValues.map(v => v.label).join(',');
          if (renderedText.length > 50) {
            return Renderers.cellTemplate({
              value: (
                <span>
                  {renderedText.substring(0, 50)}
                  <MoreTextComponent value={renderedText} row={row} question={question} />
                </span>
              )
            });
          }
          return Renderers.cellTemplate({ value: `${renderedText}` });
        }
      }
      return Renderers.cellTemplate({ value: `${''}` });
    },
    filter: customFilter({ type: FILTER_TYPES.MULTISELECT }),
    filterRenderer: (onFilter, column) => {
      return (<SelectFilter onFilter={onFilter} column={column} options={dataSource}/>);
    }
    /*filter: multiSelectFilter({
      options: filterOptions
    })
    /*
    /*filtertype: 'checkedlist',
    filteritems: optionsDataSourceAdapter,
    width: 200,
    renderer: headerRenderer,
    cellsrenderer: (row, columnfield, value, defaulthtml, columnProperties) => {
      const data = dataSource.localdata;
      const values = value.split(',');
      const selectedValues = data.filter( d => values.indexOf(d.value) !== -1 || values.indexOf(`${d.value}`) !== -1);
      const renderedText = selectedValues.map(v => v.label).join(',');
      return Renderers.cellTemplate({ value: `${renderedText}` });
    }*/
  };
  return column;
};

export const defineRadioColumn = (question, dataSource) => {
  const column = {
    dataField: question.id,
    text: getTableHeaderText(question),
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    sort: true,
    formatter: (cell, row) => {
      const selectedValue = dataSource.find( option => option.value === cell);
      const renderedText =  selectedValue ? selectedValue.label : '';
      if (renderedText.length > 50) {
        return Renderers.cellTemplate({
          value: (
            <span>
              {renderedText.substring(0, 50)}<MoreTextComponent value={renderedText} row={row} question={question} />
            </span>
          )
        });
      }
      return Renderers.cellTemplate({ value: renderedText });
    },
    filter: customFilter({ type: FILTER_TYPES.MULTISELECT }),
    filterRenderer: (onFilter, column) => {
      return (<SelectFilter onFilter={onFilter} column={column} options={dataSource}/>);
    }
  };
  return column;
};

/*
 * Given a question, this function should return an array with key value for displaying options.
 */
export const getOptionDataSource = (question, schemaVersion) => {
  const options: any = [];
  if (question.listItems) {
    const listItem = question.listItems.listItem;
    for (const li of listItem) {
      if (li.id && schemaVersion >= 2) {
        options.push({ value: li.id, label: li.value });
      } else {
        options.push({ value: li.value, label: li.value });
      }
    }
    return options;
  }
  return null;
};

export const sortTaskColumns = (columns) => {
  const taskColumns: any[] = [];
  let i = 0;
  const visibleFields = [
    'Name', 'questionnaire_id', 'parentRowId', 'taskstatus', 'assignedto', 'task', 'duedate',
    'donedate', 'doneby', 'approveddate', 'toapprove', 'approvedby', 'scheduleddate'
  ];
  columns.push({
    dataField: 'formName',
    text: getLocalization('templateName'),
    classes: 'default-column-width',
    headerClasses: 'default-column-width header-cell',
    sort: true,
    filter: textFilter()
  });
  while (i < columns.length) {
    const column = columns[i];
    switch (column.dataField) {
      case 'Name':
        taskColumns[0] = column;
        taskColumns[0].text = getLocalization('taskName');
        columns[i] = null;
        break;
      case 'formName':
        taskColumns[1] = column;
        columns[i] = null;
        break;
      case 'parentRowId':
        taskColumns[2] = column;
        column.dataField = 'parentName';
        column.text = getLocalization('parentRowId');
        column.filter = textFilter();
        columns[i] = null;
        break;
      case 'taskstatus':
        taskColumns[3] = column;
        columns[i] = null;
        break;
      case 'assignedto':
        taskColumns[4] = column;
        columns[i] = null;
        break;
      case 'task':
        taskColumns[5] = column;
        columns[i] = null;
        break;
      case 'duedate':
        taskColumns[6] = column;
        columns[i] = null;
        break;
      case 'Coordinates':
      case 'assignto':
        column.hidden = true;
        break;
      case 'user_id':
        column.text = getLocalization('editor');
        break;
      default:
        if (visibleFields.indexOf(column.dataField) === -1) {
          column.hidden = true;
        }
    }
    if (visibleFields.indexOf(column.dataField) !== -1) {
      column.hidden = false;
    }
    const ignoreTexts = ['parentName', 'Name', 'user_id', 'modified', 'formName'];
    if (getLocalization(column.dataField) !== `[${column.dataField}]` && ignoreTexts.indexOf(column.dataField) === -1
    ) {
      column.text = getLocalization(column.dataField);
    }
    i++;
  }
  return taskColumns.concat(columns).filter( c => !isNullOrUndefined(c));
};
