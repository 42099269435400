import * as React from 'react';
import './filters.scss';
import 'rc-calendar/assets/index.css';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import bind from 'bind-decorator';
import { LooseObject } from '../../../Interfaces/LooseObject';

interface Props {
  column: LooseObject;
  onFilter: (value) => void;
}

export default class DateFilter extends React.Component<Props, {}> {
  private calendarContainer = React.createRef<HTMLDivElement>();
  constructor(props) {
    super(props);
  }

  @bind
  private getCalendarContainer() {
    return this.calendarContainer.current;
  }

  @bind
  private onChange(value) {
    console.log(value);
    this.props.onFilter(value ? new Date(value) : null);
  }

  /**
   * this function ensures click events from this component do not cause sorting.
   */
  @bind
  private onClick(e) {
    e.stopPropagation();
  }

  public render(): JSX.Element {
    const  calendar = (<Calendar />);
    return (
      <div onClick={this.onClick}>
        <div className="date-picker">
          <DatePicker
            onChange={this.onChange}
            getCalendarContainer={this.getCalendarContainer}
            calendar={calendar}
          >
            {({value}) => {
              return (
                <input
                  className="input-sm form-control"
                  value={value && value.format('DD/MM/YYYY') || ''}
                  onChange={this.onChange}
                />
              );
            }}
          </DatePicker>
        </div>
        <div ref={this.calendarContainer} />
      </div>
    );
  }

}
