import './ImportDataExcelComponent.scss';
import * as React from 'react';
import { getLocalization } from 'global/global';
import { Accordion, Button, Card, Form } from 'react-bootstrap';
import { ExcelImportMappingSelector } from './ExcelImportMappingSelector';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { ExcelData } from 'Interfaces/ImportDataInterfaces';
import { toast } from 'react-toastify';
import { getFormUtils } from 'views/SingleInstance/utils/FormUtilsHolder';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';

interface Props {
  forms: FormInterface[];
  clientPersist: ClientPersistInterface;
  uploadImportExcelFile: (file: File, signal: AbortSignal) => Promise<Response>;
  setExcelData: (excelData: ExcelData | null) => void;
}


const ImportDataExcelComponent = (props: Props) => {

  const fileInput = React.useRef<HTMLInputElement>(null);
  const [file, setFile] = React.useState<File | null>(null);
  const [fileTypeError, setFileTypeError] = React.useState<boolean>(false);
  const abortController = React.useRef<AbortController | null>(null);
  const [excelData, setExcelData] = React.useState<ExcelData | null>(null);
  const [extraQnTypes, setExtraQnTypes] = React.useState<boolean>(false);

  React.useEffect(() => {
    props.setExcelData(excelData);
  }, [excelData]);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const files: FileList = e.target.files;
      let i = 0;
      let f: File;
      while (i < files.length) {
        f = files[i];
        if (!(f.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') && f.name.endsWith('.xlsx'))) {
          setFileTypeError(true);
        } else {
          setFile(f);
        }
        i++;
      }
    }
  };

  const onNext = () => {
    if (abortController !== null && abortController.current) {
      abortController.current.abort();
    }
    abortController.current = new AbortController();
    if (file) {
      props.uploadImportExcelFile(file, abortController?.current?.signal).then(response => response.json())
      .then(json => {
        setExcelData(json);
      }).catch(e => console.log(e));
    }
  };

  const checkMultipleSubFormUse = (formId: string) => {
    const form = props.forms.find(f => f.ref === formId);
    if (form) {
      const subforms: string[] = [];
      const formUtils = getFormUtils(form);
      const subFormQuestionIds = formUtils.getSubFormQuestionIds();
      for (const subformQnId of subFormQuestionIds) {
        const qn = formUtils.getQuestion(subformQnId);
        if (qn.listItems) {
          const ref = qn.listItems.relation[0].ref;
          if (subforms.indexOf(ref) !== -1) {
            return true;
          }
          subforms.push(ref);
        }
      }
    }
    return false;
  };

  const onFormSelect = (formId: string, isChild: boolean, sheetDataIndex: number) => {
    if (excelData) {
      const excelDataTemp = {...excelData};
      let hasMainFormSelected = false;
      // let mainFormId: string | undefined = undefined;
      // let selectedForms: string[] = [];
      for (const sd of excelDataTemp.sheetData) {
        if (sd.isChild === false && sd.formId) {
          hasMainFormSelected = true;
          // mainFormId = sd.formId;
        }
        /* if (sd.formId) {
          selectedForms.push(sd.formId);
        }*/
      }
      if (hasMainFormSelected && !isChild) {
        excelDataTemp.sheetData[sheetDataIndex].formId = '';
        toast('A main form has already been selected', { type: toast.TYPE.ERROR });
        return;
      }
      if (checkMultipleSubFormUse(formId)) {
        toast(getLocalization('excelImportMultipleSameSubformAlert'), { type: toast.TYPE.ERROR });
        return;
      }
      excelDataTemp.sheetData[sheetDataIndex].formId = formId;
      excelDataTemp.sheetData[sheetDataIndex].isChild = isChild;
      setExcelData(excelDataTemp);
    }
  };

  const onQuestionSelected = (
    questionId: string, index: number, sheetDataIndex: number, type: string
  ) => {
    if (excelData) {
      const excelDataTemp = {...excelData};
      excelDataTemp.sheetData[sheetDataIndex].columns[index].questionId = questionId;
      excelDataTemp.sheetData[sheetDataIndex].columns[index].questionType = type;
      setExcelData(excelDataTemp);
    }
  };

  return (
    <div className="excel-import-div">
      <p className="text-info">{getLocalization('excelDataImportMessage')}</p>
      <p className="text-info">{getLocalization('excelImportValuesAlert')}</p>
      <div className="form-group">
        {fileTypeError && (<p className="bg-danger error-alert">Only Excel files are allowed.</p>)}
        <button
          className="btn btn-sm btn-primary"
          onClick={() => fileInput && fileInput.current && fileInput.current.click()}
        >
          {getLocalization('selectImportFile')}
        </button>
        <input
          ref={fileInput}
          type="file"
          name="importFileSelect"
          accept=".xlsx"
          className="d-none"
          onChange={handleFileChange}
        />
        <span id="import-file-name">
          {file && file.name}
        </span>
      </div>
      {excelData && (
        <Form.Group>
          <Form.Check
            type={'checkbox'}
            label={getLocalization('excelImportOtherFieldsCheck')}
            checked={extraQnTypes}
            onChange={(e) => setExtraQnTypes(e.target.checked)}
          />
        </Form.Group>
      )}
      {excelData && (
        <Accordion defaultActiveKey="0">
          {excelData.sheetData.map((sheetData, index) => {
            return (
              <Card key={`import-card-${index}`}>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={`${index}`}
                    className={'toggle-btn'}
                  >
                    {sheetData.sheetName}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={`${index}`}>
                  <Card.Body>
                    <ExcelImportMappingSelector
                      forms={props.forms}
                      columns={sheetData.columns}
                      formId={sheetData.formId}
                      extraQnTypes={extraQnTypes}
                      clientPersist={props.clientPersist}
                      onFormSelect={(formId: string, isChild: boolean) => onFormSelect(formId, isChild, index)}
                      onQuestionSelected={(questionId: string, columnIndex: number, type: string) =>
                        onQuestionSelected(questionId, columnIndex, index, type)
                      }
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      )}
      <Form.Group>
        {!excelData && (
          <Button
            variant="primary"
            size={'sm'}
            onClick={onNext}
          >
            {excelData ? getLocalization('importData') : getLocalization('next')}
          </Button>
        )}
      </Form.Group>
    </div>
  );
};

export default ImportDataExcelComponent;
