import * as React from 'react';
import { connect } from 'react-redux';
import LookupValuesQuestion from '../elements/LookupValuesQuestion';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { Locations } from '../../../Interfaces/LocationInterface';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import FormUtils from '../utils/FormUtils';
import { StateInterface } from '../../../Interfaces/StateInterface';
import { FiltersMenuInterface } from '../../../Interfaces/FiltersMenuInterface';
import { dataPointsLoaded, dataPointUpdated, fetchPOI } from '../../../actions/pois';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { getLocationHierarchyQuerySelector, QueryFiltersInterface } from '../../../reducers/filtersMenuReducer';

export interface Props {
  dataPoint: DataPoint;
  question: LooseObject;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  parentQuestion?: LooseObject;
  parentDataPoint?: DataPoint;
  parentModel?: LooseObject;
  forms: LooseObject[];
  isSubQuestion?: boolean;
  edit: boolean;
}

export interface StateProps {
  clientPersist: ClientPersistInterface;
  locationHierarchy: Locations;
  pois: LooseObject;
  filtersMenu: FiltersMenuInterface;
  locationHierarchyQuery: ReturnType<typeof getLocationHierarchyQuerySelector>;
}

export interface ActionProps {
  fetchPOI: (
    formId: string, signal: AbortSignal, rowId?: number,
    filter?: QueryFiltersInterface[], query?: LooseObject, fields?: string
  ) => Promise<Response>;
  dataPointsLoaded: (dataPoints, formId) => void;
  dataPointUpdated: (dataPoint, formId) => void;
}

export type LookupPropsInterface = Props & StateProps & ActionProps;

const LookupValueQuestionContainer = (props: LookupPropsInterface) => {
  return (
    <LookupValuesQuestion {...props} />
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    clientPersist: state.clientPersist, // used to get filters for POIs
    locationHierarchy: state.locations.collection,
    pois: state.answers,
    filtersMenu: state.filtersMenu,
    locationHierarchyQuery: getLocationHierarchyQuerySelector(state)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    fetchPOI: (
      formId: string, signal: AbortSignal, rowId?: number,
      filter?: QueryFiltersInterface[], query?: LooseObject, fields?: string
    ): Promise<Response> => {
      return dispatch(fetchPOI(formId, signal, rowId, filter, query, fields));
    },
    dataPointsLoaded: (dataPoints, formId) => dispatch(dataPointsLoaded(dataPoints, formId)),
    dataPointUpdated: (dataPoint, formId) => dispatch(dataPointUpdated(dataPoint, formId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LookupValueQuestionContainer);
