import { FeedItem } from '../../Interfaces/FeedItem';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';

export const filterFeed = (feed: FeedItem[], filter: string) => {
  const filteredFeed = feed.filter((feedItem) => {
    return feedItem.name.toLowerCase().search(filter.toLowerCase()) !== -1;
  });
  return filteredFeed;
};

/*
 * We concatenate the filters and if there is any change we reload the feed.
 */
export const filtersChanged = (filtersMenu: FiltersMenuInterface, feedFilters: FiltersMenuInterface) => {
  if (filtersMenu.selectedForms !== feedFilters.selectedForms ||
    filtersMenu.selectedLocations !== feedFilters.selectedLocations ||
    filtersMenu.selectedUsers !== feedFilters.selectedUsers ||
    filtersMenu.selectedDates !== feedFilters.selectedDates) {
      return true;
  }
  return false;
};
