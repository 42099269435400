import { LooseObject } from '../../Interfaces/LooseObject';
import { FiltersMenuInterface } from 'Interfaces/FiltersMenuInterface';

export const getTableFilters = (serverdata) => {
  const filters: LooseObject[] = [];
  if (serverdata.filterscount && serverdata.filterscount > 0) {
    if ((serverdata.filterscount) && serverdata.filterscount > 0) {
      for (const i in serverdata.filterGroups) {
        const filterGroup = serverdata.filterGroups[i];
        let f = {};
        for (const j in filterGroup.filters) {
          const filter = filterGroup.filters[j];
          const temp: LooseObject = {};
          temp.condition = filter.condition;
          temp.field = filter.field;
          temp.type = filter.type;
          temp.value = encodeURIComponent(filter.value);
          if (temp.type === 'datefilter') {
            const timestamp = Date.parse(filter.value);
            const d = new Date(timestamp);
            temp.value = d.toLocaleString();
          }
          if (filter.operator === 'or') {
            temp.operator = 1;
          } else if (filter.operator === 'and') {
            temp.operator = 0;
          } else {
            temp.operator = filter.operator;
          }
          if (temp.field.endsWith('Name') && temp.field.length > 4) {
            temp.field = temp.field.substring(0, temp.field.length - 4);
          }
          if (j === '0') {
            f = Object.assign({}, temp);
          } else {
            f['condition' + ((Number(j)) + 1)] = temp;
          }
        }
        filters.push(f);
      }
    }
  }
  return filters;

  /*let start = -1;
  if (serverdata.recordstartindex === 0) {
    start = 0;
  }*/

};

export const getTableSorts = (serverdata) => {
  const sort: LooseObject = {};
  if ((serverdata.sortdatafield) && (serverdata.sortorder)) {
    sort.sortdatafield = serverdata.sortdatafield;
    sort.sortorder = serverdata.sortorder;
    if (sort.sortdatafield.endsWith('Name') && sort.sortdatafield.length > 4) {
      sort.sortdatafield = sort.sortdatafield.substring(0, sort.sortdatafield.length - 4);
    }
  }
  return sort;
};

/*
 * Should check if filters have changed.
 * If a form has been selected/deselected.
 * If location/user/data filters have changed.
 * if no change in form selection but there is change in other filtres, should return true.
 * if there is a change in form selection, just return true immediately.
 */
export const filtersChanged = (prevFiltersMenu: FiltersMenuInterface, nextFiltersMenu: FiltersMenuInterface) => {
  if (prevFiltersMenu.selectedLocations !== nextFiltersMenu.selectedLocations ||
    prevFiltersMenu.selectedUsers !== nextFiltersMenu.selectedUsers ||
    prevFiltersMenu.selectedDates !== nextFiltersMenu.selectedDates) {
    return true;
  }
  return false;
};
