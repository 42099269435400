import * as React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { BaseModalInterface } from '../../Interfaces/ModalInterface';
import { getLocalization } from '../../global/global';
import { connect } from 'react-redux';
import { StateInterface } from '../../Interfaces/StateInterface';
import bind from 'bind-decorator';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { ThunkDispatch } from 'redux-thunk';
import { updateClientPersist } from '../../actions/clientPersistActions';
import { AnyAction } from 'typescript-fsa';

interface IStateProps {
  userEmail: string;
  userId: ClientPersistInterface['user_id'];
  newDataNotification: boolean;
  updatedDataNotification: boolean;
  updatedCreatedDataNotification: boolean;
}

interface IActionProps {
  actions: {
    updateClientPersist: (clientPersist: Partial<ClientPersistInterface>) => void;
  };
}

interface IOwnState {
  newDataNotification: boolean;
  updatedDataNotification: boolean;
  updatedCreatedDataNotification: boolean;
}

const className = 'EmailNotificationModal';

export type IEmailNotificationModalProps = IStateProps &
    IActionProps & BaseModalInterface;

export class EmailNotificationModalClass extends React.Component<IEmailNotificationModalProps, IOwnState> {
  constructor(props) {
    super(props);
    this.state = {
      newDataNotification: Boolean(props.newDataNotification),
      updatedDataNotification: Boolean(props.updatedDataNotification),
      updatedCreatedDataNotification: Boolean(props.updatedCreatedDataNotification)
    };
  }

  @bind
  private handleChange({target}) {
    const copyState: IOwnState = Object.assign({}, this.state);
    const {name, checked}:
        {
          name: 'newDataNotification' | 'updatedDataNotification' | 'updatedCreatedDataNotification',
          checked: boolean
        } = target;
    copyState[name] = checked;
    this.setState({...copyState});
  }

  @bind
  private onSave() {
    this.props.actions.updateClientPersist({
      new_data_notification: this.state.newDataNotification,
      updated_data_notification: this.state.updatedDataNotification,
      updated_created_data_notification: this.state.updatedCreatedDataNotification
    });
    this.props.onClose();
  }

  private getBody(): JSX.Element {
    return (
        <Form>
          <Form.Check
            id="newDataNotification-checkbox"
            type="checkbox"
            name={'newDataNotification'}
            onChange={this.handleChange}
            checked={this.state.newDataNotification}
            label={getLocalization('emailnotificationcreated')}
          />

          <Form.Check
            id="updatedDataNotification-checkbox"
            type="checkbox"
            name={'updatedDataNotification'}
            onChange={this.handleChange}
            checked={this.state.updatedDataNotification}
            label={getLocalization('emailnotificationupdated')}
          />

          <Form.Check
            id="updatedCreatedDataNotification-checkbox"
            type="checkbox"
            name={'updatedCreatedDataNotification'}
            onChange={this.handleChange}
            checked={this.state.updatedCreatedDataNotification}
            label={getLocalization('emailnotificationupdatedme')}
          />
        </Form>
    );
  }
  public render(): JSX.Element {
    return (
        <Modal
          show={true}
          onHide={this.props.onClose}
          backdrop={true}
          className={className}
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>
              {getLocalization('emailnotification')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.getBody()}
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="light" onClick={this.props.onClose}>
              {getLocalization('cancel')}
            </Button>
            <Button size="sm" variant={'primary'} onClick={this.onSave}>
              {getLocalization('save')}
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }
}

const mapStateToProps = (state: StateInterface): IStateProps => {
  const {
    useremail: userEmail,
    user_id: userId,
    new_data_notification: newDataNotification,
    updated_data_notification: updatedDataNotification,
    updated_created_data_notification: updatedCreatedDataNotification
  } = state.clientPersist;
  return {
    userEmail,
    userId,
    newDataNotification,
    updatedDataNotification,
    updatedCreatedDataNotification
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): IActionProps => {
  return {
    actions: {
      updateClientPersist: (clientPersist: Partial<ClientPersistInterface>) => {
        dispatch(updateClientPersist(clientPersist));
      }
    }
  };
};

export const EmailNotificationModal = connect<
    IStateProps, IActionProps, BaseModalInterface, StateInterface
    >(mapStateToProps, mapDispatchToProps)(EmailNotificationModalClass);
