import '../styles/SelectQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { filter, flatMap, keys } from 'lodash-es';
import { Elements } from './index';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import FormUtils from '../utils/FormUtils';
import { globalWindow } from '../../../global/global';
import { renderQuestions } from '../utils/qnrenderer';
import { pad } from '../../../utils/utils';
import { resetSubQuestionValues } from '../utils/utils';
import QuestionLabel from './QuestionLabel';
import SelectQuestionOption from './extras/SelectQuestionOption';

interface Props {
  dataPoint: DataPoint;
  question: LooseObject;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  isTable: boolean;
  forms: LooseObject[];
  newAnswer: boolean;
  clientPersist: ClientPersistInterface;
  edit: boolean;
  parentModel?: LooseObject;
  parentDataPoint?: LooseObject;
  isSubQuestion?: boolean;
}

interface State {
  value: string[];
  edit: boolean;
  options: LooseObject[];
  inputType: string;
  likertClass: string;
  isTable: boolean;
  version: number;
  showApprove: boolean;
  namePrefix: string; // option name. has to be unique per question. This is here because of tables.
  obj: LooseObject;
}

/*
  This Class implements Select questions i.e Single, Multiple, Status, Liker, Digital Signature & Lookup value Questions
*/
export default class SelectQuestion extends React.Component <Props, State> {

  constructor(props) {
    super(props);
    const { dataPoint, question, formUtils } = this.props;
    let options: LooseObject[] = [];
    const prefix = Math.floor(Math.random() * 10000);
    const namePrefix = `${question.id}-${prefix}`;
    /*
      Set the options to the state.
    */
    if (question.type === 'BooleanQuestion' || question.type === 'DigitalSignatureQuestion') {
      const listItem = [{value: 'Yes', id : 'Yes'}, {value: 'No', id: 'No'}];
      options = listItem;
    } else if (question.type === 'LookupValuesQuestion') {
      const parts = question.lookUpValue.split(';');
      const listItem: LooseObject[] = [];
      let i = 0;
      for (let part of parts) {
        part = parts[i].split(',');
        listItem.push({value: part[0], lookupvalues: parts[i], headers: parts[0]});
        i++;
      }
      options = listItem;
    } else {
      options = question.listItems.listItem;
    }
    const obj = {};
    if (question.script && question.script !== '') {
      const qnId = formUtils.getQuestionId(question.script);
      if (qnId) {
        obj[qnId] = dataPoint[question.id];
      }
    }
    this.state = {
      value: dataPoint[question.id] || dataPoint[question.id] === false ? this.getValue(dataPoint[question.id]) :
        [],
      edit: props.edit,
      options: options,
      inputType: question.type === 'SelectMultipleQuestion' ? 'checkbox' : 'radio',
      // Likert question options should be inline.
      likertClass: question.type === 'LikertScaleQuestion' ? 'radio-inline' : '',
      isTable: props.isTable,
      version: formUtils.getSchemaVersion(),
      showApprove: this.getShowApprove(),
      namePrefix: namePrefix,
      obj: obj
    };
  }

  @bind
  private getValue(value: string | boolean): string[] {
    if (value === true || value === false) {
      return value === true ? ['Yes'] : ['No'];
    }
    return value.split(',').filter( v => v.trim() !== '' );
  }

  /*
    Gets the value of showApprove if it is true or false.
  */
  @bind
  private getShowApprove(): boolean {
    const { dataPoint, question } = this.props;
    if (question.id !== 'taskstatus') {
      return false;
    }
    const { toapprove } = dataPoint;
    if (toapprove) {
      const approvers = filter(toapprove.split(','), (id) => {
        return parseInt(id, 10) === globalWindow.userID;
      });
      if (approvers.length > 0) {
        return true;
      }
    }
    return false;
  }

  @bind
  private getOptionText(value: string): string | undefined {
    const { options } = this.state;
    const opt = options.find(f => f.id === value || f.id === value);
    if (opt) {
      return opt.value;
    }
    return undefined;
  }

  @bind
  private handleChange(e) {
    const { question } = this.props;
    const { inputType } = this.state;
    let value: string[] = Object.assign([], this.state.value);
    let newDataModel = {};
    let option;
    if (inputType === 'radio') {
      if (!e) {
        value = [];
      } else if (e.target.checked || e.target.type === 'select-one') {
        value = [ e.target.value ];
      }
      if (this.state.value && this.state.value.length > 0) {
        option = this.getOptionText(this.state.value[0]);
      }
      // can't unselect all if there is default value
      if (value.length === 0 && question.default) {
        value = [question.default];
      }
    } else {
      if (e.target.checked) {
        value.push(e.target.value);
      } else {
        value = value.filter(v => v !== e.target.value);
        option = this.getOptionText(e.target.value);
      }
    }
    if (option) {
      newDataModel = resetSubQuestionValues(question, option);
    }
    if (question.id === 'scheduleStatus' && value[0] === 'Unscheduled') {
      newDataModel['scheduleDate'] = '';
    }
    this.setState({ value });
    this.updateAnswer(value.join(','), newDataModel);
  }

  /*
    If this is taskstatus question, we set values appropriately based on the following rules.
  */
  private getTaskValues(status: string): LooseObject {
    const value = {} as LooseObject;
    const today = new Date();

    if (status === 'assigned') {
      value['assigndate'] = `${today.getFullYear()}${'-'}${pad(today.getMonth() + 1)}${'-'}${pad(today.getDate())}`;
      value['donedate'] = '';
      value['doneby'] = '';
    } else if (status === 'approved' || status === 'approvedwithcomments') {
      value['approveddate'] = `${today.getFullYear()}${'-'}${pad(today.getMonth() + 1)}${'-'}${pad(today.getDate())}`;
    } else if (status === 'done') {
      value['donedate'] = `${today.getFullYear()}${'-'}${pad(today.getMonth() + 1)}${'-'}${pad(today.getDate())}`;
    }
    return value;
  }

  /**
   * @param value
   * @param subQuestionValues
   */
  @bind
  private updateAnswer(value, subQuestionValues?: LooseObject) {
    const { updateAnswer, question } = this.props;
    let newAns = {};
    if (question.type === 'BooleanQuestion') {
      if (value === 'Yes') {
        value = true;
      } else if (value === 'No') {
        value = false;
      }
      newAns[question.id] = value;
    } else {
      newAns[question.id] = value;
    }
    if (question.id === 'taskstatus') {
      const newValue = this.getTaskValues(value);
      newAns = Object.assign({}, newAns, newValue);
    }
    if (subQuestionValues) {
      newAns = Object.assign({}, newAns, subQuestionValues);
    }
    updateAnswer(newAns);
  }

  @bind
  private getSubQuestions(option: string, value: string): JSX.Element[] | null {
    const {
      question, dataPoint, updateAnswer, formUtils, forms, newAnswer, parentModel, parentDataPoint, clientPersist
    } = this.props;
    const v = this.state.value;
    if (question.triggerValues && question.triggerValues.triggerValue) {
      for (const triggerValue of question.triggerValues.triggerValue) {
        if (triggerValue.value === option) {
          if (question.type === 'DigitalSignatureQuestion') {
            const questions = filter(triggerValue.action.subQuestions.question, (qn) => qn.type !== 'BooleanQuestion');
            return renderQuestions(questions, dataPoint,
              false, forms, updateAnswer, formUtils, newAnswer, clientPersist, undefined, undefined, undefined, true);
          }
          const sqs = renderQuestions(triggerValue.action.subQuestions.question, dataPoint, false, forms, updateAnswer,
            formUtils, newAnswer, clientPersist, parentModel, parentDataPoint, undefined, true,
            v.indexOf(value) === -1
          );
          return sqs;
        } /* reset in data model the questions that are not visible. */
      }
    }
    return null;
  }

  /*
    Checks whether a task option should be visible.
  */
  @bind
  private isTaskOptionVisible(option: string): boolean {
    const { showApprove } = this.state;
    if (!showApprove && option === 'approved' || option === 'approvedwithcomments') {
      return false;
    }
    return true;
  }

  /*
    This function returns the options to be rendered as radio/check boxes.
  */
  @bind
  public getQuestionOptions(): JSX.Element[] {
    const { value, options, version, inputType, likertClass, namePrefix, isTable  } = this.state;
    const { question } = this.props;
    const optionEl: any[] = options.map( opt => {
      if (question.id === 'taskstatus' && !this.isTaskOptionVisible(opt.id)) {
        return null;
      }
      const v = version >= 2 ? opt.id : opt.value;
      const checked = value.indexOf(v) !== -1;
      return (
        <SelectQuestionOption
          key={`${question.id}_${v}_${checked}`}
          option={opt}
          getSubQuestions={this.getSubQuestions}
          checked={checked}
          handleChange={this.handleChange}
          question={question}
          version={version}
          isTable={isTable}
          inputType={inputType}
          likertClass={likertClass}
          namePrefix={namePrefix}
          edit={this.props.edit}
        />
      );
    });
    return optionEl;
  }

  /*
    This function returns a dropdown for the options.
    When we have single select with 10 or more options or 3 or more options for tables this is rendered
  */
  @bind
  public getDropDownSelect(): JSX.Element {
    const { value, options, version } = this.state;
    const { question, clientPersist } = this.props;
    // US-3524
    const enumDisableActionStatusQn = question.id === 'actionStatus' && clientPersist.roles.includes('enumerator');
    const disabledOptions = ['agreed', 'confirmed', 'cancelled'];
    let option;
    const optionsEl = [
      (<option value="" key={`${question.id}_selectone}`}>Select one</option>)
    ].concat(options.map( opt => {
      const v = version >= 2 ? opt.id : opt.value;
      if (value.indexOf(v) !== -1) {
        option = opt.value;
      }
      return (
        <option
          value={v}
          key={`${question.id}_${v}`}
          disabled={enumDisableActionStatusQn && disabledOptions.indexOf(v.toLowerCase()) !== -1}
        >
          {opt.value}
        </option>
      );
    }));
    return (
      <React.Fragment>
        <select
          onChange={this.handleChange}
          name={question.id}
          className="form-control"
          disabled={!this.props.edit}
          value={value.length > 0 ? value[0] : undefined}
        >
          {optionsEl}
        </select>
        {option && value.length > 0 && this.getSubQuestions(value[0], option)}
      </React.Fragment>
    );
  }

  /*
    This function returns the image/video attached to this question.
  */
  @bind
  private getMediaView(question: LooseObject): JSX.Element | null {
    if (question.mediaId && question.mimeType) {
      const ids = question.mediaId.split(',');
      const mimeTypes = question.mimeType.split(',');
      const mediaViews = ids.map((id, index) => {
        if (mimeTypes[index].indexOf('image') !== -1) {
          return (
            <div className="row col-md-12">
              <img src={`/atool/Sync?action=optionMedia&MediaId=${id}`} alt="" className="option-media"/>
            </div>
          );
        } else if (mimeTypes[index].indexOf('video') !== -1) {
          return (
            <div className="row col-md-12">
              <video
                controls={true}
                src={`/atool/Sync?action=optionMedia&MediaId=${id}`}
                className="option-media"
              />
            </div>
          );
        } else if (mimeTypes[index].indexOf('audio') !== -1) {
          return (
            <div className="row col-md-12">
              <audio controls={true} src={`/atool/Sync?action=optionMedia&MediaId=${id}`} className="option-media">
                Your browser does not support the <code>audio</code> element.
              </audio>
            </div>
          );
        }
        return null;
      });
      return mediaViews;
    }
    return null;
  }

  public static getDerivedStateFromProps(props: Props, state: State) {
    const { dataPoint, question, formUtils } = props;
    const { obj, value } = state;

    let newState: any = null;
    if (question.script && question.script !== '') {
      newState = filterOptions(dataPoint, question, formUtils, obj, value);
    }

    if (question.type === 'BooleanQuestion' || question.type === 'DigitalSignatureQuestion') {
      if (dataPoint[question.id] === true || dataPoint[question.id] === false) {
        const value = dataPoint[question.id] ? 'Yes' : 'No';
        if (value !== state.value.join(',')) {
          newState = newState || {};
          newState.value =  [value];
        }
      }
    } else if (dataPoint[question.id] && dataPoint[question.id] !== state.value.join(',')) {
      newState = newState || {};
      newState.value =  dataPoint[question.id].split(',').filter( v => v.trim() !== '' );
    }
    return newState;
  }

  public shouldComponentUpdate(nextProps, nextState) {
/*    return this.state.value !== nextState.value || this.state.edit !== nextState.edit
      || nextProps.dataPoint.validate !== this.props.dataPoint.validate;*/
    return true;
  }

  /*
    renders include file.
  */
  @bind
  private renderIncludeFile(): JSX.Element[] | undefined {
    const { question, dataPoint, updateAnswer } = this.props;
    if (question.includeFile) {
      const types = question.accept.split(',');
      const fileInputs = types.map((type, index) => {
        return (
          <Elements.FileQuestion
            key={`${question.id}-file-${index}`}
            accept={type}
            question={question}
            dataPoint={dataPoint}
            updateAnswer={updateAnswer}
          />
        );
      });
      return fileInputs;
    }
    return undefined;
  }

  public render(): JSX.Element {
    const { options, isTable, inputType } = this.state;
    const { question, formUtils, dataPoint, isSubQuestion } = this.props;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    const hasError = required && dataPoint.validate && this.state.value.length === 0 ? 'has-error' : '';
    return (
      <div className={`${'form-group'} ${hasError}  ${!isSubQuestion && formUtils.getResponsiveView(question)}`}>
        {this.getMediaView(question)}
        <QuestionLabel question={question} dataPoint={dataPoint} formUtils={formUtils}>
          {required}
        </QuestionLabel>
        {inputType === 'radio' && (options.length > 9 || (options.length > 3 && isTable)) ?
          this.getDropDownSelect() : this.getQuestionOptions()}
        {this.renderIncludeFile()}
      </div>
    );
  }
}

export const filterOptions  = (dataPoint: DataPoint, question, formUtils: FormUtils, obj, currentValue) => {
  const options = [...question.listItems.listItem];
  let newState: any = null;
  const  key = keys(obj);
  for (const k of key) {
    if (dataPoint[k] !== obj[k]) {
      const newValues = dataPoint[k];
      if (newValues) {
        const qn = formUtils.getQuestion(k);
        const ids = newValues.split(',').map( v => v.trim());
        const selectedOptions = qn.listItems && qn.listItems.listItem.filter((opt) => {
          return ids.indexOf(opt.id) !== -1; //  || ids.indexOf(opt.value) !== -1;
        });
        const optionCodes = flatMap(selectedOptions, (opt) => {
          return opt.code;
        });
        if (optionCodes.length > 0) {
          newState = newState || {};
          newState.options = options.splice(options.length).concat(filter(question.listItems.listItem, (opt) => {
            return optionCodes.indexOf(opt.code) !== -1;
          }));
          const values = flatMap(newState.options, (opt) => {
            return opt.id;
          });
          newState.value = values.filter(v => {
            return currentValue.indexOf(v) !== -1;
          });
        }
      }
    }
  }
  return newState;
};
