import { IMPORT } from '../actions/actionTypes';

const importMappings = (state = null , action) => {
  switch (action.type) {
    case IMPORT.LOADED:
      if (action.importMappings) {
        return Object.assign({}, action.importMappings);
      }
      return state;
    default:
      return state;
  }
};

export default importMappings;
