import { globalWindow } from '../global/global';

export function getRequestConfig(headers?) {
  const config = {
    headers: {
      'Accept' : 'application/json',
      'Content-Type': 'application/json',
      'userid': globalWindow.userName,
      'key': globalWindow.pwrd
    }
  };
  if (headers) {
    config.headers = Object.assign({}, config.headers, headers);
  }
  return config;
}
