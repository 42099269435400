import { ExcelData } from 'Interfaces/ImportDataInterfaces';
import { getRequestConfig } from './index';

export const doUploadMapImport = (groupId, file, name, srs) => {
  const headers = getRequestConfig();
  const data = {...headers};
  const formData = new FormData();
  formData.append('file', file);
  formData.append('groupID', groupId);
  formData.append('name', name);
  formData.append('s_srs', srs);
  delete data.headers['Content-Type']; //  = 'multipart/form-data';

  data['method'] = 'POST';
  data['body'] = formData;
  return fetch(`/json/app/uploadDiagram/null/null/${groupId}`, data);
};

export const doLoadImportMappings = (formIds: string[]) => {
  const headers = getRequestConfig();
  const data = {...headers};
  data['method'] = 'POST';
  data['body'] = JSON.stringify({ poiIdentifier: formIds });
  return fetch(`/json/app/import/mapping`, data);
};

export const  doUploadDataImport = (groupId, userId, file, form, mappings, geocode) => {
  const headers = getRequestConfig();
  const data = {...headers};
  const formData = new FormData();
  formData.append('file', file);
  formData.append('mappings', JSON.stringify(mappings));
  formData.append('customTable', form.answerTable);
  delete data.headers['Content-Type']; // = 'multipart/form-data';

  data['method'] = 'POST';
  data['body'] = formData;
  return fetch(`/json/app/import/${form.id}/${groupId}/${userId}/${geocode}/${form.ref}`, data);
};

export const doUploadImportExcelFile = (file: File, signal: AbortSignal) => {
  const formData = new FormData();
  formData.append('file', file);

  const headers = getRequestConfig();
  const data = {...headers};
  delete data.headers['Content-Type']; // = 'multipart/form-data';
  data['method'] = 'POST';
  data['body'] = formData;
  data['signal'] = signal;
  return fetch(`/json/app/import/processExcelImportFile`, data);
};

export const doImportExcelData = (excelData: ExcelData) => {
  const headers = getRequestConfig();
  return fetch(`/json/app/import/excelDataImport`, {...headers, method: 'POST', body: JSON.stringify(excelData)});
};
