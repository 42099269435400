import { getRequestConfig } from './index';

export const doGetReports = (userId) => {
  const headers = getRequestConfig();
  return fetch(`/json/app/getreports/${userId}`, headers);
};

export const doSaveReports = (reportName: string, parameters: string, reportId: string) => {
  const headers = getRequestConfig();
  const requestParams = {
    report_name : reportName,
    parameters : parameters,
    report_id : reportId
  };
  const newConfig = Object.assign({ body: JSON.stringify(requestParams), method: 'POST' }, headers);
  return fetch(`/json/app/savereport`, newConfig);
};

export const doDeleteReport = (reportId: string) => {
  const headers = getRequestConfig();
  const newConfig = Object.assign({ method: 'DELETE' }, headers);
  return fetch(`/json/app/deletereport/${reportId}`, newConfig);
};
