import * as React from 'react';
import bind from 'bind-decorator';
import '../styles/IntQuestion.scss';
import FormUtils from '../utils/FormUtils';
import { DataPoint } from '../../../Interfaces/DataPoint';
import TextInputComponent from './TextInputComponent';
import { LooseObject } from '../../../Interfaces/LooseObject';
import QuestionLabel from './QuestionLabel';
import { validateNumericQuestion } from '../utils/Validator';

interface State {
  question: LooseObject;
  value: null | number | string;
  edit: boolean;
  hasError: boolean;
  extraAttrs: LooseObject;
}

interface Props {
  question: LooseObject;
  dataPoint: DataPoint;
  edit: boolean;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  isSubQuestion?: boolean;
}

export default class IntQuestion extends React.Component <Props, State> {
  private input = React.createRef<TextInputComponent>();
  constructor(props) {
    super(props);
    const { dataPoint, question } = this.props;
    const extraAttrs = { step: '1' };
    if (question.type === 'FloatQuestion') {
      extraAttrs.step = '.000001';
    }

    this.state = {
      extraAttrs: extraAttrs,
      value: dataPoint[question.id] ? dataPoint[question.id] : question.default ? question.default : '',
      question: question,
      edit: props.edit,
      hasError: false
    };
    this.input = React.createRef();
  }

  @bind
  private handleChange(value: any) {
    if (this.input.current) {
      if (this.input.current.checkValidity()) {
        const { question } = this.state;
        const { updateAnswer } = this.props;
        const hasError = false;
        if (`${value}`.indexOf('.') !== -1 && question.type === 'IntQuestion') {
          value = `${value}`.substring(0, `${value}`.indexOf('.'));
        }
        this.setState({ value, hasError });
        if (updateAnswer) {
          const newAns = {};
          newAns[question.id] = Number(value);
          updateAnswer(newAns);
        }
      } else {
        this.setState({ hasError : true });
      }
    }
  }

  public static getDerivedStateFromProps(props: Props, state: State) {
    const { question, dataPoint } = props;
    const { value } = state;
    if (dataPoint['validate'] && ((!question.optional && value === '') ||
      validateNumericQuestion(question, dataPoint[question.id]))) {
      return { hasError: true };
    }
    return null;
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value || this.state.edit !== nextState.edit
      || nextProps.dataPoint.validate !== this.props.dataPoint.validate
      || this.state.hasError !== nextState.hasError;
  }

  public render(): JSX.Element {
    const { question, extraAttrs, hasError } = this.state;
    const { formUtils, dataPoint, isSubQuestion } = this.props;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    const hasErrorClass = (required && dataPoint.validate && this.state.value === '') || hasError ? 'has-error' : '';
    const className =
      `form-group numeric-question ${hasErrorClass}` +
      `${!isSubQuestion && formUtils.getResponsiveView(question)}`;
    return (
      <div className={className}>
        <QuestionLabel question={question} dataPoint={dataPoint} formUtils={formUtils}>
          {required}
        </QuestionLabel>
        <TextInputComponent
          type="number"
          name={question.id}
          extraAttrs={extraAttrs}
          value={this.state.value || ''}
          disabled={!this.state.edit}
          ref={this.input}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
