import { GALLERY } from '../actions/actionTypes';
import { GalleryMenuInterface } from '../Interfaces/GalleryMenuInterface';
import ReducerBuilder from './ReducerBuilder';
import { FormInterface } from '../Interfaces/Forms/FormsInterface';
import { Action } from 'typescript-fsa';
import { StateInterface } from '../Interfaces/StateInterface';
import { createSelector } from 'reselect';
import { AnswersInterface } from 'Interfaces/Answers/AnswersInterface';
import { answersSelector } from './answers';
import { DataPoint } from 'Interfaces/DataPoint';

const INITIAL_STATE: GalleryMenuInterface = {
  selectedForm: undefined
};
export type PayloadType = FormInterface['ref'];

const selectForm = (state: GalleryMenuInterface, {payload}): GalleryMenuInterface => {
  return {...state, selectedForm: payload};
};

const galleryMenuReducer = new ReducerBuilder<GalleryMenuInterface, Action<PayloadType>>()
    .setInitialState(INITIAL_STATE)
    .addReducer(GALLERY.SELECT_FORM, selectForm)
    .build();

export default galleryMenuReducer;

// Selectors
export const galleryMenuSelector = (state: StateInterface): GalleryMenuInterface => state.galleryMenu;

export const selectAnswersForGallerySelectedForm = createSelector<
    StateInterface,
    AnswersInterface,
    GalleryMenuInterface,
    DataPoint[]
    >([
        answersSelector, galleryMenuSelector
    ], (answers, {selectedForm}) => {
      if (!selectedForm || !answers[selectedForm]) {
        return [];
      }
      return answers[selectedForm];
});
