import * as React from 'react';
import bind from 'bind-decorator';
import { LooseObject } from '../../../Interfaces/LooseObject';

interface Props {
  value: string | number;
  question: LooseObject;
  onUpdate: (value?: number | string) => void;
}

interface State {
  value: string | number;
}

export default class NumericEditor extends React.Component<Props, State> {
  private input = React.createRef<HTMLInputElement>();
  constructor(props) {
    super(props);
    const value = props.value || props.value === 0 ? props.value : '';
    this.state = {
      value: value
    };
  }

  @bind
  private handleChange(e) {
    if (e.target.value !== '') {
      this.setState({ value: Number(e.targe.value) });
    } else {
      this.setState({ value: e.targe.value });
    }
  }

  @bind
  private getValue() {
    if (this.props.value !== '') {
      return this.props.onUpdate(this.state.value);
    } else {
      return this.props.onUpdate();
    }
  }

  public componentDidMount() {
    this.input.current?.focus();
  }

  public render(): JSX.Element {
    const { value } = this.state;
    return (
      <div>
        <input
          ref={this.input}
          type="number"
          value={value}
          onChange={this.handleChange}
          onBlur={this.getValue}
          className={`form-control form-control-sm`}
        />
      </div>
    );
  }
}
