import * as React from 'react';
import App from './App';
import { Route, Switch } from 'react-router';
import LoginApp from './LoginApp';
import helpPaths from './views/Header/helpPaths';
import { ModuleAreaContainer } from './views/ModuleSelection/ModuleAreaContainer';
import { FiltersMenuContainer } from './views/Filters/FiltersMenuContainer';
import { ModuleSelectionContainer } from './views/ModuleSelection/ModuleSelectionContainer';
import SingleInstance from './views/SingleInstance/Containers/SingleInstance';
import CreatePasswordApp from './views/CreatePassword';

export const Routes = (): JSX.Element => {
  return (
      <Switch>
        <Route path={'/login.jsp'} component={LoginApp} />
        <Route
            exact={true}
            path={'/index.jsp#:formRef/:answerId/:rowId/:isNewAnswer'}
        >
          <App showHeader={false}>
            <SingleInstance/>
          </App>
        </Route>
        <Route path={['/index.jsp', '/']} exact={true}>
          <App showHeader={true}>
            <ModuleSelectionContainer />
            <FiltersMenuContainer/>
            <ModuleAreaContainer/>
          </App>
        </Route>
        <Route path={helpPaths} component={App} />
        <Route path={'/createPassword.jsp'} component={CreatePasswordApp} />
      </Switch>
  );
};
