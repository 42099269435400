import * as React from 'react';
import { connect } from 'react-redux';
import AddressQuestion from '../elements/AddressQuestion';
import { StateInterface } from '../../../Interfaces/StateInterface';
import { geocodeAddress } from '../../../actions/reverseGeoCode';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import FormUtils from '../utils/FormUtils';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import { Locations } from '../../../Interfaces/LocationInterface';

interface Props {
  question: LooseObject;
  dataPoint: DataPoint;
  edit: boolean;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  isSubQuestion?: boolean;
  type?: string;
}

interface ActionProps {
  geocodeAddress: (address: string, signal: AbortSignal) => Promise<Response>;
}

interface StateProps {
  locations: Locations;
  clientPersist: ClientPersistInterface;
}

export type AddressQuestionProps = Props & ActionProps & StateProps;
const AddressQuestionContainer = (props: AddressQuestionProps) => {
  return (
    <AddressQuestion {...props} />
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    clientPersist: state.clientPersist,
    locations: state.locations.collection
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    geocodeAddress: (address: string, signal: AbortSignal) => {
      return dispatch(geocodeAddress(address, signal));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressQuestionContainer);
