import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, ListGroup } from 'react-bootstrap';
import React from 'react';
import { scheduleFormAnswersSelector } from '../../reducers/scheduleAnswersReducer';
import { SchedulesInterface } from '../../Interfaces/ScheduleInterface';
import { fetchSchedulePOIs } from '../../actions/scheduleActions';
import { Draggable as FCDraggable } from '@fullcalendar/interaction';
import {
  filtersMenuSelectedDatesSelector,
  filtersMenuSelectedLocationsSelector
} from '../../reducers/filtersMenuReducer';

interface IExternalProps {
  baseClassName: string;
  selectedForm: FormInterface;
  schedules?: SchedulesInterface;
}

export const ScheduleAnswersList: FunctionComponent<IExternalProps> = (props) => {
  const dispatch = useDispatch();
  const {baseClassName, schedules, selectedForm} = props;
  const formAnswersSelector = scheduleFormAnswersSelector(selectedForm.ref);
  const answers = useSelector(formAnswersSelector);
  const selectedLocations = useSelector(filtersMenuSelectedLocationsSelector);
  const selectedDates = useSelector(filtersMenuSelectedDatesSelector);
  useEffect(() => {
    dispatch(fetchSchedulePOIs(selectedForm.ref));
  }, [selectedForm.ref, selectedLocations, selectedDates]);

  useEffect(() => {
    const draggableEl = document.getElementsByClassName(`${baseClassName}__list`)[0] as HTMLElement;
    if (draggableEl) {
      // @ts-ignore
      const fcDraggable = new FCDraggable(draggableEl, {
        itemSelector: '.list-group-item',
        eventData: function(eventEl) {
          const title = eventEl.getAttribute('title');
          const id = eventEl.getAttribute('data-id');
          return {
            title: title,
            id: id
          };
        }
      });
    }
  }, []);

  return (
      <ListGroup bsPrefix={`${baseClassName}__list`} id="schedule-external-events">
        {answers && answers.map((answer) => {
          const assigned = schedules?.find((schedule) => schedule.answerId === answer.id);
          return (
              <ListGroup.Item
                  as={Badge}
                  variant={assigned ? 'success' : 'secondary'}
                  title={answer.Name}
                  data-id={answer.id}
                  key={answer.id}
              >
                {answer.Name}
              </ListGroup.Item>
          );
        })}
      </ListGroup>
  );
};
