
import * as React from 'react';
import { ConfirmInterface } from '../../Interfaces/ConfirmInterface';
import { StateInterface } from '../../Interfaces/StateInterface';
import { showConfirm } from '../../actions/';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import bind from 'bind-decorator';
import { Button, Modal } from 'react-bootstrap';

interface IActionProps {
  showConfirm: (data: ConfirmInterface) => void;
  dispatch: Dispatch;
}

interface IOwnState {
  isOpened: boolean;
}

export type ConfirmPropsInterface = ConfirmInterface & IActionProps;

export class ConfirmContainerClass extends React.Component<ConfirmPropsInterface, IOwnState> {

  constructor(props) {
    super(props);
    this.state = { isOpened : props.visible };
  }

  @bind
  private onConfirm() {
    const { onConfirm, showConfirm } = this.props;
    if (onConfirm) {
      onConfirm();
    }
    showConfirm({ visible : false });
  }

  @bind
  private onClose() {
    const { showConfirm } = this.props;
    showConfirm({ visible : false });
  }

  public static getDerivedStateFromProps(props, state) {
    if (props.visible === true && !state.isOpened) {
      return { isOpened : props.visible };
    } else if (!props.visible) {
      return { isOpened : props.visible };
    }
    return null;
  }

  public render() {
    const messageDiv = this.props.message ? (<p className="">{this.props.message}</p>) : null;
    return (
        <Modal show={this.state.isOpened} backdrop="static" onHide={this.onClose}>
          <Modal.Header>
            <Modal.Title>{'info'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {messageDiv}
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant={'primary'} onClick={this.onClose}>{'Cancel'}</Button>
            <Button size="sm" variant={'primary'} onClick={this.onConfirm}>{'OK'}</Button>
          </Modal.Footer>
        </Modal>
    );
  }
}

const mapStateToProps = (state: StateInterface): ConfirmInterface => {
  return {
    visible: state.confirm.visible,
    message: state.confirm.message,
    onConfirm: state.confirm.onConfirm
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IActionProps => {
  return {
    showConfirm:(data: ConfirmInterface) => {
      dispatch(showConfirm(data));
    },
    dispatch: dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmContainerClass);
