import { getLocalization } from 'global/global';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { QuestionInterface, questionTypes } from 'Interfaces/Forms/QuestionInterface';
import { Column } from 'Interfaces/ImportDataInterfaces';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { getFormUtils } from 'views/SingleInstance/utils/FormUtilsHolder';

interface Props {
  forms: FormInterface[];
  columns: Column[];
  extraQnTypes: boolean;
  clientPersist: ClientPersistInterface;
  onFormSelect: (formId: string, isChild: boolean) => void;
  onQuestionSelected: (questionId: string, index: number, questionType: string) => void;
  formId?: string;
}

const allowedQuestions = [
  'IntQuestion', 'StringQuestion', 'FloatQuestion', 'NameQuestion', 'SelectMultipleQuestion',
  'SelectOneQuestion', 'BooleanQuestion', 'DateQuestion', 'StatusQuestion'
];

const extraQnTypes = [
  'CalculatedValueQuestion', 'LookupValuesQuestion', 'UserQuestion'
];

export const ExcelImportMappingSelector = (props: Props) => {

  const [selections, setSelections] = React.useState<(string | undefined)[]>([]);
  const [questions, setQuestions] = React.useState<Pick<QuestionInterface, 'id' | 'type' | 'text'>[]>([]);

  const onQuestionSelected = (value: string, index: number, type: string) => {
    console.log(type);
    props.onQuestionSelected(value, index, type);
    const sel = [...selections];
    sel[index] = value;
    setSelections(sel);
  };

  React.useEffect(() => {
    const sels = props.columns.map((col) => col.questionId);
    setSelections(sels);
  }, [props.columns]);

  React.useEffect(() => {
    if (props.formId) {
      const form = props.forms.find(f => f.ref === props.formId);
      if (form) {
        const formUtils = getFormUtils(form);
        const qnMap = formUtils.getQuestions();
        const qns: Pick<QuestionInterface, 'id' | 'type' | 'text'>[] = [];
        if (form.isChild) {
          qns.push({
            id: 'parentName',
            type: questionTypes.NAME_QUESTION,
            text: 'Parent name'
          });
        }
        for (const key in qnMap) {
          const qn = qnMap[key];
          if (
            allowedQuestions.indexOf(qn.type) !== -1 || (props.extraQnTypes && extraQnTypes.indexOf(qn.type) !== -1)
          ) {
            qns.push({ id: qn.id, type: qn.type, text: qn.text });
          }
          if (qn.type === 'GPSQuestion' && form.hasCoordinates) {
            qns.push({ id: 'Lat', type: questionTypes.GPS_QUESTION, text: 'Latitude' });
            qns.push({ id: 'Lon', type: questionTypes.GPS_QUESTION, text: 'Longitude' });
          }
        }

        if (form.hasLocationHierarchy === true) {
          const { clientPersist } = props;
          const labels = clientPersist.locationLabels.length > 0 ?
            clientPersist.locationLabels : ['Location 1', 'Location 2', 'Location 3', 'Location 4'];
          labels.forEach((label, index) => {
            qns.push({ id: `location${index + 1}`, type: questionTypes.STRING_QUESTION, text: label });
          });
        }
        setSelections([]);
        setQuestions(qns);
        return;
      }
    }
    setQuestions([]);
    setSelections([]);
  }, [props.formId, props.extraQnTypes]);

  const onFormSelection = (e) => {
    const form = props.forms.find(f => f.ref === e.target.value);
    props.onFormSelect(e.target.value, form ? form.isChild : false);
    console.log(e.target.value);
  };

  return (
    <React.Fragment>
      <Form.Group>
        <Form.Label>{getLocalization('selectform')}</Form.Label>
        <Form.Control
          as="select"
          onChange={onFormSelection}
          value={props.formId}
        >
          <option value={''} >{getLocalization('selectform')}</option>
          {props.forms.map(f => (
            <option value={f.ref} key={`import-selector-${f.ref}`}>
              {`${f.name} ${f.isClone ? ' - (clone) ' : ''}`}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <div className="container-fluid import-questions">
        {props.columns.map((col, index) => {
          return (
            <div className="row" key={`${index}`}>
              <div className="col-md-1">{(index + 1)}</div>
              <div className="col-md-5">
                {col.name}
              </div>
              <div className="col-md-6 form-group">
                <select
                  className="form-control"
                  onChange={(e) => {
                    const type = e.target.options[e.target.selectedIndex].dataset.type || '';
                    onQuestionSelected(e.target.value, index, type);
                  }}
                  value={props.columns[index] ? props.columns[index].questionId || '' : ''}
                >
                  <option>{''}</option>
                  {questions.map((q, ind) => {
                    return selections.indexOf(q.id) === -1 || selections[index] === q.id ?
                      (
                      <option
                        value={q.id}
                        key={`import-${index}-${ind}`}
                        data-type={q.type}
                      >
                        {q.text}
                      </option>
                    ) : null;
                  })}
                </select>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};
