import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './custom.scss';
import { configureStore, StoreInterface } from './store';
import { createBrowserHistory, History } from 'history';
import { Provider } from 'react-redux';
import { Routes } from './routes';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { Persistor } from 'redux-persist/es/types';
import { PersistGate } from 'redux-persist/integration/react';
import { SIApp } from './views/SingleInstance';
import { ConnectedRouter } from 'connected-react-router';

const App = (store: StoreInterface, persistor: Persistor, history: History) => ({
  async render() {
    ReactDOM.render(
        (
            <CookiesProvider>
              <BrowserRouter>
                <Provider store={store}>
                  {/*<Router history={history}>*/}
                  {/*<Routes/>*/}
                  {/*</Router>*/}
                  <ConnectedRouter history={history}>
                    <PersistGate loading={null} persistor={persistor}>
                      <Routes />
                    </PersistGate>
                  </ConnectedRouter>
                </Provider>
              </BrowserRouter>
            </CookiesProvider>
        ),
        document.getElementById('app')
    );
  }
});

const initialize = (): Persistor => {
  // prepare store
  const history = createBrowserHistory();
  const storeValues = configureStore(history);
  window['ReactApp'] = App(storeValues.store, storeValues.persistedStore, history);
  window['SIApp'] = SIApp(storeValues.store);
  const app = window['ReactApp'];
  app.render();
  return storeValues.persistedStore;
};

export const persistedStore = initialize();
