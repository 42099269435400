import { FILTERS_MENU } from './actionTypes';
import { actionCreator } from './';
import {
  DateFilters,
  filtersSelectedFormType,
  filtersSelectedLocationType,
  filtersSelectedUsers
} from '../Interfaces/FiltersMenuInterface';
import { formsForFiltersSelector } from '../reducers/formsReducer';
import { rootLocationsSelector } from '../reducers/locationsReducer';
import { fetchFormHierarchy } from './formsActions';

export const toggleFilterMenu = actionCreator<boolean>(FILTERS_MENU.TOGGLE);

export const resizeFilterMenu = actionCreator<number>(FILTERS_MENU.RESIZE);

export const selectFormAction = actionCreator<filtersSelectedFormType>(FILTERS_MENU.SELECT_FORM);

export const unSelectForm = actionCreator<filtersSelectedFormType>(FILTERS_MENU.UNSELECT_FORM);

export const selectAllForms = actionCreator<filtersSelectedFormType[]>(FILTERS_MENU.SELECT_ALL_FORMS);

export const unSelectAllForms = actionCreator<filtersSelectedFormType[]>(FILTERS_MENU.UNSELECT_ALL_FORMS);

export const selectForm = (selectedForm: filtersSelectedFormType) => {
  return (dispatch, getState) => {
    dispatch(selectFormAction(selectedForm));
    dispatch(fetchFormHierarchy(selectedForm));
  };
};

export const selectUnselectAllForms = (task: 'selectAll' | 'unSelectAll') => {
  return (dispatch, getState) => {
    const selectableForms = formsForFiltersSelector(getState());
    if (task === 'selectAll') {
      const selectedForms = selectableForms.map((form): filtersSelectedFormType => ({
        ref: form.ref,
        name: form.name,
        id: form.id
      }));
      dispatch(selectAllForms(selectedForms));
      for (const form of selectableForms) {
        dispatch(fetchFormHierarchy(form));
      }
    } else if (task === 'unSelectAll') {
      dispatch(unSelectAllForms([]));
    }
  };
};

export const selectUnSelectLocations = actionCreator<
    filtersSelectedLocationType[]
    >(FILTERS_MENU.SELECT_UNSELECT_LOCATIONS);

export const selectAllLocations = actionCreator<filtersSelectedLocationType[]>(FILTERS_MENU.SELECT_ALL_LOCATIONS);

export const unSelectAllLocations = actionCreator<filtersSelectedLocationType[]>(FILTERS_MENU.UNSELECT_ALL_LOCATIONS);

export const selectUnselectAllLocations = (task: 'selectAll' | 'unSelectAll') => {
  return (dispatch, getState) => {
    const rootLocations = rootLocationsSelector(getState());
    if (task === 'selectAll') {
      const selectedLocations = rootLocations.map((location): filtersSelectedLocationType => {
        const returnValue = {
          key: location.key,
          title: location.title
        };
        if (location.level) {
          return {
            ...returnValue,
            level: location.level
          };
        }
        return returnValue;
      });
      dispatch(selectAllLocations(selectedLocations));
    } else if (task === 'unSelectAll') {
      dispatch(unSelectAllLocations([]));
    }
  };
};

export const selectUnSelectUsers = actionCreator<filtersSelectedUsers[]>(FILTERS_MENU.SELECT_UNSELECT_USERS);

export const setSelectedDates = actionCreator<DateFilters>(FILTERS_MENU.SET_SELECTED_DATES);
