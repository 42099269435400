import { doGetFileUrl, doRotateImage } from '../api/file';

export const getFileUrl = (fileId: string, callBack: (url: string) => void) => {
  return (dispatch, getState) => {
    const responsePromise = doGetFileUrl(fileId);
    responsePromise.then(response => response.text()).then(data => {
      console.log(data);
      if (callBack) {
        callBack(data);
      }
    });
  };
};

export const rotateImage = (angle: string, fileId: number, callBack: (id: number) => void) => {
  return (dispatch, getState) => {
    const responsePromise = doRotateImage(angle, fileId);
    responsePromise.then(response => response.json()).then(data => {
      if (callBack && data.status === 'OK') {
        callBack(fileId);
      }
    });
  };
};
